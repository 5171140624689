import Tooltip from "src/components/Tooltip";

const LabeledInput = ({ label, value, setValue, placeholder, name, required = true, type = 'text' ,pattern, tooltip}) => {
  
  return (
    <div className="form-group">
      <label>{label}</label>
      {tooltip && <Tooltip originalTitle={tooltip} /> }
      <input
        type={type}
        className="form-control"
        name={name}
        placeholder={placeholder}
        onChange={({ target: { value } }) => setValue(value)}
        value={value}
        required={required}
        pattern={pattern}
      />
    </div>
  );
};

export default LabeledInput;