export const CHARTS_JS_COLORS = {
  sent: {
    borderColor: 'rgb(255, 193, 0)',
    backgroundColor: 'rgba(255, 193, 0, 0.8)',
  },
  delivered: {
    borderColor: 'rgb(87, 182, 87)',
    backgroundColor: 'rgba(87, 182, 87, 0.8)',
  },
  failed: {
    borderColor: 'rgb(255, 71, 71)',
    backgroundColor: 'rgba(255, 71, 71, 0.8)',
  },
  blocked: {
    borderColor: 'rgb(85, 85, 85)',
    backgroundColor: 'rgba(85, 85, 85, 0.8)',
  },
  hashCodes: {
    sent: '#FFC100',
    delivered: '#57B657',
    failed: '#FF4747',
    blocked: '#555555',
  },
};

export const FESTIVAL_DAYS = [
  "2023-01-01",
  "2023-04-10",
  "2023-05-01",
  "2023-05-08",
  "2023-05-18",
  "2023-05-29",
  "2023-07-14",
  "2023-08-15",
  "2023-11-01",
  "2023-11-11",
  "2023-12-25",
  "2024-01-01",
  "2024-04-01",
  "2024-05-01",
  "2024-05-08",
  "2024-05-09",
  "2024-05-20",
  "2023-07-14",
  "2024-08-15",
  "2024-11-01",
  "2024-11-11",
  "2024-12-25",
];

export const MESSAGE_MAX_LENGTH = 160;