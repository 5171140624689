import { useTranslation } from "react-i18next";
import Delivered from "../../assets/images/delivered.png";
import Notice from "../../assets/images/notice.png";
import { Link } from "react-router-dom";
import { MainContainer, FullPageContainer } from "../../components/Container";
import FormHeaderLink from "../../components/Shared/Form/FormHeaderLink";
import { useSearchParams } from "react-router-dom";

const VerifyEmail = () => {
  const [t] = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <MainContainer>
        <FullPageContainer>
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0 mt-5">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4">
                  <FormHeaderLink />
                  {searchParams.get("type") == 'success'? 
                    <div className="row">
                        <div className="d-flex flex-column col-lg-4 col-xl-4">
                          <img src={Delivered} className="d-block w-100" />
                        </div>
                        <div className="d-flex flex-column col-lg-8 col-xl-8">
                          <strong>{t("COMMON.EMAIL_ADDRESS_VERIFIED")}</strong>
                          <br />
                          <Link to="/login">{t("COMMON.LOGIN")}</Link>
                        </div>
                      </div>
                    : 
                    <div className="row">
                        <div className="d-flex flex-column col-lg-4 col-xl-4">
                          <img src={Notice} className="d-block w-100" />
                        </div>
                        <div className="d-flex flex-column col-lg-8 col-xl-8">
                          <strong>{t("COMMON.TOKEN_EXPIRED")}</strong>
                          <br />
                          <Link to="/login">{t("COMMON.LOGIN")}</Link>
                        </div>
                      </div>
                  }
                   </div> 
              </div>
            </div>
          </div>
        </FullPageContainer>
      </MainContainer>
    </>
  );
};

export default VerifyEmail;
