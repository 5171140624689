import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import moment from "moment";

import ItemsList from '../../ItemsList';
import { getContactListColumns } from '../../../utils/helpers';

const ContactsList = ({
  contacts,
  removeContact,
  handleShowModal,
  removeManyContacts,
  sendMessageToContacts,
}) => {
  const [t] = useTranslation();

  const localStorageUser = JSON.parse(localStorage.getItem('authUserData'));
  const sessionStorageUser = JSON.parse(sessionStorage.getItem('authUserData'));

  const storageUser = localStorageUser || sessionStorageUser;

  const columns = useMemo(() => getContactListColumns(t), [t]);
  
  const actions = (item, action) =>
    ({
    }[action]);
  
  const contactsList = contacts.map((contact) => ({
    ...contact,
    groups: contact.groups?.map(({ name }) => name).join(', '),
  }));
  
  return (
    <>
      <ItemsList
        columns={columns}
        actions={actions}
        searchColumns={[]}
        items={contactsList}
        defaultSorting={'createdAt'}
      />
    </>
  );
};

export default ContactsList;
