import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { campaignsSelector } from '../../../store/selectors/campaignSelector';
import { fetchDashboardData } from '../../../store/actions/creators/dashboardActions';
import {
  fetchCampaigns,
} from '../../../store/actions/creators/campaignActions';
import {
  monthlyTrendSelector,
  last30DaysSMSSelector,
  dashboardDataFetchStatusSelector,
} from '../../../store/selectors/dashboardSelector';
import Loading from '../../../components/Shared/Loading';
import MonthlyTrend from '../../../components/Member/MonthlyTrend';
import TopBar from '../../../components/MemberHome/Dashboard/TopBar';
import SMSByCampaigns from '../../../components/Member/SMSByCampaigns';
import Last30DaysTrend from '../../../components/Member/Last30DaysTrend';
import StatisticsBar from '../../../components/MemberHome/Dashboard/StatisticsBar';
import { STARTED, SUCCEEDED } from '../../../utils/helpers/store-helpers';
import { useSearchParams } from 'react-router-dom';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams()

  const [campaignId, setCampaignId] = useState(searchParams.get("campaignId") || 'all');
  const [campaignName, setCampaignName] = useState('all');
  const [campaignList, setCampaignList] = useState([]);

  const monthlyTrend = useSelector(monthlyTrendSelector);
  const last30DaysSMS = useSelector(last30DaysSMSSelector);
  const dashboardDataFetchStatus = useSelector(dashboardDataFetchStatusSelector);
  const campaigns = useSelector(campaignsSelector);

  useEffect(() => {
    dispatch(fetchCampaigns());
  }, []);

  useEffect(()=> {
    let options = campaigns.map(cmp => ({'value': cmp.campaignId, 'label':cmp.name}))
    setCampaignList([{'value': 'all', 'label': 'All Campaigns'}, ...options]);   
    setCampaignId(searchParams.get("campaignId") || 'all');
  }, [campaigns]);

  useEffect(async ()=> {
    if(campaignId){
      await dispatch(fetchDashboardData(campaignId));
      setSearchParams({'campaignId': campaignId})  
    }    
  }, [campaignId]);
 
  return dashboardDataFetchStatus === SUCCEEDED ? (
    <>
      <TopBar campaignId={campaignId} setCampaignId={setCampaignId} setCampaignName={setCampaignName} campaignName={campaignName} campaignList={campaignList}/>
      <StatisticsBar last30DaysSMS={last30DaysSMS} campaignId={campaignId} />
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="row">
            <div className="col-md-8">
              <Last30DaysTrend last30DaysSMS={last30DaysSMS} campaignName={campaignName}/>
            </div>
            <div className="col-md-4">
              <SMSByCampaigns campaignName={campaignName}/>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <MonthlyTrend monthlyTrend={monthlyTrend} campaignName={campaignName}/>
        </div>
      </div>
    </>
  ) : dashboardDataFetchStatus === STARTED
    ? <Loading/>
    : t('DASHBOARD.CAN\'T_FETCH_DASHBOARD_DATA');
};

export default Dashboard;
