import { createActionThunk } from 'redux-thunk-actions';

import {
  FETCH_GROUPS,
  CREATE_GROUP,
  UPDATE_GROUP,
  REMOVE_GROUP,
} from '../types/groupTypes';
import GroupService from '../../../services/groups';

export const fetchGroups = createActionThunk(FETCH_GROUPS, () =>
  GroupService.fetchGroups().then((res) => res),
  false
);

export const createGroup = createActionThunk(CREATE_GROUP, (data) =>
  GroupService.createGroup(data).then((data) => ({ data })),
  false
);

export const updateGroup = createActionThunk(UPDATE_GROUP, (id, data) =>
  GroupService.updateGroup(id, data).then((data) => ({ data })),
  false
);

export const removeGroup = createActionThunk(REMOVE_GROUP, (id) =>
  GroupService.removeGroup(id).then((data) => ({ data })),
  false
);
