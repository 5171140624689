/**
 * Service for authorization and authentication
 */
import useApi from "../utils/api";

class createPayment {
  #prefix = "/stripe";

  createPayment = (data) => {
    return useApi.post(`${this.#prefix}/createPayment`, data);
  };

  fetchProducts = (data) => {
    return useApi.get(`${this.#prefix}/getProduct`, data);
  };
}

export default new createPayment();
