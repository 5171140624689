import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';

import { Button } from 'react-bootstrap';
import {
  templatesSelector,
  templateErrorsSelector,
  templateFetchStatusSelector,
  templateCreateStatusSelector,
  templateUpdateStatusSelector,
} from '../../../store/selectors/templateSelector';
import {
  createTemplate,
  fetchTemplates,
  removeTemplate,
  updateTemplate
} from '../../../store/actions/creators/templateActions';
import Loading from '../../../components/Shared/Loading';
import TemplatesList from '../../../components/Member/TemplatesList';
import NewTemplateForm from '../../../components/Member/NewTemplateForm';
import { FAILED, SUCCEEDED } from '../../../utils/helpers/store-helpers';

const Templates = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const templates = useSelector(templatesSelector);
  const errors = useSelector(templateErrorsSelector);
  const templatesFetchStatus = useSelector(templateFetchStatusSelector);
  const templateCreateStatus = useSelector(templateCreateStatusSelector);
  const templateUpdateStatus = useSelector(templateUpdateStatusSelector);

  const [template, setTemplate] = useState();
  const [showModal, setShowModal] = useState();
  const [formInitialized, setFormInitialized] = useState();

  useEffect(() => {
    if (!templatesFetchStatus) dispatch(fetchTemplates());
  }, []);

  useEffect(() => {
    if (templateCreateStatus === SUCCEEDED) handleCloseModal();
  }, [templateCreateStatus]);

  useEffect(() => {
    if (templateUpdateStatus === SUCCEEDED) handleCloseModal();
  }, [templateUpdateStatus]);

  const handleSubmit = (data) => {
    if (data.id) dispatch(updateTemplate(data.id, _.omit(data, ['id'])));
    else dispatch(createTemplate(data));
  };

  const handleShowModal = (templateId) => {
    setShowModal(true);
    setFormInitialized(false);
    setTemplate(templateId ? templates.find(
      (template) => template.id === templateId
    ) : {});
  };

  const handleCloseModal = () => setShowModal(false);
  
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-body">
          <div className="d-flex justify-content-between mb-4">
            <h4 className="card-title">{t('MEMBER.TEMPLATES.TEMPLATES')}</h4>
            <Button
              variant="primary"
              size="sm"
              className="btn-icon-text"
              onClick={() => handleShowModal()}
            >
              {t('MEMBER.TEMPLATES.NEW_TEMPLATE')}
            </Button>
            {templatesFetchStatus && (
              <NewTemplateForm
                errors={errors}
                template={template}
                showModal={showModal}
                handleSubmit={handleSubmit}
                formInitialized={formInitialized}
                handleCloseModal={handleCloseModal}
                initializeForm={() => setFormInitialized(true)}
              />
            )}
          </div>
          {!templatesFetchStatus && templatesFetchStatus !== FAILED ? (
            <Loading/>
          ) : templatesFetchStatus ? (
            <TemplatesList
              templates={templates}
              handleShowModal={handleShowModal}
              removeTemplate={(templateId) => dispatch(removeTemplate(templateId))}
            />
          ) : (
            t('MEMBER.TEMPLATES.CAN\'T_FETCH_TEMPLATES')
          )}
        </div>
      </div>
    </div>
  );
};

export default Templates;
