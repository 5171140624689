import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';

import {
  contactsSelector,
  contactErrorsSelector,
  contactsFetchStatusSelector
} from '../../../store/selectors/contactSelector';
import {
  createContact,
  fetchContacts,
  updateContact,
  removeContact,
  saveSelectedContacts
} from '../../../store/actions/creators/contactActions';
import Loading from '../../../components/Shared/Loading';
import NewContactForm from '../../../components/Member/NewContactForm';
import { STARTED, SUCCEEDED } from '../../../utils/helpers/store-helpers';
import { fetchGroups } from '../../../store/actions/creators/groupActions';
import { fetchCampaigns } from '../../../store/actions/creators/campaignActions';
import { campaignsFetchStatusSelector } from '../../../store/selectors/campaignSelector';
import { groupsFetchStatusSelector, groupsSelector } from '../../../store/selectors/groupSelector';
import { clientSelector } from "src/store/selectors/clientSelector";
import {errorMessageToast, getError, successMessageToast} from '../../../utils/common/helper';
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js/max";
import ItemsList from '../../../components/ItemsList';
import { getContactListColumns } from '../../../utils/helpers';
import { useParams } from "react-router-dom";

const GroupContacts = () => {
  const [t] = useTranslation();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const params = useParams();

  const columns = useMemo(() => getContactListColumns(t), [t]);

  const groups = useSelector(groupsSelector);
  const contacts = useSelector(contactsSelector);
  const errors = useSelector(contactErrorsSelector);
  const client = useSelector(clientSelector);
  const groupsFetchStatus = useSelector(groupsFetchStatusSelector);
  const contactsFetchStatus = useSelector(contactsFetchStatusSelector);
  const campaignsFetchStatus = useSelector(campaignsFetchStatusSelector);

  const [countries, setCountries] = useState([]);
  const [contact, setContact] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [submitErrors, setSubmitErrors] = useState({})
  const [filterStatus, setFilterStatus] = useState('');
  const [filterGroupId, setFilterGroupId] = useState(params.groupId);
  const [showImportContactsModal, setShowImportContactsModal] = useState(false);
  const [contactList, setContactList] = useState([]);

  const contactStatuses = useMemo(() => ['Active', 'Inactive', 'Blocked'], []);

  useEffect(() => {
    if (client && client.credits) {
      let countries = Object.keys(client.credits).map((e) => _.lowerCase(e));
      setCountries(countries);
    }
  }, [client]);

  useEffect(() => {
    dispatch(fetchContacts());
    dispatch(fetchGroups());
    dispatch(fetchCampaigns());
  }, []);

  const handleSubmit = async (data) => {

    let parseNumber = parsePhoneNumber(data.phone);
 
    if(!isValidPhoneNumber(data.phone) || !countries.includes(_.lowerCase(parseNumber.country)) || parseNumber.getType() != 'MOBILE'){
      setSubmitErrors({'phone': t('MEMBER.CONTACTS.PHONE_NOT_VALID')});
      return false;
    } 

    try{
      if (data.id) 
        await dispatch(updateContact(data.id, _.omit(data, ['id','createdAt', 'blockedDate', 'provider', 'isSaved'])));
      else 
        await dispatch(createContact(data));
      successMessageToast("Details saved successfully.")
      setShowModal(false);
    }catch(error){
      let err = getError(error);
      typeof err == 'string' ? errorMessageToast(err) : setSubmitErrors(err);
    }
  };

  const sendMessageToContacts = (contactsIds) => {
    dispatch(saveSelectedContacts(contactsIds));
    navigate('/member/singlemessage');
  };

  const handleShowModal = (contactId) => {
    setSubmitErrors({});
    setShowModal(true);
    setContact(contactId ? contacts.find(
      (contact) => contact.id === contactId
    ) : {});
  };

  const handleCloseModal = () => setShowModal(false);

  const actions = (item, action) =>
    ({
      edit: true,
      delete: true,
    }[action]);

  useEffect(() => {
    const filteredContacts = _.filter(contacts, (contact) => {
        let isMatch = true;
        if (filterGroupId) isMatch &&= _.some(contact.groups, ({ id }) => id == filterGroupId);
        return isMatch;
    });
    const filterContacts = filteredContacts.map((contact) => ({
        ...contact,
        groups: contact.groups?.map(({ name }) => name).join(', '),
      }));
    setContactList(filterContacts)  
  },[contacts]);
 
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-body">
          <h4 className="card-title">{t('MEMBER.CONTACTS.CONTACTS')} - {(groups.find(g => g.id == filterGroupId)?.name)}</h4>
          {contactsFetchStatus && groupsFetchStatus && (
              <>
                {showModal && <NewContactForm
                  handleSubmit={handleSubmit}
                  handleCloseModal={handleCloseModal}
                  contact={contact}
                  groups={groups}
                  errors={submitErrors}
                  countries={countries}
                />}
              </>
            )}
            {contactsFetchStatus === STARTED ? (
              <Loading/>
            ) : contactsFetchStatus === SUCCEEDED ? (
                <ItemsList
                    columns={columns}
                    actions={actions}
                    searchColumns={[]}
                    isSelectable={false}
                    items={contactList}
                    removeItem={(contactId) => dispatch(removeContact(contactId))}
                    handleShowModal={handleShowModal}
                />
            ) : t('MEMBER.CONTACTS.CAN\'T_FETCH_CONTACTS')}
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupContacts;
