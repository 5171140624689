import {
  FETCH_CONTACTS,
  CREATE_CONTACT,
  UPDATE_CONTACT,
  REMOVE_CONTACT,
  IMPORT_CONTACTS,
  REMOVE_MANY_CONTACTS,
  SAVE_SELECTED_CONTACTS,
  CHANGE_STATUS,
} from '../actions/types/contactTypes';
import { getError } from '../../utils/common/helper';
import {
  FAILED,
  STARTED,
  SUCCEEDED,
  getFailed,
  getStarted,
  getSucceeded,
  calculateContactImportErrors
} from '../../utils/helpers/store-helpers';

// Initial state
const initialState = {
  errors: null,
  contacts: [],
  contactFetchStatus: '',
  contactCreateStatus: '',
  contactUpdateStatus: '',
  contactRemoveStatus: '',
  contactImportStatus: '',
  selectedContacts: false,
  manyContactsRemoveStatus: '',
  changeContactStatus:''
};

// Contact reducer
const events = (state = null, { type, payload }) => {
  if (!state) {
    state = initialState;
  }
  
  switch (type) {
    case getStarted(FETCH_CONTACTS):
      return { ...state, contactFetchStatus: STARTED };
    case getFailed(FETCH_CONTACTS):
      return { ...state, contactFetchStatus: FAILED };
    case getSucceeded(FETCH_CONTACTS):
      return { ...state, contacts: payload, contactFetchStatus: SUCCEEDED };
    case getStarted(CREATE_CONTACT):
      return { ...state, contactsCreateStatus: STARTED, errors: null };
    case getFailed(CREATE_CONTACT):
      return { ...state, contactsCreateStatus: FAILED, errors: getError(payload) };
    case getSucceeded(CREATE_CONTACT): {
      return {
        ...state,
        contacts: state.contacts.concat([payload.data]),
        contactsCreateStatus: SUCCEEDED,
        errors: null,
      };
    }
    case getStarted(UPDATE_CONTACT):
      return { ...state, contactUpdateStatus: STARTED, errors: null };
    case getFailed(UPDATE_CONTACT):
      return { ...state, contactUpdateStatus: FAILED, errors: getError(payload) };
    case getSucceeded(UPDATE_CONTACT):
      return {
        ...state,
        contacts: state.contacts.map((contact) =>
          contact.id === payload.data.id ? payload.data : contact
        ),
        contactUpdateStatus: SUCCEEDED,
        errors: null,
      };

      case getStarted(CHANGE_STATUS):
        return { ...state, changeContactStatus: STARTED, errors: null };
      case getFailed(CHANGE_STATUS):
        return { ...state, changeContactStatus: FAILED, errors: getError(payload) };
      case getSucceeded(CHANGE_STATUS):
        return {
          ...state,
          contacts:state.contacts.filter((el)=>
          el.id!==payload.data.id
          ),
          changeContactStatus: SUCCEEDED,
          errors: null,
        };

    case getStarted(REMOVE_CONTACT):
      return { ...state, contactRemoveStatus: STARTED, errors: null };
    case getFailed(REMOVE_CONTACT):
      return { ...state, contactRemoveStatus: FAILED, errors: getError(payload) };
    case getSucceeded(REMOVE_CONTACT): {
      return {
        ...state,
        contacts: state.contacts.filter((contact) => contact.id !== payload.data.id),
        contactRemoveStatus: SUCCEEDED,
        errors: null,
      };
    }
    case getStarted(IMPORT_CONTACTS):
      return { ...state, contactImportStatus: STARTED, errors: null };
    case getFailed(IMPORT_CONTACTS):
      return { ...state, contactImportStatus: FAILED, errors: calculateContactImportErrors(payload) };
    case getSucceeded(IMPORT_CONTACTS):
      return { ...state, contacts: state.contacts.concat(payload.data), contactImportStatus: SUCCEEDED, errors: null };
    case getSucceeded(SAVE_SELECTED_CONTACTS):
      return { ...state, selectedContacts: payload };
    case getStarted(REMOVE_MANY_CONTACTS):
      return { ...state, manyContactsRemoveStatus: STARTED, errors: null };
    case getFailed(REMOVE_MANY_CONTACTS):
      return { ...state, manyContactsRemoveStatus: FAILED, errors: getError(payload) };
    case getSucceeded(REMOVE_MANY_CONTACTS): {
      return {
        ...state,
        contacts: state.contacts.filter(
          (contact) => !payload.data.includes(contact.id)
        ),
        manyContactsRemoveStatus: SUCCEEDED,
        errors: null,
      };
    }
    default:
      return state;
  }
};

export default events;
