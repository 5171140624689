import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import FRflag from "../../../assets/images/fr_FR.png";
import ENflag from "../../../assets/images/en_US.png";
import NavbarBrand from '../../Shared/Navbar/NavbarBrandWrapper';
import { useDispatch, useSelector } from "react-redux";
import { clientSelector } from "src/store/selectors/clientSelector";
import { fetchClient } from "src/store/actions/creators/clientActions";
import { setSuperAdmin } from "src/store/actions/creators/authActions";
import { errorMessageToast, getError, successMessageToast } from '../../../utils/common/helper';
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { UserRoles } from '../../../utils/constants';
import Select from 'react-select';

const Header = ({ logout, userData }) => {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [creditSum,setCreditSum] = useState();
  const [language, setLanguage] = useState(i18n.language != 'en' && i18n.language != 'fr' ? 'en' : i18n.language);
  const client = useSelector(clientSelector);
  const localStorageUser = JSON.parse(localStorage.getItem('authUserData'));
  const sessionStorageUser = JSON.parse(sessionStorage.getItem('authUserData'));
  const storageUser = localStorageUser || sessionStorageUser;
  const sessionStorageUserAs = JSON.parse(sessionStorage.getItem('authUserDataAs'));

  
  useEffect(()=>{
    if(typeof client?.credits !== 'undefined' && client?.credits !== null)
    {
      const sum=Object.values(client?.credits).reduce((accumulator, value) => {
        return accumulator + value;
      }, 0);
      setCreditSum(sum)
      
    }
  },[client])
  
  useEffect(() => {
    dispatch(fetchClient(storageUser.accountId));
  }, []);

  const switchToSuperAdmin = async () => {  
    try{
      await dispatch(setSuperAdmin());
      successMessageToast("Switched back to Super Admin login."); 
    }catch(error){
      let err = getError(error);
      typeof err == 'string' && errorMessageToast(err);
    }
  }
  
  const languageList = useMemo(() => [
    { value: 'en', label: <div><img width={24} src={ENflag} /> <b>EN</b></div> },
    { value: 'fr', label: <div><img width={24} src={FRflag} /> <b>FR</b></div> },
  ], []);

  const languageHandleChange=(op)=>{
    setLanguage(op.value);
    i18n.changeLanguage(op.value)
  }

  const selectStyle = {
    control: (base) => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: "none"
    }),
    indicatorsContainer: (prevStyle, state) => ({
      ...prevStyle,
      display: 'none'
    }),
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: "100%"
    }),
    input: (base) => ({
      ...base,
      color: 'transparent'
    })
  };


  return (
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <NavbarBrand />
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-start flex-row">
        <button
          className="navbar-toggler align-self-center"
          type="button"
          data-toggle="minimize"
        >
          <span className="icon-menu"></span>
        </button>
        <Select
          options={languageList}
          name="language"
          value={
            languageList.filter(option => 
                option.value === language)
          }
          onChange={languageHandleChange}
          styles={selectStyle}
          width={20}
        ></Select>
        {/* <ul className="navbar-nav mr-lg-2">
          <li className="nav-item">
            <Link className="nav-link" to="#" onClick={() => i18n.changeLanguage("fr")}>
              <img src={FRflag} alt="French" />
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="#" onClick={() => i18n.changeLanguage("en")}>
              <img src={ENflag} alt="English" />
            </Link>
          </li>
        </ul> */}
        <ul className="navbar-nav navbar-nav-right">    
          { sessionStorageUserAs &&  
            <li className="nav-item">
              <Button onClick={() => switchToSuperAdmin()}>
                Switch to SuperAdmin
              </Button>
            </li>
          }
          <li className="nav-item nav-profile dropdown">
            <Link className="nav-link dropdown-toggle" to="#" data-toggle="dropdown" id="profileDropdown">
              <i className="ti-settings text-primary"></i>
              {userData.firstName + " " + userData.lastName} - 
              {client.prepaid == 'Yes' ? t('COMMON.PREPAID_CREDITS') : t('COMMON.POSTPAID_CREDITS')} &nbsp;
              {[UserRoles.Client].includes(userData.role) && creditSum}
            </Link>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="profileDropdown"
            >
              {[UserRoles.Client].includes(userData.role) &&<Link className="dropdown-item" to="/member/credit">
                <i className="ti-credit-card text-primary"></i>
                {t("HEADER.CREDIT")}
              </Link>}
              <Link className="dropdown-item" to="/member/profile">
                <i className="ti-user text-primary"></i>
                {t("HEADER.PROFILE")}
              </Link>
              {[UserRoles.Client].includes(userData.role) &&
                <Link className="dropdown-item" to="/member/myusers">
                  <i className="ti-more-alt text-primary"></i>
                  {t("HEADER.MYUSERS")}
                </Link>
              }
              <Link className="dropdown-item" to="/" onClick={logout}>
                <i className="ti-power-off text-primary"></i>
                {t("HEADER.LOGOUT")}
              </Link>
            </div>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          data-toggle="offcanvas"
        >
          <span className="icon-menu"></span>
        </button>
      </div>
    </nav>
  );
};

export default Header;
