import { FETCH_DASHBOARD_DATA } from '../actions/types/dashboardTypes';
import { FAILED, getFailed, getStarted, getSucceeded, STARTED, SUCCEEDED } from '../../utils/helpers/store-helpers';

// Initial state
const initialState = {
  totalUsers: '',
  totalContacts: '',
  totalSMS: [],
  todaySMS: [],
  lastCampaigns: [],
  futureCampaigns: [],
  monthlyTrend: {},
  yesterdaySMS: [],
  last7DaysSMS: [],
  last30DaysSMS: [],
  smsByCampaigns: [],
  dashboardDataFetchStatus: '',
};

// Dashboard reducer
const events = (state = initialState, { type, payload }) => {
  switch (type) {
    case getStarted(FETCH_DASHBOARD_DATA):
      return { ...state, dashboardDataFetchStatus: STARTED };
    case getFailed(FETCH_DASHBOARD_DATA): 
      return { ...state, dashboardDataFetchStatus: FAILED };
    case getSucceeded(FETCH_DASHBOARD_DATA): 
      return { ...state, ...payload, dashboardDataFetchStatus: SUCCEEDED };
    default:
      return state;
  }
};

export default events;
