import _ from 'lodash';

import {
  LOGIN,
  LOGOUT,
  REGISTER,
  RESET_LOGIN,
  CLEAR_TOKEN,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  SET_LOGIN_MESSAGE,
  SET_LOGIN_REDIRECT,
  VERIFY_PHONE_NUMBER,
  SEND_VERIFICATION_CODE,
  UPDATE_PROFILE,
  LOGIN_AS_USER,
  SET_SUPERADMIN
} from '../actions/types/authTypes';
import { getError } from '../../utils/common/helper';
import { FAILED, getFailed, getStarted, getSucceeded, STARTED, SUCCEEDED } from '../../utils/helpers/store-helpers';

// Initial state
const initialState = {
  loggedOut: true,
  loggingIn: false,
  loginMessage: '',
  loginError: null,
  loginRedirect: '/',
  registrationStatus: '',
  registrationError: null,
  resetPasswordStatus: '',
  resetPasswordError: null,
  verificationCodeSendStatus: '',
  sendVerificationCodeError: null,
  forgotPasswordEmailSendStatus: '',  
  sendForgotPasswordEmailError: null,
  phoneNumberVerificationStatus: '',
  phoneNumberVerificationError: null,
  token:
    _.trimStart(window.location.hash, '#') ||
    window.sessionStorage.getItem('authToken') ||
    window.localStorage.getItem('authToken'),
  userData: JSON.parse(
    window.sessionStorage.getItem('authUserData') ||
    window.localStorage.getItem('authUserData') ||
    '{}'
  ),
};

// Auth reducer
const events = (state = initialState, { type, payload }) => {
  switch (type) {
    case getSucceeded(SET_LOGIN_MESSAGE):
      return { ...state, loginMessage: payload };
    case getSucceeded(SET_LOGIN_REDIRECT):
      return { ...state, loginRedirect: payload };
    case getSucceeded(CLEAR_TOKEN):
      return { ...state, token: null, userData: {}, loginError: null, loginMessage: '' };

    case getStarted(LOGIN):
      return { ...state, loggingIn: true };
    case getFailed(LOGIN): {
      return {
        ...state,
        loggingIn: false,
        loginMessage: payload.message,
      };
    }
    case getSucceeded(LOGIN): {
      const { token, userData } = payload;
      return { ...state, token, userData, loginError: false, loggingIn: false, loginMessage: '', loggedOut: false };
    }

    case getSucceeded(LOGIN_AS_USER): {
      const { token, userData } = payload;
      return { ...state, token, userData, loginError: false, loggingIn: false, loginMessage: '', loggedOut: false };
    }
    case getSucceeded(SET_SUPERADMIN): {
      const { token, userData } = payload;
      return { ...state, token, userData, loginError: false, loggingIn: false, loginMessage: '', loggedOut: false };
    }

    case getStarted(FORGOT_PASSWORD):
      return { ...state, forgotPasswordEmailSendStatus: STARTED, sendForgotPasswordEmailError: null };
    case getFailed(FORGOT_PASSWORD): {
      const errors =
        getError(payload) ||
        payload.response.data.message ||
        payload.message;
      return { ...state, forgotPasswordEmailSendStatus: FAILED, sendForgotPasswordEmailError: errors };   
    }
    case getSucceeded(FORGOT_PASSWORD):
      return { ...state, forgotPasswordEmailSendStatus: SUCCEEDED, sendForgotPasswordEmailError: null };
  
    case getStarted(RESET_PASSWORD):
      return { ...state, resetPasswordStatus: STARTED, resetPasswordError: null };
    case getFailed(RESET_PASSWORD): {
      const errors =
        getError(payload) ||
        payload.response.data.message ||
        payload.message;
      return { ...state, resetPasswordStatus: FAILED, resetPasswordError: errors };
    }
    case getSucceeded(RESET_PASSWORD):
      return { ...state, resetPasswordStatus: SUCCEEDED, resetPasswordError: null };
  
    case getStarted(SEND_VERIFICATION_CODE):
      return { ...state, verificationCodeSendStatus: STARTED, sendVerificationCodeError: null };
    case getFailed(SEND_VERIFICATION_CODE): {
      const error =
        getError(payload) ||
        payload.response.data.message ||
        payload.message;
      return { ...state, verificationCodeSendStatus: FAILED, sendVerificationCodeError: error };
    }
    case getSucceeded(SEND_VERIFICATION_CODE):
      return { ...state, verificationCodeSendStatus: SUCCEEDED, sendVerificationCodeError: null  };
  
    case getStarted(VERIFY_PHONE_NUMBER):
      return { ...state, phoneNumberVerificationStatus: STARTED, phoneNumberVerificationError: null };
    case getFailed(VERIFY_PHONE_NUMBER): {
      const error =
        getError(payload) ||
        payload.response.data.message ||
        payload.message;
      return { ...state, phoneNumberVerificationStatus: FAILED, phoneNumberVerificationError: error };
    }
    case getSucceeded(VERIFY_PHONE_NUMBER):
      return { ...state, phoneNumberVerificationStatus: SUCCEEDED, phoneNumberVerificationError: null };
  
    case getStarted(REGISTER):
      return { ...state, registrationStatus: STARTED, registrationError: null };
    case getFailed(REGISTER): {
      const data = _.get(payload, 'response.data');
      return { ...state, registrationStatus: FAILED, registrationError: data ? data.message : payload.message, };
    }
    case getSucceeded(REGISTER):
      return { ...state, registrationStatus: SUCCEEDED, registrationError: null };
    
    case getStarted(LOGOUT):
      return { ...state, loggingOut: true };
    case getFailed(LOGOUT):
      return { ...state, loggingOut: false };
    case getSucceeded(LOGOUT):
      return { ...state, token: null, userData: {}, loggingOut: false, loginRedirect: '/', loggedOut: true };

    case getStarted(UPDATE_PROFILE):
      return { ...state, updateProfileStatus: STARTED, updateProfileErrors: null };
    case getFailed(UPDATE_PROFILE):
      return { ...state, updateProfileStatus: FAILED, updateProfileErrors: getError(payload) };
    case getSucceeded(UPDATE_PROFILE):
      const { userData } = payload;
      return {
        ...state,
        userData,
        updateProfileStatus: SUCCEEDED,
        updateProfileErrors: null,
      };

    case getSucceeded(RESET_LOGIN):
      return initialState;
    default:
      return state;
  }
};

export default events;
