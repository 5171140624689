import { Link } from 'react-router-dom';
import React from 'react';

const NavbarLogoMini = () => (
  <Link className="navbar-brand brand-logo-mini" to="/">
    <span>
      <b>SMS</b>
    </span>
  </Link>
);

export default NavbarLogoMini;