import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';

import {
  contactsSelector,
  contactErrorsSelector,
  contactsFetchStatusSelector
} from '../../../store/selectors/contactSelector';
import {
  createContact,
  fetchContacts,
  updateContact,
  removeContact,
  importContacts,
  removeManyContacts,
  saveSelectedContacts
} from '../../../store/actions/creators/contactActions';
import Loading from '../../../components/Shared/Loading';
import ContactsList from '../../../components/Admin/ContactList';
import { STARTED, SUCCEEDED } from '../../../utils/helpers/store-helpers';
import { clientsSelector, clientsFetchStatusSelector } from 'src/store/selectors/clientSelector';
import { fetchAllClients } from 'src/store/actions/creators/clientActions';
import { Form } from 'react-bootstrap';

const AdminContacts = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const contacts = useSelector(contactsSelector);
  const contactsFetchStatus = useSelector(contactsFetchStatusSelector);
  const clients = useSelector(clientsSelector);

  const [filterStatus, setFilterStatus] = useState('');
  const [filterGroupId, setFilterGroupId] = useState('');
  const [clientId, setClientId] = useState('all');
  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    dispatch(fetchAllClients(true));
    dispatch(fetchContacts());
  }, []);

  const filteredContacts = _.filter(contacts, (contact) => {
    let isMatch = true;
    if (filterStatus) isMatch &&= contact.status === filterStatus;
    if (filterGroupId) isMatch &&= _.some(contact.groups, ({ name }) => name === filterGroupId);
    return isMatch;
  });

  useEffect(()=> {
    if(clients && clients.length){
      let temp = clients.map(({ id, company }) =>  ({ 'value': id, 'label': company}) )
      setClientList(temp);
    }
  },[clients]);

  const handleClientFilter = (event) => {
    const value = event.target.value;
    setClientId(value);
    dispatch(fetchContacts(value,''));
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-body">
            <div className="col-md-6 mb-3 p-0">
              <Form.Label className="pt-2 mr-3">
                {t('ADMIN.REPORTS.FILTER_BY_CLIENT')}
              </Form.Label>
              <Form.Select
                className="form-control w-35 d-inline-flex form-control-sm"
                value={clientId}
                onChange={handleClientFilter}
              >
                <option key='all' value="">All Clients</option>
                {clientList.map((item) => (
                    <option key={item.value} value={item.value}>
                     {item.label}
                    </option>
                ))}
              </Form.Select>
            </div>
            {contactsFetchStatus === STARTED ? (
              <Loading/>
            ) : contactsFetchStatus === SUCCEEDED ? (
              <ContactsList
                contacts={filteredContacts}
              />
            ) : t('MEMBER.CONTACTS.CAN\'T_FETCH_CONTACTS')}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminContacts;