import {
    FETCH_REGISTRATION_SETTING,
    CREATE_REGISTRATION_SETTING,
    UPDATE_REGISTRATION_SETTING
  } from '../actions/types/registrationSettingTypes';
  import { getError } from '../../utils/common/helper';
  import { FAILED, getFailed, getStarted, getSucceeded, STARTED, SUCCEEDED } from '../../utils/helpers/store-helpers';
  
  // Initial state
  const initialState = {
    errors: null,
    registrationSetting: {},
    registrationSettingFetchStatus: '',
    registrationSettingCreateStatus: '',
    registrationSettingUpdateStatus: '',
  };
  
  // Registration Setting reducer
  const events = (state = initialState, { type, payload }) => {
      switch (type) {
        case getStarted(FETCH_REGISTRATION_SETTING):
          return { ...state, registrationSettingFetchStatus: STARTED };
        case getFailed(FETCH_REGISTRATION_SETTING):
          return { ...state, registrationSetting: {}, registrationSettingFetchStatus: FAILED, errors: getError(payload) };
        case getSucceeded(FETCH_REGISTRATION_SETTING):
          return { ...state, registrationSetting: payload.data, registrationSettingFetchStatus: SUCCEEDED };

        case getStarted(CREATE_REGISTRATION_SETTING):
          return { ...state, registrationSettingCreateStatus: STARTED, errors: null };
        case getFailed(CREATE_REGISTRATION_SETTING):
          return { ...state, registrationSettingCreateStatus: FAILED, errors: getError(payload) };
        case getSucceeded(CREATE_REGISTRATION_SETTING):
          return {
            ...state,
            registrationSetting: payload.data,
            registrationSettingCreateStatus: SUCCEEDED,
            errors: null,
          };

        case getStarted(UPDATE_REGISTRATION_SETTING):
          return { ...state, registrationSettingUpdateStatus: STARTED, errors: null };
        case getFailed(UPDATE_REGISTRATION_SETTING):
          return { ...state, registrationSettingUpdateStatus: FAILED, errors: getError(payload) };
        case getSucceeded(UPDATE_REGISTRATION_SETTING):
          return {
            ...state,
            registrationSetting: payload.data,
            registrationSettingUpdateStatus: SUCCEEDED,
            errors: null,
          };

        default:
          return state;
      }
    }
  ;
  
  export default events;
  