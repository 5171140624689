import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Form, Modal } from "react-bootstrap";
import FormRow from "src/components/Shared/Form/FormRow";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const HolidayModalBody = ({ inputs, setInputs, initialState, holiday ,showError}) => {
  const [t] = useTranslation();

  const [errors, setErrors] = useState({});
  const [formInitialized, setFormInitialized] = useState(false);
  const [localDate, setLocalDate] = useState('');

  useEffect(()=>{
    setErrors(showError)
  },[showError])

  const handleChange = ({ target: { name, value } }) => {
    setErrors('')
    setInputs({
      ...inputs,
      [name]: moment(value).format('YYYY-MM-DD'),
    });
    setLocalDate(value)
    console.log(value, moment(value), moment(value).format('YYYY-MM-DD'))
  };

  const disabledDate = (current) => {
    return !(
      moment(current).day() === 0 
    );
  }
  useEffect(() => {
    if (formInitialized) return;

    if (_.isEmpty(holiday)) {
      setInputs(initialState);
    } else {
      setInputs({
        id: holiday.id,
        holidayTitle: holiday.holidayTitle,
        holidayDate: moment(holiday.holidayDate).toDate(),
      });
      setLocalDate(moment(holiday.holidayDate).toDate());
    }
    setFormInitialized(true);
  }, [formInitialized]);

  const onChange = ({ target: { name, value } }) => {
    setErrors('')
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const rows = [
    [{ title: t("COMMON.TITLE"), name: "holidayTitle", required: true }],
  ];
  return (
    <Modal.Body>
      {rows.map((row, idx) => (
        <FormRow
          key={idx}
          id={idx}
          row={row}
          inputs={inputs}
          errors={errors}
          handleChange={onChange}
        />
      ))}
      <Form.Group className="form-group mb-3 date-picker">
        <Form.Label>{t("COMMON.DATE")}</Form.Label>

        <DatePicker
          selected={localDate}
          onChange={(date) => {
            handleChange({ target: { name: "holidayDate", value: date } });
          }}
          placeholderText="Please select a date"
          dateFormat="dd-MM-yyyy"
          showTimeSelect={false}
          className="form-control col-md-6"
          filterDate={disabledDate}
        />
      </Form.Group>
      {errors.holidayDate && (
        <p className="text-danger">
          {t("COMMON.DATE") + " " + errors.holidayDate}
        </p>
      )}
    </Modal.Body>
  );
};

export default HolidayModalBody;
