import React from 'react';
import { useTranslation } from 'react-i18next';

const Tabs = ({ setSmsTimeRange }) => {
  const [t] = useTranslation();

  const handleSmsTimeRangeChange = (event) => {
    setSmsTimeRange(event.currentTarget.value);
  };

  return (
    <div className="col-xl-2 col-lg-4 col-md-6">
      <div className="p-2 h-100 border-md-right border-bottom border-xl-bottom-0">
        <div className="form-group mb-0">
          <div className="form-check form-check-info">
            <label className="form-check-label">
              <input
                type="radio"
                className="form-check-input"
                name="smsTimeRange"
                value="today"
                defaultChecked={true}
                onChange={handleSmsTimeRangeChange}
              />
              {t('DASHBOARD.TODAY_SMS')}
              <i className="input-helper"></i>
            </label>
          </div>
          <div className="form-check form-check-info">
            <label className="form-check-label">
              <input
                type="radio"
                className="form-check-input"
                name="smsTimeRange"
                value="yesterday"
                onChange={handleSmsTimeRangeChange}
              />
              {t('DASHBOARD.YESTERDAY_SMS')}
              <i className="input-helper"></i>
            </label>
          </div>
          <div className="form-check form-check-info">
            <label className="form-check-label">
              <input
                type="radio"
                className="form-check-input"
                name="smsTimeRange"
                value="last7Days"
                onChange={handleSmsTimeRangeChange}
              />
              {t('DASHBOARD.LAST_7_DAYS_SMS')}
              <i className="input-helper"></i>
            </label>
          </div>
          <div className="form-check form-check-info">
            <label className="form-check-label">
              <input
                type="radio"
                className="form-check-input"
                name="smsTimeRange"
                value="last30Days"
                onChange={handleSmsTimeRangeChange}
              />
              {t('DASHBOARD.LAST_30_DAYS_SMS')}
              <i className="input-helper"></i>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;