import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';

import { clientsSelector, clientsFetchStatusSelector } from '../../../store/selectors/clientSelector';
import Loading from '../../../components/Shared/Loading';
import ClientCredentialsList from '../../../components/Admin/ClientCredentialsList';
import { fetchAllClients } from '../../../store/actions/creators/clientActions';
import { STARTED, SUCCEEDED } from '../../../utils/helpers/store-helpers';

const AdminClientsCredentials = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const clients = useSelector(clientsSelector);
  const clientsFetchStatus = useSelector(clientsFetchStatusSelector);

  useEffect(() => {
    dispatch(fetchAllClients());
  }, []);
  

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-body">
          {clientsFetchStatus === STARTED ? (
            <Loading/>
          ) : clientsFetchStatus === SUCCEEDED ? (
            <ClientCredentialsList clients={clients}/>
          ) : t('ADMIN.CLIENTS.CAN\'T_FETCH_ALL_CLIENTS')}
        </div>
      </div>
    </div>
  );
};

export default AdminClientsCredentials;
