export const FETCH_CLIENT = 'FETCH_CLIENT';
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const REMOVE_CLIENT = 'REMOVE_CLIENT';
export const FETCH_ALL_CLIENTS = 'FETCH_ALL_CLIENTS';
export const FETCH_SUBACCOUNTS = 'FETCH_SUBACCOUNTS';
export const FETCH_SUBACCOUNT = 'FETCH_SUBACCOUNT';
export const CREATE_SUBACCOUNT = 'CREATE_SUBACCOUNT';
export const UPDATE_SUBACCOUNT = 'UPDATE_SUBACCOUNT';
export const REMOVE_SUBACCOUNT = 'REMOVE_SUBACCOUNT';
export const FETCH_USERS = 'FETCH_USERS';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const REFRESH_SECRET_KEYS = 'REFRESH_SECRET_KEYS';
export const ACCESS_FLAGS = 'ACCESS_FLAGS';
export const FETCH_REGISTERED_USERS = 'FETCH_REGISTERED_USERS';
export const UPDATE_REGISTERED_USER = 'UPDATE_REGISTERED_USER';
export const REMOVE_REGISTERED_USER = 'REMOVE_REGISTERED_USER';