import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { contactsAddGroups } from "src/store/actions/creators/contactActions";
import { fetchContacts } from 'src/store/actions/creators/contactActions';
import { successMessageToast, errorMessageToast } from "src/utils/common/helper";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { t } from "i18next";
import CreatableSelect from "react-select/creatable";
import _ from 'lodash';

const GroupModal = ({ show, modalClose, groupArray, groupContacts }) => {
  const [localGroup, setLocalGroup] = useState([]);
  const [error, setError] = useState("");
  
  const dispatch = useDispatch();

  const onChangeEvent = async (newValue) => {
    setLocalGroup(newValue);
    setError("");
  };

  const addGroups = async () => {
    try {
      if (_.isEmpty(localGroup)) {
        setError(t("COMMON.FIELD_IS_REQUIRED"));
        return false;
      }
      let group = localGroup.map((g) => g.value);
      await dispatch(contactsAddGroups({'groups':group, 'contacts': groupContacts}));
      await dispatch(fetchContacts());
      successMessageToast("Groups added to contacts successfully.");
      modalClose();
      setLocalGroup([]);
    } catch (error) {
      errorMessageToast(error.message);
    }
  };
  
  return (
    <>
      <Modal show={show} onHide={modalClose}>
        <Modal.Header>
          <Modal.Title> {t("MEMBER.SMS.CHOOSE_GROUP")}</Modal.Title>
        </Modal.Header>

        <Form className="px-4 pb-2">
          <Form.Group controlId="formName">
            <Form.Label className="py-2">{t("MEMBER.GROUPS.GROUPS")}</Form.Label>
              <CreatableSelect
                options={groupArray}
                name="group"
                value={localGroup}
                onChange={onChangeEvent}
                //formatCreateLabel={userInput => userInput}
                createOptionPosition="first"
                isMulti
              />
          </Form.Group>
        </Form>
        {error && <p className="text-danger pl-4">{error}</p>}
        <Modal.Footer>
          <Button variant="outline-primary" onClick={modalClose}>
            {t("COMMON.CLOSE")}
          </Button>
          <Button variant="outline-primary" onClick={addGroups}>
            {t("COMMON.SAVE")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GroupModal;
