import React from 'react';
import Icon from '@mdi/react';
import { useNavigate } from 'react-router-dom';

const InfoColumn = ({ text, iconPath, iconColor, count, className, horizontal = false, redirect = null }) => {
  const navigate = useNavigate();

  const redirectTo = () => {
    if(redirect){
      navigate(redirect);
    }
  }

  return (
    <div className="col-xl-2 col-lg-4 col-md-6" onClick={()=> redirectTo()} role="button">
      <div className={className}>
        <div className="d-flex mb-2">
          <p className="mb-0 font-regular text-muted font-weight-bold">
            {text}
          </p>
        </div>
        <div className="d-block d-sm-flex h-100 align-items-center">
          <div>
            <Icon
              path={iconPath}
              size={2}
              horizontal={horizontal}
              color={iconColor}
            />
          </div>
          <div className="statistics mt-3 mt-sm-0 ml-sm-auto text-center text-sm-right">
            <h3 className="mb-0">
              <i className="icon-arrow-up-circle"></i> {count}
            </h3>
            <p></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoColumn;