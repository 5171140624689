import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

import { holidayFormInitialState } from "../../../utils/helpers";
import HolidayModalBody from "../HolidayModalBody";
import {
  HolidayUpdateStatusSelector,
  holidayCreateStatusSelector,
} from "src/store/selectors/holidaySelector";
import { STARTED } from "src/utils/helpers/store-helpers";

const HolidayForm = ({ handleCloseModal, handleSubmit, holiday ,showError}) => {
  const [t] = useTranslation();

  const initialState = useMemo(() => holidayFormInitialState, []);
  const [inputs, setInputs] = useState(initialState);

  const holidayCreateStatus = useSelector(holidayCreateStatusSelector);
  const holidayUpdateStatus = useSelector(HolidayUpdateStatusSelector);

  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit({
      ...inputs,
    });
  };

  return (
    <Modal show={true} onHide={handleCloseModal} size="lg">
      <Modal.Header closeButton={false}>
        <Modal.Title>
          {inputs.id
            ? t("ADMIN.HOLIDAYS.EDIT_HOLIDAY")
            : t("ADMIN.HOLIDAYS.INSERT_HOLIDAY")}
        </Modal.Title>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={handleCloseModal}
        >
          <span aria-hidden="true">x</span>
        </button>
      </Modal.Header>
      <Form>
        <HolidayModalBody
          setInputs={setInputs}
          inputs={inputs}
          initialState={initialState}
          holiday={holiday}
          showError={showError}
        />
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t("COMMON.CLOSE")}
          </Button>
          <Button
            variant="primary"
            className="btn-icon-text"
            type="submit"
            onClick={onSubmit}
          >
            {holidayCreateStatus === STARTED ||
            holidayUpdateStatus === STARTED ? (
              <Spinner
                className="mr-2"
                animation="border"
                variant="secondary"
                size="sm"
              />
            ) : (
              <i
                className={`${
                  inputs.id ? "feather-save" : "feather-user-plus"
                } btn-icon-prepend`}
              ></i>
            )}
            {inputs.id ? t("COMMON.SAVE") : t("COMMON.INSERT")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default HolidayForm;
