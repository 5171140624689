import React from 'react';
import { Link } from 'react-router-dom';

import Icon from '@mdi/react';

const Tab = ({value, title, iconPath, to}) => {
  return (
    <div className="col-md-4">
      <div className="p-2 border-md-right border-bottom border-xl-bottom-0">
        <div className="d-flex mb-2">
          <p className="mb-0 font-regular text-muted font-weight-bold">
            {title}
          </p>
          {to && <Link className="mb-0 ml-auto font-weight-bold" to={to}>
            <i className="ti ti-more-alt"></i>{' '}
          </Link>}
        </div>
        <div className="d-block d-sm-flex h-100 align-items-center">
          <div>
            <Icon
              path={iconPath}
              size={3}
              horizontal
              color="#a6a9b7"
            />
          </div>
          <div className="statistics mt-3 mt-sm-0 ml-sm-auto text-center text-sm-right">
            <h3 className="mb-0">
              <i className="icon-arrow-up-circle"></i>{' '}
              {value}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tab;