/**
 * Service for Countries
 */
import useApi from '../utils/api';

class RegistrationSettingService {
  #prefix = '/registrationSetting';

  fetchRegistrationSetting = (id) => {
    return useApi.get(`${this.#prefix}/${id}`);
  };

  createRegistrationSetting = (data) => {
    return useApi.post(this.#prefix, data);
  };

  updateRegistrationSetting = (id, data) => {
    return useApi.put(`${this.#prefix}/${id}`, data);
  };

}

export default new RegistrationSettingService();