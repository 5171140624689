import { createActionThunk } from 'redux-thunk-actions';

import {
    FETCH_ALL_COUNTRIES,
    FETCH_COUNTRY,
    CREATE_COUNTRY,
    UPDATE_COUNTRY,
    REMOVE_COUNTRY,
} from '../types/countryTypes';
import CountryService from '../../../services/country';

export const fetchAllCountries = createActionThunk(FETCH_ALL_COUNTRIES, () =>
    CountryService.fetchAllCountries().then(res => res)
  , false
);

export const fetchCountry = createActionThunk(FETCH_COUNTRY, (id) =>
    CountryService.fetchCountry(id).then((data) => ({ data })),
  false
);

export const createCountry = createActionThunk(CREATE_COUNTRY, (data) =>
    CountryService.createCountry(data).then((data) => ({ data }))
  , false
);

export const updateCountry = createActionThunk(UPDATE_COUNTRY, (id, data) =>
  CountryService.updateCountry(id, data).then((data) => ({ data }))
  , false
);

export const removeCountry = createActionThunk(REMOVE_COUNTRY, (id) =>
    CountryService.removeCountry(id).then((data) => ({ data }))
  , false
);
