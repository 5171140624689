import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Icon from "@mdi/react";
import { mdiArrowDown, mdiArrowUp } from "@mdi/js";
import { getClientListColumns, sortFunc } from "../../../../utils/helpers";
import ConfirmationModal from "src/components/ConfirmationModal";

const ClientTable = ({
  page,
  sortBy,
  perPage,
  sortOrder,
  filteredClients,
  handleChangeSort,
  handleShowEditModal,
  showCreditModal,
  removeClient,
}) => {
  const [t] = useTranslation();
  const columns = useMemo(() => getClientListColumns(t), [t]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [removingItemId, setRemovingItemId] = useState(null);

  function sum(obj) {
    let sum = 0;
    for (var el in obj) {
      if (obj.hasOwnProperty(el)) {
        sum += parseFloat(obj[el]);
      }
    }
    return sum;
  }

  const handleShowConfirmModal = (itemId) => {
    setRemovingItemId(itemId);
    setShowConfirmModal(true);
  };
  const handleRemoveItem = () => {
    removeClient(removingItemId);
    setShowConfirmModal(false);
  };

  const creditPercentage = (obj) => {
    let usedCredits = 0;
    let totalCredits = 0;

    if (obj?.credits) {
      usedCredits = Object.values(obj?.credits).reduce((accumulator, value) => {
        return accumulator + value;
      }, 0);
    }
    if (obj?.lastCredits) {
      totalCredits = Object.values(obj?.lastCredits).reduce(
        (accumulator, value) => {
          return accumulator + value;
        },
        0
      );
    }
    if (
      usedCredits === null ||
      usedCredits === undefined ||
      usedCredits > totalCredits
    ) {
      return <span>-</span>;
    }

    if (usedCredits === 0) {
      return <span className="text-danger">{0}%</span>;
    }

    const percentage = (usedCredits / totalCredits) * 100;
    if (percentage <= 25) {
      return <span className="text-warning">{percentage.toFixed(2)}%</span>;
    } else return <span>{percentage.toFixed(2)}%</span>;
  };

  return (
    <>
      <ConfirmationModal
        handleConfirm={handleRemoveItem}
        handleCancel={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
        title={t("COMMON.CONFIRM_MODAL_TITLE")}
        description={t("COMMON.CONFIRM_MODAL_DESCRPTION")}
      />

      <div className="row">
        <div className="col-sm-12 mb-2">
          <table className="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column.accessor} className="justify-content-between">
                    {column.Header}{" "}
                    <Link
                      to="#"
                      onClick={() => handleChangeSort(column.accessor)}
                    >
                      {sortBy === column.accessor ? (
                        sortOrder === "desc" ? (
                          <Icon
                            path={mdiArrowDown}
                            size={0.6}
                            color="#4B49AC"
                          />
                        ) : (
                          <Icon path={mdiArrowUp} size={0.6} color="#4B49AC" />
                        )
                      ) : (
                        <Icon path={mdiArrowDown} size={0.6} color="#CCC" />
                      )}
                    </Link>
                  </th>
                ))}
                <th>{t("ADMIN.CLIENTS.ACTIONS")}</th>
              </tr>
            </thead>

            <tbody>
              {filteredClients
                .sort(sortFunc(sortBy, sortOrder))
                .slice((page - 1) * perPage, page * perPage)
                .map((row) => (
                  <tr key={row.id}>
                    {columns.map((column) => {
                      return (
                        <td key={`${row.id}-${column.accessor}`}>
                          {row[column.accessor] === null ? (
                            "-"
                          ) : column.accessor === "credits" ? (
                            <div onClick={() => showCreditModal(row)}>
                              <Link to="#">{sum(row[column.accessor])}</Link>
                            </div>
                          ) : column.accessor === "balance" ? (
                            <div>
                              {row["prepaid"] == "Yes"
                                ? creditPercentage(row)
                                : "--"}
                            </div>
                          ) : typeof row[column.accessor] === "object" ? (
                            JSON.stringify(row[column.accessor]).replace(
                              /[{}]/g,
                              ""
                            )
                          ) : (
                            row[column.accessor]
                          )}
                        </td>
                      );
                    })}
                    <td>
                      <Link
                        to="#"
                        className="mr-1"
                        onClick={(e) => {
                          e.preventDefault();
                          handleShowEditModal(row);
                        }}
                      >
                        <i className="feather-edit btn-icon-prepend"></i>
                      </Link>
                      <Link to="#" className="mr-1">
                        <i className="feather-trending-up btn-icon-prepend"></i>
                      </Link>
                      <Link
                        to={`/admin/clients/${row.id}/subaccounts`}
                        className="mr-1"
                      >
                        <i className="feather-users btn-icon-prepend"></i>
                        <span>{`(${row.subaccount_count})`}</span>
                      </Link>
                      <Link to={`/admin/clients/${row.id}`}>
                        <i className="feather-eye btn-icon-prepend"></i>
                      </Link>
                      <Link
                        to="#"
                        className="ml-1"
                        onClick={(e) => {
                          e.preventDefault();
                          handleShowConfirmModal(row.id);
                        }}
                      >
                        <i className="feather-trash-2 btn-icon-prepend"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ClientTable;
