import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import Row from '../Row';
import { getAdminHomeClientTableRows } from '../../../../utils/helpers';

const ClientTable = ({ client }) => {
  const [t] = useTranslation();

  const rows = useMemo(() => getAdminHomeClientTableRows(t, client), [t, client]);

  return (
    <>
      <h5 className="card-title">
        {t("ADMIN.CLIENTS.BASIC_DATA_CLIENT")}
      </h5>
      <table className="table user-view-table m-0">
        <tbody>
          {rows.map((el,index) => <Row key={index} title={el.title} value={el.value}/>)}
        </tbody>
      </table>
    </>
  );
};

export default ClientTable;