import Spinner from "react-bootstrap/Spinner";
import { useMemo, useState, createRef, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import $ from "jquery";

import _ from "lodash";

import PasswordInputs from "../../PasswordInputs";
import ReCAPTCHA from "react-google-recaptcha";
import LabeledInput from "../../../Shared/LabeledInput";
import LabeledSelect from "../../../Shared/LabeledSelect";
import Hint from "src/components/ResetPassword/Hint";

const Step1 = ({
  step,
  setStep,
  loginDisabled,
  errors,
  setErrors,
  isLoading,
  setIsLoading,
  inputs,
  setInputs,
  setting,
}) => {
  const [t, i18n] = useTranslation();

  const [submitClicked, setSubmitClicked] = useState(false);

  const [email, setEmail] = useState("");
  const [login, setLogin] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [company, setCompany] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [acceptTermsOfCondition, setAcceptTermsOfCondition] = useState(false);

  // const countries = useMemo(() => ['France', 'Spain', 'Germany', 'Armenia'], []);
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState();
  const [regexError, setRegexError] = useState(false);
  const recaptchaRef = createRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    //setIsLoading(true);
    let err = {};
    if (password !== confirmPassword) err.password = true;
    if (!recaptchaRef.current.getValue()) err.recaptcha = true;
    if (!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()-_=+;:\'"<,>.]).{8,}$/.test(password))
      err.password = true;

    if (!_.isEmpty(err)) return setErrors(err);
    if (regexError) return setSubmitClicked(false);

    setStep(step + 1);
    setInputs({
      ...inputs,
      login,
      firstName,
      lastName,
      company,
      country,
      address,
      city,
      zip,
      email,
      password,
      confirmPassword,
      acceptTermsOfCondition,
    });
  };

  const handleSubmitButtonClick = (event) => {
    setSubmitClicked(true);
  };

  const onRecaptchaChange = (value) => {
    //console.log('Captcha value:', value);
  };

  useEffect(() => {
    if (setting && setting.countryList) {
      let countries = setting.countryList.map((c) => c.countryName);
      setCountries(countries);
      let code = [];
      setting.countryList.map((element) => {
        code.concat(element.phoneCodes.split(","));
      });
      setCountryCode(code);
    }
  }, [setting]);

  const onChangeLogin = (value) => {
   // let regex = /^(?=.*[0-9])(?=.*[@.+])[a-z0-9@.+]{6,10}$/; 
    let regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9@.+]{6,}$/;
    regex.test(value);

    if (!regex.test(value)) {
      setRegexError(true);
    } else {
      setRegexError(false);
    }

    setErrors("");
    setLogin(value);
  };

  useEffect(() => {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }, []);

  return (
    <>
      <h6 className="font-weight-light">
        {t("REGISTRATION.PLEASE_FILL_REGISTRATION_FORM")}
      </h6>
      <form
        className={"pt-3" + (submitClicked ? " was-validated" : "")}
        onSubmit={handleSubmit}
      >
        <LabeledInput
          label={t("COMMON.FIRST_NAME")}
          name={"firstName"}
          placeholder={t("COMMON.FIRST_NAME")}
          value={firstName}
          setValue={setFirstName}
          tooltip={t("REGISTRATION.TOOLTIP_FIRSTNAME")}
        />
        <LabeledInput
          label={t("COMMON.LAST_NAME")}
          name={"lastName"}
          placeholder={t("COMMON.LAST_NAME")}
          value={lastName}
          setValue={setLastName}
          tooltip={t("REGISTRATION.TOOLTIP_LASTNAME")}
        />
        <LabeledInput
          label={t("REGISTRATION.COMPANY_NAME")}
          name={"company"}
          placeholder={t("REGISTRATION.COMPANY_NAME")}
          value={company}
          setValue={setCompany}
          tooltip={t("REGISTRATION.TOOLTIP_COMPANYNAME")}
        />
        <LabeledSelect
          label={t("REGISTRATION.COUNTRY")}
          name={"country"}
          setValue={setCountry}
          value={country}
          placeholder={t("REGISTRATION.SELECT_COUNTRY")}
          options={countries}
          tooltip={t("REGISTRATION.TOOLTIP_COUNTRYNAME")}
        />
        <LabeledInput
          label={t("REGISTRATION.ADDRESS")}
          name={"address"}
          setValue={setAddress}
          value={address}
          placeholder={t("REGISTRATION.ADDRESS")}
          tooltip={t("REGISTRATION.TOOLTIP_ADDRESS")}
        />
        <LabeledInput
          label={t("REGISTRATION.CITY")}
          name={"city"}
          setValue={setCity}
          value={city}
          placeholder={t("REGISTRATION.CITY")}
          tooltip={t("REGISTRATION.TOOLTIP_CITY")}
        />
        <LabeledInput
          label={t("REGISTRATION.POSTAL_CODE")}
          name={"zip"}
          setValue={setZip}
          value={zip}
          placeholder={t("REGISTRATION.POSTAL_CODE")}
          tooltip={t("REGISTRATION.TOOLTIP_POSTAL")}
        />
        <LabeledInput
          label={t("COMMON.EMAIL")}
          name={"email"}
          placeholder={t("COMMON.EMAIL")}
          value={email}
          setValue={setEmail}
          type={"email"}
          tooltip={t("REGISTRATION.TOOLTIP_EMAIL")}
        />
        <LabeledInput
          label={t("COMMON.CHOOSE_LOGIN")}
          name={"login"}
          placeholder={t("COMMON.CHOOSE_LOGIN")}
          value={login}
          setValue={onChangeLogin}
          tooltip={t("REGISTRATION.TOOLTIP_LOGIN")}
        />
        {regexError && (
          <p className="text-danger">
            {t("ADMIN.CLIENTS.REGISTER_FIELD_REGEX_ERROR")}
          </p>
        )}
        <PasswordInputs
          errors={errors}
          password={password}
          setPassword={setPassword}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
        />
        <div className="form-check form-check-flat form-check-primary">
          <label className="form-check-label">
            <input
              type="checkbox"
              name="acceptTermsOfCondition"
              className="form-check-input"
              onChange={() =>
                setAcceptTermsOfCondition(!acceptTermsOfCondition)
              }
              checked={acceptTermsOfCondition}
              required
            />
            <i className="input-helper"></i>
            <span>
              {t("REGISTRATION.I_HAVE_READ_AND_ACCEPT_THE")}{" "}
              <a
                href={`https://www.smsreflex.com/${i18n.language}/conditions.php`}
                target="_blank"
              >
                {t("REGISTRATION.TERMS_AND_CONDITIONS")}
              </a>
            </span>
          </label>
        </div>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
          onChange={onRecaptchaChange}
          size="normal"
          onErrored={(err) => console.log(err)}
          ref={recaptchaRef}
        />
        {errors?.recaptcha ? (
          <div className="invalid-feedback d-sm-inline-block">
            {t("REGISTRATION.RECAPTCHA_IS_REQUIRED")}
          </div>
        ) : null}
        <div className="mt-3">
          <button
            type="submit"
            disabled={loginDisabled}
            className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
            onClick={handleSubmitButtonClick}
          >
            {isLoading ? (
              <Spinner
                className="mr-2"
                animation="border"
                variant="secondary"
                size="sm"
              />
            ) : (
              t("REGISTRATION.REGISTER")
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default Step1;
