import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';

import { Button } from 'react-bootstrap';
import { subaccountsSelector, subaccountsFetchStatusSelector } from '../../../store/selectors/clientSelector';
import {
  createSubaccount,
  fetchSubaccounts,
  removeSubaccount, 
  updateSubaccount
} from '../../../store/actions/creators/clientActions';
import Loading from '../../../components/Shared/Loading';
import SubaccountForm from '../../../components/Admin/SubaccountForm';
import SubaccountsList from '../../../components/Admin/SubaccountsList';
import { STARTED, SUCCEEDED } from '../../../utils/helpers/store-helpers';
import { errorMessageToast, getError, successMessageToast } from '../../../utils/common/helper'
import ClientForm from 'src/components/Admin/ClientForm';
import { fetchAllProviders } from 'src/store/actions/creators/providerAction';
import { providersSelector } from 'src/store/selectors/providerSelector';

const AdminSubaccounts = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const params = useParams();

  const subaccounts = useSelector(subaccountsSelector);
  const subaccountsFetchStatus = useSelector(subaccountsFetchStatusSelector);
  const providers = useSelector(providersSelector);

  const [subaccount, setSubaccount] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [clientId, setClientId] = useState();
  const [submitErrors, setSubmitErrors] = useState({});

  useEffect(() => {
    if (params.clientId) {
      const clientId = parseInt(params.clientId, 10);
      setClientId(clientId);
      dispatch(fetchSubaccounts(clientId));
      dispatch(fetchAllProviders());
    }
  }, [params]);


  const handleShowModal = (subaccountId) => {
    setShowModal(true);
    setSubaccount(subaccountId ? subaccounts?.find((subaccount) => subaccount.id === subaccountId) : {});
    setSubmitErrors({});
  };

  const handleSubmit = async (data) => {
    try{
      if (data.id) {
        await dispatch(updateSubaccount(
          clientId,
          data.id,
          _.omit(data, ['id','credits'])
        ));
      } else {
        await dispatch(createSubaccount(clientId, _.omit(data, ["credits"])));
      }
      handleCloseModal(); 
      successMessageToast("Details successfully saved.")
    }catch(error){
      setSubmitErrors(getError(error));
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSubaccount({});
    setSubmitErrors({});
  };

  const removeSubAccountFunction = async (subaccountId) => {
    try {
      await dispatch(removeSubaccount(clientId ,subaccountId));
    } catch (err) {
      errorMessageToast(getError(err));
    }
  };
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-body">
          <div className="d-flex justify-content-between mb-4">
            <h4 className="card-title">
              {t('ADMIN.CLIENTS.SUBACCOUNTS')  + ' #' + clientId}
            </h4>
            <Button variant="primary" size="sm" className="btn-icon-text" onClick={() => handleShowModal()}>
              <i className="feather-user-plus btn-icon-prepend"></i>
              {t('ADMIN.SUBACCOUNTS.NEW_SUBACCOUNT')}
            </Button>
            {subaccountsFetchStatus && showModal && (
              <ClientForm
                handleSubmit={handleSubmit}
                handleCloseModal={handleCloseModal}
                client={subaccount}
                submitErrors={submitErrors}
                providers={providers}
              />
            )}
          </div>
          {subaccountsFetchStatus === STARTED ? (
            <Loading/>
          ) : subaccountsFetchStatus === SUCCEEDED && subaccounts.length > 0 ? (
            <SubaccountsList
              subaccounts={subaccounts}
              handleShowModal={handleShowModal}
              removeSubaccount={removeSubAccountFunction}
            />
          ) : (
            t('ADMIN.SUBACCOUNTS.CAN\'T_FETCH_SUBACCOUNTS')
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminSubaccounts;
