import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber, parsePhoneNumber } from  "libphonenumber-js/max";
import _ from 'lodash';

import ModalBody from './ModalBody';
import {
  userCreateStatusSelector,
  userUpdateStatusSelector
} from '../../../store/selectors/clientSelector';
import { useFirstRender } from '../../../utils/hooks';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { userFormInitialState } from '../../../utils/helpers';
import { STARTED, SUCCEEDED } from '../../../utils/helpers/store-helpers';

const DirectUserForm = ({
  user,
  handleSubmit,
  handleCloseModal,
  client,
  isRolePresent
}) => {
  const [t] = useTranslation();

  const userCreateStatus = useSelector(userCreateStatusSelector);
  const userUpdateStatus = useSelector(userUpdateStatusSelector);
  
  const firstRender = useFirstRender();

  const initialState = useMemo(() => userFormInitialState, []);

  const [inputs, setInputs] = useState(initialState);
  const [submitClicked, setSubmitClicked] = useState();
  const [phoneError, setPhoneError] = useState(false);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (client && client.credits) {
      let countries = Object.keys(client.credits).map((e) => _.lowerCase(e));
      setCountries(countries);
    }
  }, [client]);

  useEffect(() => {
    if (!firstRender) return;

    if (_.isEmpty(user)) setInputs(initialState);
    else setInputs({
      ...initialState,
      ..._.pick(user, _.keys(initialState)),
      id: user.id,
      autoGeneratePassword: false,
    });
  }, []);
  
  useEffect(() => {
    if (!firstRender && userCreateStatus === SUCCEEDED) handleCloseModal();
  }, [userCreateStatus]);

  useEffect(() => {
    if (!firstRender && userUpdateStatus === SUCCEEDED) handleCloseModal();
  }, [userUpdateStatus]);

  const handleSubmitButtonClick = () => setSubmitClicked(true);

  const onSubmit = (event) => {
    event.preventDefault();

    let parseNumber = parsePhoneNumber(inputs.phone);
 
    if(!isValidPhoneNumber(inputs.phone) || !countries.includes(_.lowerCase(parseNumber.country)) || parseNumber.getType() != 'MOBILE'){
      setPhoneError(true);
      return false;
    }else{
      setPhoneError(false);
      handleSubmit(inputs);
    }
  };

  return (
    <Modal show={true} onHide={handleCloseModal}>
      <Modal.Header closeButton={false}>
        <Modal.Title>
          {inputs.id ? t('ADMIN.SUBACCOUNTS.EDIT_ADDITIONAL_USER') : t('ADMIN.SUBACCOUNTS.INSERT_ADDITIONAL_USER')}
        </Modal.Title>
        <button type="button" className="close" aria-label="Close" onClick={handleCloseModal}>
          <span aria-hidden="true">x</span>
        </button>
      </Modal.Header>
      <Form className={'form-sample' + (submitClicked ? ' was-validated' : '')} onSubmit={onSubmit}>
        <ModalBody
          inputs={inputs}
          setInputs={setInputs}
          countries={countries}
          submitClicked={submitClicked}
          isRolePresent={isRolePresent}
          phoneError={phoneError}
        />
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t('COMMON.CLOSE')}
          </Button>
          <Button variant="primary" className="btn-icon-text" type="submit" onClick={handleSubmitButtonClick}>
            {userCreateStatus === STARTED || userUpdateStatus === STARTED ? (
              <Spinner className="mr-2" animation="border" variant="secondary" size="sm"/>
            ) : (
              <i className={`${inputs.id ? 'feather-save' : 'feather-user-plus'} btn-icon-prepend`}></i>
            )}
            {inputs.id ? t('COMMON.SAVE') : t('COMMON.INSERT')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default DirectUserForm;
