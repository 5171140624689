import { UserRoles } from '../constants';

function getClientAppId(appId) {
  return appId + 'a001';
}

function omit(obj, ...props) {
  const result = { ...obj };
  props.forEach(function (prop) {
    delete result[prop];
  });
  return result;
}

function pick(obj, ...props) {
  return props.reduce((result, prop) => {
    result[prop] = obj[prop];
    return result;
  }, {});
}

export const isCmtelecomProvider = () => {
  const localStorageUser = JSON.parse(localStorage.getItem('authUserData'));
  const sessionStorageUser = JSON.parse(sessionStorage.getItem('authUserData'));

  const storageUser = localStorageUser || sessionStorageUser;

  return storageUser.provider == 'cmtelecom';
}

export const getTemplateList = (t) => [
  { Header: t('COMMON.ID'), accessor: 'id' },
  { Header: t('COMMON.NAME'), accessor: 'name' },
  { Header: t('MEMBER.TEMPLATES.TEMPLATE'), accessor: 'body' },
  { Header: t('COMMON.STATUS'), accessor: 'status', },
];

export const sortFunc = (sortBy, sortOrder) => {
  return (item1, item2) => {
    if (item1[sortBy] > item2[sortBy]) return sortOrder === 'asc' ? 1 : -1;
    if (item1[sortBy] < item2[sortBy]) return sortOrder === 'asc' ? -1 : 1;
    return 0;
  };
};

export const subaccountFormInitialState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: UserRoles.Client,
  login: '',
  password: '',
  sendEmailWithCredentials: false,
  autoGeneratePassword: true,
  status: 'Active',
};

export const userFormInitialState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: '',
  login: '',
  password: '',
  sendEmailVerification: false,
  resendVerificationCode: false,
  autoGeneratePassword: true,
  status: 'Active',
  superadmin: false,
};

export const newCampaignFormInitialState = {
  name: '',
  from: '',
  startDate: '',
  template: '',
  message: '',
  groups: [],
  status: 'Active',
  numContacts: '',
  shortUrl: false,
  trackUrl: false
};

export const clientsFormInitialState = {
  email: '',
  company: '',
  address: '',
  city: '',
  zip: '',
  sendEmailVerification: false,
  monthlyMinPrice: '',
  pricePerSms: 1,
  credits: {},
  smsPricesPerCountry: {},
  fromFieldSet: '',
  prepaid: 'Yes',
  language: '',
  apiAccess: true,
  provider: '',
};

export const newContactFormInitialState = {
  phone: '',
  firstName: '',
  lastName: '',
  f1: '',
  f2: '',
  f3: '',
  f4: '',
  f5: '',
  f6: '',
  f7: '',
  f8: '',
  f9: '',
  status: 'Active',
  groups: [],
};

export const countryFormInitialState = {
  countryName: '',
  shortName: '',
  phoneCodes: '',
  purchasePrice: '',
  minimumPrice: ''
};

export const holidayFormInitialState = {
  holidayTitle: '',
  holidayDate: '',  
};

export const registerSettingInitialState = {
  countries: '',
  expirationDelay: '',
  creditAmount: '',
  emailNotification: '',
  sendEmailReminder: '',
  sendMobileReminder: '',
};

export const registeredUsersFormInitialState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  login: '',
  password: '',
  country: '',
  company: ''
};

export const getGroupListColumns = (t) => [
  { Header: t('COMMON.ID'), accessor: 'id' },
  { Header: t('COMMON.NAME'), accessor: 'name' },
  { Header: t('MEMBER.GROUPS.MEMBERS'), accessor: 'countMembers' },
  { Header: t('MEMBER.GROUPS.CREATION_DATE'), accessor: 'createdAt' },
];

export const getCreditColumns = (t, client) => [
  { Header: t('COMMON.COUNTRY'), accessor: 'country' },
  { Header: client?.prepaid == 'Yes'? t('COMMON.PREPAID_CREDITS') : t('COMMON.POSTPAID_CREDITS') , accessor: 'credits' },
];

export const getSmsStatusesColumns = (t) => [
  { Header: t('COMMON.PHONE'), accessor: 'phone' },
  { Header: t('MEMBER.SMS_STATUSES.SMS_ID'), accessor: 'smsId' },
  { Header: t('MEMBER.SMS_STATUSES.SENT_DATE_TIME'), accessor: 'sendDt' },
  { Header: t('COMMON.STATUS'), accessor: 'status' },
  { Header: t('MEMBER.SMS_STATUSES.UPDATED_DATE_TIME'), accessor: 'statusDt' },
];

export const getReportColumns = (t) => [
  { Header: t('COMMON.PHONE'), accessor: 'phone' },
  { Header: t('MEMBER.REPORTS.DATE'), accessor: 'sendDt' },
  { Header: t('COMMON.STATUS'), accessor: 'status' },
  { Header: t('COMMON.TYPE'), accessor: 'type' },
  { Header: t('MEMBER.REPORTS.LENGTH'), accessor: 'length' },
  { Header: t('MEMBER.REPORTS.HESH_SMS'), accessor: 'smsCount' },
  // { Header: t("MEMBER.REPORTS.CAMPAIGN"), accessor: "campaign" },
  // { Header: t('MEMBER.REPORTS.CONTACTS'), accessor: 'contacts' },
  { Header: t('COMMON.MESSAGE'), accessor: 'message' },
  { Header: t('MEMBER.REPORTS.SMSID'), accessor: 'smsId' },
];

export const getContactListColumns = (t) => [
  { Header: t('COMMON.ID'), accessor: 'id' },
  { Header: t('COMMON.PHONE'), accessor: 'phone' },
  { Header: t('COMMON.FIRST_NAME'), accessor: 'firstName' },
  { Header: t('COMMON.LAST_NAME'), accessor: 'lastName' },
  { Header: t('COMMON.STATUS'), accessor: 'status' },
  { Header: t('COMMON.GROUPS'), accessor: 'groups' },
  { Header: t('COMMON.CREATION_DATE'), accessor: 'createdAt' },
];
export const getCampaignListColumns = (t) => [
  { Header: t('COMMON.ID'), accessor: 'id' },
  { Header: t('COMMON.NAME'), accessor: 'name' },
  { Header: t('COMMON.MESSAGE'), accessor: 'message' },
  // { Header: t('MEMBER.CAMPAIGNS.NUMBER_OF_SMS'), accessor: 'numberOfSms' },
  { Header: t('MEMBER.CAMPAIGNS.NUMBER_OF_CONTACTS'), accessor: 'numContacts' },
  { Header: t('MEMBER.CAMPAIGNS.CREATION_DATE'), accessor: 'creationDate' },
  { Header: t('MEMBER.CAMPAIGNS.START_DATE'), accessor: 'startDate' },
  { Header: t('COMMON.STATUS'), accessor: 'status' },
];

export const getHolidayListColumns = (t) => [
  { Header: t('COMMON.TITLE'), accessor: 'holidayTitle' },
  { Header: t('COMMON.DATE'), accessor: 'holidayDate' },
];

export const getStopManagementListColumns = (t) => [
  { Header: t('COMMON.ID'), accessor: 'id' },
  { Header: t('REGISTRATION.PHONE_NUMBER'), accessor: 'phone' },
  { Header: t('COMMON.DATE_BLOCKED'), accessor: 'blockedDate' },
  { Header: t('COMMON.PROVIDER'), accessor: 'provider' },
];

export const getGeneralRoutes = () => [
  { name: 'About', to: '/about' },
  { name: 'Prices', to: '/prices' },
  { name: 'FAQ', to: '/faq' },
  { name: 'Contact', to: '/contact' },
];

export const getUnauthorizedRoutes = (t) => [
  { name: 'COMMON.LOGIN', to: '/login' },
  { name: 'REGISTRATION.REGISTER', to: '/register' }
];

export const getAuthorizedRoutes = (logout) => [
  { name: 'HEADER.MEMBER', to: '/member' },
  { name: 'HEADER.ADMIN', to: '/admin' },
  { name: 'HEADER.LOGOUT', to: '/', onClick: logout },
];

export const getAdminHomeClientTableRows = (t, client) => [
  { title: t('ADMIN.CLIENTS.COMPANY'), value: client.company },
  { title: t('COMMON.EMAIL'), value: client.email },
  { title: t('COMMON.CREDITS'), value: client.credits ? JSON.stringify(client.credits).replace(/[{}]/g, '') : t('ADMIN.CLIENTS.NO_CREDITS') },
  { title: t('ADMIN.CLIENTS.FROM_FIELDS_SET'), value: client.fromFieldSet || 'Not set' },
  { title: t('ADMIN.CLIENTS.ADDRESS'), value: client.address || '-' },
  { title: t('ADMIN.CLIENTS.CITY'), value: client.city || '-' },
  { title: t('ADMIN.CLIENTS.POSTAL_CODE'), value: client.zip || '-' },
];

export const getAdminHomeAdminTableRows = (t, client) => [
  { title: t('ADMIN.CLIENTS.APP_ID'), value: getClientAppId(client.smsAccountId), field: 'smsAccountId' },
  { title: t('ADMIN.CLIENTS.AUTH_CK'), value: client.smsCk, field: 'smsCk' },
  { title: t('ADMIN.CLIENTS.AUTH_AK'), value: client.smsSecretKey, field: 'smsSecretKey' },
  { title: t('ADMIN.CLIENTS.AUTH_PASS'), value: client.smsAccountPass, field: 'smsAccountPass' }
];

export const getGeneralColumns = (t) => [
  { Header: t('ADMIN.CLIENTS.ID'), accessor: 'id' },
  { Header: t('ADMIN.CLIENTS.LOGIN'), accessor: 'login' },
  { Header: t('COMMON.FIRST_NAME'), accessor: 'firstName' },
  { Header: t('COMMON.LAST_NAME'), accessor: 'lastName' },
];

export const getStatusColumn = (t) => [{ Header: t('COMMON.STATUS'), accessor: 'status' }];

export const getSubaccountListColumns = (t) => [
  ...getGeneralColumns(t),
  ...getStatusColumn(t),
  { Header: t('ADMIN.SUBACCOUNTS.LASTLOGIN'), accessor: 'lastLogin' },
  { Header: t('ADMIN.SUBACCOUNTS.IPADDRESS'), accessor: 'lastIp' },
];

export const getUserListColumns = (t) => [
  ...getGeneralColumns(t),
  { Header: t('ADMIN.SUBACCOUNTS.LASTLOGIN'), accessor: 'lastLogin' },
  { Header: t('ADMIN.SUBACCOUNTS.IPADDRESS'), accessor: 'lastIp' },
  ...getStatusColumn(t),
];

export const getRegisteredUserListColumns = (t) => [
  ...getGeneralColumns(t),
  { Header: t('ADMIN.CLIENTS.COMPANY'), accessor: 'account.company' },
  { Header: t('ADMIN.CLIENTS.CREDIT'), accessor: 'account.credits' },
  { Header: t('ADMIN.SUBACCOUNTS.LASTLOGIN'), accessor: 'lastLogin' },
  { Header: t('ADMIN.SUBACCOUNTS.IPADDRESS'), accessor: 'lastIp' },
  ...getStatusColumn(t),
];

export const getClientListColumns = (t) => [
  { Header: t('ADMIN.CLIENTS.EMAIL'), accessor: 'email' },
  { Header: t('ADMIN.CLIENTS.COMPANY'), accessor: 'company' },
  { Header: t('ADMIN.CLIENTS.CREDIT'), accessor: 'credits' },
  { Header: t('ADMIN.CLIENTS.FROM_FIELDS_SET'), accessor: 'fromFieldSet' },
  { Header: t('ADMIN.CLIENTS.BALANCE'), accessor: 'balance' },
];

export const getClientCredentialsListColumns = (t) => [
  { Header: t('ADMIN.CLIENTS.COMPANY'), accessor: 'company' },
  { Header: t('ADMIN.CLIENTS.AUTH_CK'), accessor: 'login' },
  { Header: t('ADMIN.CLIENTS.AUTH_AK'), accessor: 'smsSecretKey' },
  { Header: t('ADMIN.CLIENTS.AUTH_PASS'), accessor: 'smsAccountPass' },
  { Header: t('ADMIN.CLIENTS.CREDIT'), accessor: 'credits' },
  { Header: t('ADMIN.CLIENTS.FROM_FIELDS_SET'), accessor: 'fromFieldSet' },
];

export const getStatusRadioData = (t, status) => ({
  label: t('COMMON.STATUS'),
  buttons: [
    { checked: status === 'Active', name: 'status', label: t('COMMON.ACTIVE'), defaultValue: 'Active' },
    { checked: status === 'Inactive', name: 'status', label: t('COMMON.INACTIVE'), defaultValue: 'Inactive' },
    { checked: status === 'Suspended', name: 'status', label: t('ADMIN.CLIENTS.SUSPENDED'), defaultValue: 'Suspended' }
  ]
});

export const getGeneralFieldRows = (t) => [
  [{ title: t('COMMON.FIRST_NAME'), name: 'firstName', required: true }],
  [{ title: t('COMMON.LAST_NAME'), name: 'lastName', required: true }],
  [{ title: t('ADMIN.CLIENTS.EMAIL'), name: 'email', type: 'email', required: true }]
  // [{
  //   title: t('ADMIN.CLIENTS.PHONE'),
  //   name: 'phone',
  //   type: 'tel',
  //   required: true,
  //   placeholder: t('ADMIN.CLIENTS.EXAMPLE') + ' +33111222333'
  // }]
];


export const getPrepaidRadioData = (t, prepaid) => ({
  label: t('ADMIN.CLIENTS.PREPAID_ACCOUNT'),
  buttons: [
    { checked: prepaid === 'Yes', name: 'prepaid', label: t('ADMIN.CLIENTS.YES'), defaultValue: 'Yes' },
    { checked: prepaid === 'No', name: 'prepaid', label: t('ADMIN.CLIENTS.NO'), defaultValue: 'No' }
  ]
});

export const getCountryListColumns = (t) => [
  { Header: t('COMMON.ID'), accessor: 'id' },
  { Header: t('ADMIN.COUNTRY.COUNTRY_NAME'), accessor: 'countryName' },
  { Header: t('ADMIN.COUNTRY.SHORT_NAME'), accessor: 'shortName' },
  { Header: t('ADMIN.COUNTRY.PHONE_CODES'), accessor: 'phoneCodes' },
  { Header: t('ADMIN.COUNTRY.PURCHASE_PRICE'), accessor: 'purchasePrice' },
  { Header: t('ADMIN.COUNTRY.MINIMUM_PRICE'), accessor: 'minimumPrice' }
];

export const getClientDetailsTableRows = (t, country) => [
  { title: t('ADMIN.COUNTRY.COUNTRY_NAME'), value: country.countryName },
  { title: t('ADMIN.COUNTRY.SHORT_NAME'), value: country.shortName },
  { title: t('ADMIN.COUNTRY.PHONE_CODES'), value: country.phoneCodes },
  { title: t('ADMIN.COUNTRY.PURCHASE_PRICE'), value: country.purchasePrice },
  { title: t('ADMIN.COUNTRY.MINIMUM_PRICE'), value: country.minimumPrice },
  { title: t('ADMIN.COUNTRY.PROVIDER'), value: country.providersWithName }
];

export default {
  omit,
  pick,
  getUserAppId: getClientAppId
};
