import _ from "lodash";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import Logo from "../../assets/images/logo.png";
import { login } from "../../store/actions/creators/authActions";
import LoginBackgroundImg from "../../assets/images/login-background.jpeg";
import { MainContainer, FullPageContainer } from "../../components/Container";
import toast from "react-hot-toast";
import "../../assets/skydash/css/vertical-layout-light/style.css";
import { errorMessageToast } from "src/utils/common/helper";
import FRflag from "../../assets/images/flage.png";
import UKflag from "../../assets/images/UK_FLAGE.png";
import Select from 'react-select';

const Login = (props) => {
  const dispatch = useDispatch();

  const [t, i18n] = useTranslation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [keepSignedIn, setKeepSignedIn] = useState(false);
  const [isDisabledLogin, setIsDisabledLogin] = useState(false);
  const [errors, setErrors] = useState({});
  const [language, setLanguage] = useState(i18n.language != 'en' && i18n.language != 'fr' ? 'en' : i18n.language);
  console.log(i18n.language)
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsDisabledLogin(true);
    const trimmedUsername = _.trim(username);
    const trimmedPassword = _.trim(password);
    const err = {};
    if (_.isEmpty(trimmedUsername)) {
      err.username = "Username is required";
    }
    if (_.isEmpty(trimmedPassword)) {
      err.password = "Password is required";
    }

    if (!_.isEmpty(err)) {
      setErrors(err);
      setIsDisabledLogin(false);
      return;
    }
    try {
      await dispatch(login(trimmedUsername, trimmedPassword, keepSignedIn));
    } catch (error) {
      errorMessageToast(error.message);
    }
    setIsDisabledLogin(false);
  };

  useEffect(() => {
    if (!_.isEmpty(username) && !_.isEmpty(password)) {
      setErrors({});
    } else if (!_.isEmpty(username)) {
      setErrors({ password: errors.password });
    } else if (!_.isEmpty(password)) {
      setErrors({ username: errors.username });
    }
  }, [username, password]);

  const languageList = useMemo(() => [
    { value: 'en', label: <div><img width={24} src={UKflag} /> <b>EN</b></div> },
    { value: 'fr', label: <div><img width={24} src={FRflag} /> <b>FR</b></div> },
  ], []);

  const languageHandleChange=(op)=>{
    setLanguage(op.value);
    i18n.changeLanguage(op.value)
  }

  const selectStyle = {
    control: (base) => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: "none"
    }),
    indicatorsContainer: (prevStyle, state) => ({
      ...prevStyle,
      display: 'none'
    }),
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: "100%"
    }),
    input: (base) => ({
      ...base,
      color: 'transparent'
    })
  };

  return (
    <MainContainer>
      <Helmet>
        
      </Helmet>
      <FullPageContainer>
          <div className="d-flex align-items-center auth p-0">
            <div className="row  mx-auto">
              <div className="d-flex flex-column justify-content-center col-lg-6 col-xl-6">
                <div className="d-flex justify-content-left ml-5 mt-2" >
                  <Select
                    options={languageList}
                    name="language"
                    value={
                      languageList.filter(option => 
                          option.value === language)
                    }
                    onChange={languageHandleChange}
                    styles={selectStyle}
                    width={20}
                  ></Select>
                  {/* <Link
                    className="nav-link"
                    to="#"
                    onClick={() => i18n.changeLanguage("fr")}
                  >
                    <img width={30} src={FRflag} alt="French" />
                  </Link>
                  <Link
                    className="nav-link "
                    to="#"
                    onClick={() => i18n.changeLanguage("en")}
                  >
                    <img width={30} src={UKflag} alt="UK" />
                  </Link> */}
                </div>

                <div className="d-md-none divider border-bottom w-100 mb-4"></div>

                <div className="mx-auto px-md-4 ms-md-4">
                  <img
                    src={Logo}
                    className="img-fluid img-responsive h-75"
                    alt="SmsReflex"
                  />
                </div>

                <div className="w-75 mx-auto">
                  <form onSubmit={handleSubmit}>
                    <div className="form-outline mb-4">
                      <input
                        type="text"
                        className={`form-control form-control-lg border rounded border-dark${
                          errors && errors.username ? "is-invalid" : ""
                        }`}
                        name="username"
                        id="username"
                        onChange={({ target: { value } }) => setUsername(value)}
                        required
                        placeholder={t("COMMON.USERNAME")}
                      />

                      {errors && errors.username && (
                        <p className="text-danger">{errors.username}</p>
                      )}
                    </div>

                    <div className="form-outline mb-4">
                      <input
                        type="password"
                        className={`form-control form-control-lg border rounded border-dark${
                          errors && errors.password ? "is-invalid" : ""
                        }`}
                        name="password"
                        id="password"
                        onChange={({ target: { value } }) => setPassword(value)}
                        required
                        placeholder={t("COMMON.PASSWORD")}
                      />
                      {errors && errors.password && (
                        <p className="text-danger">{errors.password}</p>
                      )}
                    </div>

                    <div className="d-flex flex-row mb-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={keepSignedIn}
                        onChange={() => setKeepSignedIn(!keepSignedIn)}
                        id="keep-signed-in"
                      />
                      <label
                        className="form-check-label ml-4"
                        htmlFor="keep-signed-in"
                      >
                        <h6>{t("LOGIN.REMEMBER_ME")}</h6>
                      </label>
                    </div>
                    <div className="d-flex flex-row border-bottom border-dark align-items-center">
                      <div className="form-check form-check-inline d-flex flex-column col-4">
                        <div className="justify-content-center text-center d-flex">
                          <Link to="/forgotpassword">
                            <h6>{t("LOGIN.FORGOT_PASSWORD?")}</h6>
                          </Link>
                        </div>
                      </div>

                      <div className="mt-3 mb-4 col-8">
                        <button
                          disabled={isDisabledLogin}
                          className="btn btn-color text-white mb btn-lg font-weight-medium auth-form-btn w-100 px-0"
                          onClick={handleSubmit}
                        >
                          {t("LOGIN.SIGN_IN")}
                        </button>
                      </div>
                    </div>

                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <h5 className="m-4">{t("LOGIN.DONT_HAVE_AN_ACCOUNT")}</h5>
                      <Link className="link-info mr-5 w-25 " to="/register">
                        {'>>'+t("REGISTRATION.REGISTER")}
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
              <div className="d-none d-lg-flex col-lg-6 col-xl-6 p-0">
                <img
                  src={LoginBackgroundImg}
                  alt="Login image"
                  style={{ maxWidth: "100%", objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
      </FullPageContainer>
    </MainContainer>
  );
};

export default Login;
