import { getError } from '../common/helper';

export const STARTED = '_STARTED';
export const SUCCEEDED = '_SUCCEEDED';
export const FAILED = '_FAILED';

export const getStarted = (state) => state + STARTED;
export const getSucceeded = (state) => state + SUCCEEDED;
export const getFailed = (state) => state + FAILED;

export const calculateContactImportErrors = (payload) => {
  let errors = getError(payload);
  if (!errors) {
    const regex = /\"\[([0-9]+)\].([a-zA-Z ]+)\" (.+)/;
    const errorMessage = payload.response.data.message;
    const result = errorMessage.match(regex);
    if (result) {
      errors = {
        contactsList: `"${result[2]}" in row ${result[1]} ${result[3]}`,
      };
    } else {
      errors = { data: errorMessage };
    }
  }
  return errors;
};
