import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';

const PricePerSms = ({ inputs, errors, handleChange }) => {
  const [t] = useTranslation();

  return (
    <div className="row">
      <div className="col-md-6">
        <Form.Group className="form-group mb-3">
          <Form.Label>
            {t('ADMIN.CLIENTS.PRICE_PER_SMS')}
          </Form.Label>
            <Form.Control
              type="text"
              name="pricePerSms"
              value={inputs.pricePerSms??''}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.pricePerSms
                ? `aa`
                : ''}
            </Form.Control.Feedback>
        </Form.Group>
      </div>
    </div>
  );
};

export default PricePerSms;