import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import _ from "lodash";
import $ from "jquery";
import moment from "moment";

import ModalBody from "./ModalBody";
import { useFirstRender } from "../../../utils/hooks";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { newCampaignFormInitialState } from "../../../utils/helpers";
import { STARTED, SUCCEEDED } from "../../../utils/helpers/store-helpers";
import {
  campaignCreateStatusSelector,
  campaignUpdateStatusSelector,
} from "../../../store/selectors/campaignSelector";
import { fetchClient } from "src/store/actions/creators/clientActions";
import { clientSelector } from "src/store/selectors/clientSelector";
import { getHolidaysForValidation } from "src/store/actions/creators/holidayAction";
import { MESSAGE_MAX_LENGTH } from "src/utils/common/constants";
import { errorMessageToast } from "src/utils/common/helper";

const NewCampaignForm = ({
  campaign,
  handleSubmit,
  handleCloseModal,
  inputs,
  setInputs,
  tempMessage,
  setTempMessage,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const initialState = useMemo(() => newCampaignFormInitialState, []);
  const [localForm, setLocalForm] = useState(false);

  const campaignUpdateStatus = useSelector(campaignUpdateStatusSelector);
  const campaignCreateStatus = useSelector(campaignCreateStatusSelector);

  //const [inputs, setInputs] = useState(initialState);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [fromOptions, setFromOptions] = useState([]);
  const [validateHolidays, setValidateHolidays] = useState();
  const [messagePosition, setMessagePosition] = useState(false);
  const [dateErr, setDateErr] = useState("");

  const localStorageUser = JSON.parse(localStorage.getItem("authUserData"));
  const sessionStorageUser = JSON.parse(sessionStorage.getItem("authUserData"));

  const storageUser = localStorageUser || sessionStorageUser;
  const client = useSelector(clientSelector);

  useEffect(() => {
    if (client && client.fromFieldSet) {
      let temp = client.fromFieldSet.split(",");
      let options = temp.map((value) => ({ label: value, value: value }));
      setFromOptions(options);
    }
  }, [client]);

  const firstRender = useFirstRender();

  useEffect(() => {
    if (!firstRender) return;
    dispatch(fetchClient(storageUser.accountId));
    if (_.isEmpty(campaign)) {
      setInputs(initialState);
      setSubmitClicked(false);
    } else {
      setInputs({
        ...initialState,
        ...campaign,
        startDate: moment(campaign.startDate).toDate(),
        // startDate: moment(campaign.startDate).format('YYYY-MM-DDTHH:mm'),
        groups: campaign.groups.map((group) => ({
          value: group.id,
          label: group.name,
        })),
      });
      setLocalForm({ value: campaign.from, label: campaign.from });
      setSubmitClicked(false);
    }
  }, []);

  useEffect(() => {
    if (!firstRender && campaignCreateStatus === SUCCEEDED && handleCloseModal)
      handleCloseModal();
  }, [campaignCreateStatus]);

  useEffect(() => {
    if (!firstRender && campaignUpdateStatus === SUCCEEDED && handleCloseModal)
      handleCloseModal();
  }, [campaignUpdateStatus]);

  const handleSubmitButtonClick = () => {
    if (dateErr) {
      errorMessageToast(dateErr);
      setSubmitClicked(false);
    }
    setSubmitClicked(true);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (dateErr) {
      return false;
    }
    handleSubmit({
      ..._.omit(inputs, ["template"]),
      groups: inputs.groups.map(({ value }) => value),
      startDate: moment(inputs.startDate)
        .utc()
        .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
    });
  };

  const getValidateHolidays = async () => {
    try {
      const result = await dispatch(getHolidaysForValidation());
      if (result)
        setValidateHolidays(result.map((object) => object.holidayDate));
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getValidateHolidays();
  }, []);

  const disabledDate = (current) => {
    return !(
      moment(current).day() === 0 ||
      validateHolidays.find(
        (date) => date === moment(current).format("YYYY-MM-DD")
      )
    );
  };

  const handleChange = (
    { target: { name, value, selectionStart } },
    tempMessagePosition = null
  ) => {
    const startDate = moment(value).format("YYYY-MM-DD");
    if (validateHolidays.includes(startDate)) {
      setDateErr("This date is disable please select other date..");
    }
    if (name === "startDate" && !validateHolidays.includes(startDate)) {
      setDateErr("");
    }
    if (name === "template") {
      setInputs({
        ...inputs,
        [name]: value,
        message: value ? templates.find((elem) => elem.id == value).body : "",
      });
    } else {
      if (name === "message" && value.length > 4 * MESSAGE_MAX_LENGTH) {
        value = value.substring(0, 4 * MESSAGE_MAX_LENGTH);
      }
      if (name === "message" && tempMessagePosition == null) {
        setMessagePosition(false);
      }
      setInputs({
        ...inputs,
        [name]: value,
      });
    }
  };

  return (
    <Form
      className={"form-sample" + (submitClicked ? " was-validated" : "")}
      onSubmit={onSubmit}
    >
      <ModalBody
        messagePosition={messagePosition}
        setMessagePosition={setMessagePosition}
        dateErr={dateErr}
        disabledDate={disabledDate}
        handleChange={handleChange}
        inputs={inputs}
        setInputs={setInputs}
        fromOptions={fromOptions}
        localForm={localForm}
        setLocalForm={setLocalForm}
      />
      <Button
        variant="primary"
        className="btn-icon-text"
        type="submit"
        onClick={handleSubmitButtonClick}
      >
        {campaignCreateStatus === STARTED ||
        campaignUpdateStatus === STARTED ? (
          <Spinner
            className="mr-2"
            animation="border"
            variant="secondary"
            size="sm"
          />
        ) : (
          <i
            className={
              (inputs.id ? "feather-save" : "feather-plus") +
              " btn-icon-prepend"
            }
          ></i>
        )}
        {inputs.id ? t("COMMON.SAVE") : t("COMMON.SAVE")}
      </Button>
    </Form>
  );
};

export default NewCampaignForm;
