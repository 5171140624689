import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getAdminHomeAdminTableRows } from "../../../../utils/helpers";
import { errorMessageToast, getError } from "../../../../utils/common/helper";
import {
  refreshSecretKeys,
  fetchClient,
  accessFlags,
  fetchSubaccount,
} from "src/store/actions/creators/clientActions";
import { Link, useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import toast from "react-hot-toast";
import ConfirmationModal from "src/components/ConfirmationModal";
import { Form } from "react-bootstrap";
import { mdiAbTesting, mdiContentCopy } from "@mdi/js";
import Icon from "@mdi/react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const AdminTable = ({ client }) => {
  const [t] = useTranslation();

  const dispatch = useDispatch();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [apiAccess, setApiAccess] = useState(false);
  const [appAccess, setAppAccess] = useState(false);
  const [removeField, setRemoveField] = useState("");
  const [copiedValue, setCopiedValue] = useState("");

  const [isCopied, setIsCopied] = useState(false);

  const rows = useMemo(
    () => getAdminHomeAdminTableRows(t, client),
    [t, client]
  );

  const refreshSecretKey = async (field) => {
    try {
      setLoading(true);
      await dispatch(
        refreshSecretKeys(params.clientId, params.subaccountId, field)
      );
      await dispatch(
        params.subaccountId
          ? fetchSubaccount(params.clientId, params.subaccountId)
          : fetchClient(params.clientId)
      );
      toast.success("Key renewed successfully.");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      let err = getError(error);
      typeof err == "string" && errorMessageToast(err);
    }
  };

  const handleShowConfirmModal = (field) => {
    setRemoveField(field);
    setShowConfirmModal(true);
  };

  const handleRemoveField = () => {
    setShowConfirmModal(false);
    refreshSecretKey(removeField);
  };

  const accessChange = async ({ target: { name, value } }) => {
    try {
      setLoading(true);
      let body = {};
      if (name == "apiAccess") {
        body = { apiAccess: !apiAccess, appAccess };
        setApiAccess((state) => !state);
      } else {
        body = { appAccess: !appAccess, apiAccess };
        setAppAccess((state) => !state);
      }

      await dispatch(accessFlags(params.clientId, params.subaccountId, body));
      await dispatch(
        params.subaccountId
          ? fetchSubaccount(params.clientId, params.subaccountId)
          : fetchClient(params.clientId)
      );
      toast.success("Updated successfully.");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      let err = getError(error);
      typeof err == "string" && errorMessageToast(err);
    }
  };

  useEffect(() => {
    setApiAccess(client.apiAccess);
    setAppAccess(client.appAccess);
  }, [client]);

  const onCopyText = (val) => {
    setCopiedValue(val);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <>
      {showConfirmModal && (
        <ConfirmationModal
          showModal={showConfirmModal}
          handleConfirm={handleRemoveField}
          handleCancel={() => setShowConfirmModal(false)}
          title={t("COMMON.CONFIRM_MODAL_TITLE")}
          description={t("ADMIN.CLIENTS.RENEW_MODAL_DESCRIPTION")}
        />
      )}
      <h5 className="card-title">{t("ADMIN.CLIENTS.CREDENTIALS")}</h5>
      {loading && <Spinner animation="border" role="status" />}
      {!loading && (
        <table className="table user-view-table m-0">
          <tbody>
            {rows.map((el, index) => (
              <tr key={index}>
                <td>{el.title}</td>
                <td className="d-flex">
                  {el.value}{" "}
                  {el.field == "smsSecretKey" ||
                  el.field == "smsAccountPass" ? (
                    <Link
                      to="#"
                      className="mr-1"
                      onClick={(e) => {
                        e.preventDefault();
                        handleShowConfirmModal(el.field);
                      }}
                    >
                      <i className="feather-refresh-ccw btn-icon-prepend ml-2"></i>
                    </Link>
                  ) : (
                    ""
                  )}
                  <CopyToClipboard
                    text={el.value}
                    onCopy={(e) => onCopyText(e)}
                  >
                    <span className="pl-3" role="button">
                      {isCopied && copiedValue === el.value ? (
                       <h6 className="text-muted"> Copied!</h6> 
                      ) : (
                        <Icon path={mdiContentCopy} size={0.7}/>
                      )}
                    </span>
                  </CopyToClipboard>
                </td>
              </tr>
            ))}
            <tr>
              <td>API Access</td>
              <td>
                <Form.Group className="form-check form-check-flat form-check-primary mt-3">
                  <Form.Label className="form-check-label">
                    <Form.Control
                      type="checkbox"
                      name="apiAccess"
                      className="form-check-input"
                      checked={apiAccess}
                      onChange={accessChange}
                    />
                    <i className="input-helper"></i>
                  </Form.Label>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td>APP Access</td>
              <td>
                <Form.Group className="form-check form-check-flat form-check-primary mt-3">
                  <Form.Label className="form-check-label">
                    <Form.Control
                      type="checkbox"
                      name="appAccess"
                      className="form-check-input"
                      checked={appAccess}
                      onChange={accessChange}
                    />
                    <i className="input-helper"></i>
                  </Form.Label>
                </Form.Group>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
};

export default AdminTable;
