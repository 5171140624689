import React from "react";
import { useTranslation } from "react-i18next";

import {
  Title,
  Legend,
  Tooltip,
  ArcElement,
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { CHARTS_JS_COLORS as colors }  from "../../../utils/common/constants";

const TotalSMS = ({ totalSMS }) => {
  const [t] = useTranslation();

  ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    Title,
    Tooltip,
    Legend
  );

  const doughnutData = {
    labels: ["Sent", "Delivered", "Failed", "Blocked"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          totalSMS.sent,
          totalSMS.delivered,
          totalSMS.failed,
          totalSMS.blocked,
        ],
        backgroundColor: Object.values(colors.hashCodes),
        borderWidth: 1,
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
        labels: {
          boxWidth: 6,
          boxHeight: 6,
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
  };

  return (
    <>
      <p className="mb-0 font-regular text-muted font-weight-bold">
        {t("DASHBOARD.ALL_TIME_SMS")}
      </p>
      <div className="d-block d-sm-flex align-items-center">
        <div className="chart-wrapper-two doughnut">
          <Doughnut data={doughnutData} options={doughnutOptions} />
        </div>
      </div>
    </>
  );
};

export default TotalSMS;
