import React from 'react';
import TodaySMS from '../../../../Member/TodaySMS';
import YesterdaySMS from '../../../../Member/YesterdaySMS';
import Last7DaysSMS from '../../../../Member/Last7DaysSMS';
import Last30DaysSMS from '../../../../Member/Last30DaysSMS';

const Blocked = ({
  todaySMS,
  smsTimeRange,
  yesterdaySMS,
  last7DaysSMS,
  last30DaysSMS,
  navigateToReport
}) => {
  return (
    <div className="col-xl-2 col-lg-4 col-md-6">
      <div className="p-2 h-100 border-md-right border-bottom border-md-bottom-0">
        {smsTimeRange === 'today' && (
          <TodaySMS todaySMS={todaySMS.blocked} smsType="blocked" navigateToReport={navigateToReport} smsTimeRange={smsTimeRange}/>
        )}
        {smsTimeRange === 'yesterday' && (
          <YesterdaySMS yesterdaySMS={yesterdaySMS.blocked} smsType="blocked" navigateToReport={navigateToReport} smsTimeRange={smsTimeRange}/>
        )}
        {smsTimeRange === 'last7Days' && (
          <Last7DaysSMS last7DaysSMS={last7DaysSMS.blocked} smsType="blocked" navigateToReport={navigateToReport} smsTimeRange={smsTimeRange}/>
        )}
        {smsTimeRange === 'last30Days' && (
          <Last30DaysSMS last30DaysSMS={last30DaysSMS.blocked} smsType="blocked" navigateToReport={navigateToReport} smsTimeRange={smsTimeRange}/>
        )}
      </div>
    </div>
  );
}

export default Blocked;