import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const RadioFields = ({ radioData, handleChange, className }) => {
  const [t] = useTranslation();
  
  return (
    <div className="row">
      {
        radioData.map(el => (
          <div className={className} key={el.label}>
            <Form.Group className="form-group row mb-0">
              <Form.Label className={`${(el.buttons.length > 2 ? 'col-sm-2' : 'col-sm-4')} col-form-label`}>{el.label}</Form.Label>
              {
                el.buttons.map((button,index) => (
                  <div className="col-sm-3" key={el.label+index}>
                    <div className="form-check">
                      <Form.Label className="form-check-label">
                        <Form.Control
                          type="radio"
                          className="form-check-input"
                          name={button.name}
                          defaultValue={button.defaultValue}
                          checked={button.checked}
                          onChange={handleChange}
                        />
                        {button.label}
                        <i className="input-helper"></i>
                      </Form.Label>
                    </div>
                  </div>
                ))
              }
            </Form.Group>
          </div>
        ))
      }
    </div>
  );
};

export default RadioFields;