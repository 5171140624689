import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  totalUsersSelector,
  totalContactsSelector,
  todaySMSSelector,
  last30DaysSMSSelector,
  dashboardDataFetchStatusSelector,
  lastCampaignsSelector,
  futureCampaignsSelector
} from '../../../store/selectors/dashboardSelector';
import Loading from '../../../components/Shared/Loading';
import SmsInfo from '../../../components/AdminHome/Dashboard/SmsInfo';
import UserInfo from '../../../components/AdminHome/Dashboard/UserInfo';
import MonthlyInfo from '../../../components/AdminHome/Dashboard/MonthlyInfo';
import CampaignInfo from '../../../components/AdminHome/Dashboard/CampaignInfo';
import { fetchDashboardData } from '../../../store/actions/creators/dashboardActions';
import { STARTED, SUCCEEDED } from '../../../utils/helpers/store-helpers';

const AdminDashboard = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const totalUsers = useSelector(totalUsersSelector);
  const totalContacts = useSelector(totalContactsSelector);
  const todaySMS = useSelector(todaySMSSelector);
  const last30DaysSMS = useSelector(last30DaysSMSSelector);
  const dashboardDataFetchStatus = useSelector(dashboardDataFetchStatusSelector);
  const lastCampaigns = useSelector(lastCampaignsSelector);
  const futureCampaigns = useSelector(futureCampaignsSelector);

  const todayAllSMS = useMemo(() => Object.values(todaySMS).reduce(
    (acc, arr) => acc + arr.length, 0
  ), [todaySMS]);

  useEffect(() => {
    dispatch(fetchDashboardData());
  }, []);

  return (
    dashboardDataFetchStatus === SUCCEEDED
      ? (
        <>
          <UserInfo last30DaysSMS={last30DaysSMS} todayAllSMS={todayAllSMS} totalUsers={totalUsers} totalContacts={totalContacts} />
          <SmsInfo todayAllSMS={todayAllSMS} todaySMS={todaySMS}/>
          <MonthlyInfo last30DaysSMS={last30DaysSMS}/>
          <CampaignInfo lastCampaigns={lastCampaigns} futureCampaigns={futureCampaigns} />
        </>
      ) : dashboardDataFetchStatus === STARTED
        ? <Loading/>
        : t('DASHBOARD.CAN\'T_FETCH_DASHBOARD_DATA')
  );
};

export default AdminDashboard;
