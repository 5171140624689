import React from 'react';

import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SelectableContacts = ({
  actionWithSelected,
  actionsWithSelected,
  setActionWithSelected,
  applyActionWithSelectedItems
}) => {
  const [t] = useTranslation();
  
  return (
    <div className="row">
      <div className="col-sm-12 mb-2">
        <Form.Label className="mr-2">
          {t('MEMBER.CONTACTS.WITH_SELECTED')}
        </Form.Label>
        <Form.Select
          className="form-control w-25 d-inline-flex mr-2"
          size="sm"
          name="actionWithSelected"
          value={actionWithSelected}
          onChange={(event) => setActionWithSelected(event.target.value)}
        >
          <option value="">Choose action</option>
          {actionsWithSelected.map((action) => (
            <option key={action.name} value={action.name}>
              {action.name}
            </option>
          ))}
        </Form.Select>
        <Button variant="primary" size="sm" className="btn-icon-text" onClick={applyActionWithSelectedItems}>
          {t('COMMON.APPLY')}
        </Button>
      </div>
    </div>
  );
};

export default SelectableContacts;