import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';

const ConfirmationModal = ({ handleConfirm, showModal, handleCancel, title, description } ) => {
  const [t] = useTranslation();
  
  return (
    <Modal show={showModal} onHide={handleCancel}>
      <Modal.Header closeButton={false}>
        <Modal.Title>{title}</Modal.Title>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={handleCancel}
        >
          <span aria-hidden="true">x</span>
        </button>
      </Modal.Header>

      <Modal.Body>
        <p>{description}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={handleConfirm}>
          {t('COMMON.CONFIRM')}
        </Button>
        <Button variant="secondary" onClick={handleCancel}>
          {t('COMMON.CANCEL')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
