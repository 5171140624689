import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import { Form } from 'react-bootstrap';
import Tooltip from '../../../../Tooltip';
import { STARTED } from '../../../../../utils/helpers/store-helpers';
import { fetchContacts } from '../../../../../store/actions/creators/contactActions';
import { contactsSelector, contactsFetchStatusSelector } from '../../../../../store/selectors/contactSelector';

const MessageToContacts = ({ errors, inputContacts , setContacts }) => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const contacts = useSelector(contactsSelector);
  const contactsFetchStatus = useSelector(contactsFetchStatusSelector);

  useEffect(() => {
    dispatch(fetchContacts());
  }, []);

  const contactsList = useMemo(() => contacts.map((contact) => {
    if (contact.status === 'Active') return {
      value: contact.id,
      label: `${contact.phone} (${contact.firstName} ${contact.lastName})`,
    };
    else
      return {
        value: contact.id,
        label: `${contact.phone} (${contact.firstName} ${contact.lastName})`,
        disabled: true
      };
  }).filter(el => el), [contacts]);

  return (
    <Form.Group className="form-group mb-3">
      <Form.Label className="required">
        {t('COMMON.LIST')}
      </Form.Label>
      <Tooltip
        originalTitle={t('MEMBER.SMS.CONTACTS_LIST_TOOL_TIP')}
      />
      <Select
        options={contactsList}
        name="contacts"
        value={inputContacts}
        onChange={(value) => setContacts(value)}
        isLoading={contactsFetchStatus === STARTED}
        isOptionDisabled={(option) => option.disabled}
        isMulti
      ></Select>
      {errors?.contacts
        ? <Form.Control.Feedback
          type="invalid"
          className="d-block"
        >
          {t('MEMBER.SMS.CONTACTS_LIST') +
            ' ' +
            errors?.contacts}
        </Form.Control.Feedback>
        : ''
      }
    </Form.Group>
  );
};

export default MessageToContacts;