import React from "react";
import Tab from "../CampaignGeneralInfo/Tab";
import {
  mdiCalendarMonth,
  mdiEmailFast,
  mdiCardAccountDetails,
  mdiPackageVariantClosedCheck,
} from "@mdi/js";
import { useTranslation } from "react-i18next";
import moment from "moment";

const CampaignTab = ({ inputs }) => {
  const [t] = useTranslation();
  return (
    <div className="row">
      <div className="col-lg-12 grid-margin">
        <div className="card card-body ">
          <div className="row no-gutters">
            <Tab
              lg={3}
              title={t("COMMON.NUMBER_OF_SMS")}
              className={"p-2 border-lg-right border-bottom border-lg-bottom-0"}
              icon={mdiEmailFast}
              color={"#0000CD"}
              value={inputs.smsSent?inputs.smsSent:'--'}
            />
            <Tab
              lg={3}
              title={t("COMMON.FROM")}
              className={"p-2 border-lg-right border-bottom border-lg-bottom-0"}
              icon={mdiCardAccountDetails}
              color={"#0000CD"}
              value={inputs.from}
            />
            <Tab
              lg={3}
              title={t("COMMON.STATUS")}
              className={"p-2 border-lg-right border-bottom border-lg-bottom-0"}
              icon={mdiPackageVariantClosedCheck}
              color={"#0000CD"}
              value={inputs.status}
            />
            <Tab
              lg={3}
              title={t("MEMBER.SMS_STATUS.SENT_AT")}
              className={"p-2"}
              icon={mdiCalendarMonth}
              color={"#0000CD"}
              value={moment(inputs.creationDate).format("DD/MM/YYYY HH:mm")}
              horizontal
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignTab;
