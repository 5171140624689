import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  MainContainer,
  Container,
  MainPanel,
  ContentWrapper,
} from "../../components/Container";

import AdminHeader from "../../components/Admin/Header";
import AdminSideBar from "../../components/Admin/SideBar";
import Footer from "../../components/Footer";
import { logout } from "../../store/actions/creators/authActions";
import { userDataSelector } from "../../store/selectors/authSelector";

const AdminHome = () => {
  const userData = useSelector(userDataSelector);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  return (
    <MainContainer>
      <AdminHeader logout={() =>  dispatch(logout())} userData={userData} />
      <Container>
        <AdminSideBar />
        <MainPanel>
          <ContentWrapper>
            <Outlet />
          </ContentWrapper>
          <Footer />
        </MainPanel>
      </Container>
    </MainContainer>
  );
};

export default AdminHome;
