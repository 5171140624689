import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Auth from "../../containers/Auth";
import MemberHome from "../../containers/MemberHome";
import Groups from "../../containers/MemberHome/Groups";
import Reports from "../../containers/MemberHome/Reports";
import MyUsers from "../../containers/MemberHome/MyUsers";
import Statuses from "../../containers/MemberHome/Statuses";
import Contacts from "../../containers/MemberHome/Contacts";
import GroupContacts from "../../containers/MemberHome/GroupContacts";
import Campaigns from "../../containers/MemberHome/Campaigns";
import SmsStatus from "../../containers/MemberHome/SmsStatus";
import Templates from "../../containers/MemberHome/Templates";
import Dashboard from "../../containers/MemberHome/Dashboard";
import MemberProfile from "../../containers/MemberHome/Profile";
import SingleMessage from "../../containers/MemberHome/SingleMessage";
import Credit from "../../containers/MemberHome/Credit";
import Logout from "../../containers/Logout";
import { UserRoles } from "../../utils/constants";

import helpers from "../../utils/helpers";
import NewCampaign from "src/containers/MemberHome/NewCampaign";
import CampaignView from "src/components/Member/CampaignView";
import Holidays from "src/components/Holidays";
import StopManagement from "src/components/StopManagement";

const UserRoutes = (roles) => {
  return (
    <Routes>
      {roles.roles === UserRoles.Webmaster ||
      roles.roles === UserRoles.Editor ? (
        <Route path="*" element={<Navigate to="/member/singlemessage" />} />
      ) : (
        <Route path="*" element={<Navigate to="/member/dashboard" />} />
      )}
      <Route path="/logout" element={<Logout />} />
      <Route path="/member" element={<MemberHome />}>
        <Route
          element={
            <Auth
              notAllowedRoles={Object.values(
                helpers.pick(UserRoles, UserRoles.Marketing)
              )}
            />
          }
        >
          <Route
            element={
              <Auth
                notAllowedRoles={Object.values(
                  helpers.pick(UserRoles, UserRoles.Webmaster)
                )}
              />
            }
          >
            <Route path="statuses" element={<Statuses />} />
            <Route path="statuses/:smsId" element={<SmsStatus />} />
            <Route path="templates" element={<Templates />} />
            <Route path="myusers" element={<MyUsers />} />

            <Route
            element={
              <Auth
                notAllowedRoles={Object.values(
                  helpers.pick(UserRoles, UserRoles.Editor)
                )}
              />
            }
          >
            <Route path="credit" element={<Credit />} />
            </Route>
          </Route>
          <Route path="singlemessage" element={<SingleMessage />} />
          <Route path="campaign_list" element={<Campaigns />} />
          <Route path="new_campaign" element={<NewCampaign />} />
          <Route path="campaign/:campaignId" element={<NewCampaign />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="stops" element={<StopManagement  /> } />
          <Route path="blocked" element={<StopManagement />} />
          <Route path="contacts/group/:groupId" element={<GroupContacts />} />
          <Route path="campaign/view/:campaignId" element={<CampaignView />} />
          <Route path="groups" element={<Groups />} />
          <Route path="holidays" element={<Holidays />} />
        </Route>
        <Route path="profile" element={<MemberProfile />} />
        <Route
          element={
            <Auth
              notAllowedRoles={Object.values(
                helpers.pick(UserRoles, UserRoles.Webmaster)
              )}
            />
          }
        >
          <Route index path="dashboard" element={<Dashboard />} />
          <Route path="reports" element={<Reports />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default UserRoutes;
