import { createActionThunk } from 'redux-thunk-actions';

import {
  FETCH_CAMPAIGNS,
  FETCH_CAMPAIGN,
  CREATE_CAMPAIGN,
  UPDATE_CAMPAIGN,
  REMOVE_CAMPAIGN,
} from '../types/campaignTypes';
import CampaignService from '../../../services/campaigns';

export const fetchCampaigns = createActionThunk(FETCH_CAMPAIGNS, () =>
  CampaignService.fetchCampaigns().then((res) => res)
);

export const fetchCampaign = createActionThunk(FETCH_CAMPAIGN, (id) =>
  CampaignService.fetchCampaign(id).then((res) => res)
);

export const createCampaign = createActionThunk(CREATE_CAMPAIGN, (data) =>
  CampaignService.createCampaign(data).then((data) => data),
  false
);

export const updateCampaign = createActionThunk(UPDATE_CAMPAIGN, (id, data) =>
  CampaignService.updateCampaign(id, data).then((data) => data),
  false
);

export const removeCampaign = createActionThunk(REMOVE_CAMPAIGN, (id) =>
  CampaignService.removeCampaign(id).then((data) => ({ data })),
  false
);