import Icon from '@mdi/react';
import { useNavigate } from 'react-router-dom';

const InfoColumn = ({ classNameSuffix = '', iconPath, iconColor, text, count ,textStyle ,lg, redirect }) => {
  const navigate = useNavigate();

  const redirectTo = () => {
    if(redirect){
      navigate(redirect);
    }
  }

  return(
    <div className={`col-lg-3 col-xl-${lg} ${classNameSuffix}`} onClick={()=> redirectTo()} role="button">
      <div className="card h-100 mb-0">
        <div className="card-body">
          <div className="d-flex h-100">
            <div className="align-self-center">
              <div className="apexchart-wrapper">
                <img src={iconPath} width={44} />
              </div>
            </div>
            <div className="align-self-center ml-1">
              <h3 className="f-26 mb-0">
                <span className="count ml-5">{count}</span>
              </h3>
              <p className={`text-muted mb-0 ${textStyle}`}>
                {text}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default InfoColumn;