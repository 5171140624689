import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import Tabs from './Tabs';
import Sent from './Sent';
import Failed from './Failed';
import Blocked from './Blocked';
import Delivered from './Delivered';
import TotalSMS2 from '../../../Member/TotalSMS';
import {
  todaySMSSelector,
  totalSMSSelector,
  yesterdaySMSSelector,
  last7DaysSMSSelector
} from '../../../../store/selectors/dashboardSelector';

const StatisticsBar = ({ last30DaysSMS, campaignId }) => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const todaySMS = useSelector(todaySMSSelector);
  const totalSMS = useSelector(totalSMSSelector);
  const yesterdaySMS = useSelector(yesterdaySMSSelector);
  const last7DaysSMS = useSelector(last7DaysSMSSelector);

  const [smsTimeRange, setSmsTimeRange] = useState('today');

  const navigateToReport = (rangeType,smsType) => {

    let status = smsType == 'sent' ? 'delivering' : smsType;
    let cmp = ''
    if(campaignId && campaignId != 'all'){
      cmp = `&campaignId=${campaignId}`
    }
    if(rangeType == 'today'){
      navigate(`/member/reports?startDate=${moment().format('YYYY/MM/DD')}&endDate=${moment().format('YYYY/MM/DD')}&status=${status}${cmp}`);
    }
    if(rangeType == 'yesterday'){
      navigate(`/member/reports?startDate=${moment().subtract(1, 'days').format('YYYY/MM/DD')}&endDate=${moment().subtract(1, 'days').format('YYYY/MM/DD')}&status=${status}${cmp}`);
    }
    if(rangeType == 'last7Days'){
      navigate(`/member/reports?startDate=${moment().subtract(7, 'days').format('YYYY/MM/DD')}&endDate=${moment().format('YYYY/MM/DD')}&status=${status}${cmp}`);
    }
    if(rangeType == 'last30Days'){
      navigate(`/member/reports?startDate=${moment().subtract(30, 'days').format('YYYY/MM/DD')}&endDate=${moment().subtract(1, 'days').format('YYYY/MM/DD')}&status=${status}${cmp}`);
    }
  };

  return (
    <div className="row">
      <div className="col-sm-12 grid-margin">
        <div className="card card-body">
          <div className="row no-gutters">
            <Tabs setSmsTimeRange={setSmsTimeRange}/>
            <Sent
              todaySMS={todaySMS}
              yesterdaySMS={yesterdaySMS}
              last7DaysSMS={last7DaysSMS}
              smsTimeRange={smsTimeRange}
              last30DaysSMS={last30DaysSMS}
              navigateToReport={navigateToReport}
            />
            <Delivered
              todaySMS={todaySMS}
              smsTimeRange={smsTimeRange}
              yesterdaySMS={yesterdaySMS}
              last7DaysSMS={last7DaysSMS}
              last30DaysSMS={last30DaysSMS}
              navigateToReport={navigateToReport}
            />
            <Failed
              todaySMS={todaySMS}
              yesterdaySMS={yesterdaySMS}
              last7DaysSMS={last7DaysSMS}
              last30DaysSMS={last30DaysSMS}
              smsTimeRange={smsTimeRange}
              navigateToReport={navigateToReport}
            />
            <Blocked
              todaySMS={todaySMS}
              yesterdaySMS={yesterdaySMS}
              last7DaysSMS={last7DaysSMS}
              last30DaysSMS={last30DaysSMS}
              smsTimeRange={smsTimeRange}
              navigateToReport={navigateToReport}
            />
            <div className="col-xl-2 col-lg-4 col-md-6">
              <div className="p-2">
                <TotalSMS2 totalSMS={totalSMS}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsBar;