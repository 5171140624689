import Tooltip from "src/components/Tooltip";

const LabeledSelect = ({ label, name, setValue, placeholder, value, options, required = true, tooltip }) => {
  return (
    <div className="form-group">
      <label>{label}</label>
      { tooltip && <Tooltip
        originalTitle={tooltip}
      /> }
      <select
        className="form-control"
        size="sm"
        name={name}
        onChange={({ target: { value } }) => setValue(value)}
        value={value}
        required={required}
      >
        <option value="" disabled>{placeholder}</option>
        {options.map((el) => <option key={el} value={el}>{el}</option>)}
      </select>
    </div>
  );
};

export default LabeledSelect;