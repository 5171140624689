import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";

import Loading from "../../../components/Shared/Loading";
import MessageInfo from "../../../components/MemberHome/SmsStatus/MessageInfo";
import DateInfo from "../../../components/MemberHome/SmsStatus/DateInfo";
import { fetchSmsStatuses } from "../../../store/actions/creators/smsActions";
import {
  FAILED,
  STARTED,
  SUCCEEDED,
} from "../../../utils/helpers/store-helpers";
import GeneralInfoBar from "../../../components/MemberHome/SmsStatus/GeneralInfoBar";
import {
  smsListSelector,
  smsStatusesFetchStatusSelector,
} from "../../../store/selectors/smsSelector";
import UrlInfo from "src/components/MemberHome/SmsStatus/UrlInfo";

const SmsStatus = () => {
  const [t] = useTranslation();

  const params = useParams();

  const dispatch = useDispatch();

  const smsList = useSelector(smsListSelector);
  const smsStatusesFetchStatus = useSelector(smsStatusesFetchStatusSelector);

  const [smsId, setSmsId] = useState();

  useEffect(() => {
    const smsId = parseInt(params.smsId, 10);
    setSmsId(smsId);
  }, []);

  useEffect(() => {
    if (!smsStatusesFetchStatus || smsStatusesFetchStatus === FAILED)
      dispatch(fetchSmsStatuses());
  }, []);

  const smsStatus = useMemo(
    () => _.find(smsList, (smsStatus) => smsStatus.id === smsId),
    [smsList, smsId, smsStatusesFetchStatus]
  );

  return smsStatusesFetchStatus === SUCCEEDED && smsStatus ? (
    <>
      <GeneralInfoBar inputs={smsStatus} />
      <DateInfo inputs={smsStatus} />
      <MessageInfo inputs={smsStatus} />
      <UrlInfo inputs={smsStatus} />
    </>
  ) : smsStatusesFetchStatus === STARTED ? (
    <Loading />
  ) : (
    t("MEMBER.SMS_STATUSES.CAN'T_FETCH_SMS_STATUSES")
  );
};

export default SmsStatus;
