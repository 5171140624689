import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Gravatar from 'react-gravatar';
import UpdateProfileForm from '../../../components/AdminHome/Profile/UpdateProfile';
import { updateProfile } from '../../../store/actions/creators/authActions';
import toast from 'react-hot-toast';
import {getError, successMessageToast} from '../../../utils/common/helper'

const AdminProfile = () => {
  const dispatch = useDispatch();
  const localStorageUser = JSON.parse(localStorage.getItem('authUserData'));
  const sessionStorageUser = JSON.parse(sessionStorage.getItem('authUserData'));

  const storageUser = localStorageUser || sessionStorageUser;

  const defaultUserState = {
    firstName: storageUser.firstName,
    lastName: storageUser.lastName,
    login: storageUser.login,
    email: storageUser.email,
    phone: storageUser.phone,
    role: storageUser.role,
  };

  const [user, setUser] = useState(defaultUserState);

  const [inputs, setInputs] = useState();
  const [errors, setErrors] = useState({});
  const [submitClicked, setSubmitClicked] = useState(false);

  useEffect(() => {
    setInputs({
      ...user,
    });
  },[user]);

  const handleChange = ({ target: { name, value } }) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    
    try{
      await dispatch(updateProfile(inputs));
      successMessageToast("Details successfully saved.")
    }catch(error){
      setErrors(getError(error));
    }
  };

  const handleSubmitButtonClick = (event) => setSubmitClicked(true);

  return (
    <div className="container-fluid d-flex flex-column justify-content-center">
      {/* <div className="d-flex">
        <Gravatar email={user.email} rating={'pg'} className="rounded"/>
        <h2 className="ml-2">{user.fullName}</h2>
      </div> */}

      <div className="col-md-12 mt-4">
        <div className="card mb-3">
          <div className="card-body">
          <Form className={'form-sample' + (submitClicked ? ' was-validated' : '')} onSubmit={onSubmit}>
            {inputs && <UpdateProfileForm 
              errors={errors}
              inputs={inputs}
              setInputs={setInputs}
              handleChange={handleChange}
              handleSubmitButtonClick={handleSubmitButtonClick}
            />
          }
          </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminProfile;
