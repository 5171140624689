import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { useParams } from "react-router-dom";
import { fetchCampaign } from "../../../store/actions/creators/campaignActions";
import { campaignSelector } from "src/store/selectors/campaignSelector";
import CampaignTab from "../../CampaignTab";
import CampaignSmsInfo from "../../CampaignSmsInfo";
import CampaignGeneralInfo from "../../CampaignGeneralInfo";
import CampaignUrlInfo from "src/components/CampaignUrlInfo";

const CampaignView = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();

  const inputs = useSelector(campaignSelector);

  useEffect(() => {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }, []);

  useEffect(() => {
    if (params.campaignId) dispatch(fetchCampaign(params.campaignId));
  }, [params]);
  return (
    <>
      <CampaignTab inputs={inputs} />
      <CampaignSmsInfo inputs={inputs} />
      <CampaignGeneralInfo inputs={inputs} />
      <CampaignUrlInfo inputs={inputs} />
    </>
  );
};

export default CampaignView;
