import { Link } from 'react-router-dom';
import React from 'react';
import HeaderText from '../../HeaderText';

const FormHeaderLink = () => (
  <div>
    <Link to="/" className="navbar-brand brand-logo mr-5">
      <h1>
        <HeaderText />
      </h1>
    </Link>
  </div>
);

export default FormHeaderLink;