import React from "react";
import moment from "moment";
import "./style.css";
import { t } from "i18next";

const IPhone = (props) => {
  const time = moment().format("hh:mm");
  const { from, message } = props;
  return (
    <div className="iphone" id="iphone">
      <div className="silence-switch outer-button"></div>
      <div className="volume-rocker-top outer-button"></div>
      <div className="volume-rocker-bottom outer-button"></div>
      <div className="power-button outer-button-reversed"></div>
      <div className="top-section">
        <i className="arrow left"></i>
        <div className="top-section-time">{time}</div>
        <div className="top-section-symbols">
          <i className="signal-icon"></i>
          <i className="wifi"></i>
          <i className="battery-icon"></i>
        </div>
        <div className="top-section-middle">
          <div className="speaker">
            <div className="front-camera"></div>
          </div>
          <div className="top-section-user-pic selectDisable">
            <i className="fa-user-circle"></i>
          </div>
          <div className="top-section-user-name">{from.toUpperCase() || t("COMMON.UNKNOWN")}</div>
        </div>
        <div className="messages-section" id="messages-left">
          {message && <div className="message from">{message}</div>}
        </div>
      </div>
    </div>
  );
};

export default IPhone;
