import React from "react";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js/max";
import PhoneInput from "react-phone-input-2";
import { Spinner } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";

const CustomPhoneInput = ({ phone, countries, setPhone, setPhoneValid }) => {
  const [t] = useTranslation();

  return (
    // <div className="m-1">
    //   {countries.length > 0 ? (
    //     <PhoneInput
    //       placeholder={t("REGISTRATION.PHONE_NUMBER")}
    //       value={phone}
    //       onChange={(p) => setPhone("+" + p)}
    //       onlyCountries={countries}
    //       country={countries[0]}
    //       isValid={(value, country) => {
    //         let parse = parsePhoneNumber("+" + value);
            
    //         if (parse && parse.number.length > 6) {
    //           console.log(parse, parse.number.length, countries.includes(_.lowerCase(parse.country)), isValidPhoneNumber(parse.number))
    //           if (
    //             countries.includes(_.lowerCase(parse.country)) &&
    //             isValidPhoneNumber(parse.number)
    //           ) {
    //             setPhoneValid(true);
    //             return true;
    //           } else {
    //             setPhoneValid(false);
    //             return false;
    //           }
    //         } else {
    //           setPhoneValid(false);
    //           return true;
    //         }
    //       }}
    //     />
    //   ) : (
    //     <Spinner
    //       className="mr-2"
    //       animation="border"
    //       variant="secondary"
    //       size="sm"
    //     />
    //   )}
    // </div>
    <div className="m-1">
     {countries?.length > 0 ? (
       <PhoneInput
         placeholder={t("REGISTRATION.PHONE_NUMBER")}
         value={phone}
         onChange={(p) => setPhone("+" + p)}
         onlyCountries={countries}
         country={countries[0]}
         isValid={(value, country) => {
           let parse = value.length > 9 ? parsePhoneNumber("+" + value) : false;
           if (parse) {
             if (
               countries.includes(_.lowerCase(parse.country)) &&
               `+${value}` &&
               isValidPhoneNumber(`+${value}`) && parse.getType() == 'MOBILE'
             ) {
               return true;
             } else if (country.countryCode !== parse.countryCallingCode) {
               return false;
             } else {
               return true;
             }
           } else {
             return true;
           }
         }}
       />
     ) : (
       <Spinner
         className="mr-2"
         animation="border"
         variant="secondary"
         size="sm"
       />
     )}
   </div>
  );
};

export default CustomPhoneInput;
