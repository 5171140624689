import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Icon from '@mdi/react';
import { mdiArrowDown, mdiArrowUp } from '@mdi/js';
import { getClientListColumns, sortFunc } from '../../../../utils/helpers';

const Table = ({
  page,
  sortBy,
  perPage,
  sortOrder,
  handleShowModal,
  handleChangeSort,
  filteredSubaccounts,
  handleShowConfirmModal
}) => {
  const [t] = useTranslation();
  
  function sumOfCredits(obj) {
    let sum = 0;
    for (var el in obj) {
      if (obj.hasOwnProperty(el)) {
        sum += parseFloat(obj[el]);
      }
    }
    return sum;
  }

  const columns = useMemo(() => getClientListColumns(t), [t]);

  return (
    <div className="row">
      <div className="col-sm-12 mb-2 overflow-auto">
        <table className="table table-bordered table-striped table-hover">
          <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.accessor} className="justify-content-between">
                {column.Header}{' '}
                <Link to="#" onClick={() => handleChangeSort(column.accessor)}>
                  {sortBy === column.accessor
                    ? sortOrder === 'desc'
                      ? <Icon path={mdiArrowDown} size={0.6} color="#4B49AC"/>
                      : <Icon path={mdiArrowUp} size={0.6} color="#4B49AC"/>
                    : <Icon path={mdiArrowDown} size={0.6} color="#CCC"/>
                  }
                </Link>
              </th>
            ))}
            <th>{t('ADMIN.CLIENTS.ACTIONS')}</th>
          </tr>
          </thead>
          <tbody>
          {filteredSubaccounts
            .sort(sortFunc(sortBy, sortOrder))
            .slice((page - 1) * perPage, page * perPage)
            .map((row) => (
              <tr key={row.id}>
                {columns.map((column) => (
                  <td key={`${row.id}-${column.accessor}`}>
                    { row[column.accessor] == null ? '-':
                    column.accessor === "credits" ? (
                      <div onClick={() => showCreditModal(row)}>
                        <Link to="#">{sumOfCredits(row[column.accessor])}</Link>
                      </div>
                    )
                    : 
                      (typeof row[column.accessor] === 'object' ?
                          JSON.stringify(row[column.accessor]).replace(/[{}]/g, '') : row[column.accessor]) 
                    }
                  </td>
                ))}
                <td>
                  <Link to="#" className="mr-1" onClick={(e) => {
                    e.preventDefault()
                    handleShowModal(row.id)
                  }}>
                    <i className="feather-edit btn-icon-prepend"></i>
                  </Link>
                  <Link to={`/admin/clients/${row.ownerId}/subaccounts/${row.id}`}>
                    <i className="feather-eye btn-icon-prepend"></i>
                  </Link>
                  <Link to="#" className="ml-1" onClick={(e) => {
                    e.preventDefault()
                    handleShowConfirmModal(row.id)
                  }}>
                    <i className="feather-trash-2 btn-icon-prepend"></i>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;