import { Modal } from "react-bootstrap";
import CreditLists from "../CreditLists";
import { useTranslation } from "react-i18next";

const ClientModalBody = ({ client, handleCloseCreditListModal }) => {
  const [t] = useTranslation();
  return (
    <div className="App">
      <Modal show={true} size="lg">
        <Modal.Header>
          <Modal.Title>{t('COMMON.CREDITS')}</Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={handleCloseCreditListModal}
          >
            <span aria-hidden="true">X</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <CreditLists client={[client]} />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ClientModalBody;
