import { createActionThunk } from 'redux-thunk-actions';

import { CREATE_SMS, FETCH_SMS_STATUSES } from '../types/smsTypes';
import SmsService from '../../../services/sms';

export const createSms = createActionThunk(CREATE_SMS, (data) =>
    SmsService.createSms(data).then((data) => data),
  false
);

export const fetchSmsStatuses = createActionThunk(FETCH_SMS_STATUSES, (clientId) => 
    SmsService.fetchSmsStatuses(clientId).then((data) => data ),
  false
);
