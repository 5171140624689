/**
 * Service for Sms Contacts
 */
import FormData from "form-data";

import useApi from "../utils/api";

class ContactsService {
  #prefix = "/contacts";

  fetchContacts = (clientId, status) => {
    return clientId === ""
      ? useApi.get(`${this.#prefix}?clientId=${clientId}&status=${status}`)
      : clientId && typeof clientId != "object"
      ? useApi.get(`${this.#prefix}?clientId=${clientId}&status=${status}`)
      : useApi.get(`${this.#prefix}`);
  };

  createContact = (data) => {
    return useApi.post(this.#prefix, data);
  };

  importContacts = (data) => {
    const formData = new FormData();
    formData.append("file", data.contactsList);
    formData.append("groups", data.groups);
    return useApi.post(`${this.#prefix}/import-contacts`, formData);
  };

  updateContact = (id, data) => {
    return useApi.put(`${this.#prefix}/${id}`, data);
  };

  changeContactStatus = (id,data) => {
    return useApi.put(`${this.#prefix}/${id}/changeStatus`, data);
  };
  
  removeContact = (id) => {
    return useApi.delete(`${this.#prefix}/${id}`);
  };

  removeManyContacts = (contacts) => {
    return useApi.post(`${this.#prefix}/delete-contacts`, {
      contacts,
    });
  };

  contactsAddGroups = (data) => {
    return useApi.post(`${this.#prefix}/add-groups`, data);
  };
}

export default new ContactsService();
