import { createActionThunk } from "redux-thunk-actions";

import {
  FETCH_REGISTRATION_SETTING,
  CREATE_REGISTRATION_SETTING,
  UPDATE_REGISTRATION_SETTING,
} from "../types/registrationSettingTypes";
import RegistrationSettingService from "../../../services/registrationSetting";

export const fetchRegistrationSetting = createActionThunk(
  FETCH_REGISTRATION_SETTING,
  (id) =>
    RegistrationSettingService.fetchRegistrationSetting(id).then((data) => ({
      data,
    })),
  false
);

export const createRegistrationSetting = createActionThunk(
  CREATE_REGISTRATION_SETTING,
  (data) =>
    RegistrationSettingService.createRegistrationSetting(data).then((data) => ({
      data,
    })),
  false
);

export const updateRegistrationSetting = createActionThunk(
  UPDATE_REGISTRATION_SETTING,
  (id, data) =>
    RegistrationSettingService.updateRegistrationSetting(id, data).then(
      (data) => ({ data })
    ),
  false
);
