import FormGroup from '../FormGroup';

const FormRow = ({id, row, errors, inputs, handleChange ,formColumn}) => {
  
  return (
    <div className="row">
      <div className={formColumn?"col-md-12":"col-md-6"}>
        {
          row.map((col) => (
            <FormGroup 
              key={id+col.name}
              errors={errors}
              title={col.title}
              value={inputs[col.name]}
              type={col.type}
              handleChange={handleChange}
              disabled={col.disabled}
              className={col.className}
              name={col.name}
              required={col.required}
              placeholder={col.placeholder}
            />
          ))
        }
      </div>
    </div>
  );
};

export default FormRow;
