import { combineReducers } from 'redux';

import smsReducer from './smsReducer';
import authReducer from './authReducer';
import groupReducer from './groupReducer';
import clientReducer from './clientReducer';
import contactReducer from './contactReducer';
import templateReducer from './templateReducer';
import campaignReducer from './campaignReducer';
import dashboardReducer from './dashboardReducer';
import countryReducer from './countryReducer';
import providerReducer from './providerReducer';
import holidayReducer from './holidayReducer';
import registrationSettingReducer from './registrationSettingReducer';

import { LOGOUT } from '../actions/types/authTypes';
import { getSucceeded } from '../../utils/helpers/store-helpers';

const reducers = combineReducers({
  sms: smsReducer,
  auth: authReducer,
  group: groupReducer,
  client: clientReducer,
  contact: contactReducer,
  campaign: campaignReducer,
  template: templateReducer,
  dashboard: dashboardReducer,
  country: countryReducer,
  provider: providerReducer,
  registrationSetting: registrationSettingReducer,
  holiday:holidayReducer,
});

const rootReducer = (state, action) => {
  if (action.type === getSucceeded(LOGOUT)) {
    state = {};
  }

  return reducers(state, action);
};

export default rootReducer;
