import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal } from 'react-bootstrap';
import CustomPhoneInput from "src/components/Shared/PhoneInput";
import CreatableSelect from 'react-select/creatable';

const ModalBody = ({
  errors,
  inputs,
  groupsList,
  handleChange,
  handleChangeGroups,
  showAdditionalFields,
  toggleShowAdditionalFields,
  countries
}) => {
  const [t] = useTranslation();

  const [phone, setPhone] = useState('');
  const [phoneValid, setPhoneValid] = useState('');

  useEffect(()=>{
    handleChange({ 'target': {'name': 'phone', 'value': phone }})
  },[phone])

  return (
    <Modal.Body>
      <div className="row">
        <div className="col-md-12">
          <Form.Group className="form-group mb-3">
            <Form.Label className="required">
              {t('COMMON.PHONE')}
            </Form.Label>
            <Form.Control
                as = {CustomPhoneInput}
                phone={inputs.phone}
                countries={countries} 
                setPhone={setPhone} 
                setPhoneValid={setPhoneValid}
            />
        
            {errors?.phone && (
              <p className="text-danger text-sm pt-2 4xl:text-3xl ">
                {errors?.phone}
              </p>
            )}
          </Form.Group>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <Form.Group className="form-group mb-3">
            <Form.Label>
              {t('COMMON.FIRST_NAME')}
            </Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              isInvalid={Boolean(errors?.firstName)}
              placeholder={t('COMMON.FIRST_NAME')}
              value={inputs.firstName}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.firstName &&
                t('COMMON.FIRST_NAME') + ' ' + errors?.firstName}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <Form.Group className="form-group mb-3">
            <Form.Label>
              {t('COMMON.LAST_NAME')}
            </Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              isInvalid={Boolean(errors?.lastName)}
              placeholder={t('COMMON.LAST_NAME')}
              value={inputs.lastName}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.lastName &&
                t('COMMON.LAST_NAME') + ' ' + errors?.firstName}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mb-3">
          <Button variant="primary" size="sm" className="btn-icon-text" onClick={toggleShowAdditionalFields}>
            {!showAdditionalFields ? t('MEMBER.CONTACTS.SHOW_ADDITIONAL_FIELDS') : t('MEMBER.CONTACTS.HIDE_ADDITIONAL_FIELDS')}
          </Button>
        </div>
      </div>

      {showAdditionalFields &&
        new Array(9).fill().map((val, idx) => (
          <div key={idx} className="row">
            <div className="col-md-12">
              <Form.Group className="form-group mb-3 row">
                <div className="col-md-1">
                  <Form.Label className="pt-2">f{idx + 1}:</Form.Label>
                </div>
                <div className="col-md-11">
                  <Form.Control 
                    type="text"
                    name={'f' + (idx + 1)}
                    value={inputs[`f${idx + 1}`]}
                    onChange={handleChange}/>
                </div>
              </Form.Group>
            </div>
          </div>
        ))}

      <div className="row">
        <div className="col-md-12">
          <Form.Group className="form-group row mb-0">
            <Form.Label className="col-sm-2 col-form-label">
              {t('COMMON.STATUS')}
            </Form.Label>
            <div className="col-sm-3">
              <div className="form-check">
                <Form.Label className="form-check-label">
                  <Form.Control
                    type="radio"
                    className="form-check-input"
                    name="status"
                    defaultValue="Active"
                    checked={inputs.status === 'Active'}
                    onChange={handleChange}
                  />
                  {t('COMMON.ACTIVE')}
                  <i className="input-helper"></i>
                </Form.Label>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-check">
                <Form.Label className="form-check-label">
                  <Form.Control
                    type="radio"
                    className="form-check-input"
                    name="status"
                    defaultValue="Inactive"
                    checked={inputs.status === 'Inactive'}
                    onChange={handleChange}
                  />
                  {t('COMMON.INACTIVE')}
                  <i className="input-helper"></i>
                </Form.Label>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-check">
                <Form.Label className="form-check-label">
                  <Form.Control
                    type="radio"
                    className="form-check-input"
                    name="status"
                    defaultValue="Blocked"
                    checked={inputs.status === 'Blocked'}
                    onChange={handleChange}
                  />
                  {t('COMMON.BLOCKED')}
                  <i className="input-helper"></i>
                </Form.Label>
              </div>
            </div>
          </Form.Group>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <Form.Group className="form-group mb-0">
            <Form.Label className="required">
              {t('COMMON.GROUPS')}
            </Form.Label>
            {/* <Select
              options={groupsList}
              name="groups"
              value={inputs.groups}
              onChange={handleChangeGroups}
              isMulti
            ></Select> */}
            <CreatableSelect
                options={groupsList}
                name="from"
                value={inputs.groups}
                onChange={handleChangeGroups}
                isMulti
              />
            {errors?.groups ? (
              <Form.Control.Feedback type="invalid" className="d-block">
                {t('MEMBER.SMS.GROUPS_LIST') + ' ' + errors?.groups}
              </Form.Control.Feedback>
            ) : (
              ''
            )}
          </Form.Group>
        </div>
      </div>

      {Boolean(errors?.data) && (
        <div className="row">
          <div className="col-md-12">
            <div className="invalid-feedback d-sm-inline-block">
              {errors?.data}
            </div>
          </div>
        </div>
      )}
    </Modal.Body>
  );
};

export default ModalBody;