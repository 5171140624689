import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import _ from "lodash";

import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { STARTED } from "src/utils/helpers/store-helpers";
import ContactModalBody from "../../Admin/ContactList/ContactModalBody";
import { contactStatusUpdateStatusSelector } from "src/store/selectors/contactSelector";
import { useSelector } from "react-redux";
import { useFirstRender } from "src/utils/hooks";
import { newContactFormInitialState } from "src/utils/helpers";

const ContactForm = ({ contact, handleCloseModal, handleSubmit }) => {
  const [t] = useTranslation();

  const initialState = useMemo(() => newContactFormInitialState, []);
  const contactUpdateStatus = useSelector(contactStatusUpdateStatusSelector);
  const [inputs, setInputs] = useState(initialState);
  const firstRender = useFirstRender();

  useEffect(() => {
    if (!firstRender && contactUpdateStatus) handleCloseModal(false);
  }, [contactUpdateStatus]);

  const handleChange = ({ target: { name, value } }) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  useEffect(() => {
    if (!firstRender) return;

    if (_.isEmpty(contact)) setInputs(initialState);
    else
      setInputs({
        ...initialState,
        ...contact,
      });
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit({
      ...inputs,
    });
  };

  return (
    <Modal show={true} onHide={handleCloseModal}>
      <Modal.Header closeButton={false}>
        <Modal.Title>{t("MEMBER.CONTACTS.EDIT_CONTACT")}</Modal.Title>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={handleCloseModal}
        >
          <span aria-hidden="true">x</span>
        </button>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <ContactModalBody inputs={inputs} handleChange={handleChange} />
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t("COMMON.CLOSE")}
          </Button>
          <Button variant="primary" className="btn-icon-text" type="submit">
            {contactUpdateStatus === STARTED ? (
              <Spinner
                className="mr-2"
                animation="border"
                variant="secondary"
                size="sm"
              />
            ) : (
              <i className={"feather-save" + " btn-icon-prepend"}></i>
            )}
            {/* {inputs.id ? t('COMMON.SAVE') : t('COMMON.INSERT')} */}
            {t("COMMON.SAVE")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ContactForm;
