import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import FormRow from '../../../Shared/Form/FormRow';
import { UserRoles } from '../../../../utils/constants';
import CheckboxesRow from '../../../Shared/Form/CheckboxesRow';
import RadioFields from '../../ClientForm/ModalBody/RadioFields';
import { Dropdown, DropdownButton, Form, Modal } from 'react-bootstrap';
import { clientErrorsSelector } from '../../../../store/selectors/clientSelector';
import Hint from 'src/components/ResetPassword/Hint';
import Select from 'react-select';

const ModalBody = ({ setInputs, inputs, setting }) => {
  const [t] = useTranslation();

  const errors = useSelector(clientErrorsSelector);
  const passwordPattern = useMemo(() => '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()-_=+;:\'"<,>.]).{8,}$', []);
  const [hintStatus, setHintStatus] = useState({});
  const [countries, setCountries] = useState([]);

  const handleChange = ({ target: { name, value } }) => {
    if (value === 'true') value = true;
    if (value === 'false') value = false;

    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const rows = [
    [{ title: t('COMMON.FIRST_NAME'), name: 'firstName', required: true }],
    [{ title: t('COMMON.LAST_NAME'), name: 'lastName', required: true }],
    [{ title: t('ADMIN.CLIENTS.EMAIL'), name: 'email', type: 'email', required: true }],
    [{ title: t('ADMIN.CLIENTS.PHONE'),name: 'phone', type: 'tel', required: true, placeholder: t('ADMIN.CLIENTS.EXAMPLE') + ' +33111222333' }],
    [{ title: t('ADMIN.CLIENTS.COMPANY'), name: 'company', required: true }],
  ];

  const onChangePassword = (e) => {
    let value = e.target.value;
    let check = {
      min_char: false,
      one_digit:false,
      one_special_char:false,
      one_uppercase:false,
      one_lowercase: false,
    };
   
    if(/^.{8,}$/.test(value)){
      check.min_char = true;
    }
    if(/([a-z])/.test(value)){
      check.one_lowercase = true;
    }   
    if(/([0-9])/.test(value)){
      check.one_digit = true;
    }
    if(/[!@#$%^&*()_=+;:\'"<,>.-]/.test(value)){
      check.one_special_char = true;
    }
    if(/([A-Z])/.test(value)){
      check.one_uppercase = true;
    }
    if(/([a-z])/.test(value)){
      check.one_lowercase = true;
    }
    setHintStatus(check); 
    handleChange(e);
  }

  useEffect(()=> {
    if(setting && setting.countryList){
      let countries = setting.countryList.map((c) => { return { value: c.countryName, label: c.countryName }})
      setCountries(countries);
    }
  }, [setting]);
  
  return (
    <Modal.Body>
      {rows.map((row, idx) => <FormRow key={idx} row={row} inputs={inputs} handleChange={handleChange} errors={errors}/>)}
      <div className="row">
        <div className="col-md-12">
          <Form.Group className="form-group mb-3">
            <Form.Label className="required">
              {t('ADMIN.CLIENTS.LOGIN')}
            </Form.Label>
            <Form.Control
              type="text"
              name="login"
              isInvalid={Boolean(errors?.login)}
              placeholder={t('ADMIN.CLIENTS.LOGIN')}
              value={inputs.login}
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors?.login ? `${t('ADMIN.CLIENTS.LOGIN')} ${errors?.login}` : ''}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
            <Form.Group className="form-group mb-3">
                <Form.Label className="required">
                  {t('REGISTRATION.COUNTRY')}
                </Form.Label>
                <Select
                    options={countries}
                    name="country"
                    value={
                        countries.filter(option => 
                            option.value === inputs.country)
                    }
                    onChange={(op) => setInputs({
                        ...inputs,
                        country: op.value
                    })}
                ></Select>
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Form.Group className="form-group mb-3">
            <Form.Label className={inputs.autoGeneratePassword || inputs.id ? '' : 'required'}>
              {t('ADMIN.CLIENTS.PASSWORD')}
            </Form.Label>
            <Form.Control
              type="password"
              name="password"
              pattern={passwordPattern}
              placeholder={t('ADMIN.CLIENTS.PASSWORD')}
              disabled={inputs.autoGeneratePassword}
              value={inputs.password}
              onChange={onChangePassword}
              required={!inputs.autoGeneratePassword && !inputs.id}
            />
          </Form.Group>
          <Hint status={hintStatus}/>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="invalid-feedback d-sm-inline-block">
            {errors?.data || ''}
          </div>
        </div>
      </div>
    </Modal.Body>
  );
};

export default ModalBody;