import React, { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import FormRow from "../../../Shared/Form/FormRow";
import { UserRoles } from "../../../../utils/constants";
import CheckboxesRow from "../../../Shared/Form/CheckboxesRow";
import RadioFields from "../../ClientForm/ModalBody/RadioFields";
import { Dropdown, DropdownButton, Form, Modal } from "react-bootstrap";
import { clientErrorsSelector } from "../../../../store/selectors/clientSelector";
import {
  getGeneralFieldRows,
  getStatusRadioData,
} from "../../../../utils/helpers";
import Hint from "src/components/ResetPassword/Hint";
import CustomPhoneInput from "src/components/Shared/PhoneInput";

const ModalBody = ({
  setInputs,
  inputs,
  countries,
  submitClicked,
  isRolePresent,
  phoneError,
}) => {
  const [t] = useTranslation();

  const errors = useSelector(clientErrorsSelector);
  const passwordPattern = useMemo(
    () =>
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()-_=+;:'\"<,>.]).{8,}$",
    []
  );
  const [hintStatus, setHintStatus] = useState({});

  const roles = useMemo(() => {
    return Object.keys(UserRoles).filter(
      (role) => role !== UserRoles.SuperAdmin && role !== UserRoles.Admin
    );
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    if (value === "true") value = true;
    if (value === "false") value = false;

    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleRoleSelect = (eventKey) =>
    setInputs({
      ...inputs,
      role: eventKey,
    });

  const rows = getGeneralFieldRows(t);
  const radioData = [getStatusRadioData(t, inputs.status)];

  const [phone, setPhone] = useState("");

  useEffect(() => {
    handleChange({ target: { name: "phone", value: phone } });
  }, [phone]);

  const onChangePassword = (e) => {
    let value = e.target.value;
    let check = {
      min_char: false,
      one_digit: false,
      one_special_char: false,
      one_uppercase: false,
      one_lowercase: false,
    };

    if (/^.{8,}$/.test(value)) {
      check.min_char = true;
    }
    if (/([a-z])/.test(value)) {
      check.one_lowercase = true;
    }
    if (/([0-9])/.test(value)) {
      check.one_digit = true;
    }
    if (/[!@#$%^&*()_=+;:\'"<,>.-]/.test(value)) {
      check.one_special_char = true;
    }
    if (/([A-Z])/.test(value)) {
      check.one_uppercase = true;
    }
    if (/([a-z])/.test(value)) {
      check.one_lowercase = true;
    }
    setHintStatus(check);
    handleChange(e);
  };
  return (
    <Modal.Body>
      {rows.map((row, idx) => (
        <FormRow
          key={idx}
          row={row}
          inputs={inputs}
          handleChange={handleChange}
          errors={errors}
        />
      ))}
      <div className="row">
        <div className="col-md-12">
          <Form.Group className="form-group mb-3">
            <Form.Label className="required">
              {t("ADMIN.SUBACCOUNTS.ROLE")}
            </Form.Label>
            <DropdownButton
              title={inputs.role === "" ? "Select role" : inputs.role}
              id="dropdown-menu-align-right"
              onSelect={handleRoleSelect}
            >
              {roles
                .filter((role) => isRolePresent !== role)
                .map((role) => (
                  <Dropdown.Item
                    key={role}
                    eventKey={role}
                    active={inputs.role === role}
                  >
                    {role}
                  </Dropdown.Item>
                ))}
            </DropdownButton>
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Form.Label className="required">{t("COMMON.PHONE")}</Form.Label>
          <CustomPhoneInput
            phone={inputs.phone}
            countries={countries}
            setPhone={setPhone}
          />
          <div className="invalid-feedback d-sm-inline-block">
            {phoneError && (
              <p className="text-dangerr">
                {t("MEMBER.CONTACTS.PHONE_NOT_VALID")}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Form.Group className="form-group mb-3">
            <Form.Label className="required">
              {t("ADMIN.CLIENTS.LOGIN")}
            </Form.Label>
            <Form.Control
              type="text"
              name="login"
              isInvalid={Boolean(errors?.login)}
              placeholder={t("ADMIN.CLIENTS.LOGIN")}
              value={inputs.login}
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors?.login
                ? `${t("ADMIN.CLIENTS.LOGIN")} ${errors?.login}`
                : ""}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <Form.Group className="form-group mb-3">
            <Form.Label
              className={
                inputs.autoGeneratePassword || inputs.id ? "" : "required"
              }
            >
              {t("ADMIN.CLIENTS.PASSWORD")}
            </Form.Label>
            <Form.Control
              type="password"
              name="password"
              pattern={passwordPattern}
              placeholder={t("ADMIN.CLIENTS.PASSWORD")}
              disabled={inputs.autoGeneratePassword}
              value={inputs.password}
              onChange={onChangePassword}
              required={!inputs.autoGeneratePassword && !inputs.id}
            />
          </Form.Group>
          <Hint status={hintStatus} />
        </div>
      </div>
      <CheckboxesRow inputs={inputs} setInputs={setInputs} />
      <div className="row">
        <div className="col-md-6">
          <Form.Group className="form-check form-check-flat form-check-primary mb-3">
            <Form.Label className="form-check-label">
              <Form.Control
                type="checkbox"
                name="resendVerificationCode"
                className="form-check-input"
                checked={inputs.resendVerificationCode}
                onChange={() =>
                  setInputs({
                    ...inputs,
                    resendVerificationCode: !inputs.resendVerificationCode,
                  })
                }
              />
              {t("ADMIN.CLIENTS.RESEND_VERIFICATION_CODE")}
              <i className="input-helper"></i>
            </Form.Label>
          </Form.Group>
        </div>
        {!isRolePresent && (
          <div className="col-md-6">
            <Form.Group className="form-check form-check-flat form-check-primary mb-3">
              <Form.Label className="form-check-label">
                <Form.Control
                  type="checkbox"
                  name="superadmin"
                  className="form-check-input"
                  checked={inputs.superadmin}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      superadmin: !inputs.superadmin,
                    })
                  }
                />
                {t("ADMIN.CLIENTS.SUPERADMIN")}
                <i className="input-helper"></i>
              </Form.Label>
            </Form.Group>
          </div>
        )}
      </div>
      <RadioFields
        handleChange={handleChange}
        radioData={radioData}
        className="col-md-12"
      />
      <div className="row">
        <div className="col-md-12">
          <div className="invalid-feedback d-sm-inline-block">
            {errors?.data || ""}
          </div>
        </div>
      </div>
    </Modal.Body>
  );
};

export default ModalBody;
