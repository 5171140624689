import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";

import _ from "lodash";
import {
  errorMessageToast,
  getError,
  successMessageToast,
} from "../../../utils/common/helper";

import {
  usersSelector,
  usersFetchStatusSelector,
} from "../../../store/selectors/clientSelector";
import {
  createUser,
  fetchUsers,
  removeUser,
  updateUser,
} from "../../../store/actions/creators/clientActions";
import DirectUserForm from "../../../components/Admin/DirectUserForm";
import DirectUserList from "src/components/Admin/DirectUserList";
import { STARTED, SUCCEEDED } from "src/utils/helpers/store-helpers";
import Loading from "src/components/Shared/Loading";

const MemberUsers = ({ client }) => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const users = useSelector(usersSelector);
  const usersFetchStatus = useSelector(usersFetchStatusSelector);
  const [user, setUser] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (client.id) {
      dispatch(fetchUsers(client.id, null));
    }
  }, [client]);

  const handleShowModal = (userId) => {
    setShowModal(true);
    let temp = userId ? users.find((u) => u.id === userId) : {};
    setUser(_.omit(temp, ["lastLogin", "lastIp"]));
  };
  const handleSubmit = async (data) => {
    try {
      if (data.id) {
        await dispatch(
          updateUser(client.id, null, data.id, _.omit(data, ["id"]))
        );
      } else {
        await dispatch(createUser(client.id, null, data));
      }
      successMessageToast("Details successfully saved.");
    } catch (error) {
      let err = getError(error);
      typeof err == "string" && errorMessageToast(err);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setUser({});
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-body">
          <div className="d-flex justify-content-between mb-4">
            <h4 className="card-title">{t("ADMIN.CLIENTS.USERS")}</h4>
            <Button
              variant="primary"
              size="sm"
              className="btn-icon-text"
              onClick={() => handleShowModal()}
            >
              <i className="feather-user-plus btn-icon-prepend"></i>
              {t("ADMIN.CLIENTS.NEW_USER")}
            </Button>
            {usersFetchStatus && showModal && (
              <DirectUserForm
                isRolePresent="Client"
                handleSubmit={handleSubmit}
                handleCloseModal={handleCloseModal}
                user={user}
                client={client}
              />
            )}
          </div>
          {!usersFetchStatus||usersFetchStatus === STARTED ? (
            <Loading />
          ) : usersFetchStatus === SUCCEEDED && users.length > 0 ? (
            <DirectUserList
              isIcon={true}
              users={users}
              handleShowModal={handleShowModal}
              removeUser={(u) => dispatch(removeUser(client.id, null, u))}
            />
          ) : (
            t("ADMIN.CLIENTS.CAN'T_FETCH_USERS")
          )}
        </div>
      </div>
    </div>
  );
};

export default MemberUsers;
