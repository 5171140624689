import React, { useEffect } from 'react';

import jQuery from 'jquery';

import { Menu, SubMenuNavItem, MenuNavItem } from '../../SideMenu';
import template from '../../../assets/skydash/js/template';
import hoverableCollapse from '../../../assets/skydash/js/hoverable-collapse';

const AdminSideBar = (props) => {
  useEffect(() => {
    hoverableCollapse(jQuery);
    template(jQuery);
  }, []);

  return (
    <Menu>
      <SubMenuNavItem href="/admin/dashboard" defaultHref="/admin" iClassName="feather-grid" {...props} />
      <SubMenuNavItem href="/admin/clients" iClassName="feather-users" {...props} />
      <SubMenuNavItem href="/admin/registered_users" iClassName="feather-users" {...props} />
      <MenuNavItem name="Reports" iClassName="feather-grid" {...props}>
        <SubMenuNavItem href="/admin/reports" iClassName="icon-paper-stack" {...props} />
        <SubMenuNavItem href="/admin/statuses" iClassName="icon-bar-graph" {...props} />
        <SubMenuNavItem href="/admin/campaign_list" iClassName="icon-bar-graph" {...props} />
        <SubMenuNavItem href="/admin/contacts" iClassName="icon-bar-graph" {...props} />
        <SubMenuNavItem href="/admin/stops" iClassName="feather-users" {...props} />
        <SubMenuNavItem href="/admin/blocked" iClassName="feather-users" {...props} />
      </MenuNavItem>
      <MenuNavItem name="tools" iClassName="feather-settings" {...props}>
        <SubMenuNavItem href="/admin/tools/countries" iClassName="feather-menu" {...props} />
        {/* <SubMenuNavItem href="/admin/tools/provider" iClassName="feather-users" {...props} /> */}
        <SubMenuNavItem href="/admin/tools/registration_setting" iClassName="feather-users" {...props} />
        <SubMenuNavItem href="/admin/tools/holidays" iClassName="feather-users" {...props} />
      </MenuNavItem>
    </Menu>
  );
};

export default AdminSideBar;