import React from 'react';
import { useTranslation } from 'react-i18next';
import PerPageCount from '../../../Shared/PerPageCount';

const ClientsEntries = ({ perPage, changePerPage }) => {
  const [t] = useTranslation();
  
  return (
    <div className="col-sm-12 col-md-6">
      <span className="mr-1">{t('ADMIN.CLIENTS.SHOW')}</span>
      <div className="dropdown d-inline-flex">
        <button
          className="btn btn-outline-primary btn-sm dropdown-toggle"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {perPage}
        </button>
        <PerPageCount changePerPage={changePerPage}/>
      </div>
      <span className="ml-1">{t('ADMIN.CLIENTS.ENTRIES')}</span>
    </div>
  );
};

export default ClientsEntries;