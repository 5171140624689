import React from "react";
import Tab from "../../../CampaignGeneralInfo/Tab";
import { mdiLinkBoxVariant } from "@mdi/js";
import { useTranslation } from "react-i18next";

const UrlInfo = ({ inputs }) => {
  const [t] = useTranslation();
  return (
    <div className="row">
      <div className="col-lg-12 grid-margin">
        <div className="card card-body mt-4">
          <div className="row no-gutters">
            <Tab
              lg={6}
              titleStyle={"ml-1"}
              title={t("MEMBER.SMS.SHORT_URL")}
              className={"p-2 border-lg-right border-bottom border-lg-bottom-0"}
              icon={mdiLinkBoxVariant}
              color={"#0000CD"}
              value={inputs.shortUrl === true ? "Yes" : "No"}
            />
            <Tab
              lg={6}
              titleStyle={"ml-1"}
              title={t("MEMBER.SMS.TRACK_URL")}
              className={"p-2 border-bottom border-lg-bottom-0"}
              icon={mdiLinkBoxVariant}
              color={"#0000CD"}
              value={inputs.trackUrl === true ? "Yes" : "No"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UrlInfo;
