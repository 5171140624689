import React, {useState, useMemo} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FRflag from '../../assets/images/fr_FR.png';
import ENflag from '../../assets/images/en_US.png';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from '../../store/selectors/authSelector';
import { logout } from '../../store/actions/creators/authActions';
import NavbarBrand from '../Shared/Navbar/NavbarBrandWrapper';
import NavigationBar from './NavigationBar';
import Select from 'react-select';

const HomeHeader = () => {
  const dispatch = useDispatch();

  const [, i18n] = useTranslation();
  const [language, setLanguage] = useState(i18n.language != 'en' && i18n.language != 'fr' ? 'en' : i18n.language);
  
  const token = useSelector(tokenSelector);

  const languageList = useMemo(() => [
    { value: 'en', label: <div><img width={24} src={ENflag} /> <b>EN</b></div> },
    { value: 'fr', label: <div><img width={24} src={FRflag} /> <b>FR</b></div> },
  ], []);

  const languageHandleChange=(op)=>{
    setLanguage(op.value);
    i18n.changeLanguage(op.value)
  }

  const selectStyle = {
    control: (base) => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: "none"
    }),
    indicatorsContainer: (prevStyle, state) => ({
      ...prevStyle,
      display: 'none'
    }),
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: "100%"
    }),
    input: (base) => ({
      ...base,
      color: 'transparent'
    })
  };

  return (
    <nav className="navbar navbar-white navbar-expand-lg col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <NavbarBrand/>
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-start">
        <div className="d-flex flex-row space-between">
          <Select
            options={languageList}
            name="language"
            value={
              languageList.filter(option => 
                  option.value === language)
            }
            onChange={languageHandleChange}
            styles={selectStyle}
            width={20}
          ></Select>
          {/* <ul className="navbar-nav mr-lg-2">
            <li className="nav-item">
              
              <Link
                className="nav-link"
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  i18n.changeLanguage('fr');
                }}
              >
                <img src={FRflag} alt="French"/>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  i18n.changeLanguage('en');
                }}
              >
                <img src={ENflag} alt="English"/>
              </Link>
            </li>
          </ul> */}
          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-menu"></span>
          </button>
        </div>
        <NavigationBar logout={() => dispatch(logout())} token={token}/>
      </div>
    </nav>
  );
};

export default HomeHeader;
