import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from "react-router-dom";
import _ from 'lodash';

import Icon from '@mdi/react';
import { mdiEye } from '@mdi/js';
import { Spinner } from 'react-bootstrap';
import Hint from '../../components/ResetPassword/Hint';
import { STARTED, SUCCEEDED } from '../../utils/helpers/store-helpers';
import FormHeaderLink from '../../components/Shared/Form/FormHeaderLink';
import { resetPassword } from '../../store/actions/creators/authActions';
import { MainContainer, FullPageContainer } from '../../components/Container';
import ResetPasswordSuccess from '../../components/Shared/ResetPasswordSuccess';
import { resetPasswordStatusSelector, resetPasswordErrorSelector } from '../../store/selectors/authSelector';

import '../../assets/skydash/css/vertical-layout-light/style.css';

const ResetPassword = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams()
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [status, setStatus] = useState({});

  const resetPasswordError = useSelector(resetPasswordErrorSelector);
  const resetPasswordStatus = useSelector(resetPasswordStatusSelector);

  const passwordPattern = useMemo(() => '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()-_=+;:\'"<,>.]).{8,}$', []);
   
  const handleSubmit = async (event) => {
    event.preventDefault();
    const forgotPasswordToken = searchParams.get("token");
   
    try{
      await dispatch(resetPassword({
        forgotPasswordToken, newPassword: _.trim(newPassword), confirmNewPassword: _.trim(confirmNewPassword)
      }));
    }catch(error){}
  };

  const onChangePassword = (value) => {
    let check = {
      min_char: false,
      one_digit:false,
      one_special_char:false,
      one_uppercase:false,
      one_lowercase: false,
    };
   
    if(/^.{8,}$/.test(value)){
      check.min_char = true;
    }
    if(/([a-z])/.test(value)){
      check.one_lowercase = true;
    }   
    if(/([0-9])/.test(value)){
      check.one_digit = true;
    }
    if(/[!@#$%^&*()_=+;:\'"<,>.-]/.test(value)){
      check.one_special_char = true;
    }
    if(/([A-Z])/.test(value)){
      check.one_uppercase = true;
    }
    if(/([a-z])/.test(value)){
      check.one_lowercase = true;
    }
    setStatus(check); 
    setNewPassword(value);
  }

  return (
    <MainContainer>
      <FullPageContainer>
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <FormHeaderLink/>
                {resetPasswordStatus === SUCCEEDED ? <ResetPasswordSuccess/> : (
                  <form
                    className="pt-3"
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    <div className="d-flex justify-content-between">
                      <h6 className="font-weight-light">
                        {t('RESET_PASSWORD.ENTER_NEW_PASSWORD')}
                      </h6>
                      <label>
                        <Link to="#" onClick={(e) => {
                            e.preventDefault();
                            setShowPassword(!showPassword)
                          }}>
                          <Icon path={mdiEye} size={0.6} color="#000"/>{' '}
                          <h6 className="d-inline">
                            {showPassword ? t('REGISTRATION.HIDE') : t('REGISTRATION.VIEW')}{' '}
                            {t('COMMON.PASSWORD')}
                          </h6>
                        </Link>
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className={'form-control form-control-lg' + (resetPasswordError?.password ? ' is-invalid' : '')}
                        name="newPassword"
                        pattern={passwordPattern}
                        placeholder={t('RESET_PASSWORD.NEW_PASSWORD')}
                        value={newPassword}
                        onChange={({ target: { value } }) => onChangePassword(value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className={'form-control form-control-lg' + (resetPasswordError?.password ? ' is-invalid' : '')}
                        name="confirmNewPassword"
                        pattern={passwordPattern}
                        placeholder={t('RESET_PASSWORD.CONFIRM_NEW_PASSWORD')}
                        value={confirmNewPassword}
                        onChange={({ target: { value } }) => setConfirmNewPassword(value)}
                        required
                      />
                    </div>
                    <Hint status={status}/>
                    <div>
                      <p>
                        {t('REGISTRATION.AUTHORIZED_SPECIAL_CHARACTERS')}
                        !@#$%^&amp;*&#40;&#41;-_=+;:'"&lt;,&gt;.
                      </p>
                    </div>
                    {resetPasswordError?.password ? (<div className="invalid-feedback d-sm-inline-block">
                      {t('REGISTRATION.PASSWORDS_MUST_MEET_THE_PATTERN_AND_BE_THE_SAME')}
                    </div>) : resetPasswordError ? (<div className="invalid-feedback d-sm-inline-block">
                      {resetPasswordError}
                    </div>) : ('')}
                    <div className="mt-3">
                      <button
                        type="submit"
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      >
                        {resetPasswordStatus === STARTED ? (<Spinner
                          className="mr-2"
                          animation="border"
                          variant="secondary"
                          size="sm"
                        />) : (t('RESET_PASSWORD.CHANGE_PASSWORD'))}
                      </button>
                    </div>
                  </form>)}
              </div>
            </div>
          </div>
        </div>
      </FullPageContainer>
    </MainContainer>
  );
};

export default ResetPassword;
