import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Nav, NavDropdown } from "react-bootstrap";
import Tooltip from '../../../components/Tooltip'
import * as XLSX from "xlsx";
import moment from "moment";
import PayButton from "src/components/PayButton";
import { clientSelector } from "src/store/selectors/clientSelector";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import $ from "jquery";

const ActionsCell = ({
  hideIcon,
  actions,
  row,
  handleShowModal,
  handleShowConfirmModal,
  viewUrl,
  editRedirectUrl,
  uniqueId,
  actionViewStyle,
}) => {

  const client = useSelector(clientSelector);
  const [t] = useTranslation();
  const handleExport = (data, type) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet");
    XLSX.writeFile(
      workbook,
      `smsreflex_contacts_${row.name}_${moment(new Date()).format(
        "DDMMYYYY"
      )}.${type}`
    );
  };
  useEffect(() => {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }, [])

  const modify = row?.contacts?.map((value) => {
    delete value.SmsGroupsContacts;
    return {
      ...value,
    };
  });

  return (
    <>
      {(actions(row, "edit") ||
        actions(row, "editRedirect") ||
        actions(row, "statistics") ||
        actions(row, "view") ||
        actions(row, "delete") ||
        actions(row, "paymentLink")) && (
        <td>
          <div className="d-flex align-items-center">
            {(row.isDefault===true &&hideIcon)?'':actions(row, "edit") && (
              <Link
                to="#"
                className="mr-1"
                onClick={(e) => {
                  e.preventDefault();
                  handleShowModal(row.id);
                }}
              >
                <i className="feather-edit btn-icon-prepend"></i>
              </Link>
            )}
            {actions(row, "editRedirect") && moment(row.startDate, "DD/MM/YYYY HH:mm").isAfter(moment()) && (
              <Link to={editRedirectUrl + row.campaignId} className="mr-1">
                <i className="feather-edit btn-icon-prepend"></i>
              </Link>
            )}
            {actions(row, "statistics") && (
              <Link to="#" className="mr-1">
                <i className="feather-trending-up btn-icon-prepend"></i>
              </Link>
            )}
            {actions(row, "view") && (
              <Link
                to={viewUrl + "/" + (row[uniqueId] || row.id)}
                state={{ row: row }}
                className={`ml-2 ${actionViewStyle}`}
              >
                <i className="feather-eye btn-icon-prepend"></i>
              </Link>
            )}
            {row.contacts && (
              <Nav>
                <NavDropdown
                  id="nav-dropdown-dark-example"
                  title={
                    <i className="feather-download-cloud btn-icon-prepend" />
                  }
                  className="dropdown-style"
                  disabled={row.countMembers === 0 ? true : false}
                >
                  <NavDropdown.Item
                    href="#"
                    onClick={() => handleExport(modify, "csv")}
                    className="text-primary"
                  >
                    CSV Format
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="#"
                    onClick={() => handleExport(modify, "xlsx")}
                    className="text-primary"
                  >
                    Excel Format
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            )}
            {(row.isDefault===true && hideIcon)?  <Tooltip originalTitle={t('ADMIN.HOLIDAYS.HOLIDAY_TOOL_TIP')}/>: actions(row, "delete") && (
              <Link
                to="#"
                className="mr-1"
                onClick={(e) => {
                  e.preventDefault();
                  handleShowConfirmModal(row.id);
                }}
              >
                <i className="feather-trash-2 btn-icon-prepend"></i>
              </Link>
            )}
            {actions({}, "paymentLink") && client.prepaid == 'Yes' && <PayButton  Items={client}  credits={row}/>}
            {actions({}, "paymentLink") && client.prepaid == 'No' && "--"}
            {actions(row, "sendSms") && (
              <Link to="/member/singlemessage"  state={{ 'number': row.phone }} className="mr-1">
                <i className="feather-send btn-icon-prepend"></i>
              </Link>
            )}
          </div>
        </td>
      )}
    </>
  );
};

export default ActionsCell;
