import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

import RegistrationSuccess from '../../../Shared/RegistrationSuccess';
import { registrationStatusSelector, registrationErrorSelector } from "../../../../store/selectors/authSelector";

const Step5 = () => {
  const [t] = useTranslation();
  
  const registered = useSelector(registrationStatusSelector);
  const registrationError = useSelector(registrationErrorSelector);

  return registered ? <RegistrationSuccess/> : (
    <h6 className="font-weight-light text-danger">
      {registrationError}
    </h6>
  );
};

export default Step5