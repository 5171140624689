import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Spinner from "react-bootstrap/Spinner";
import {
  subaccountSelector,
  clientErrorsSelector,
  subaccountFetchStatusSelector,
} from "../../../store/selectors/clientSelector";
import { STARTED, SUCCEEDED } from "../../../utils/helpers/store-helpers";
import { fetchSubaccount } from "../../../store/actions/creators/clientActions";

import ClientTable from "../../../components/AdminHome/Client/ClientTable";
import AdminTable from "../../../components/AdminHome/Client/AdminTable";
import AdminSubaccounts from "../Subaccounts";
import AdminUsers from "../Users";

const AdminViewSubacount = () => {
  const [t] = useTranslation();

  const params = useParams();

  const dispatch = useDispatch();

  const subaccount = useSelector(subaccountSelector);
  const subaccountFetchStatus = useSelector(subaccountFetchStatusSelector);

  useEffect(() => {
    if (params.clientId) dispatch(fetchSubaccount(params.clientId, params.subaccountId));
  }, [params]);

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-body">
          <h4 className="card-title">
            {t("ADMIN.SUBACCOUNTS.BASIC_DATA_SUBACCOUNT") + " #" + params.subaccountId}
          </h4>
          {!subaccount && subaccountFetchStatus === STARTED && (
            <Spinner animation="border" role="status" />
          )}
          {subaccount && subaccountFetchStatus === SUCCEEDED && (
            <>
               <div className="row justify-content-center">
                <div className="col-sm-5 m-3 round-border">
                  <ClientTable client={subaccount} />
                </div>
                <div className="col-sm-5 m-3 round-border">
                  <AdminTable client={subaccount} />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <AdminUsers client={subaccount} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminViewSubacount;
