import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import ClientTable from "./ClientTable";
import ClientsEntries from "./ClientsEntries";
import PageControl from "../../Shared/Pagination/PageControl";
import { useDispatch } from "react-redux";
import { removeClient } from "src/store/actions/creators/clientActions";
import { errorMessageToast, getError } from "src/utils/common/helper";

const ClientList = ({
  clients,
  handleShowModal,
  handleShowCreditListModal,
}) => {
  const [t] = useTranslation();

  const [page, setPage] = useState(1);
  const [userId, setUserId] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();

  const searchColumns = useMemo(() => ["email", "company"], []);

  const filterFunc = (client) =>
    searchColumns.some((col) => client[col].includes(searchValue));

  const handleSearchChange = (e) => {
    const value = e.target.value || "";
    setSearchValue(value);
    setPage(1);
  };

  const changePerPage = (perPage) => {
    if (clients.length / perPage < page) setPage(1);
    setPerPage(perPage);
  };

  const decPage = () => {
    if (page > 1) setPage(page - 1);
  };

  const incPage = () => {
    if (page < clients.length / perPage) setPage(page + 1);
  };

  const handleChangeSort = (newSortBy) => {
    setSortBy(newSortBy);
    setSortOrder(sortBy === newSortBy && sortOrder === "asc" ? "desc" : "asc");
  };

  const filteredItems = clients.filter(filterFunc);

  const handleShowEditModal = (clientRow) => {
    setUserId(clientRow.id);
    handleShowModal(clientRow.id);
  };

  const handleShowCreditModal = (clientRow) => {
    setUserId(clientRow.id);
    handleShowModal(clientRow.id);
  };

  const showCreditModal = (clientRow) => {
    setUserId(clientRow.id);
    handleShowCreditListModal(clientRow.id);
  };

  const removeClientFunction = async (clientId) => {
    try {
      await dispatch(removeClient(clientId));
    } catch (err) {
      errorMessageToast(getError(err));
    }
  };

  return (
    <>
      <div className="row no-gutters mb-3">
        <ClientsEntries changePerPage={changePerPage} perPage={perPage} />
        <div className="col-sm-12 col-md-6">
          <div className="form-group-sm row">
            <label className="col-sm-5 col-form-label text-right">
              {t("ADMIN.CLIENTS.SEARCH")}:
            </label>
            <div className="col-sm-7">
              <input
                type="text"
                className="form-control form-control-sm"
                value={searchValue}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
      </div>
      <ClientTable
        page={page}
        sortBy={sortBy}
        perPage={perPage}
        sortOrder={sortOrder}
        filteredClients={filteredItems}
        handleChangeSort={handleChangeSort}
        handleShowEditModal={handleShowEditModal}
        handleShowCreditModal={handleShowCreditModal}
        showCreditModal={showCreditModal}
        // removeClient={(clientId) => dispatch(removeClient(clientId))}
        removeClient={removeClientFunction}
      />
      <div className="row">
        <div className="col-sm-12 col-md-5">
          <span>
            {t("ADMIN.CLIENTS.SHOWING_START_TO_END_OF_TOTAL_ENTRIES", {
              start: (page - 1) * perPage + 1,
              end: Math.min(page * perPage, filteredItems.length),
              total: filteredItems.length,
            })}
          </span>
        </div>
        <PageControl
          page={page}
          perPage={perPage}
          setPage={setPage}
          decPage={decPage}
          incPage={incPage}
          filteredItems={filteredItems}
        />
      </div>
    </>
  );
};

export default ClientList;
