import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Auth from '../../containers/Auth';
import AdminHome from '../../containers/AdminHome';
import AdminTools from '../../containers/AdminHome/Tools';
import AdminClients from '../../containers/AdminHome/Clients';
import ClientsCredentials from '../../containers/AdminHome/ClientsCredentials';
import AdminProfile from '../../containers/AdminHome/Profile';
import AdminDashboard from '../../containers/AdminHome/Dashboard';
import AdminViewClient from '../../containers/AdminHome/ViewClient';
import AdminViewSubaccount from '../../containers/AdminHome/ViewSubaccount';
import AdminSubaccounts from '../../containers/AdminHome/Subaccounts';
import AdminCountries from '../../containers/AdminHome/Countries';
import AdminViewCountry from '../../containers/AdminHome/ViewCountry';
import { UserRoles } from '../../utils/constants';
import RegistrationSetting from '../../containers/AdminHome/RegistrationSetting';
import Logout from '../../containers/Logout';
import RegisteredUsers from '../../containers/AdminHome/RegisteredUsers';
import Statuses from '../../containers/AdminHome/Statuses';
import SmsStatus from '../../containers/AdminHome/SmsStatus';
import Reports from '../../containers/AdminHome/Reports';
import Campaigns from '../../containers/AdminHome/Campaign';
import CampaignView from 'src/components/Admin/CampaignView';
import AdminContacts from 'src/containers/AdminHome/Contacts';
import Holidays from 'src/components/Holidays';
import StopManagement from 'src/components/StopManagement';

const AdminRoutes = () => {
  return (
    <Routes>
      <Route element={<Auth allowedRoles={[UserRoles.SuperAdmin]} />}>
        <Route path="*" element={<Navigate to="/admin/dashboard" />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/admin" element={<AdminHome />}>
          <Route index element={<AdminDashboard />} />
          <Route path="dashboard" element={<AdminDashboard />} />
          <Route>
            <Route path="reports" element={<Reports />} />
            <Route path="statuses" element={<Statuses />} />
            <Route path="statuses/:smsId" element={<SmsStatus />} />
            <Route path="campaign_list" element={<Campaigns />} />
            <Route path="campaign/:campaignId" element={<CampaignView />} />
            <Route path="contacts" element={<AdminContacts />} />
            <Route path="stops" element={<StopManagement />} />
            <Route path="blocked" element={<StopManagement />} />
          </Route>
          <Route path="clients" element={<AdminClients />} />
          <Route path="clients_credentials" element={<ClientsCredentials />} />
          <Route path="clients/:clientId" element={<AdminViewClient />} />
          <Route
            path="clients/:clientId/subaccounts"
            element={<AdminSubaccounts />}
          />
          <Route
            path="clients/:clientId/subaccounts/:subaccountId"
            element={<AdminViewSubaccount />}
          />
          <Route path="tools" element={<AdminTools />} />
          <Route path="tools/countries" element={<AdminCountries />} />
          <Route path="tools/countries/:countryId" element={<AdminViewCountry />} />
          <Route path="tools/registration_setting" element={<RegistrationSetting />} />
          <Route path="tools/holidays" element={<Holidays />} />
          <Route path="profile" element={<AdminProfile />} />
          <Route path="registered_users" element={<RegisteredUsers />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AdminRoutes;