import React from "react";
import MemberUsers from "../User";
import { useSelector } from "react-redux";
import { clientSelector } from "src/store/selectors/clientSelector";
import DirectUserList from "src/components/Admin/DirectUserList";

const MyUsers = () => {
  const client = useSelector(clientSelector);
  return (
    <div>
      <MemberUsers client={client} />
    </div>
  );
};

export default MyUsers;
