/**
 * Service for clients page
 */
import useApi from '../utils/api';

class ClientService {
  #prefix = '/clients';
  
  fetchAllClients = (isSubaccount) => {
    return isSubaccount && typeof  isSubaccount != 'object' ? useApi.get(`${this.#prefix}?isSubaccount=${isSubaccount}`)  : useApi.get(this.#prefix);
  };

  fetchClient = (id) => {
    return useApi.get(`${this.#prefix}/${id}`);
  };

  createClient = (data) => {
    return useApi.post(this.#prefix, data);
  };

  updateClient = (id, data) => {
    return useApi.put(`${this.#prefix}/${id}`, {
      ...data,
    });
  };

  removeClient = (id) => {
    return useApi.delete(`${this.#prefix}/${id}`);
  };

  fetchSubaccounts = (clientId) => {
    return useApi.get(`${this.#prefix}/${clientId}/subaccounts`);
  };

  fetchSubaccount = (clientId, subaccountId) => {
    return useApi.get(`${this.#prefix}/${clientId}/subaccounts/${subaccountId}`);
  };

  createSubaccount = (clientId, data) => {
    return useApi.post(`${this.#prefix}/${clientId}/subaccounts`, data);
  };

  updateSubaccount(clientId, subaccountId, data) {
    return useApi.put(`${this.#prefix}/${clientId}/subaccounts/${subaccountId}`, data);
  }

  removeSubaccount = (clientId, subaccountId) => {
    return useApi.delete(`${this.#prefix}/${clientId}/subaccounts/${subaccountId}`);
  };

  fetchUsers = (clientId, subaccountId) => {
    return subaccountId ? useApi.get(`${this.#prefix}/${subaccountId}/users`): useApi.get(`${this.#prefix}/${clientId}/users`);
  };

  createUser= (clientId, subaccountId,  data) => {
    return subaccountId ?  useApi.post(`${this.#prefix}/${subaccountId}/users`, data) : useApi.post(`${this.#prefix}/${clientId}/users`, data);
  };

  updateUser(clientId, subaccountId,  userId, data) {
    return  subaccountId ? useApi.put(`${this.#prefix}/${subaccountId}/users/${userId}`, data) : useApi.put(`${this.#prefix}/${clientId}/users/${userId}`, data);
  }

  removeUser = (clientId, subaccountId,  userId) => {
    return subaccountId ? useApi.delete(`${this.#prefix}/${subaccountId}/users/${userId}`) : useApi.delete(`${this.#prefix}/${clientId}/users/${userId}`);
  };

  refreshSecretKeys = (clientId, subaccountId, field) => {
    return subaccountId ? useApi.put(`${this.#prefix}/${clientId}/subaccounts/${subaccountId}/refreshSecretKey?field=${field}`)
    : useApi.put(`${this.#prefix}/${clientId}/refreshSecretKey?field=${field}`) ;
  };

  accessFlags = (clientId, subaccountId, data) => {
    return subaccountId ? useApi.put(`${this.#prefix}/${clientId}/subaccounts/${subaccountId}/accessFlags`,data)
    : useApi.put(`${this.#prefix}/${clientId}/accessFlags`,data) ;
  };

  fetchRegisteredUsers = () => {
    return useApi.get(`${this.#prefix}/getRegisteredUsers`);
  };

  updateRegisteredUser(userId, data) {
    return useApi.put(`${this.#prefix}/getRegisteredUsers/${userId}`, data);
  }
}

export default new ClientService();
