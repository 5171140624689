import { createActionThunk } from 'redux-thunk-actions';

import {
  FETCH_CONTACTS,
  CREATE_CONTACT,
  UPDATE_CONTACT,
  REMOVE_CONTACT,
  IMPORT_CONTACTS,
  REMOVE_MANY_CONTACTS,
  SAVE_SELECTED_CONTACTS,
  ADD_GROUPS_TO_CONTACTS,
  CHANGE_STATUS
} from '../types/contactTypes';
import ContactService from '../../../services/contacts';

export const fetchContacts = createActionThunk(FETCH_CONTACTS, (clientId,status) =>
  ContactService.fetchContacts(clientId,status).then((res) => res),
  false
);

export const createContact = createActionThunk(CREATE_CONTACT, (data) =>
  ContactService.createContact(data).then((data) => ({ data })),
  false
);

export const importContacts = createActionThunk(IMPORT_CONTACTS, (data) =>
  ContactService.importContacts(data).then((data) => ({ data })),
false
);

export const updateContact = createActionThunk(UPDATE_CONTACT, (id, data) =>
  ContactService.updateContact(id, data).then((data) => ({ data })),
  false
);

export const changeContactStatus = createActionThunk(CHANGE_STATUS, (id, data) =>
  ContactService.changeContactStatus(id, data).then((data) => ({ data })),
  false
);
export const removeContact = createActionThunk(REMOVE_CONTACT, (id) =>
  ContactService.removeContact(id).then((data) => ({ data })),
  false
);

export const saveSelectedContacts = createActionThunk(
  SAVE_SELECTED_CONTACTS,
  (contacts) => contacts,
  false
);

export const removeManyContacts = createActionThunk(
  REMOVE_MANY_CONTACTS,
  (contacts) =>
    ContactService.removeManyContacts(contacts).then((data) => ({ data })),
  false
);

export const contactsAddGroups = createActionThunk(
  ADD_GROUPS_TO_CONTACTS,
  (data) =>
    ContactService.contactsAddGroups(data).then((data) => ({ data })),
  false
);
