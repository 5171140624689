import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';

import {
  contactsSelector,
  contactErrorsSelector,
  contactsFetchStatusSelector
} from '../../../store/selectors/contactSelector';
import {
  createContact,
  fetchContacts,
  updateContact,
  removeContact,
  importContacts,
  removeManyContacts,
  saveSelectedContacts
} from '../../../store/actions/creators/contactActions';
import Loading from '../../../components/Shared/Loading';
import ContactsList from '../../../components/Member/ContactsList';
import TopBar from '../../../components/MemberHome/Contacts/TopBar';
import ToolBar from '../../../components/MemberHome/Contacts/ToolBar';
import NewContactForm from '../../../components/Member/NewContactForm';
import { STARTED, SUCCEEDED } from '../../../utils/helpers/store-helpers';
import { fetchGroups } from '../../../store/actions/creators/groupActions';
import ImportContactsForm from '../../../components/Member/ImportContactsForm';
import { fetchCampaigns } from '../../../store/actions/creators/campaignActions';
import { campaignsFetchStatusSelector } from '../../../store/selectors/campaignSelector';
import { groupsFetchStatusSelector, groupsSelector } from '../../../store/selectors/groupSelector';
import toast from 'react-hot-toast';
import {errorMessageToast, getError, successMessageToast} from '../../../utils/common/helper';
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js/max";
import { clientSelector } from "src/store/selectors/clientSelector";
import GroupModal from 'src/components/Member/ContactsList/GroupModal';

const Contacts = () => {
  const [t] = useTranslation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const groups = useSelector(groupsSelector);
  const contacts = useSelector(contactsSelector);
  const errors = useSelector(contactErrorsSelector);
  const groupsFetchStatus = useSelector(groupsFetchStatusSelector);
  const contactsFetchStatus = useSelector(contactsFetchStatusSelector);
  const campaignsFetchStatus = useSelector(campaignsFetchStatusSelector);
  const client = useSelector(clientSelector);

  const [countries, setCountries] = useState([]);
  const [contact, setContact] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [submitErrors, setSubmitErrors] = useState({})
  const [filterStatus, setFilterStatus] = useState('');
  const [filterGroupId, setFilterGroupId] = useState('');
  const [showImportContactsModal, setShowImportContactsModal] = useState(false);
  const [show, setShow] = useState(false);
  const [groupContacts, setGroupContacts] = useState([]);
  const [groupArray, setGroupArray] = useState([]);

  const contactStatuses = useMemo(() => ['Active', 'Inactive', 'Blocked','Stop'], []);

  const [queryParameters] = useSearchParams()

  const status = queryParameters.get("status")

  useEffect(()=> {
    if(groups.length){
      let options = groups.map(g => ({'label': g.name, 'value': g.id}))
      setGroupArray(options);
    }
  }, [groups]);

  useEffect(()=>{
    if(status){
      setFilterStatus(status)
    }
    else{
      setFilterStatus('')
    }
  },[status])

  useEffect(() => {
    if (client && client.credits) {
      let countries = Object.keys(client.credits).map((e) => _.lowerCase(e));
      setCountries(countries);
    }
  }, [client]);

  useEffect(() => {
    // if (!contactsFetchStatus) dispatch(fetchContacts());
    // if (!groupsFetchStatus) dispatch(fetchGroups());
    // if (!campaignsFetchStatus) dispatch(fetchCampaigns());
    dispatch(fetchContacts());
    dispatch(fetchGroups());
    dispatch(fetchCampaigns());
  }, []);

  const handleSubmit = async (data) => {
    let parseNumber = parsePhoneNumber(data.phone);
 
    if(!isValidPhoneNumber(data.phone) || !countries.includes(_.lowerCase(parseNumber.country)) || parseNumber.getType() != 'MOBILE'){
      setSubmitErrors({'phone': t('MEMBER.CONTACTS.PHONE_NOT_VALID')});
      return false;
    } 

    if (data.groups.length === 0) {
      setSubmitErrors({ groups: "required" });
      return false;
    }

   // for france
  //  if(data.phone[0] == 3 && data.phone[1] == 3 && data.phone[2] != 6 && data.phone[2] != 7){
  //   setSubmitErrors({'phone': t('MEMBER.CONTACTS.PHONE_NOT_VALID')});
  //   return false;
  //  }
  //  if(data.phone[0] == '+' && data.phone[1] == 3 && data.phone[2] == 3 && data.phone[3] != 6 && data.phone[3] != 7){
  //   setSubmitErrors({'phone': t('MEMBER.CONTACTS.PHONE_NOT_VALID')});
  //   return false;
  //  }

   try{
      if (data.id) 
        await dispatch(updateContact(data.id, _.omit(data, ['id', 'createdAt', 'blockedDate', 'provider', 'isSaved'])));
      else 
        await dispatch(createContact(data));
        successMessageToast("Details saved successfully.")
      setShowModal(false);
    }catch(error){
      let err = getError(error);
      typeof err == 'string' ? errorMessageToast(err) : setSubmitErrors(err);
    }
  };

  const handleShowModal = (contactId) => {
    setSubmitErrors({});
    setShowModal(true);
    setContact(contactId ? contacts.find(
      (contact) => contact.id === contactId
    ) : {});
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowImportContactsModal = () => setShowImportContactsModal(true);
  const handleCloseImportContactsModal = () => setShowImportContactsModal(false);

  const filteredContacts = _.filter(contacts, (contact) => {
    let isMatch = true;
    if (filterStatus) isMatch &&= contact.status === filterStatus;
    if (filterGroupId) isMatch &&= _.some(contact.groups, ({ name }) => name === filterGroupId);
    return isMatch;
  });

  const sendMessageToContactsFunc = async (contactsIds) => {
    try{
      await dispatch(saveSelectedContacts(contactsIds));
      navigate('/member/singlemessage');
    }catch(err){}
  };

  const removeManyContactsFunc = async (contactsIds) => {
    try{
      await dispatch(removeManyContacts(contactsIds));
      successMessageToast("Contacts deleted successfully.")
    }catch(err){}
  };

  const addToGroupFunc = async (contactsIds) => {
    try{
      setGroupContacts(contactsIds)
      setShow(true);
    }catch(err){}
  }

  const modalClose = () => {
    setShow(false)
  }

  return (
    <>
      <TopBar groups={groups} contacts={contacts}/>
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-body">
            <ToolBar
              groups={groups}
              filterStatus={filterStatus}
              filterGroupId={filterGroupId}
              contactStatuses={contactStatuses}
              setFilterStatus={setFilterStatus}
              handleShowModal={handleShowModal}
              setFilterGroupId={setFilterGroupId}
              handleShowImportContactsModal={handleShowImportContactsModal}
            />
            {contactsFetchStatus && groupsFetchStatus && (
              <>
                {showModal && <NewContactForm
                  handleSubmit={handleSubmit}
                  handleCloseModal={handleCloseModal}
                  contact={contact}
                  groups={groups}
                  errors={submitErrors}
                  countries={countries}
                />}
                {showImportContactsModal && <ImportContactsForm
                  handleSubmit={(data) => dispatch(importContacts(data))}
                  handleCloseModal={handleCloseImportContactsModal}
                  groups={groups}
                  errors={submitErrors}
                />}
              </>
            )}
            {contactsFetchStatus === STARTED ? (
              <Loading/>
            ) : contactsFetchStatus === SUCCEEDED ? (
              <ContactsList
                contacts={filteredContacts}
                handleShowModal={handleShowModal}
                removeContact={(contactId) => dispatch(removeContact(contactId))}
                sendMessageToContacts={sendMessageToContactsFunc}
                removeManyContacts={removeManyContactsFunc}
                addToGroup={addToGroupFunc}
              />
            ) : t('MEMBER.CONTACTS.CAN\'T_FETCH_CONTACTS')}
            <GroupModal show={show} modalClose={modalClose} groupArray={groupArray} groupContacts={groupContacts}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contacts;
