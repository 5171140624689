import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import PerPageCount from "../Shared/PerPageCount";
import ConfirmationModal from "../ConfirmationModal";
import SelectableContacts from "./SelectableContacts";
import PageControl from "../Shared/Pagination/PageControl";
import Table from "./Table";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { errorMessageToast } from "src/utils/common/helper";

const ItemsList = ({
  hideIcon,
  items,
  columns,
  actions,
  viewUrl,
  editRedirectUrl,
  removeItem,
  searchColumns,
  handleShowModal,
  actionsWithSelected,
  isSelectable = false,
  defaultSorting,
  exportData,
  handleExport,
  uniqueId,
  actionViewStyle,
  downloadContacts
}) => {
  const [t] = useTranslation();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState(defaultSorting);
  const [selectAll, setSelectAll] = useState(false);
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchValue, setSearchValue] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [removingItemId, setRemovingItemId] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [actionWithSelected, setActionWithSelected] = useState("");

  const filterFunc = (item) =>
    searchColumns.some((col) => item[col].includes(searchValue));

  const handleSearchChange = ({ target: { value } }) => {
    setSearchValue(value || "");
    setPage(1);
  };
  
  const changePerPage = (perPage) => {
    if (items.length / perPage < page) setPage(1);
    setPerPage(perPage);
  };

  const decPage = () => {
    if (page > 1) setPage(page - 1);
  };
  const incPage = () => {
    if (page < items.length / perPage) setPage(page + 1);
  };

  const handleChangeSort = (newSortBy) => {
    let newSortOrder = "asc";
    if (sortBy === newSortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
  };

  const handleShowConfirmModal = (itemId) => {
    setRemovingItemId(itemId);
    setShowConfirmModal(true);
  };
  const handleRemoveItem = () => {
    removeItem(removingItemId);
    setShowConfirmModal(false);
  };

  // const initSelectedItems = items.reduce(
  //   (acc, item) => ({ ...acc, [item.id]: true }),
  //   {}
  // );

  const handleSelectedItemsChange = (event) => {
    const name = event.target.name;
    if (name === "selectAll") {
      if (selectAll) {
        setSelectedItems([]);
        setSelectAll(false);
      } else {
        //set selected item in array
        setSelectedItems([...items]);
        setSelectAll(true);
      }
    } else {
      const index = JSON.parse(event.target.value);
      const isExist = selectedItems.some((value) => value.id == index.id);
      //if item exist remove it when unchecked check box
      if (isExist) {
        setSelectedItems([
          ...selectedItems.filter((selected) => selected.id !== index.id),
        ]);
      } else {
        setSelectedItems((prevSelectedItems) => [...prevSelectedItems, index]);
      }
    }
  };

  const applyActionWithSelectedItems = () => {
    if (actionWithSelected) {
      const { func } = actionsWithSelected.find(
        (actionObj) => actionObj.name === actionWithSelected
      );
      const selectedItemIds = [];
      for (let index in selectedItems) {
        if (selectedItems[index]) {
          selectedItemIds.push(selectedItems[index].id);
        }
      }
      func(selectedItemIds);
      setSelectedItems([]);
    }
  };
  
  const filteredItems = searchColumns.length ? items.filter(filterFunc) : items;

  return (
    <>
      {actions({}, "delete") && (
        <ConfirmationModal
          handleConfirm={handleRemoveItem}
          handleCancel={() => setShowConfirmModal(false)}
          showModal={showConfirmModal}
          title={t("COMMON.CONFIRM_MODAL_TITLE")}
          description={t("COMMON.CONFIRM_MODAL_DESCRPTION")}
        />
      )}
      <div className="row no-gutters mb-3">
        <div className="col-sm-12 col-md-6">
          <span className="mr-1">{t("ITEMSLIST.SHOW")}</span>
          <div className="dropdown d-inline-flex">
            <button
              className="btn btn-outline-primary btn-sm dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {perPage}
            </button>
            <PerPageCount changePerPage={changePerPage} />
            <span className="m-2">{t("ITEMSLIST.ENTRIES")}</span>
            {exportData && (
              <DropdownButton
                id={'dropdown-variants'}
                title={t("ITEMSLIST.EXPORT")}
                className="dropdown-style"
              >
                <Dropdown.Item
                  eventKey="1"
                  onClick={() =>
                    handleExport(
                      downloadContacts ? selectedItems.length===0 ?errorMessageToast("No contact selected"):selectedItems:items,
                      "csv"
                    )
                  }
                >
                  CSV Format
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  onClick={() =>
                    handleExport(
                      downloadContacts ? selectedItems.length===0 ?errorMessageToast("No contact selected"):selectedItems:items,
                      "xlsx"
                    )
                  }
                >
                  Excel Format
                </Dropdown.Item>
              </DropdownButton>
            )}
          </div>
        </div>

        <div className="col-sm-12 col-md-6">
          <div className="form-group-sm row">
            {searchColumns.length > 0 && (
              <>
                <label className="col-sm-5 col-form-label text-right">
                  {t("ITEMSLIST.SEARCH")}:
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    value={searchValue}
                    onChange={handleSearchChange}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Table
        hideIcon={hideIcon}
        page={page}
        sortBy={sortBy}
        perPage={perPage}
        actions={actions}
        viewUrl={viewUrl}
        editRedirectUrl={editRedirectUrl}
        columns={columns}
        selectAll={selectAll}
        sortOrder={sortOrder}
        isSelectable={isSelectable}
        filteredItems={filteredItems}
        selectedItems={selectedItems}
        handleShowModal={handleShowModal}
        handleChangeSort={handleChangeSort}
        handleShowConfirmModal={handleShowConfirmModal}
        handleSelectedItemsChange={handleSelectedItemsChange}
        uniqueId={uniqueId}
        actionViewStyle={actionViewStyle}
      />
      <div className="row">
        <div className="col-sm-12 col-md-5">
          <span>
            {t("ITEMSLIST.SHOWING_START_TO_END_OF_TOTAL_ENTRIES", {
              start: (page - 1) * perPage + 1,
              end: Math.min(page * perPage, filteredItems.length),
              total: filteredItems.length,
            })}
          </span>
        </div>
        <PageControl
          page={page}
          perPage={perPage}
          setPage={setPage}
          decPage={decPage}
          incPage={incPage}
          filteredItems={filteredItems}
        />
      </div>
      {isSelectable && (
        <SelectableContacts
          actionWithSelected={actionWithSelected}
          actionsWithSelected={actionsWithSelected}
          setActionWithSelected={setActionWithSelected}
          applyActionWithSelectedItems={applyActionWithSelectedItems}
        />
      )}
    </>
  );
};

export default ItemsList;
