import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';

import { Spinner } from 'react-bootstrap';
import {
  forgotPasswordEmailSendStatusSelector,
  sendForgotPasswordEmailErrorSelector
} from '../../store/selectors/authSelector';
import '../../assets/skydash/css/vertical-layout-light/style.css';
import { STARTED, SUCCEEDED } from '../../utils/helpers/store-helpers';
import FormHeaderLink from '../../components/Shared/Form/FormHeaderLink';
import { forgotPassword } from '../../store/actions/creators/authActions';
import { MainContainer, FullPageContainer } from '../../components/Container';
import HomeHeader from "../../components/HomeHeader";

const ForgotPassword = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const sendForgotPasswordEmailError = useSelector(sendForgotPasswordEmailErrorSelector);
  const forgotPasswordEmailSendStatus = useSelector(forgotPasswordEmailSendStatusSelector);
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    try{
      await dispatch(forgotPassword(_.trim(email)));
      setSuccess(true);
      setError(false);
    }catch(error){
      setSuccess(false);
      setError(true);
    }
  };

  return (
    <MainContainer>
      <HomeHeader />
      <FullPageContainer>
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <FormHeaderLink/>
                {forgotPasswordEmailSendStatus === SUCCEEDED && success? (
                  <h6 className="font-weight-light text-success">
                    {t(
                      'FORGOT_PASSWORD.PLEASE_CHECK_YOUR_EMAIL_TO_RESET_YOUR_PASSWORD'
                    )}
                  </h6>
                ) : (
                  <>
                    <h6 className="font-weight-light">
                      {t(
                        'FORGOT_PASSWORD.ENTER_YOUR_ADDRESS_AND_WE_WILL_SEND_YOU_A_PASSWORD_RESET_LINK'
                      )}
                    </h6>
                    <form
                      className="pt-3"
                      onSubmit={(e) => handleSubmit(e)}
                    >
                      <div className="form-group">
                        <input
                          type="email"
                          className={
                            'form-control form-control-lg' +
                            (sendForgotPasswordEmailError && error
                              ? ' is-invalid'
                              : '')
                          }
                          name="email"
                          placeholder={t('COMMON.EMAIL')}
                          onChange={({ target: { value } }) => setEmail(value)}
                          value={email}
                          required
                        />
                        <div className="invalid-feedback d-sm-inline-block">
                          {error ? sendForgotPasswordEmailError : ""}
                        </div>
                      </div>
                      <div className="mt-3">
                        <button
                          disabled={(forgotPasswordEmailSendStatus === STARTED)}
                          type="submit"
                          className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        >
                          {forgotPasswordEmailSendStatus === STARTED ? (
                            <Spinner
                              className="mr-2"
                              animation="border"
                              variant="secondary"
                              size="sm"
                            />
                          ) : (
                            t('FORGOT_PASSWORD.SEND')
                          )}
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </FullPageContainer>
    </MainContainer>
  );
};

export default ForgotPassword;
