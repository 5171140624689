import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ItemsList from "src/components/ItemsList";
import { getCreditColumns } from "src/utils/helpers";

const CreditLists = ({ client }) => {
  const [t] = useTranslation();

  const columns = useMemo(() => getCreditColumns(t), [t]);
  const searchColumns = useMemo(() => [], []);
  const actions = (item, action) => ({}[action]);

  const creditDetails = Object.keys(client[0]?.credits).map((value) => {
    return { country: value, credits: client[0]?.credits[value] };
  });
  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card card-body">
            <ItemsList
              items={creditDetails}
              columns={columns}
              searchColumns={searchColumns}
              actions={actions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditLists;
