import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Button, Form } from "react-bootstrap";

import StopTable from "src/components/StopManagement/StopTable";
import { useDispatch, useSelector } from "react-redux";
import {
  contactsFetchStatusSelector,
  contactsSelector,
} from "src/store/selectors/contactSelector";
import {
  changeContactStatus,
  fetchContacts,
  removeContact,
} from "src/store/actions/creators/contactActions";
import { clientsSelector } from "src/store/selectors/clientSelector";
import { fetchAllClients } from "src/store/actions/creators/clientActions";
import ContactForm from "src/components/StopManagement/ContactForm";
import {
  errorMessageToast,
  getError,
  successMessageToast,
} from "src/utils/common/helper";
import { UserRoles } from "src/utils/constants";
import { STARTED, SUCCEEDED } from "src/utils/helpers/store-helpers";
import Loading from "../Shared/Loading";

const StopManagement = () => {
  const [t] = useTranslation();
  const [clientList, setClientList] = useState([]);
  const clients = useSelector(clientsSelector);
  const [clientId, setClientId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [contact, setContact] = useState({});
  const contacts = useSelector(contactsSelector);
  const dispatch = useDispatch();

  const localStorageUser = JSON.parse(localStorage.getItem("authUserData"));
  const sessionStorageUser = JSON.parse(sessionStorage.getItem("authUserData"));

  const storageUser = localStorageUser || sessionStorageUser;
  const contactsFetchStatus = useSelector(contactsFetchStatusSelector);

  const urlParams = new URLSearchParams(window.location.search);
  const getStatus = urlParams.get("status");

  useEffect(() => {
    if (storageUser.role === UserRoles.SuperAdmin) {
      dispatch(fetchAllClients(true));
    }
    dispatch(fetchContacts(clientId, getStatus));
  }, [getStatus]);

  useEffect(() => {
    if (clients && clients.length) {
      let temp = clients.map(({ id, company }) => ({
        value: id,
        label: company,
      }));
      setClientList(temp);
    }
  }, [clients]);

  const handleClientFilter = (event) => {
    const value = event.target.value;
    setClientId(value);
    dispatch(fetchContacts(value, getStatus));
  };

  const handleSubmit = async (data) => {
    try {
      if (data.id)
        await dispatch(
          changeContactStatus(
            data.id,
            {'status': data.status}
          )
        );
       await dispatch(fetchContacts(clientId, getStatus));
       successMessageToast("Details saved successfully.");
       setShowModal(false);
    } catch (error) {
      let err = getError(error);
      typeof err == "string" && errorMessageToast(err);
    }
  };

  const handleShowModal = (contactId) => {
    setShowModal(true);
    setContact(
      contactId ? contacts.find((contact) => contact.id === contactId) : {}
    );
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-body">
          <div className="d-flex justify-content-between mb-4">
            <h4 className="card-title"> {t(`SIDEBAR.${getStatus.toUpperCase()}`)} {t('COMMON.STATUS')}</h4>
          </div>

          {storageUser.role === UserRoles.SuperAdmin && (
            <div className="col-md-6 mb-3 p-0">
              <Form.Label className="pt-2 mr-3">
                {t("ADMIN.REPORTS.FILTER_BY_CLIENT")}
              </Form.Label>
              <Form.Select
                className="form-control w-35 d-inline-flex form-control-sm"
                value={clientId}
                onChange={handleClientFilter}
              >
                <option key="all" value="">
                  All Clients
                </option>
                {clientList.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Form.Select>
            </div>
          )}

          {showModal && (
            <ContactForm
              handleSubmit={handleSubmit}
              handleCloseModal={handleCloseModal}
              contact={contact}
            />
          )}
          {contactsFetchStatus === STARTED ? (
            <Loading />
          ) : contactsFetchStatus === SUCCEEDED ? (
            <StopTable
              contacts={contacts}
              handleShowModal={handleShowModal}
              removeContact={(contactId) => dispatch(removeContact(contactId))}
            />
          ) : (
            t("MEMBER.CONTACTS.CAN'T_FETCH_CONTACTS")
          )}
        </div>
      </div>
    </div>
  );
};

export default StopManagement;
