import React from "react";

import Icon from "@mdi/react";
import { useTranslation } from "react-i18next";

const Tab = ({
  title,
  className,
  icon,
  color,
  lg,
  value,
  children,
  horizontal = false,
  titleStyle,
  message
}) => {
  const [t] = useTranslation();

  return (
    <div className={`col-lg-${lg}`}>
      {!children && (
        <div className={className}>
          <div className="d-flex mb-2">
            <p
              className={`mb-0 font-regular text-muted font-weight-bold ${titleStyle}`}
            >
              {title}
            </p>
          </div>
          <div className="d-block d-sm-flex h-100 align-items-center">
            <div>
              <Icon
                path={icon}
                size={2}
                horizontal={horizontal}
                color={color}
              />
            </div>
            <div className="statistics mt-3 mt-sm-0 ml-sm-auto text-center text-sm-right">
              <h3 className="mb-0 pl-4 text-left text-break">
                <i className="icon-arrow-up-circle"></i> {value}
              </h3>
            </div>
          </div>
          {message&&<p
              className={`my-5 ml-5 font-regular text-muted font-weight-bold ${titleStyle}`}
            >
              Message id:{message}
            </p>}
        </div>
      )}
      {children && children}
    </div>
  );
};

export default Tab;
