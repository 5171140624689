/**
 * This file contains helper methods
 */
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
/**
 * Get error from response
 * @param {Object} payload
 * @returns error
 */
export const getError = (payload) => {
  const data = payload.response.data;
  const regex = /\"data.([a-zA-Z]+)\" (.+)/;
  const result = data?.message?.match(regex);
  if (result) {
    return {
      [result[1]]: result[2],
    };
  } else if (data.message) {
    return data.message;
  }
  return false;
};

export const toastError = (payload) => {
  const data = payload.response.data;
  const regex = /\"data.([a-zA-Z]+)\" (.+)/;
  const result = data?.message?.match(regex);
  if (result) {
    return result[1] + " field " + result[2];
  } else if (data.message) {
    return data.message;
  }
  return false;
};

export const successMessageToast = (message) => {
  const Toast = toast(message, {
    style: {
      backgroundColor: "MediumSeaGreen",
      borderRadius: 0,
      color: "white",
    },
    duration: 2000,
    position: "top-center",
  });
  return Toast;
};

export const errorMessageToast = (message) => {
  const Toast = toast(message, {
    style: {
      backgroundColor: "red",
      borderRadius: 0,
      color: "white",
    },
    duration: 2000,
    position: "top-center",
  });
  return Toast;
};