import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import _ from 'lodash';

import { Form } from 'react-bootstrap';
import {
  createCampaign,
  fetchCampaigns,
  removeCampaign,
  updateCampaign
} from '../../../store/actions/creators/campaignActions';
import Loading from '../../../components/Shared/Loading';
import CampaignsList from '../../../components/Admin/CampaignList';
import { STARTED, SUCCEEDED } from '../../../utils/helpers/store-helpers';
import { campaignsSelector, campaignsFetchStatusSelector } from '../../../store/selectors/campaignSelector';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { clientsSelector, clientsFetchStatusSelector } from '../../../store/selectors/clientSelector';
import { fetchAllClients } from '../../../store/actions/creators/clientActions';

const Campaigns = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const campaigns = useSelector(campaignsSelector);
  const campaignsFetchStatus = useSelector(campaignsFetchStatusSelector);
  const clients = useSelector(clientsSelector);

  const [clientId, setClientId] = useState('all');
  const [clientList, setClientList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);

  useEffect(() => {
    dispatch(fetchCampaigns());
    dispatch(fetchAllClients(true));
  }, []);

  useEffect(()=> {
    if(clients && clients.length){
      let temp = clients.map(({ id, company }) =>  ({ 'value': id, 'label': company}) )
      setClientList(temp);
    }
  },[clients]);

  const handleShowModal = (campaignId) => {
    setShowModal(true);
    setCampaign(campaignId ? campaigns.find(
      (campaign) => campaign.id === campaignId
    ) : {});
  };
  
  const handleClientFilter = (event) => {
    const value = event.target.value;
    setClientId(value);
  }

  useEffect(()=>{
    clientId == 'all' ? setCampaignList(campaigns) : setCampaignList(campaigns.filter(i => i.clientId == clientId))
  },[clientId, campaigns])

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-body">
          <div className="d-flex mb-4">
            <h4 className="card-title">{t('MEMBER.CAMPAIGNS.CAMPAIGNS')}</h4>
        
              <Form.Label className="pt-2 ml-5  mr-3">
                {t('ADMIN.REPORTS.FILTER_BY_CLIENT')}
              </Form.Label>
              <Form.Select
                className="form-control w-15  form-control-sm"
                value={clientId}
                onChange={handleClientFilter}
              >
                <option key='all' value="all">All Clients</option>
                {clientList.map((item) => (
                    <option key={item.value} value={item.value}>
                     {item.label}
                    </option>
                ))}
              </Form.Select>
           
          </div>
          {campaignsFetchStatus === STARTED ? (
            <Loading/>
          ) : campaignsFetchStatus === SUCCEEDED && campaigns.length > 0 ? (  
            <Tabs defaultActiveKey="active_campaigns" className="mb-3">
              <Tab eventKey="active_campaigns" title="Active Campaigns">
                <CampaignsList
                  campaigns={(campaignList.filter(i => i.status == 'Active'))}
                  handleShowModal={handleShowModal}
                  removeCampaign={(campaignId) => dispatch(removeCampaign(campaignId))}
                />
              </Tab>
              <Tab eventKey="sent_campaigns" title="Sent Campaigns">
                <CampaignsList
                  campaigns={(campaignList.filter(i => i.status == 'Sent'))}
                  handleShowModal={handleShowModal}
                  removeCampaign={(campaignId) => dispatch(removeCampaign(campaignId))}
                />
              </Tab>
              <Tab eventKey="inactive_campaigns" title="Inactive Campaigns">
                <CampaignsList
                  campaigns={(campaignList.filter(i => i.status == 'Inactive'))}
                  handleShowModal={handleShowModal}
                  removeCampaign={(campaignId) => dispatch(removeCampaign(campaignId))}
                />
              </Tab>
            </Tabs>
          ) : (
            t('MEMBER.CAMPAIGNS.CAN\'T_FETCH_CAMPAIGNS')
          )}
        </div>
      </div>
    </div>
  );
};

export default Campaigns;