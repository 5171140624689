import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import _ from "lodash";

import {
  clientFetchStatusSelector,
  clientSelector,
} from "src/store/selectors/clientSelector";
import { fetchClient } from "src/store/actions/creators/clientActions";
import { STARTED } from "../../../utils/helpers/store-helpers";
import CreditList from "src/components/Member/CreditList";
import Loading from "src/components/Shared/Loading";

const Credit = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();
  const localStorageUser = JSON.parse(localStorage.getItem("authUserData"));
  const sessionStorageUser = JSON.parse(sessionStorage.getItem("authUserData"));

  const storageUser = localStorageUser || sessionStorageUser;
  const client = useSelector(clientSelector);

  const clientFetchStatus = useSelector(clientFetchStatusSelector);

  useEffect(() => {
    dispatch(fetchClient(storageUser.accountId));
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card card-body">
            <div className="d-flex justify-content-between mb-4">
              <h4 className="card-title">{t("COMMON.CREDITS")}</h4>
            </div>

            {clientFetchStatus === STARTED ? (
              <Loading />
            ) : clientFetchStatus ? (
              <CreditList credit={[client]} />
            ) : (
              t("COMMON.CAN'T_FETCH_CREDIT")
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Credit;
