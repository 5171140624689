export const totalUsersSelector = (state) => state.dashboard.totalUsers;
export const totalContactsSelector = (state) => state.dashboard.totalContacts;
export const totalSMSSelector = (state) => state.dashboard.totalSMS;
export const todaySMSSelector = (state) => state.dashboard.todaySMS;
export const monthlyTrendSelector = (state) => state.dashboard.monthlyTrend;
export const yesterdaySMSSelector = (state) => state.dashboard.yesterdaySMS;
export const last7DaysSMSSelector = (state) => state.dashboard.last7DaysSMS;
export const last30DaysSMSSelector = (state) => state.dashboard.last30DaysSMS;
export const smsByCampaignsSelector = (state) => state.dashboard.smsByCampaigns;
export const dashboardDataFetchStatusSelector = (state) => state.dashboard.dashboardDataFetchStatus;
export const lastCampaignsSelector = (state) => state.dashboard.lastCampaigns;
export const futureCampaignsSelector = (state) => state.dashboard.futureCampaigns;