import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import _ from "lodash";

import { Button } from "react-bootstrap";
import HolidayList from "./HolidayList";
import HolidayForm from "./HolidayForm";
import {
  createHoliday,
  fetchHolidaysByAccount,
  getHolidaysForValidation,
  removeHoliday,
  updateHoliday,
} from "src/store/actions/creators/holidayAction";
import { useDispatch, useSelector } from "react-redux";
import {
  successMessageToast,
  errorMessageToast,
  getError,
} from "src/utils/common/helper";
import {
  holidayFetchStatusSelector,
  holidayValidationFetchStatusSelector,
  holidaysSelector,
} from "src/store/selectors/holidaySelector";
import Loading from "../Shared/Loading";
import { STARTED, SUCCEEDED } from "src/utils/helpers/store-helpers";
import { UserRoles } from "src/utils/constants";

const Holidays = () => {
  const [showModal, setShowModal] = useState(false);
  const [holiday, setHoliday] = useState([]);
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const holidays = useSelector(holidaysSelector);
  const holidayFetchValidation = useSelector(
    holidayValidationFetchStatusSelector
  );
  const holidayFetchByAccount = useSelector(holidayFetchStatusSelector);

  const localStorageUser = JSON.parse(localStorage.getItem("authUserData"));
  const sessionStorageUser = JSON.parse(sessionStorage.getItem("authUserData"));
  const storageUser = localStorageUser || sessionStorageUser;

  const handleShowModal = (holidayId) => {
    setShowModal(true);
    setHoliday(holidayId ? holidays?.find((c) => c.id === holidayId) : {});
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const filteredHolidays = holidays.filter(
    (el, index) => holidays.findIndex((obj) => obj.id === el.id) === index
  );

  useEffect(() => {
    if (storageUser.role === UserRoles.SuperAdmin) {
      dispatch(fetchHolidaysByAccount());
    } else {
      dispatch(getHolidaysForValidation());
    }
  }, [storageUser.role]);

  const [showError, setShowError]=useState('');
  
  const handleSubmit = async (data) => {
    try {
      if (data.id) await dispatch(updateHoliday(data.id, _.omit(data, ["id"])));
      else await dispatch(createHoliday(data));
      successMessageToast("Details saved successfully.");
      handleCloseModal();
    } catch (error) {
      let err = getError(error);
      errorMessageToast(err)
    }
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-body">
          <div className="d-flex justify-content-between mb-4">
            <h4 className="card-title">{t("COMMON.HOLIDAY_LIST")}</h4>
            <Button
              variant="primary"
              size="sm"
              className="btn-icon-text"
              onClick={handleShowModal}
            >
              <i className="feather-user-plus btn-icon-prepend"></i>
              {t("COMMON.ADD_HOLIDAY")}
            </Button>
            {showModal && (
              <HolidayForm
                handleSubmit={handleSubmit}
                handleCloseModal={handleCloseModal}
                holiday={holiday}
                // submitErrors={submitErrors}
                showError={showError}
              />
            )}
          </div>
          {holidayFetchValidation === STARTED ||
          holidayFetchByAccount === STARTED ? (
            <Loading />
          ) : holidayFetchValidation === SUCCEEDED ||
            holidayFetchByAccount === SUCCEEDED ? (
            <HolidayList
              holiday={
                storageUser.role === UserRoles.SuperAdmin ? holidays : filteredHolidays
              }
              handleShowModal={handleShowModal}
              removeHoliday={(holidayId) => dispatch(removeHoliday(holidayId))}
            />
          ) : (
            t("ADMIN.COUNTRY.CANT_FETCH_ALL_HOLIDAYS")
          )}
        </div>
      </div>
    </div>
  );
};

export default Holidays;
