import React from "react";
import Tab from "./Tab";
import {
  mdiEmailFast,
  mdiCardAccountDetails,
  mdiPackageVariantClosedCheck,
} from "@mdi/js";
import { useTranslation } from "react-i18next";

const GeneralInfoBar = ({ inputs }) => {
  const [t] = useTranslation();
  return (
    <div className="row">
      <div className="col-lg-12 grid-margin">
        <div className="card card-body ">
          <div className="row no-gutters">
            <Tab
              lg={4}
              title={t("COMMON.STATUS")}
              className={"p-2 border-lg-right border-bottom border-lg-bottom-0"}
              icon={mdiPackageVariantClosedCheck}
              color={
                inputs.status === "DELIVERED"
                  ? "#22A184"
                  : inputs.status === "DELIVERING"
                  ? "#787373"
                  : "#FF0000"
              }
              value={inputs.status}
            />
            <Tab
              lg={4}
              title={t("COMMON.NUMBER_OF_MESSAGES")}
              className={"p-2 border-lg-right border-bottom border-lg-bottom-0"}
              icon={mdiEmailFast}
              color={"#0000CD"}
              value={inputs.smsCount}
            />
            <Tab
              lg={4}
              title={t("COMMON.FROM")}
              className={"p-2 border-lg-bottom-0"}
              icon={mdiCardAccountDetails}
              color={"#0000CD"}
              value={inputs.from}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfoBar;
