import {
    FETCH_ALL_PROVIDERS,
    FETCH_PROVIDER,
    CREATE_PROVIDER,
    UPDATE_PROVIDER,
    REMOVE_PROVIDER,
  } from '../actions/types/providerTypes';
  import { getError } from '../../utils/common/helper';
  import { FAILED, getFailed, getStarted, getSucceeded, STARTED, SUCCEEDED } from '../../utils/helpers/store-helpers';
  
  // Initial state
  const initialState = {
    errors: null,
    providers: [],
    provider: {},
    providersFetchStatus: '',
    providerFetchStatus: '',
    providerCreateStatus: '',
    providerUpdateStatus: '',
    providerRemoveStatus: '',
  };
  
  // Provider reducer
  const events = (state = initialState, { type, payload }) => {
      switch (type) {
        case getStarted(FETCH_ALL_PROVIDERS):
          return { ...state, providersFetchStatus: STARTED };
        case getFailed(FETCH_ALL_PROVIDERS):
          return { ...state, providersFetchStatus: FAILED };
        case getSucceeded(FETCH_ALL_PROVIDERS):
          return { ...state, providers: payload, providersFetchStatus: SUCCEEDED };

        case getStarted(FETCH_PROVIDER):
          return { ...state, providerFetchStatus: STARTED };
        case getFailed(FETCH_PROVIDER):
          return { ...state, provider: {}, providerFetchStatus: FAILED, errors: getError(payload) };
        case getSucceeded(FETCH_PROVIDER):
          return { ...state, provider: payload.data, providerFetchStatus: SUCCEEDED };

        default:
          return state;
      }
    }
  ;
  
  export default events;
  