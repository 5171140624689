export const tokenSelector = (state) => state.auth.token;
export const userDataSelector = (state) => state.auth.userData;
export const registrationErrorSelector = (state) => state.auth.registrationError;
export const registrationStatusSelector = (state) => state.auth.registrationStatus;
export const resetPasswordErrorSelector = (state) => state.auth.resetPasswordError;
export const resetPasswordStatusSelector = (state) => state.auth.resetPasswordStatus;
export const sendVerificationCodeErrorSelector = (state) => state.auth.sendVerificationCodeError;
export const verificationCodeSendStatusSelector = (state) => state.auth.verificationCodeSendStatus;
export const sendForgotPasswordEmailErrorSelector = (state) => state.auth.sendForgotPasswordEmailError;
export const phoneNumberVerificationErrorSelector = (state) => state.auth.phoneNumberVerificationError;
export const phoneNumberVerificationStatusSelector = (state) => state.auth.phoneNumberVerificationStatus;
export const forgotPasswordEmailSendStatusSelector = (state) => state.auth.forgotPasswordEmailSendStatus;
export const updateProfileStatusSelector = (state) => state.auth.updateProfileStatus;
export const updateProfileErrorsrSelector = (state) => state.auth.updateProfileErrors;