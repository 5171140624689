import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ItemsList from "../../ItemsList";
import { getHolidayListColumns } from "../../../utils/helpers";

const HolidayList = ({ holiday, handleShowModal, removeHoliday }) => {
  const [t] = useTranslation();

  const columns = useMemo(() => getHolidayListColumns(t), [t]);

  const actions = (item, action) =>
    ({
      delete: true,
      edit: true,
    }[action]);

  return (
    <ItemsList
      items={holiday}
      columns={columns}
      searchColumns={""}
      actions={actions}
      handleShowModal={handleShowModal}
      removeItem={removeHoliday}
      hideIcon={window.location.href.includes('member')}
    />
  );
};

export default HolidayList;
