import { Link } from 'react-router-dom';
import HeaderText from '../../HeaderText';
import NavbarLogoMini from '../NavbarLogoMini';
import React from 'react';

const NavbarBrand = () => (
  <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
    <Link to="/login" className="navbar-brand brand-logo mr-5">
      <HeaderText />
    </Link>
    <NavbarLogoMini />
  </div>
)

export default NavbarBrand;