/**
 * Service for dashboard page
 */
import useApi from '../utils/api';

class DashboardService {
  #prefix = '/dashboard';
  
  fetchDashboardData = (campaignId) => {
    return campaignId && typeof  campaignId != 'object' ? useApi.get(`${this.#prefix}?campaignId=${campaignId}`) : useApi.get(`${this.#prefix}`);
  };
}

export default new DashboardService();
