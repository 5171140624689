import smsSentToday from "../../assets/images/sms_sent_today.svg";
import smsDeliveredToday from "../../assets/images/sms_delivered_today.svg";
import smsFailedToday from "../../assets/images/sms_failed_today.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import InfoColumn from "src/components/AdminHome/Dashboard/SmsInfo/InfoColumn";

const CampaignSmsInfo = ({ inputs }) => {
  const [t] = useTranslation();

  return (
    <div className="row">
      <div className="col-12 grid-margin">
        <div className="row">
          <InfoColumn
            lg={4}
            textStyle={"pl-5 pt-4"}
            iconPath={smsSentToday}
            iconColor="#0276f8"
            classNameSuffix="mb-3 mb-lg-0"
            text={t("COMMON.SMS_SENT")}
            count={inputs.smsSent?inputs.smsSent:'--'}
          />
          <InfoColumn
            lg={4}
            textStyle={"pl-5 pt-4"}
            iconPath={smsDeliveredToday}
            iconColor="#46a146"
            classNameSuffix="mb-3 mb-lg-0"
            text={t("COMMON.SMS_DELIVERED")}
            count={inputs.smsDelivered?inputs.smsDelivered:"--"}
          />
          <InfoColumn
            lg={4}
            textStyle={"pl-5 pt-4"}
            iconPath={smsFailedToday}
            iconColor="#ff2121"
            text={t("COMMON.SMS_FAILED")}
            count={inputs.smsFailed?inputs.smsFailed:'--'}
          />
        </div>
      </div>
    </div>
  );
};

export default CampaignSmsInfo;
