import React from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  MainPanel,
  MainContainer,
  ContentWrapper,
} from "../../components/Container";
import MemberHeader from "../../components/Member/Header";
import Footer from "../../components/Footer";
import MemberSideBar from "../../components/Member/SideBar";
import { logout } from "../../store/actions/creators/authActions";
import { userDataSelector } from "../../store/selectors/authSelector";

const MemberHome = () => {
  const dispatch = useDispatch();
  
  const userData = useSelector(userDataSelector);

  return (
    <MainContainer>
      <MemberHeader logout={() => dispatch(logout())} userData={userData} />
      <Container>
        <MemberSideBar/>
        <MainPanel>
          <ContentWrapper>
            <Outlet />
          </ContentWrapper>
          <Footer />
        </MainPanel>
      </Container>
    </MainContainer>
  );
};

export default MemberHome;
