import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Icon from '@mdi/react';
import { mdiArrowDown, mdiArrowUp } from '@mdi/js';
import { getClientCredentialsListColumns, sortFunc } from '../../../../utils/helpers';

const ClientCredentialsTable = ({
  page,
  sortBy,
  perPage,
  sortOrder,
  filteredClients,
  handleChangeSort
}) => {
  const [t] = useTranslation();

  const columns = useMemo(() => getClientCredentialsListColumns(t), [t]);

  return (
    <div className="row">
      <div className="col-sm-12 mb-2">
        <table className="table table-bordered table-striped table-hover">
          <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.accessor} className="justify-content-between">
                {column.Header}{' '}
                <Link to="#" onClick={() => handleChangeSort(column.accessor)}>
                  {sortBy === column.accessor ?
                    sortOrder === 'desc'
                      ? <Icon path={mdiArrowDown} size={0.6} color="#4B49AC"/>
                      : <Icon path={mdiArrowUp} size={0.6} color="#4B49AC"/>
                    : <Icon path={mdiArrowDown} size={0.6} color="#CCC"/>}
                </Link>
              </th>
            ))}
          </tr>
          </thead>
          <tbody>
          {filteredClients
            .sort(sortFunc(sortBy, sortOrder))
            .slice((page - 1) * perPage, page * perPage)
            .map((row) => (
              <tr key={row.id}>
                {columns.map((column) => {
                  return (
                    <td key={`${row.id}-${column.accessor}`}>
                      { row[column.accessor] == null ? '-' : 
                         (typeof row[column.accessor] === 'object' ?
                            JSON.stringify(row[column.accessor]).replace(/[{}]/g, '') : row[column.accessor]) 
                      }
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ClientCredentialsTable;