import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";
import ModalHeader from "./ModalHeader";
import { Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  clientCreateStatusSelector,
  clientUpdateStatusSelector,
} from "../../../store/selectors/clientSelector";
import { useFirstRender } from "../../../utils/hooks";
import { SUCCEEDED } from "../../../utils/helpers/store-helpers";
import { clientsFormInitialState } from "../../../utils/helpers";

const ClientForm = ({
  client,
  handleSubmit,
  handleCloseModal,
  submitErrors,
  providers,
}) => {
  const [t] = useTranslation();
 
  const initialState = useMemo(() => clientsFormInitialState, []);

  const clientUpdateStatus = useSelector(clientUpdateStatusSelector);
  const clientCreateStatus = useSelector(clientCreateStatusSelector);

  const firstRender = useFirstRender();

  const [inputs, setInputs] = useState(initialState);
  const [submitClicked, setSubmitClicked] = useState(false);

  useEffect(() => {
    if (!firstRender && clientCreateStatus === SUCCEEDED) handleCloseModal();
  }, [clientCreateStatus]);

  useEffect(() => {
    if (!firstRender && clientUpdateStatus === SUCCEEDED) handleCloseModal();
  }, [clientUpdateStatus]);

  const handleSubmitButtonClick = (event) => setSubmitClicked(true);

  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit({
      ...inputs,
      fromFieldSet: inputs.fromFieldSet
        ? inputs.fromFieldSet.map((item) => item.label.toUpperCase()).join(",")
        : "",
    });
  };

  return (
    <Modal show={true} onHide={handleCloseModal} size="lg">
      <ModalHeader
        title={
          inputs.id
            ? t("ADMIN.CLIENTS.EDIT_CLIENT")
            : t("ADMIN.CLIENTS.INSERT_CLIENT")
        }
        handleCloseModal={handleCloseModal}
      />
      <Form
        className={"form-sample" + (submitClicked ? " was-validated" : "")}
        onSubmit={onSubmit}
      >
        <ModalBody
          setInputs={setInputs}
          inputs={inputs}
          initialState={initialState}
          client={client}
          submitErrors={submitErrors}
          providers={providers}
        />
        <ModalFooter
          handleCloseModal={handleCloseModal}
          handleSubmitButtonClick={handleSubmitButtonClick}
          btnClassName={inputs.id ? "feather-save" : "feather-user-plus"}
          btnValue={
            inputs.id ? t("ADMIN.CLIENTS.SAVE") : t("ADMIN.CLIENTS.INSERT")
          }
        />
      </Form>
    </Modal>
  );
};

export default ClientForm;
