import { useTranslation } from 'react-i18next';

import FormRow from '../../../../Shared/Form/FormRow';
import MonthlyMinPrice from './MonthlyMinPrice';
import { Form } from 'react-bootstrap';
import { useState } from 'react';
import CreatableSelect from 'react-select/creatable';

const GeneralForm = ({
  errors,
  inputs,
  setInputs,
  handleChange,
}) => {
  const [t] = useTranslation();

  const [regexError, setRegexError] = useState(false);

  const handleChangeFrom = (newValue, actionMeta) => {
    const name = actionMeta.name;
    let reg = /^(?=.*[a-zA-Z])(?!.*\s)[a-zA-Z\d]{1,11}$/; 
    const temp = newValue.filter(element => {
      return reg.test(element.value);
    });
    
    if(newValue.length > temp.length){
      setRegexError(true);
    }else{
      setRegexError(false);
    }

    setInputs({
      ...inputs,
      [name]: temp,
    });
  };

  const rows = [
    [
      { title: t('ADMIN.CLIENTS.COMPANY'), name: 'company', required: true },
      { title: t('ADMIN.CLIENTS.ADDRESS'), name: 'address', required: true }
    ],
    [
      { title: t('ADMIN.CLIENTS.CITY'), name: 'city', required: true },
      { title: t('ADMIN.CLIENTS.POSTAL_CODE'), name: 'zip', required: true }
    ],
    [
      { title: t('ADMIN.CLIENTS.EMAIL'), type: 'email', name: 'email', required: true },
    ]
  ];

  return (
    <>
      {rows.map((row, idx) => <FormRow key={idx} id={idx} row={row} inputs={inputs} handleChange={handleChange} errors={errors}/>)}
      <div className="row">
        <div className="col-md-6">
          <Form.Group className="form-check form-check-flat form-check-primary mb-3">
            <Form.Label className="form-check-label">
              <Form.Control
                type="checkbox"
                name="sendEmailVerification"
                className="form-check-input"
                checked={inputs.sendEmailVerification}
                onChange={() => setInputs({
                  ...inputs,
                  sendEmailVerification: !inputs.sendEmailVerification
                })}
              />
              {t('ADMIN.CLIENTS.SEND_EMAIL_FOR_VERIFICATION')}
              <i className="input-helper"></i>
            </Form.Label>
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Form.Group className="form-group mb-3">
            <Form.Label >
              {t('ADMIN.CLIENTS.FROM_FIELDS_SET')}
            </Form.Label>
            <CreatableSelect
              options={[]}
              name="fromFieldSet"
              value={inputs.fromFieldSet}
              onChange={handleChangeFrom}
              isMulti
            />
            <Form.Control.Feedback type="invalid">
              {errors?.fromFieldSet
                ? `${t('ADMIN.CLIENTS.LOGIN')} ${errors?.fromFieldSet}`
                : ''}
            </Form.Control.Feedback>
            {regexError && <p className="text-danger">{t('ADMIN.CLIENTS.FROM_FIELD_REGEX_ERROR')}</p> }
          </Form.Group>
        </div>
      </div>
    </>
  );
};

export default GeneralForm;