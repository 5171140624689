import React from "react";
import Tab from "./Tab";
import { mdiCommentText, mdiAccountMultiple, mdiAccountGroup } from "@mdi/js";
import { useTranslation } from "react-i18next";

const CampaignGeneralInfo = ({ inputs }) => {
  const [t] = useTranslation();

  return (
    <div className="row">
      <div className="col">
        <div className="card card-body ">
          <div className="row no-gutters">
            <Tab
              lg={8}
              titleStyle={"ml-1"}
              title={t("COMMON.MESSAGE")}
              className={"p-2 border-lg-right border-bottom border-lg-bottom-0"}
              icon={mdiCommentText}
              color={"#0000CD"}
              value={inputs.message}
              horizontal
            />
            <Tab lg={4}>
              <Tab
                lg={12}
                titleStyle={"ml-1"}
                title={t("COMMON.NUMBER_OF_CONTACTS")}
                className={"p-2  border-bottom border-lg-bottom-0"}
                icon={mdiAccountMultiple}
                color={"#0000CD"}
                value={inputs.numContacts}
              />
              <Tab
                lg={12}
                titleStyle={"ml-1"}
                title={t("COMMON.GROUP")}
                className={"p-2 border-bottom border-lg-bottom-0"}
                icon={mdiAccountGroup}
                color={"#0000CD"}
                value={inputs?.groups?.map((value) => {
                  return value.name;
                })}
              />
            </Tab>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignGeneralInfo;
