import { useTranslation } from 'react-i18next';
import CheckMark from '../../../../assets/images/checkmark.png';
import Icon from "@mdi/react";
import { mdiWalletGiftcard } from "@mdi/js";

const Step2 = ({ step, setStep, setIsLoading }) => {
  const [t] = useTranslation();
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setStep(step + 1);
  }
  
  return (
    <>
       <div className="row">
              <div className="d-flex flex-column col-lg-4 col-xl-4">
                <img src={CheckMark} className="d-block w-100"/>
              </div>
              <div className="d-flex flex-column col-lg-8 col-xl-8">
                 <strong>{t("REGISTRATION.VERIFY_NUMBER")}</strong>
                 <br/>
                <p>
                   {t("REGISTRATION.SMS_UNIQUE_CODE")}
                </p>
              </div>
        </div>
        <div className="row">
          <div className="d-flex flex-column col-lg-12 col-xl-12  text-center">
            <form className="pt-3" onSubmit={(e) => handleSubmit(e)}>
                <button
                  type="submit"
                  className="btn btn-primary px-5"
                >
                  <strong> {t("REGISTRATION.RECIEVE_THE_CODE")}</strong>
                </button>
            </form> 
          </div>
        </div>
    </>
  );
};

export default Step2;
