import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getAdminHomeAdminTableRows } from "src/utils/helpers/";
import { Link, useParams } from "react-router-dom";
import { mdiAbTesting, mdiContentCopy } from "@mdi/js";
import Icon from "@mdi/react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ClientTable = ({ client }) => {
  const [t] = useTranslation();

  const [copiedValue, setCopiedValue] = useState("");

  const [isCopied, setIsCopied] = useState(false);

  const rows = useMemo(
    () => getAdminHomeAdminTableRows(t, client),
    [t, client]
  );

  const onCopyText = (val) => {
    setCopiedValue(val);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <>
      <h5 className="card-title">{t("COMMON.API_CREDENTIALS")}</h5>
        <table className="table user-view-table m-0">
          <tbody>
            {rows.map((el, index) => (
              <tr key={index}>
                <td>{el.title}</td>
                <td className="d-flex">
                  {el.value}{" "}
                  {el.field == "smsSecretKey" ||
                  el.field == "smsAccountPass" ? (
                    <Link
                      to="#"
                      className="mr-1"
                      onClick={(e) => {
                        e.preventDefault();
                        handleShowConfirmModal(el.field);
                      }}
                    >
                    </Link>
                  ) : (
                    ""
                  )}
                  <CopyToClipboard
                    text={el.value}
                    onCopy={(e) => onCopyText(e)}
                  >
                    <span className="pl-3" role="button">
                      {isCopied && copiedValue === el.value ? (
                       <h6 className="text-muted"> Copied!</h6> 
                      ) : (
                        <Icon path={mdiContentCopy} size={0.7} />
                      )}
                    </span>
                  </CopyToClipboard>
                </td>
              </tr>
            ))}
        
          </tbody>
        </table>
    </>
  );
};

export default ClientTable;
