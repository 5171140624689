import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useFirstRender } from "../../../utils/hooks";
import { SUCCEEDED, STARTED } from "../../../utils/helpers/store-helpers";
import { registerSettingInitialState } from "../../../utils/helpers";
import { fetchAllCountries } from 'src/store/actions/creators/countryActions';
import { countriesSelector } from 'src/store/selectors/countrySelector';
import { registrationSettingCreateStatusSelector, registrationSettingUpdateStatusSelector } from 'src/store/selectors/registrationSettingSelector';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

const RegistrationSettingForm = ({ setting, handleSubmit, submitErrors }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const initialState = useMemo(() => registerSettingInitialState, []);
  const countries = useSelector(countriesSelector);

  const registrationSettingCreateStatus = useSelector(registrationSettingCreateStatusSelector);
  const registrationSettingUpdateStatus = useSelector(registrationSettingUpdateStatusSelector);

  const [inputs, setInputs] = useState(initialState);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [formInitialized, setFormInitialized] = useState(false);

  useEffect(async () => {
    await dispatch(fetchAllCountries());
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit({
      ...inputs,
      countries: inputs.countries
        ? inputs.countries.map((item) => item.value).join(",")
        : "",
    });
  };

  const countryList = useMemo(() => {
    return countries && countries.map(p => {
      return {value: p.id, label: p.countryName}
    })
  }, [countries]);

  const expiryDelayList = useMemo(() => [
    { value: '86400', label: '24 hours' },
    { value: '172800', label: '48 hours' },
    { value: '259200', label: '72 hours' },
    { value: '345600', label: '96 hours' },
  ], []);

  const handleChangeCountry = (newValue, actionMeta) => {
    const name = actionMeta.name;
    setInputs({
      ...inputs,
      [name]: newValue,
    });
  }

  useEffect(() => {
    if (formInitialized) return;
    if(!_.isEmpty(countries)){
      if (_.isEmpty(setting)) {
        setInputs(initialState);
      } else {

        setInputs({
          id: setting.id,
          creditAmount: setting.creditAmount,
          sendEmailReminder: setting.sendEmailReminder,
          sendMobileReminder: setting.sendMobileReminder,
          emailNotification: setting.emailNotification,
          expirationDelay: setting.expirationDelay,
          countries: setting.countryList
            ? setting.countryList.map((p) => ({ label: p.countryName, value: p.id }))
            : '',
        });
        setFormInitialized(true);
      }

    }
  }, [formInitialized, setting ,countries]);
 
  return (
    <Form
      className={"form-sample" + (submitClicked ? " was-validated" : "")}
      onSubmit={onSubmit}
    >
      <div className="row">
        <div className="col-md-8">
          <Form.Group className="form-group mb-3">
            <Form.Label className="required">
              {t("ADMIN.REGISTRATION_SETTING.COUNTRY")}
            </Form.Label>
            <Select
              options={countryList}
              name="countries"
              value={inputs.countries}
              onChange={handleChangeCountry}
              isMulti
            />
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0 }}
              value={inputs.countries}
              required={true}
              onChange={() => {}}
            />
            <Form.Control.Feedback type="invalid">
              {submitErrors?.countries
                ? `${t("ADMIN.REGISTRATION_SETTING.COUNTRY")} ${submitErrors?.countries}`
                : ""}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <Form.Group className="form-group mb-3">
              <Form.Label className="required">
                  {t('ADMIN.REGISTRATION_SETTING.EXPIRY_DELAY')}
              </Form.Label>
              <Select
                  options={expiryDelayList}
                  name="expirationDelay"
                  value={
                    expiryDelayList.filter(option => 
                        option.value == inputs.expirationDelay)
                  }
                  onChange={(op) => setInputs({
                    ...inputs,
                    expirationDelay: op.value
                  })}
              ></Select>
          </Form.Group>
          </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <Form.Group className="form-group mb-3" >
            <Form.Label className='required'>{t('ADMIN.REGISTRATION_SETTING.CREDIT_AMOUNT')}</Form.Label>
            <Form.Control
                type="text"
                name="creditAmount"
                isInvalid={Boolean(submitErrors && submitErrors.creditAmount)}
                placeholder={t('ADMIN.REGISTRATION_SETTING.CREDIT_AMOUNT')}
                value={inputs.creditAmount}
                onChange={({ target: { name, value } }) => setInputs({
                    ...inputs,
                    creditAmount: value
                  })}
                required={true}
            />
            <Form.Control.Feedback type="invalid">
                {submitErrors && submitErrors.creditAmount ? `Credit Amount ${submitErrors && submitErrors.creditAmount}` : ''}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <Form.Group className="form-group mb-3" >
            <Form.Label  className='required'>{t('ADMIN.REGISTRATION_SETTING.EMAIL_NOTIFICATION')}</Form.Label>
            <Form.Control
                type="text"
                name="emailNotification"
                isInvalid={Boolean(submitErrors && submitErrors.emailNotification)}
                value={inputs.emailNotification}
                onChange={({ target: { name, value } }) => setInputs({
                    ...inputs,
                    emailNotification: value
                })}
            />
            <Form.Control.Feedback type="invalid">
                {submitErrors && submitErrors.emailNotification ? `${submitErrors && submitErrors.emailNotification}` : ''}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 mt-4">
            <Form.Group className="form-check form-check-flat form-check-primary mb-3">
              <Form.Label className="form-check-label">
                <Form.Control
                  type="checkbox"
                  name="sendEmailReminder"
                  className="form-check-input"
                  checked={inputs.sendEmailReminder}
                  onChange={() => setInputs({
                    ...inputs,
                    sendEmailReminder: !inputs.sendEmailReminder
                  })}
                />
                {t('ADMIN.REGISTRATION_SETTING.SEND_EMAIL_REMINDER')}
                <i className="input-helper"></i>
              </Form.Label>
            </Form.Group>
        </div>
        <div className="col-md-4 mt-4">
            <Form.Group className="form-check form-check-flat form-check-primary mb-3">
              <Form.Label className="form-check-label">
                <Form.Control
                  type="checkbox"
                  name="sendMobileReminder"
                  className="form-check-input"
                  checked={inputs.sendMobileReminder}
                  onChange={() => setInputs({
                    ...inputs,
                    sendMobileReminder: !inputs.sendMobileReminder
                  })}
                />
                {t('ADMIN.REGISTRATION_SETTING.SEND_MOBILE_REMINDER')}
                <i className="input-helper"></i>
              </Form.Label>
            </Form.Group>
        </div>
      </div>
      <Button variant="primary" className="btn-icon-text" type="submit">
          {registrationSettingCreateStatus === STARTED || registrationSettingUpdateStatus === STARTED ? (
            <Spinner className="mr-2" animation="border" variant="secondary" size="sm"/>
          ) : (
            <i className={`feather-save btn-icon-prepend`}></i>
          )}
          {inputs.id ? t('COMMON.SAVE') : t('COMMON.INSERT')}
      </Button>
    </Form>
  );
};

export default RegistrationSettingForm;
