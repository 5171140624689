import { Modal } from 'react-bootstrap';

const ModalHeader = ({ title, handleCloseModal }) => {
  return (
    <Modal.Header closeButton={false}>
      <Modal.Title>{title}</Modal.Title>
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={handleCloseModal}
      >
        <span aria-hidden="true">x</span>
      </button>
    </Modal.Header>
  )
}

export default ModalHeader