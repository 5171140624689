import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
import moment from 'moment';

import { Form } from 'react-bootstrap';
import ItemsList from '../../../components/ItemsList';
import Loading from '../../../components/Shared/Loading';
import { STARTED } from '../../../utils/helpers/store-helpers';
import { getSmsStatusesColumns } from '../../../utils/helpers';
import { fetchSmsStatuses } from '../../../store/actions/creators/smsActions';
import { smsListSelector, smsStatusesFetchStatusSelector } from '../../../store/selectors/smsSelector';

const Statuses = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const smsList = useSelector(smsListSelector);
  const smsStatusesFetchStatus = useSelector(smsStatusesFetchStatusSelector);
  
  const [statusList, setStatusList] = useState([]);
  const [tmpSmsList, setTmpSmsList] = useState([]);
  const [filterStatus, setFilterStatus] = useState();

  const columns = useMemo(() => getSmsStatusesColumns(t), [t]);
  const searchColumns = useMemo(() => ['phone', 'smsId', 'statusDt' ,'sendDt'], []);
  
  useEffect(() => {
     dispatch(fetchSmsStatuses());
  }, []);
  
  useEffect(() => {
    if (smsList && smsList.length) {
      setStatusList(_.uniq(smsList.map(({ status }) => status)));

      const tmpList = _.filter(
        smsList,
        (sms) => !filterStatus || sms.status === filterStatus
      ).map((sms) => ({
        ...sms,
        statusDt: sms.statusDt
          ? moment(sms.statusDt).format('DD/MM/YYYY HH:mm')
          : '',
          sendDt: sms.sendDt
          ? moment(sms.sendDt).format('DD/MM/YYYY HH:mm')
          : '',
      }));

      setTmpSmsList([...tmpList]);
    }
  }, [smsList,filterStatus]);
  
  const handleChangeFilter = (event) => {
    const value = event.target.value;
    setFilterStatus(value);
  };

  const actions = (item, action) => ({
    view: true,
  }[action]);

  const itemsContent = useMemo(() => (
    <ItemsList
      items={tmpSmsList}
      columns={columns}
      searchColumns={searchColumns}
      actions={actions}
      defaultSorting={'sendDt'}
      viewUrl="/member/statuses"
    />
  ), [tmpSmsList, columns, searchColumns]);

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-body">
          <div className="d-flex justify-content-between mb-3">
            <h4 className="card-title">
              {t('MEMBER.SMS_STATUSES.SMS_STATUSES')}
            </h4>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <Form.Label className="pt-2 mr-3">
                {t('MEMBER.SMS_STATUSES.FILTER_BY_STATUS')}
              </Form.Label>
              <Form.Select
                className="form-control w-15 d-inline-flex form-control-sm"
                name="filterStatus"
                value={filterStatus}
                onChange={handleChangeFilter}
              >
                <option value="">All statuses</option>
                {statusList.map((status, id) => (
                  <option key={id} value={status}>
                    {status}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
          {smsStatusesFetchStatus === STARTED ? (
            <Loading/>
          ) : smsStatusesFetchStatus ? (
            itemsContent
          ) : (
            t('MEMBER.SMS_STATUSES.CAN\'T_FETCH_SMS_STATUSES')
          )}
        </div>
      </div>
    </div>
  );
};

export default Statuses;
