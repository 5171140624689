import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Modal, Spinner } from 'react-bootstrap';
import { STARTED } from '../../../../utils/helpers/store-helpers';
import { clientCreateStatusSelector, clientUpdateStatusSelector } from '../../../../store/selectors/clientSelector';

const ModalFooter = ({handleCloseModal, handleSubmitButtonClick, btnClassName, btnValue}) => {
  const [t] = useTranslation();
  
  const clientUpdateStatus = useSelector(clientUpdateStatusSelector);
  const clientCreateStatus = useSelector(clientCreateStatusSelector);
  
  return (
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCloseModal}>
        {t('COMMON.CLOSE')}
      </Button>
      <Button
        variant="primary"
        className="btn-icon-text"
        type="submit"
        onClick={handleSubmitButtonClick}
        disabled={clientCreateStatus === STARTED || clientUpdateStatus === STARTED ? true : false}
      >
        {clientCreateStatus === STARTED || clientUpdateStatus === STARTED ? (
          <Spinner
            className="mr-2"
            animation="border"
            variant="secondary"
            size="sm"
          />
        ) : (
          <i className={`${btnClassName} btn-icon-prepend`}></i>
        )}
        {btnValue}
      </Button>
    </Modal.Footer>
  )
}

export default ModalFooter;