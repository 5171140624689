import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ItemsList from '../../ItemsList';
import { getTemplateList } from '../../../utils/helpers';

const TemplatesList = ({ templates, handleShowModal, removeTemplate }) => {
  const [t] = useTranslation();
  
  const columns = useMemo(() => getTemplateList(t), []);
  
  const searchColumns = ['name', 'body'];
  const actions = (item, action) =>
    ({
      edit: true,
      delete: true,
    }[action]);

  return (
    <ItemsList
      items={templates}
      columns={columns}
      searchColumns={searchColumns}
      actions={actions}
      handleShowModal={handleShowModal}
      removeItem={removeTemplate}
    />
  );
};

export default TemplatesList;
