import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const RegistrationSuccess = () => {
  const [t] = useTranslation();
  
  return (
    <>
      <h5 className="font-weight-light text-success">
        {t('REGISTRATION.YOU_HAVE_SUCCESSFULLY_REGISTERED')}
      </h5>
      <Link to="/login">{t('COMMON.LOGIN')}</Link>
    </>
  )
}

export default RegistrationSuccess;