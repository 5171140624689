import {
  FETCH_CAMPAIGNS,
  FETCH_CAMPAIGN,
  CREATE_CAMPAIGN,
  UPDATE_CAMPAIGN,
  REMOVE_CAMPAIGN,
} from '../actions/types/campaignTypes';
import { getError } from '../../utils/common/helper';
import { FAILED, getFailed, getStarted, getSucceeded, STARTED, SUCCEEDED } from '../../utils/helpers/store-helpers';

// Initial state
const initialState = {
  errors: null,
  campaigns: [],
  campaign: {},
  campaignsFetchStatus: '',
  campaignFetchStatus: '',
  campaignCreateStatus: '',
  campaignUpdateStatus: '',
  campaignRemoveStatus: '',
};

// Campaign reducer
const events = (state = initialState, { type, payload }) => {
  switch (type) {
    case getStarted(FETCH_CAMPAIGNS):
      return { ...state, campaignsFetchStatus: STARTED };
    case getFailed(FETCH_CAMPAIGNS):
      return { ...state, campaignsFetchStatus: FAILED };
    case getSucceeded(FETCH_CAMPAIGNS):
      return { ...state, campaigns: payload, campaignsFetchStatus: SUCCEEDED };

    case getStarted(FETCH_CAMPAIGN):
        return { ...state, campaignFetchStatus: STARTED };
      case getFailed(FETCH_CAMPAIGN):
        return { ...state, campaign: {}, campaignFetchStatus: FAILED, errors: getError(payload) };
      case getSucceeded(FETCH_CAMPAIGN):
        return { ...state, campaign: payload, campaignFetchStatus: SUCCEEDED };

    case getStarted(CREATE_CAMPAIGN):
      return { ...state, campaignCreateStatus: STARTED, errors: null };
    case getFailed(CREATE_CAMPAIGN):
      return { ...state, campaignCreateStatus: FAILED, errors: getError(payload) };
    case getSucceeded(CREATE_CAMPAIGN):
      return {
        ...state,
        campaigns: state.campaigns.concat([payload]),
        campaignCreateStatus: SUCCEEDED,
        errors: null
      };

    case getStarted(UPDATE_CAMPAIGN):
      return { ...state, campaignUpdateStatus: STARTED, errors: null };
    case getFailed(UPDATE_CAMPAIGN):
      return { ...state, campaignUpdateStatus: FAILED, errors: getError(payload) };
    case getSucceeded(UPDATE_CAMPAIGN):
      return {
        ...state,
        campaigns: state.campaigns.map((campaign) =>
          campaign.id === payload.id ? payload : campaign
        ),
        campaignUpdateStatus: SUCCEEDED,
        errors: null,
      };

    case getStarted(REMOVE_CAMPAIGN):
      return { ...state, campaignRemoveStatus: STARTED, errors: null };
    case getFailed(REMOVE_CAMPAIGN):
      return {
        ...state,
        campaignRemoveStatus: FAILED,
        errors: getError(payload),
      };
    case getSucceeded(REMOVE_CAMPAIGN):
      return {
        ...state,
        campaigns: state.campaigns.map((campaign) =>
          campaign.id === payload.data.id ? payload.data : campaign
        ),
        campaignRemoveStatus: SUCCEEDED,
        errors: null,
      };
    default:
      return state;
  }
};

export default events;
