import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import { Button } from 'react-bootstrap';
import {
  groupsSelector,
  groupErrorsSelector,
  groupsFetchStatusSelector,
} from '../../../store/selectors/groupSelector';
import Loading from '../../../components/Shared/Loading';
import GroupsList from '../../../components/Member/GroupsList';
import { STARTED } from '../../../utils/helpers/store-helpers';
import { errorMessageToast, getError } from '../../../utils/common/helper';
import NewGroupForm from '../../../components/Member/NewGroupForm';
import { createGroup, fetchGroups, removeGroup, updateGroup } from '../../../store/actions/creators/groupActions';
import toast from 'react-hot-toast';
import {successMessageToast} from '../../../utils/common/helper'

const Groups = () => {
  const [t] = useTranslation();
  
  const dispatch = useDispatch();
  
  const groups = useSelector(groupsSelector);
  const errors = useSelector(groupErrorsSelector);
  const groupsFetchStatus = useSelector(groupsFetchStatusSelector);
  
  const [showModal, setShowModal] = useState(false);
  const [group, setGroup] = useState(null);
  const [showError, setShowError] = useState();

  useEffect(() => {
    dispatch(fetchGroups());
  }, []);

  const handleSubmit = (data) => {
     try {
      if (group?.id) 
        dispatch(updateGroup(group.id, _.omit(data, ['id', 'countMembers','contacts' ,'createdAt'])));
      else
        dispatch(createGroup(data)); 
        if (!data.name) {
          return false;
        } 
        if(data.name!==''){
        successMessageToast("Client details successfully saved.")
        }
      handleCloseModal();
    } catch (error) {
      let err = getError(error);
      typeof err == 'string' && errorMessageToast(err);
    }
   };
      useEffect(() => {
        setShowError(errors);
      }, [errors]);

  const handleCloseModal = () => {
    setShowModal(false);
    setGroup(null);
    setShowError('')
  };

  const handleShowModal = (groupId) => {
    setShowModal(true);
    setGroup(groupId ? groups.find((group) => group.id === groupId) : null)
  }
  
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-body">
          <div className="d-flex justify-content-between mb-4">
            <h4 className="card-title">{t('MEMBER.GROUPS.GROUPS')}</h4>
            <Button
              variant="primary"
              size="sm"
              className="btn-icon-text"
              onClick={handleShowModal}
            >
              {t('MEMBER.GROUPS.NEW_GROUP')}
            </Button>
            {showModal && (
              <NewGroupForm
                handleSubmit={handleSubmit}
                handleCloseModal={handleCloseModal}
                group={group}
                errors={showError}
              />
            )}
          </div>
          {groupsFetchStatus === STARTED ? (
            <Loading/>
          ) : groupsFetchStatus ? (
            <GroupsList
              groups={groups}
              handleShowModal={handleShowModal}
              removeGroup={(groupId) => dispatch(removeGroup(groupId))}
            />
          ) : (
            t('MEMBER.GROUPS.CAN\'T_FETCH_GROUPS')
          )}
        </div>
      </div>
    </div>
  );
};

export default Groups;
