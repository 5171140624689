import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';

const middlewares = [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initializeStore = () => {
  return process.env.NODE_ENV === 'development'
    ? createStore(
      reducers,
      composeEnhancers(applyMiddleware(...middlewares))
    ) : createStore(
      reducers,
      applyMiddleware(...middlewares)
    );
};

const index = initializeStore();

export default index;
