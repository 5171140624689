import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import bootstrap from 'bootstrap';

// import 'bootstrap/dist/css/bootstrap.min.css';
import 'puse-icons-feather/feather.css';
import 'ti-icons/css/themify-icons.css';
import './assets/feather-icons-web/feather.css';
import './assets/skydash/css/vertical-layout-light/style.css';
import './assets/styles/style.css';

import './i18n';
import index from './store';
import App from './App';
import './index.css';
import { Toaster } from "react-hot-toast";

ReactDOM.render(
  <Provider store={index}>
    <Suspense fallback={<div>Loading...</div>}>
      <App/>
      <Toaster />
    </Suspense>
  </Provider>,
  document.getElementById('root')
);
