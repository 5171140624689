import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Form, Button, Spinner } from "react-bootstrap";

import FormRow from "../../../Shared/Form/FormRow";
import ClientTable from "./ClientTable";

const UpdateProfileForm = ({
  errors,
  inputs,
  setInputs,
  handleChange,
  handleSubmitButtonClick,
  client,
  storageUser,
}) => {
  const [t] = useTranslation();

  const rows = [
    [
      { title: t("COMMON.FIRST_NAME"), name: "firstName", required: true },
      { title: t("COMMON.LAST_NAME"), name: "lastName", required: true },
    ],
    [
      {
        title: t("ADMIN.CLIENTS.PHONE"),
        type: "tel",
        name: "phone",
        placeholder: t("ADMIN.CLIENTS.EXAMPLE") + " +33111222333",
        required: true,
      },
      {
        title: t("ADMIN.CLIENTS.EMAIL"),
        type: "email",
        name: "email",
        required: true,
      },
    ],
    [
      {
        title: t("ADMIN.CLIENTS.LOGIN"),
        name: "login",
        required: true,
        disabled: inputs.role != "SuperAdmin" ? true : false,
      },
    ],
  ];

  const roleList = useMemo(
    () => [
      { value: "SuperAdmin", label: "SuperAdmin" },
      { value: "Admin", label: "Admin" },
      { value: "Client", label: "Client" },
      { value: "Marketing", label: "Marketing" },
      { value: "Developer", label: "Developer" },
      { value: "Webmaster", label: "Webmaster" },
    ],
    []
  );

  const handleChangeSelect = ({ value }) => {
    setInputs({ ...inputs, role: value });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="card mb-3">
            <div className="card-body">
              {rows.map((row, idx) => (
                <FormRow
                  formColumn={12}
                  key={idx}
                  id={idx}
                  row={row}
                  inputs={inputs}
                  handleChange={handleChange}
                  errors={errors}
                />
              ))}
              <Form.Group className="form-group mb-3">
                <Form.Label className="required">
                  {t("ADMIN.CLIENTS.ROLE")}
                </Form.Label>
                <Select
                  options={roleList}
                  name="role"
                  value={{ label: inputs["role"] }}
                  onChange={handleChangeSelect}
                  isDisabled={inputs.role != "SuperAdmin" ? true : false}
                ></Select>
              </Form.Group>
              <Button
                variant="primary"
                className="btn-icon-text"
                type="submit"
                onClick={handleSubmitButtonClick}
              >
                {1 === "1" ? (
                  <Spinner
                    className="mr-2"
                    animation="border"
                    variant="secondary"
                    size="sm"
                  />
                ) : (
                  <i className={`feather-save btn-icon-prepend`}></i>
                )}
                {t("ADMIN.CLIENTS.SAVE")}
              </Button>
            </div>
          </div>
        </div>
        {(storageUser?.role === "Client" ||storageUser?.role === "Developer")&& (
          <div className="col-sm-6" style={{ height: '635px'}}>
            <div className="card mb-3" style={{height:'100%'}}>
              <div className="card-body">
                <ClientTable client={client} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UpdateProfileForm;
