import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';

import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { STARTED } from '../../../utils/helpers/store-helpers';
import { templateCreateStatusSelector, templateUpdateStatusSelector } from '../../../store/selectors/templateSelector';
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import Icon from "@mdi/react";
import { mdiEmoticon } from "@mdi/js";
import { isCmtelecomProvider } from "../../../utils/helpers";

const NewTemplateForm = ({
  errors,
  template,
  showModal,
  handleSubmit,
  initializeForm,
  formInitialized,
  handleCloseModal,
}) => {
  const [t] = useTranslation();
  const textareaRef = useRef();
  const initialInputs = useMemo(() => ({
    name: '',
    body: '',
    status: 'Active'
  }), []);
  
  const createStatus = useSelector(templateCreateStatusSelector);
  const updateStatus = useSelector(templateUpdateStatusSelector);

  const [inputs, setInputs] = useState(initialInputs);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [messagePosition,setMessagePosition] = useState(false);
  const isEmojiAllowed = useMemo(()=> isCmtelecomProvider(), []);

  useEffect(() => {
    if (formInitialized) return;

    if (_.isEmpty(template)) setInputs(initialInputs);
    else {
      setInputs({
        ...initialInputs,
        ...template,
      });
    }
    initializeForm();
  }, [formInitialized]);

  const handleChange = ({ target: { name, value } }, tempMessagePosition = null) => {
    if(tempMessagePosition == null){
      setMessagePosition(false)
    }
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit(inputs);
  };

  const addEmoji = (e) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    let cursorPosition = messagePosition || textareaRef.current.selectionEnd;
    setMessagePosition(cursorPosition + emoji.length)
    let textBeforeCursorPosition = inputs.body.substring(0, cursorPosition)
    let textAfterCursorPosition = inputs.body.substring(cursorPosition, inputs.body.length)
    let value = textBeforeCursorPosition + emoji + textAfterCursorPosition
    handleChange({ target: { name: 'body', value: value } }, true);
    setShowEmojis(!showEmojis)
  };

  useEffect(() => {
    messagePosition && textareaRef.current.setSelectionRange(messagePosition, messagePosition);
  },[inputs.body]);

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton={false}>
        <Modal.Title>
          {inputs.id
            ? t('MEMBER.TEMPLATES.EDIT_TEMPLATE')
            : t('MEMBER.TEMPLATES.INSERT_TEMPLATE')}
        </Modal.Title>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={handleCloseModal}
        >
          <span aria-hidden="true">x</span>
        </button>
      </Modal.Header>
      <Form
        className={'form-sample' + (submitClicked ? ' was-validated' : '')}
        onSubmit={onSubmit}
      >
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <Form.Group className="form-group mb-3">
                <Form.Label className="required">
                  {t('COMMON.NAME')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  isInvalid={Boolean(errors?.name)}
                  placeholder={t('MEMBER.TEMPLATES.TEMPLATE_NAME')}
                  value={inputs.name}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.name && t('COMMON.NAME') + ' ' + errors?.name}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Form.Group className="form-group mb-3">
                <Form.Label className="required">
                  {t('MEMBER.TEMPLATES.TEMPLATE')}
                </Form.Label>
                {isEmojiAllowed && <>
                  <Button
                    className="mx-1 px-2 py-1 mb-1"
                    onClick={() => setShowEmojis(!showEmojis)}
                    size="sm"
                  >
                    <Icon path={mdiEmoticon} size={0.7} color="white" /> Emoji
                  </Button>
                  <div style={{ zIndex: '2', position: 'absolute', height: '100px' }} >
                    {showEmojis && (
                      <Picker
                        data={data}
                        onEmojiSelect={addEmoji}
                        onClickOutside={() => (showEmojis ? setShowEmojis(false) : "")}
                        emojiButtonSize={28}
                        emojiSize={22}
                        maxFrequentRows={1}
                        perLine={20}
                        previewPosition='none'
                        skinTonePosition='search'
                      />
                    )}
                  </div>
                 </>
                } 
                <Form.Control
                  ref={textareaRef}
                  as="textarea"
                  rows={4}
                  name="body"
                  placeholder={t('COMMON.ENTER...')}
                  size="sm"
                  onChange={handleChange}
                  value={inputs.body}
                  isInvalid={Boolean(errors?.body)}
                  required
                  onMouseUp={(e)=> setMessagePosition(e.target.selectionEnd)}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.body &&
                    t('MEMBER.TEMPLATES.TEMPLATE') + ' ' + errors?.body}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Form.Group className="form-group row mb-0">
                <Form.Label className="col-sm-2 col-form-label">
                  {t('COMMON.STATUS')}
                </Form.Label>
                <div className="col-sm-3">
                  <div className="form-check">
                    <Form.Label className="form-check-label">
                      <Form.Control
                        type="radio"
                        className="form-check-input"
                        name="status"
                        defaultValue="Active"
                        checked={inputs.status === 'Active'}
                        onChange={handleChange}
                      />
                      {t('COMMON.ACTIVE')}
                      <i className="input-helper"></i>
                    </Form.Label>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-check">
                    <Form.Label className="form-check-label">
                      <Form.Control
                        type="radio"
                        className="form-check-input"
                        name="status"
                        defaultValue="Inactive"
                        checked={inputs.status === 'Inactive'}
                        onChange={handleChange}
                      />
                      {t('COMMON.INACTIVE')}
                      <i className="input-helper"></i>
                    </Form.Label>
                  </div>
                </div>
              </Form.Group>
            </div>
          </div>

          {Boolean(errors?.data) && (
            <div className="row">
              <div className="col-md-12">
                <div className="invalid-feedback d-sm-inline-block">
                  {errors?.data}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t('COMMON.CLOSE')}
          </Button>
          <Button
            variant="primary"
            className="btn-icon-text"
            type="submit"
            onClick={() => setSubmitClicked(true)}
          >
            {createStatus === STARTED || updateStatus === STARTED ? (
              <Spinner className="mr-2" animation="border" variant="secondary" size="sm"/>
            ) : (
              <i className={(inputs.id ? 'feather-save' : 'feather-plus') + ' btn-icon-prepend'}></i>
            )}
            {inputs.id ? t('COMMON.SAVE') : t('COMMON.INSERT')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NewTemplateForm;
