/**
 * Service for Countries
 */
import useApi from '../utils/api';

class CountryService {
  #prefix = '/country';
  
  fetchAllCountries = () => {
    return useApi.get(this.#prefix);
  };

  fetchCountry = (id) => {
    return useApi.get(`${this.#prefix}/${id}`);
  };

  createCountry= (data) => {
    return useApi.post(this.#prefix, data);
  };

  updateCountry = (id, data) => {
    return useApi.put(`${this.#prefix}/${id}`, data);
  };

  removeCountry = (id) => {
    return useApi.delete(`${this.#prefix}/${id}`);
  };
}

export default new CountryService();