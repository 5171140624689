/**
 * Service for Sms Templates
 */
import useApi from '../utils/api';

class TemplatesService {
  #prefix = '/templates';
  
  fetchTemplates = () => {
    return useApi.get(this.#prefix);
  };

  createTemplate = (data) => {
    return useApi.post(this.#prefix, data);
  };

  updateTemplate = (id, data) => {
    return useApi.put(`${this.#prefix}/${id}`, data);
  };

  removeTemplate = (id) => {
    return useApi.delete(`${this.#prefix}/${id}`);
  };
}

export default new TemplatesService();
