import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import RadioFields from './RadioFields';
import GeneralForm from './GeneralForm';
import CountryFields from './CountryFields';
import { Form, Modal } from 'react-bootstrap';
import { clientErrorsSelector } from '../../../../store/selectors/clientSelector';
import { getPrepaidRadioData } from '../../../../utils/helpers';
import ProviderSelect from './ProviderSelect';
import MonthlyMinPrice from './GeneralForm/MonthlyMinPrice';
import PricePerSms from './GeneralForm/PricePerSms';

const ModalBody = ({ inputs, setInputs, initialState, client, submitErrors, providers }) => {
  const [t] = useTranslation();
  
  const [errors, setErrors] = useState({});
 
  useEffect(() => {
    setErrors(submitErrors)
  },[submitErrors])

  const handleChange = ({ target: { name, value } }) => {
    setErrors('')
    if (name.includes('.')) {
      const [fieldName, countryCode, index, subFieldName] = name.split('.');

      if (fieldName === 'credits') {
        setInputs({
          ...inputs,
          credits: {
            ...inputs.credits,
            [countryCode]: parseInt(value) || '',
          },
        });
      } else if (fieldName === 'smsPricesPerCountry') {
        let ranges = inputs.smsPricesPerCountry[countryCode];

        if (subFieldName === 'add') {
          ranges = [
            ...ranges,
            {
              min: parseInt(ranges[ranges.length - 1].max) + 1,
              max: parseInt(ranges[ranges.length - 1].max) + 1,
              price: 0,
            },
          ];
        } else if (subFieldName === 'del') {
          ranges = ranges.filter((range, idx) => idx != index);
        } else {
          ranges = ranges.map((range, idx) => {
            if (idx == index) {
              return {
                ...range,
                [subFieldName]:
                  subFieldName === 'price'
                    ? parseFloat(value) || ''
                    : parseInt(value) || '',
              };
            }
            return range;
          });
        }
        setInputs({
          ...inputs,
          smsPricesPerCountry: {
            ...inputs.smsPricesPerCountry,
            [countryCode]: ranges,
          },
        });
      }
    }else {
      if (value === 'true') {
        value = true;
      }
      if (value === 'false') {
        value = false;
      }
      setInputs({
        ...inputs,
        [name]: value,
      });
    }
  };

  const radioData = [
    getPrepaidRadioData(t, inputs.prepaid)
  ];

  const languageList = useMemo(() => [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'French' },
  ], []);

  const providerList = useMemo(() => {
    return providers && providers.map(p => {
      return {value: p.providerName, label: p.providerName}
    })
  }, [providers]);
  
  const providerHandleChange=(op)=>{
    setErrors('')
    setInputs({
      ...inputs,
      provider: op.value
    })
  }

  const languageHandleChange=(op)=>{
    setErrors('')
    setInputs({
      ...inputs,
      language: op.value
    })
  }
  
  return (
    <Modal.Body>
      <GeneralForm
        errors={errors}
        inputs={inputs}
        setInputs={setInputs}
        handleChange={handleChange}
      />
      <RadioFields handleChange={handleChange} radioData={radioData} className="col-md-6"/>
      {/* {inputs.prepaid == 'No' && <MonthlyMinPrice inputs={inputs} handleChange={handleChange} errors={errors} /> }
      {inputs.prepaid == 'Yes' && <PricePerSms inputs={inputs} handleChange={handleChange} errors={errors} /> } */}
      <PricePerSms inputs={inputs} handleChange={handleChange} errors={errors} />
      <CountryFields
        setInputs={setInputs}
        handleChange={handleChange}
        inputs={inputs}
        initialState={initialState}
        errors={errors}
        client={client}
      />
       {errors.smsPricesPerCountry && (
        <p className="text-danger">
          {t('ADMIN.CLIENTS.NUMBER_OF_SMS_PER_COUNTRY') + " " + errors.smsPricesPerCountry}
        </p>
      )}
      <div className="row">
          <div className="col-md-6">
            <Form.Group className="form-group mb-3">
                <Form.Label className="required">
                    {t('ADMIN.CLIENTS.LANGUAGE')}
                </Form.Label>
              <Select
                    options={languageList}
                    name="language"
                    value={
                      languageList.filter(option => 
                          option.value === inputs.language)
                    }
                    onChange={languageHandleChange}
                ></Select>
            </Form.Group>
            {errors.language && (
                <p className="text-danger">
                  {t("ADMIN.CLIENTS.LANGUAGE") + " " + errors.language}
                </p>
      )}
          </div>
          <div className="col-md-3 mt-4">
            <Form.Group className="form-check form-check-flat form-check-primary mb-3">
              <Form.Label className="form-check-label">
                <Form.Control
                  type="checkbox"
                  name="apiAccess"
                  className="form-check-input"
                  checked={inputs.apiAccess}
                  onChange={() => setInputs({
                    ...inputs,
                    apiAccess: !inputs.apiAccess
                  })}
                />
                {t('ADMIN.CLIENTS.API_ACCESS')}
                <i className="input-helper"></i>
              </Form.Label>
            </Form.Group>
          </div>
      </div>
      <div className='row'>
          <div className="col-md-6">
            <Form.Group className="form-group mb-3">
                <Form.Label className="required">
                    {t('ADMIN.COUNTRY.PROVIDER')}
                </Form.Label>
              <Select
                    options={providerList}
                    name="provider"
                    value={
                      providerList.filter(option => 
                          option.value === inputs.provider)
                    }
                    onChange={providerHandleChange}
                ></Select>
            </Form.Group>
            {errors.provider && (
            <p className="text-danger">
              {t("ADMIN.COUNTRY.PROVIDER") + " " + errors.provider}
            </p>
          )}
          </div>
      </div>

      {/* {errors?.data && (
        <div className="row">
          <div className="col-md-6">
            <div className="invalid-feedback d-sm-inline-block">
              {errors?.data}
            </div>
          </div>
        </div>
      )} */}
    </Modal.Body>
  );
};

export default ModalBody;
