import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";

import { Button } from "react-bootstrap";
import {
  clientsSelector,
  clientsFetchStatusSelector,
} from "../../../store/selectors/clientSelector";
import Loading from "../../../components/Shared/Loading";
import ClientForm from "../../../components/Admin/ClientForm";
import ClientList from "../../../components/Admin/ClientsList";
import {
  createClient,
  fetchAllClients,
  updateClient,
} from "../../../store/actions/creators/clientActions";
import { STARTED, SUCCEEDED } from "../../../utils/helpers/store-helpers";
import { getError, successMessageToast } from "../../../utils/common/helper";
import { fetchAllProviders } from "src/store/actions/creators/providerAction";
import { providersSelector } from "src/store/selectors/providerSelector";
import Credit from "src/containers/MemberHome/Credit";
import ClientModalBody from "src/components/Admin/ClientsList/ClientModalBody";
import CreditList from "src/components/Member/CreditList";

const AdminClients = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const clients = useSelector(clientsSelector);
  const clientsFetchStatus = useSelector(clientsFetchStatusSelector);
  const providers = useSelector(providersSelector);

  const [client, setClient] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showCreditModal, setShowCreditModal] = useState(false);
  const [submitErrors, setSubmitErrors] = useState({});

  useEffect(() => {
    dispatch(fetchAllClients());
    dispatch(fetchAllProviders());
  }, []);

  const handleSubmit = async (data) => {
    try {
      if (data.id) await dispatch(updateClient(data.id, _.omit(data, ["id","credits"])));
      else await dispatch(createClient(_.omit(data, ["credits"])));
      successMessageToast("Client details successfully saved.");
    } catch (error) {
      setSubmitErrors(getError(error));
    }
  };

  const handleShowModal = (clientId) => {
    setShowModal(true);
    setClient(
      clientId ? clients?.find((client) => client.id === clientId) : {}
    );
    setSubmitErrors({});
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setClient({});
    setSubmitErrors({});
  };

  const handleShowCreditListModal = (clientId) => {
    setShowCreditModal(true);
    setClient(
      clientId ? clients?.find((client) => client.id === clientId) : {}
    );
    setSubmitErrors({});
  };

  const handleCloseCreditListModal = () => {
    setShowCreditModal(false);
    setClient({});
    setSubmitErrors({});
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-body">
          <div className="d-flex justify-content-between mb-4">
            <h4 className="card-title">{t("ADMIN.CLIENTS.CLIENTS_LIST")}</h4>
            <Button
              variant="primary"
              size="sm"
              className="btn-icon-text"
              onClick={handleShowModal}
            >
              <i className="feather-user-plus btn-icon-prepend"></i>
              {t("ADMIN.CLIENTS.NEW_CLIENT")}
            </Button>
            {showModal && (
              <ClientForm
                handleSubmit={handleSubmit}
                handleCloseModal={handleCloseModal}
                client={client}
                submitErrors={submitErrors}
                providers={providers}
              />
            )}
          </div>
          {showCreditModal && (
            <ClientModalBody
              client={client}
              handleCloseCreditListModal={handleCloseCreditListModal}
            />
          )}
          {clientsFetchStatus === STARTED ? (
            <Loading />
          ) : clientsFetchStatus === SUCCEEDED ? (
            <ClientList
              clients={clients}
              handleShowModal={handleShowModal}
              handleShowCreditListModal={handleShowCreditListModal}
            />
          ) : (
            t("ADMIN.CLIENTS.CAN'T_FETCH_ALL_CLIENTS")
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminClients;
