import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from '../../../Tooltip';
import { Form, ProgressBar } from 'react-bootstrap';
import { MESSAGE_MAX_LENGTH } from '../../../../utils/common/constants';

const SmsCalculationsInfo = ({ message, setMessagesCount }) => {
  const [t] = useTranslation();
  
  const [progress, setProgress] = useState([]);
  
  useEffect(() => {
    const progress = [0, 0, 0, 0];

    let len = message.length;
    const messagesCount =
      Math.floor(len / MESSAGE_MAX_LENGTH) + (len % MESSAGE_MAX_LENGTH ? 1 : 0);
    setMessagesCount(messagesCount);
    for (let i = 0; len > 0; ++i, len -= MESSAGE_MAX_LENGTH) {
      progress[i] =
        len >= MESSAGE_MAX_LENGTH ? 100 : (len / MESSAGE_MAX_LENGTH) * 100;
    }

    setProgress(progress);
  }, [message]);

  return (
    <Form.Group className="form-group mb-3">
      <div className="row">
        {progress.map((el, idx) => (
          <div className="col-sm-2 col-lg-2" key={idx}>
            <Form.Label>{idx + 1} SMS</Form.Label>
            <ProgressBar now={el}/>
          </div>
        ))}
        <div className="col-sm-2 col-lg-1">
          <Tooltip originalTitle={t('MEMBER.SMS.MESSAGE_PROGRESS_TOOL_TIP')}/>
        </div>
      </div>
    </Form.Group>
  );
};

export default SmsCalculationsInfo;