/**
 * Service for Sms Groups
 */
import useApi from '../utils/api';

class GroupsService {
  #prefix = '/groups';
  
  fetchGroups = () => {
    return useApi.get(this.#prefix);
  };

  createGroup = (data) => {
    return useApi.post(this.#prefix, data);
  };

  updateGroup = (id, data) => {
    return useApi.put(`${this.#prefix}/${id}`, data);
  };

  removeGroup = (id) => {
    return useApi.delete(`${this.#prefix}/${id}`);
  };
}

export default new GroupsService();
