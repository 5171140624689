import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";

import Icon from "@mdi/react";
import { mdiEye } from "@mdi/js";
import { Spinner } from "react-bootstrap";
import { STARTED, SUCCEEDED } from "../../utils/helpers/store-helpers";
import FormHeaderLink from "../../components/Shared/Form/FormHeaderLink";
import { MainContainer, FullPageContainer } from "../../components/Container";
import {
    phoneNumberVerificationErrorSelector
  } from "../../store/selectors/authSelector";
import "../../assets/skydash/css/vertical-layout-light/style.css";
import Step4 from "src/components/Register/Steps/Step4";
import { toast } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';

const VerifyNumber = () => {
  const navigate = useNavigate();
  const [success, setSuccess ] = useState(false);
  const [errors, setErrors ] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");

  const phoneNumberVerificationError = useSelector(phoneNumberVerificationErrorSelector);
 
  useEffect(()=> {
    setErrors({'phoneNumberVerificationError': phoneNumberVerificationError});
  },[phoneNumberVerificationError]);

  useEffect(()=>{
    if(success){
        successMessageToast("Phone number verified successfully.");
        navigate("/");
    }
  },[success])

  return (
    <MainContainer>
      <FullPageContainer>
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <FormHeaderLink/>
                <Step4  token={token} setLoginDisabled={setSuccess} errors={errors}/>
              </div>
            </div>
          </div>
        </div>
      </FullPageContainer>
    </MainContainer>
  );
};

export default VerifyNumber;
