import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ResetPasswordSuccess = () => {
  const [t] = useTranslation();
  
  return (
    <>
      <h6 className="font-weight-light text-success">
        {t('RESET_PASSWORD.RESET_PASSWORD_SUCCESS')}
      </h6>
      <Link to="/login">{t('COMMON.PLEASE_SIGN_IN')}</Link>
    </>
  )
}

export default ResetPasswordSuccess;