import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Select from 'react-select';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { STARTED, SUCCEEDED } from '../../../utils/helpers/store-helpers';
import { contactImportStatusSelector } from '../../../store/selectors/contactSelector';
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';

const ImportContactsForm = ({ errors, groups, handleSubmit, handleCloseModal }) => {
  const [t] = useTranslation();
  
  const contactsImportStatus = useSelector(contactImportStatusSelector)
  
  const [contactsList, setContactsList] = useState('');
  const [inputGroups, setGroups] = useState([]);

  const groupsList = useMemo(() => {
    const groupsList = [];

    for (let group of groups) {
      groupsList.push({
        value: group.id,
        label: group.name,
      });
    }

    return groupsList;
  }, [groups]);
  
  useEffect(() => {
    if (contactsImportStatus === SUCCEEDED) handleCloseModal();
  }, [contactsImportStatus]);
  
  const handleSelectFile = ({ target: { files } }) => setContactsList(files[0]);

  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit({
      contactsList,
      groups: inputGroups.map((group) => group.value).join(','),
    });
  };
  
  const handleExport = (data ,type) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet");
    XLSX.writeFile(workbook, `smsreflex_contacts_sample_data.${type}`);
  };

  const header = [{phone:''},{ firstName: ''}, { lastName: '' },{ status: ''},{ f1: ''},{ f2: ''},{ f3: ''},{ f4: ''},{ f5: ''},{ f6: ''},{ f7: ''},{ f8: ''},{ f9: ''}]

  return (
    <Modal show={true} onHide={handleCloseModal}>
      <Modal.Header closeButton={false}>
        <Modal.Title>{t('MEMBER.CONTACTS.IMPORT_CONTACTS')}</Modal.Title>
        <button type="button" className="close" aria-label="Close" onClick={handleCloseModal}>
          <span aria-hidden="true">x</span>
        </button>
      </Modal.Header>
      <Form className="form-sample" onSubmit={onSubmit}>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <Form.Group className="form-group">
                <Form.Label>
                  {t('MEMBER.CONTACTS.IMPORT_TO_GROUP')}
                </Form.Label>
                <Select
                  options={groupsList}
                  name="groups"
                  value={inputGroups}
                  onChange={(value) => setGroups(value)}
                  isMulti
                ></Select>
                {errors?.groups ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {t('MEMBER.SMS.GROUPS_LIST') + ' ' + errors?.groups}
                  </Form.Control.Feedback>
                ) : (
                  ''
                )}
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Form.Group className="form-group mb-0">
                <Form.Label className="required mr-2">
                  {t('COMMON.SELECT_FILE')}
                </Form.Label>
                <input
                  className={
                    'form-control-file d-inline-flex w-75' +
                    (errors?.contactsList ? ' is-invalid' : '')
                  }
                  type="file"
                  name="contactsList"
                  onChange={handleSelectFile}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.contactsList &&
                    t('MEMBER.CONTACTS.CONTATCS_FILE') +
                    ' ' +
                    errors?.contactsList}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Form.Text>{t('MEMBER.CONTACTS.FIELDS_ORDER')}</Form.Text>
            </div>
          </div>

          {Boolean(errors?.data) && (
            <div className="row">
              <div className="col-md-12">
                <div className="invalid-feedback d-sm-inline-block">
                  {errors?.data}
                </div>
              </div>
            </div>
          )}
          <div className="row pt-2 pl-2">
            <Form.Label className="p-2">
              {t("CONTACTS.DOWNLOAD_FILE")}:
            </Form.Label>


            <Link to='#' className="pt-2" onClick={() => handleExport(header, "csv")}>
              CSV Format/
            </Link>
            <Link to='#' className="pt-2" onClick={() => handleExport(header, "xlsx")}>
            Excel Format
            </Link>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t('COMMON.CLOSE')}
          </Button>
          <Button variant="primary" className="btn-icon-text" type="submit">
            {contactsImportStatus === STARTED ? (
              <Spinner className="mr-2" animation="border" variant="secondary" size="sm"/>
            ) : (
              <i className="feather-upload btn-icon-prepend"></i>
            )}
            {t('COMMON.IMPORT')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ImportContactsForm;
