import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import _ from "lodash";

import Spinner from "react-bootstrap/Spinner";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import { sendVerificationCode } from "../../../../store/actions/creators/authActions";
import { errorMessageToast } from 'src/utils/common/helper';

const Step3 = ({ isLoading, setIsLoading, inputs, setInputs, errors, setErrors, phone, setPhone, setting }) => {
  const [t] = useTranslation();
  
  const dispatch = useDispatch();
  
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState()

  useEffect(() => {
    setIsLoading(false);
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault();
    let err = {};
    if (!phone) err.sendVerificationCodeError = "Country code and phone number required.";
   
    if (!_.isEmpty(err)) return setErrors(err);

    if(!isValidPhoneNumber(phone))
          err.sendVerificationCodeError = "Phone number is not valid.";
    
    if (!_.isEmpty(err)) return setErrors(err);

    if (countryCode.includes(parsePhoneNumber(phone).countryCallingCode)) 
          err.sendVerificationCodeError = "Please enter number from allowed country only or select correct country from dropdown.";

    if (!_.isEmpty(err)) return setErrors(err);      

    setIsLoading(true);
    
    setInputs({
      ...inputs,
      phone,
    });
  
    try {
      await dispatch(sendVerificationCode({ phone }));
    } catch (error) {
      errorMessageToast(error?.response?.data?.message)
    }
    setIsLoading(false);
  };
  
  useEffect(()=> {
    if(setting && setting.countryList){
      // let countries = setting.countryList.map((c) => (c.shortName))
      // setCountries(countries);
      let country = setting.countryList.find((c) => c.countryName == inputs.country)
      setCountries([country?.shortName])
      let code = [];
      setting.countryList.map(element => {
        code.concat(element.phoneCodes.split(','))
      });
      setCountryCode(code);
    }
  }, [setting]);
  
  return (
    <>
      <h6 className="font-weight-light">
        {t(
          "REGISTRATION.IN_ORDER_FOR_YOU_FINALIZE_YOUR_REGISTRATION_YOU_NEED_TO_CERTIFY_YOUR_MOBILE_PHONE_NUMBER"
        )}
        <br/>
        {t(
          "REGISTRATION.WE_WILL_SEND_YOU_A_VERIFICATION_CODE_TO_YOUR_MOBILE_INSTANTLY"
        )}
        <br/>
        <br/>
        {t("REGISTRATION.YOUR_PHONE_NUMBER")}
      </h6>
      <form className="pt-3" onSubmit={(e) => handleSubmit(e)}>
        <div className="form-group">
          <label>{t("REGISTRATION.PHONE_NUMBER")}</label>
          { countries.length > 0 && <PhoneInput
            placeholder={t("REGISTRATION.PHONE_NUMBER")}
            value={phone}
            onChange={(phone) => setPhone(phone)}
            countries={countries}
            addInternationalOption={false} 
          /> }
          {errors && !_.isEmpty(errors?.sendVerificationCodeError) ? (
            <div className="invalid-feedback d-sm-inline-block">
              {errors?.sendVerificationCodeError.phone
                ? "Phone " + errors?.sendVerificationCodeError.phone
                : errors?.sendVerificationCodeError}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="mt-3">
          <button
            disabled={isLoading}
            type="submit"
            className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
          >
            {isLoading ? (
              <Spinner
                className="mr-2"
                animation="border"
                variant="secondary"
                size="sm"
              />
            ) : (
              t("REGISTRATION.RECEIVE_THE_CODE")
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default Step3;
