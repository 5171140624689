import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';

import Select from 'react-select';
import { Form } from 'react-bootstrap';
import ActiveCountryTab from './ActiveCountryTab';
import { fetchAllCountries } from 'src/store/actions/creators/countryActions';
import { countriesSelector } from 'src/store/selectors/countrySelector';
import { useDispatch, useSelector } from 'react-redux';

const CountryFields = ({
  inputs,
  errors,
  client,
  setInputs,
  initialState,
  handleChange,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [formInitialized, setFormInitialized] = useState(false);
  const [activeCountryTab, setActiveCountryTab] = useState('');
  const [selectedCountries, setSelectedCountries] = useState([]);

  const countries = useSelector(countriesSelector);

  useEffect(() => {
    dispatch(fetchAllCountries());
  }, []);

  // const countryCodeToName = useMemo(() => ({
  //   FR: 'France',
  //   ES: 'Spain',
  //   DE: 'Germany',
  //   NO: 'Norway',
  //   AM: 'Armenia',
  //   UK: 'United kingdom'
  // }), [countries]);

  const countryCodeToName = useMemo(() => {
    let temp = {};
    countries?.forEach(element => {
      temp[element.shortName] = element.countryName;
    });
    return temp;
  }, [countries]);

  useEffect(() => {
    if (formInitialized) return;

    if(!_.isEmpty(countries)){
      if (_.isEmpty(client)) {
        setInputs(initialState);
        setSelectedCountries([]);
      } else {
        setInputs({
          ...initialState,
          ..._.pick(client, _.keys(initialState)),
          id: client.id,
          pricePerSms: client.pricePerSms ?? 1,
          fromFieldSet: client.fromFieldSet
            ? client.fromFieldSet.split(',').map((from) => ({
              label: from,
              value: from,
            }))
            : '',
        });
        setSelectedCountries(client.credits ? Object.keys(client.credits).map((countryCode) => ({
          value: countryCode,
          label: countryCodeToName[countryCode],
        })) : []);
        setActiveCountryTab(client.credits ? Object.keys(client.credits)[0] : null);
      }

      setFormInitialized(true);
    }
  }, [formInitialized,countries]);

  const countriesList = useMemo(() => {
    return countries && countries.map(c => {
      return {value: c.shortName, label: c.countryName}
    })
  }, [countries]);

  const handleChangeSelect = (newValue) => {
    const countryCodes = newValue.map((item) => item.value);
    let credits = {};
    let smsPricesPerCountry = {};

    if (!countryCodes.includes(activeCountryTab)) setActiveCountryTab(countryCodes[0]);

    for (let countryCode of countryCodes) {
      credits[countryCode] = inputs.credits[countryCode] || 0;
      smsPricesPerCountry[countryCode] = inputs.smsPricesPerCountry[
        countryCode
        ] || [{ min: 1, max: 1, price: 0 }];
    }

    setInputs({ ...inputs, credits, smsPricesPerCountry });
    setSelectedCountries(newValue);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Form.Group className="form-group mb-3">
            <Form.Label className="required">
              {t('ADMIN.CLIENTS.NUMBER_OF_SMS_PER_COUNTRY')}
            </Form.Label>
            <Select
              options={countriesList}
              name="selectedCountries"
              value={selectedCountries}
              onChange={handleChangeSelect}
              isMulti
            ></Select>
          </Form.Group>
        </div>
      </div>
      <ActiveCountryTab 
        activeCountryTab={activeCountryTab}
        selectedCountries={selectedCountries}
        handleChange={handleChange} 
        errors={errors}
        inputs={inputs}
        setActiveCountryTab={setActiveCountryTab}
      />
    </>
  );
};

export default CountryFields;