import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { errorMessageToast, getError, successMessageToast } from '../../../utils/common/helper';

import _ from 'lodash';

import { Button } from 'react-bootstrap';
import { usersSelector, usersFetchStatusSelector } from '../../../store/selectors/clientSelector';
import {
  createUser,
  fetchUsers,
  removeUser, 
  updateUser
} from '../../../store/actions/creators/clientActions';
import Loading from '../../../components/Shared/Loading';
import DirectUserForm from '../../../components/Admin/DirectUserForm';
import DirectUserList from '../../../components/Admin/DirectUserList';
import { STARTED, SUCCEEDED } from '../../../utils/helpers/store-helpers';

const AdminUsers = ({client}) => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const params = useParams();

  const users = useSelector(usersSelector);
  const usersFetchStatus = useSelector(usersFetchStatusSelector);

  const [user, setUser] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [clientId, setClientId] = useState();
  const [subaccountId, setSubaccountId] = useState(null);

  useEffect(() => {
    if (params.clientId) {
      const clientId = parseInt(params.clientId, 10);
      setClientId(clientId);
      const subaccountId = parseInt(params.subaccountId, 10);
      setSubaccountId(subaccountId);
      dispatch(fetchUsers(clientId, subaccountId));
    }
  }, [params]);

  const handleShowModal = (userId) => {
    setShowModal(true);
    let temp = userId ? users.find(
      (u) => u.id === userId
    ) : {};
    setUser(_.omit(temp, ['lastLogin', 'lastIp']));
  };

  const handleSubmit = async (data) => {
    try{
      if (data.id) {
        await dispatch(updateUser(
          clientId,
          subaccountId,
          data.id,
          _.omit(data, ['id'])
        ));
      } else {
        await dispatch(createUser(clientId, subaccountId, data));
      }
      successMessageToast("Details successfully saved.") 
    }catch(error){
        let err = getError(error);
        typeof err == 'string' && errorMessageToast(err);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setUser({});
  };
  
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-body">
          <div className="d-flex justify-content-between mb-4">
            <h4 className="card-title">
              {t('ADMIN.CLIENTS.USERS')}
            </h4>
            <Button variant="primary" size="sm" className="btn-icon-text" 
              onClick={() => handleShowModal()} disabled={!client.appAccess}
            >
              <i className="feather-user-plus btn-icon-prepend"></i>
              {t('ADMIN.CLIENTS.NEW_USER')}
            </Button>
            {usersFetchStatus && showModal && (
              <DirectUserForm
                handleSubmit={handleSubmit}
                handleCloseModal={handleCloseModal}
                user={user}
                client={client}
              />
            )}
          </div>
          {usersFetchStatus === STARTED ? (
            <Loading/>
          ) : usersFetchStatus === SUCCEEDED && users.length > 0 ? (
            <DirectUserList
              users={users}
              handleShowModal={handleShowModal}
              removeUser={(u) => dispatch(removeUser(clientId, subaccountId, u))}
            />
          ) : (
            t('ADMIN.CLIENTS.CAN\'T_FETCH_USERS')
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminUsers;
