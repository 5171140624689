import {
  FETCH_CLIENT,
  CREATE_CLIENT,
  UPDATE_CLIENT,
  FETCH_ALL_CLIENTS,
  REMOVE_CLIENT,
  FETCH_SUBACCOUNTS,
  FETCH_SUBACCOUNT,
  CREATE_SUBACCOUNT,
  UPDATE_SUBACCOUNT,
  REMOVE_SUBACCOUNT,
  FETCH_USERS,
  CREATE_USER,
  UPDATE_USER,
  REMOVE_USER,
  FETCH_REGISTERED_USERS,
  UPDATE_REGISTERED_USER,
  REMOVE_REGISTERED_USER
} from "../actions/types/clientTypes";
import { getError } from "../../utils/common/helper";
import {
  FAILED,
  getFailed,
  getStarted,
  getSucceeded,
  STARTED,
  SUCCEEDED,
} from "../../utils/helpers/store-helpers";

// Initial state
const initialState = {
  errors: null,
  client: {},
  clients: [],
  subaccounts: [],
  users: [],
  clientFetchStatus: "",
  clientsFetchStatus: "",
  clientCreateStatus: "",
  clientUpdateStatus: "",
  clientRemoveStatus: "",
  subaccountCreateStatus: "",
  subaccountUpdateStatus: "",
  subaccountRemoveStatus: "",
  subaccountsFetchStatus: "",
  subaccountFetchStatus: "",
  userCreateStatus: "",
  userUpdateStatus: "",
  userRemoveStatus: "",
  usersFetchStatus: "",
  registeredUsers: [],
  registeredUsersUpdateStatus: "",
  registeredUsersFetchStatus: "",
  registeredUsersRemoveStatus: "",
};

// Client reducer
const events = (state = initialState, { type, payload }) => {
  switch (type) {
    case getStarted(FETCH_ALL_CLIENTS):
      return { ...state, clientsFetchStatus: STARTED };
    case getFailed(FETCH_ALL_CLIENTS):
      return { ...state, clientsFetchStatus: FAILED };
    case getSucceeded(FETCH_ALL_CLIENTS):
      return { ...state, clients: payload.data, clientsFetchStatus: SUCCEEDED };

    case getStarted(FETCH_CLIENT):
      return { ...state, clientFetchStatus: STARTED };
    case getFailed(FETCH_CLIENT):
      return {
        ...state,
        client: {},
        clientFetchStatus: FAILED,
        errors: getError(payload),
      };
    case getSucceeded(FETCH_CLIENT):
      return { ...state, client: payload.data, clientFetchStatus: SUCCEEDED };

    case getStarted(CREATE_CLIENT):
      return { ...state, clientCreateStatus: STARTED, errors: null };
    case getFailed(CREATE_CLIENT):
      return {
        ...state,
        clientCreateStatus: FAILED,
        errors: getError(payload),
      };
    case getSucceeded(CREATE_CLIENT):
      return {
        ...state,
        clients: state.clients.concat([payload.data]),
        clientCreateStatus: SUCCEEDED,
        errors: null,
      };

    case getStarted(UPDATE_CLIENT):
      return { ...state, clientUpdateStatus: STARTED, errors: null };
    case getFailed(UPDATE_CLIENT):
      return {
        ...state,
        clientUpdateStatus: FAILED,
        errors: getError(payload),
      };
    case getSucceeded(UPDATE_CLIENT):
      return {
        ...state,
        clients: state.clients.map((client) =>
          client.id === payload.data.id ? payload.data : client
        ),
        clientUpdateStatus: SUCCEEDED,
        errors: null,
      };
    case getStarted(REMOVE_CLIENT):
      return { ...state, clientRemoveStatus: STARTED, errors: null };
    case getFailed(REMOVE_CLIENT):
      return {
        ...state,
        clientRemoveStatus: FAILED,
        errors: getError(payload),
      };
    case getSucceeded(REMOVE_CLIENT): {
      return {
        ...state,
        clients: state.clients.filter(
          (client) => client.id !== payload.data.id
        ),
        clientRemoveStatus: SUCCEEDED,
        errors: null,
      };
    }

    case getStarted(FETCH_SUBACCOUNTS):
      return { ...state, subaccountsFetchStatus: STARTED };
    case getFailed(FETCH_SUBACCOUNTS):
      return { ...state, subaccountsFetchStatus: FAILED };
    case getSucceeded(FETCH_SUBACCOUNTS):
      return {
        ...state,
        subaccounts: payload.data,
        subaccountsFetchStatus: SUCCEEDED,
      };

    case getStarted(FETCH_SUBACCOUNT):
      return { ...state, subaccountFetchStatus: STARTED };
    case getFailed(FETCH_SUBACCOUNT):
      return {
        ...state,
        subaccount: {},
        subaccountFetchStatus: FAILED,
        errors: getError(payload),
      };
    case getSucceeded(FETCH_SUBACCOUNT):
      return {
        ...state,
        subaccount: payload.data,
        subaccountFetchStatus: SUCCEEDED,
      };

    case getStarted(CREATE_SUBACCOUNT):
      return { ...state, subaccountCreateStatus: STARTED, errors: null };
    case getFailed(CREATE_SUBACCOUNT):
      return {
        ...state,
        subaccountCreateStatus: FAILED,
        errors: getError(payload),
      };
    case getSucceeded(CREATE_SUBACCOUNT):
      return {
        ...state,
        subaccounts: state.subaccounts.concat([payload.data]),
        subaccountCreateStatus: SUCCEEDED,
        errors: null,
      };

    case getStarted(UPDATE_SUBACCOUNT):
      return { ...state, subaccountUpdateStatus: STARTED, errors: null };

    case getFailed(UPDATE_SUBACCOUNT):
      return {
        ...state,
        subaccountUpdateStatus: FAILED,
        errors: getError(payload),
      };
    case getSucceeded(UPDATE_SUBACCOUNT):
      return {
        ...state,
        subaccounts: state.subaccounts.map((subaccount) =>
          subaccount.id === payload.data.id ? payload.data : subaccount
        ),
        subaccountUpdateStatus: SUCCEEDED,
        errors: null,
      };

    case getStarted(REMOVE_SUBACCOUNT):
      return { ...state, subaccountRemoveStatus: STARTED, errors: null };
    case getFailed(REMOVE_SUBACCOUNT):
      return {
        ...state,
        subaccountRemoveStatus: FAILED,
        errors: getError(payload),
      };
    case getSucceeded(REMOVE_SUBACCOUNT):
      return {
        ...state,
        subaccounts: state.subaccounts.filter(
          (subaccount) => subaccount.id !== payload.data.id
        ),
        subaccountRemoveStatus: SUCCEEDED,
      };

    case getStarted(FETCH_USERS):
      return { ...state, usersFetchStatus: STARTED };
    case getFailed(FETCH_USERS):
      return { ...state, usersFetchStatus: FAILED };
    case getSucceeded(FETCH_USERS):
      return { ...state, users: payload.data, usersFetchStatus: SUCCEEDED };

    case getStarted(CREATE_USER):
      return { ...state, userCreateStatus: STARTED, errors: null };
    case getFailed(CREATE_USER):
      return { ...state, userCreateStatus: FAILED, errors: getError(payload) };
    case getSucceeded(CREATE_USER):
      return {
        ...state,
        users: state.users.concat([payload.data]),
        userCreateStatus: SUCCEEDED,
        errors: null,
      };

    case getStarted(UPDATE_USER):
      return { ...state, userUpdateStatus: STARTED, errors: null };
    case getFailed(UPDATE_USER):
      return { ...state, userUpdateStatus: FAILED, errors: getError(payload) };
    case getSucceeded(UPDATE_USER):
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === payload.data.id ? payload.data : user
        ),
        userUpdateStatus: SUCCEEDED,
        errors: null,
      };

    case getStarted(REMOVE_USER):
      return { ...state, userRemoveStatus: STARTED, errors: null };
    case getFailed(REMOVE_USER):
      return { ...state, userRemoveStatus: FAILED, errors: getError(payload) };
    case getSucceeded(REMOVE_USER):
      return {
        ...state,
        users: state.users.filter((user) => user.id !== payload.data.id),
        userRemoveStatus: SUCCEEDED,
      };

    case getStarted(FETCH_REGISTERED_USERS):
      return { ...state, registeredUsersFetchStatus: STARTED };
    case getFailed(FETCH_REGISTERED_USERS):
      return { ...state, registeredUsersFetchStatus: FAILED };
    case getSucceeded(FETCH_REGISTERED_USERS):
      return {
        ...state,
        registeredUsers: payload.data,
        registeredUsersFetchStatus: SUCCEEDED,
      };

    case getStarted(UPDATE_REGISTERED_USER):
      return { ...state, registeredUsersUpdateStatus: STARTED, errors: null };
    case getFailed(UPDATE_REGISTERED_USER):
      return {
        ...state,
        registeredUsersUpdateStatus: FAILED,
        errors: getError(payload),
      };
    case getSucceeded(UPDATE_REGISTERED_USER):
      return {
        ...state,
        registeredUsers: state.registeredUsers.map((user) =>
          user.id === payload.data.id ? payload.data : user
        ),
        registeredUsersUpdateStatus: SUCCEEDED,
        errors: null,
      };

    case getStarted(REMOVE_REGISTERED_USER):
      return { ...state, registeredUsersRemoveStatus: STARTED, errors: null };
    case getFailed(REMOVE_REGISTERED_USER):
      return {
        ...state,
        registeredUsersRemoveStatus: FAILED,
        errors: getError(payload),
      };
    case getSucceeded(REMOVE_REGISTERED_USER):
      return {
        ...state,
        registeredUsers: state.registeredUsers.filter(
          (user) => user.id !== payload.data.id
        ),
        registeredUsersRemoveStatus: SUCCEEDED,
      };
    default:
      return state;
  }
};

export default events;
