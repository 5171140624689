import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';

import { Button } from 'react-bootstrap';
import { countriesSelector, countriesFetchStatusSelector } from '../../../store/selectors/countrySelector';
import Loading from '../../../components/Shared/Loading';
import CountryForm from '../../../components/Admin/CountryForm';
import CountryList from '../../../components/Admin/CountryList';
import { createCountry, fetchAllCountries, updateCountry } from '../../../store/actions/creators/countryActions';
import { STARTED, SUCCEEDED } from '../../../utils/helpers/store-helpers';
import toast from 'react-hot-toast';
import {getError, successMessageToast} from '../../../utils/common/helper'

const AdminCountries = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const countriesWithList = useSelector(countriesSelector);
  const countriesFetchStatus = useSelector(countriesFetchStatusSelector);

  const [country, setCountry] = useState();
  const [showModal, setShowModal] = useState(false);
  const [submitErrors, setSubmitErrors] = useState({});
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    dispatch(fetchAllCountries());
  }, []);
  
  const handleSubmit = async (data) => {
    try{
      if (data.id) 
        await dispatch(updateCountry(data.id, _.omit(data, ['id'])));
      else 
        await dispatch(createCountry(data));

        successMessageToast("Details saved successfully.");  
    }catch(error){
      setSubmitErrors(getError(error));
    }
  };

  const handleShowModal = (countryId) => {
    setShowModal(true);
    setCountry(countryId ? countries?.find((c) => c.id === countryId) : {});
    setSubmitErrors({});
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCountry({});
    setSubmitErrors({});
  };
 
  useEffect(()=> {
    if(!_.isEmpty(countriesWithList)){
      let temp = countriesWithList.map((c)=> {
        c.providersWithName = _.map(c.providerList, 'providerName').join(",");
        return c;
      })
      setCountries(temp);
    }
  },[countriesWithList])

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-body">
          <div className="d-flex justify-content-between mb-4">
            <h4 className="card-title">{t('ADMIN.COUNTRY.COUNTRY_LIST')}</h4>
            <Button variant="primary" size="sm" className="btn-icon-text" onClick={handleShowModal}>
              <i className="feather-user-plus btn-icon-prepend"></i>
              {t('ADMIN.COUNTRY.NEW_COUNTRY')}
            </Button>
            {showModal && <CountryForm
                handleSubmit={handleSubmit}
                handleCloseModal={handleCloseModal}
                country={country}
                submitErrors={submitErrors}
              />}
          </div>
          {countriesFetchStatus === STARTED ? (
            <Loading/>
          ) : countriesFetchStatus === SUCCEEDED ? (
            <CountryList countries={countries} handleShowModal={handleShowModal}/>
          ) : t('ADMIN.COUNTRY.CANT_FETCH_ALL_COUNTRIES')}
        </div>
      </div>
    </div>
  );
};

export default AdminCountries;