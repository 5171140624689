import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NavigationLink = ({ name, to, onClick }) => {
  const [t] = useTranslation();

  return <li key={name} className="nav-item">
    <Link className="nav-link" to={to} onClick={onClick}>
      <span>{t(name)}</span>
    </Link>
  </li>
};

export default NavigationLink;
