import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ModalBody from "./ModalBody";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  countryCreateStatusSelector,
  countryUpdateStatusSelector,
} from "../../../store/selectors/countrySelector";
import { useFirstRender } from "../../../utils/hooks";
import { SUCCEEDED, STARTED } from "../../../utils/helpers/store-helpers";
import { countryFormInitialState } from "../../../utils/helpers";

const CountryForm = ({
  country,
  handleSubmit,
  handleCloseModal,
  submitErrors,
}) => {
  const [t] = useTranslation();

  const initialState = useMemo(() => countryFormInitialState, []);

  const countryCreateStatus = useSelector(countryCreateStatusSelector);
  const countryUpdateStatus = useSelector(countryUpdateStatusSelector);

  const firstRender = useFirstRender();

  const [inputs, setInputs] = useState(initialState);
  const [submitClicked, setSubmitClicked] = useState(false);

  useEffect(() => {
    if (!firstRender && countryCreateStatus === SUCCEEDED) handleCloseModal();
  }, [countryCreateStatus]);

  useEffect(() => {
    if (!firstRender && countryUpdateStatus === SUCCEEDED) handleCloseModal();
  }, [countryUpdateStatus]);

  const handleSubmitButtonClick = (event) => setSubmitClicked(true);

  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit({
      ...inputs,
      phoneCodes: inputs.phoneCodes
        ? inputs.phoneCodes.map((item) => item.value).join(",")
        : ""
    });
  };
  
  return (
    <Modal show={true} onHide={handleCloseModal} size="lg">
      <Modal.Header closeButton={false}>
        <Modal.Title>
          {inputs.id
            ? t("ADMIN.COUNTRY.EDIT_COUNTRY")
            : t("ADMIN.COUNTRY.INSERT_COUNTRY")}
        </Modal.Title>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={handleCloseModal}
        >
          <span aria-hidden="true">x</span>
        </button>
      </Modal.Header>
      <Form
        className={"form-sample" + (submitClicked ? " was-validated" : "")}
        onSubmit={onSubmit}
      >
        <ModalBody
          setInputs={setInputs}
          inputs={inputs}
          initialState={initialState}
          country={country}
          submitErrors={submitErrors}
        />
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t("COMMON.CLOSE")}
          </Button>
          <Button
            variant="primary"
            className="btn-icon-text"
            type="submit"
            onClick={handleSubmitButtonClick}
            disabled={
              countryCreateStatus === STARTED || countryUpdateStatus === STARTED
                ? true
                : false
            }
          >
            {countryCreateStatus === STARTED ||
            countryUpdateStatus === STARTED ? (
              <Spinner
                className="mr-2"
                animation="border"
                variant="secondary"
                size="sm"
              />
            ) : (
              <i
                className={`${
                  inputs.id ? "feather-save" : "feather-user-plus"
                } btn-icon-prepend`}
              ></i>
            )}
            {inputs.id ? t("COMMON.SAVE") : t("COMMON.INSERT")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CountryForm;
