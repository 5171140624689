import { CREATE_PAYMENT, FETCH_PRODUCTS } from "../types/PaymentType";
import { createActionThunk } from "redux-thunk-actions";
import PaymentService from "../../../services/payment";

export const createPayment = createActionThunk(
  CREATE_PAYMENT,
  (data) => PaymentService.createPayment(data).then((data) => data),
  false
);

export const fetchProducts = createActionThunk(
  FETCH_PRODUCTS,
  (data) => PaymentService.fetchProducts(data).then((data) => data),
  false
);
