import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';

import { Button } from 'react-bootstrap';
import { registrationSettingSelector, registrationSettingFetchStatusSelector } from '../../../store/selectors/registrationSettingSelector';
import { fetchRegistrationSetting , createRegistrationSetting, updateRegistrationSetting } from '../../../store/actions/creators/registrationSettingActions';
import { STARTED, SUCCEEDED } from '../../../utils/helpers/store-helpers';
import toast from 'react-hot-toast';
import {getError, successMessageToast} from '../../../utils/common/helper';
import RegistrationSettingForm from 'src/components/Admin/RegistrationSettingForm';

const RegistrationSetting = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const setting = useSelector(registrationSettingSelector);
  const settingFetchStatus = useSelector(registrationSettingFetchStatusSelector);

  const [submitErrors, setSubmitErrors] = useState({});

  useEffect(() => {
    dispatch(fetchRegistrationSetting(1));
  }, []);
  
  const handleSubmit = async (data) => {
    try{
      if (data.id) 
        await dispatch(updateRegistrationSetting(data.id, _.omit(data, ['id'])));
      else 
        await dispatch(createRegistrationSetting(data));
        
      successMessageToast("Details saved successfully.")
    }catch(error){
      setSubmitErrors(getError(error));
    }
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-body">
            <h4 className="card-title">{t('ADMIN.COUNTRY.COUNTRY_LIST')}</h4>
            <RegistrationSettingForm
                handleSubmit={handleSubmit}
                setting={setting}
                submitErrors={submitErrors}
            />
        </div>
      </div>
    </div>
  );
};

export default RegistrationSetting;