import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import { Form } from 'react-bootstrap';
import Tooltip from '../../../../Tooltip';
import {
  groupsSelector,
  groupsFetchStatusSelector,
} from '../../../../../store/selectors/groupSelector';
import { FAILED, STARTED } from '../../../../../utils/helpers/store-helpers';
import { fetchGroups } from '../../../../../store/actions/creators/groupActions';

const MessageToGroups = ({ inputGroups, setGroups, errors }) => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const groups = useSelector(groupsSelector);
  const groupsFetchStatus = useSelector(groupsFetchStatusSelector);

  useEffect(() => {
    if (!groupsFetchStatus) dispatch(fetchGroups());
  }, []);

  const groupsList = useMemo(() => groups.map((group) => ({
    value: group.id,
    label: group.name,
  })), [groups]);

  return (
    <Form.Group className="form-group mb-3">
      <Form.Label className="required">
        {t('MEMBER.SMS.CHOOSE_GROUP')}
      </Form.Label>
      <Tooltip
        originalTitle={t('MEMBER.SMS.GROUPS_LIST_TOOL_TIP')}
      />
      <Select
        options={groupsList}
        name="groups"
        value={inputGroups}
        onChange={(value) => setGroups(value)}
        isLoading={groupsFetchStatus === STARTED}
        isMulti
      ></Select>
      {errors?.groups
        ? <Form.Control.Feedback
          type="invalid"
          className="d-block"
        >
          {t('MEMBER.SMS.GROUPS_LIST') + ' ' + errors?.groups}
        </Form.Control.Feedback>
        : ''
      }
    </Form.Group>
  );
};

export default MessageToGroups;