import React from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import {
  Title,
  Legend,
  Tooltip,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  Chart as ChartJS,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { CHARTS_JS_COLORS as colors } from '../../../utils/common/constants';

const Last30DaysTrend = (props) => {
  const [t] = useTranslation();
  const { last30DaysSMS, campaignName } = props;
  const DAYS_COUNT = 30;

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        align: 'start',
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      filler: {
        drawTime: 'beforeDatasetDraw',
        propagate: true,
      },
      tooltip: {
        enabled: true,
      },
      title: {
        display: false,
      },
    },
    scales: {
      xAxis: {
        display: true,
      },
    },
    radius: 1,
    interaction: {
      intersect: false,
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
  };

  const labels = [];
  const today = moment();
  for (let i = 0; i < DAYS_COUNT; ++i) {
    labels.unshift(today.get('date') + '.' + today.format('MM'));
    today.subtract(1, 'days');
  }

  const data = {};
  const startOf30DaysAsDayOfYear = moment()
    .subtract(DAYS_COUNT - 1, 'days')
    .dayOfYear();

  for (let status in last30DaysSMS) {
    data[status] = new Array(DAYS_COUNT).fill(0);
    for (let sms of last30DaysSMS[status]) {
      let idx = moment(sms.sendDt).dayOfYear() - startOf30DaysAsDayOfYear;
      data[status][idx]++;
    }
  }

  const chartData = {
    labels,
    datasets: [
      { label: t('DASHBOARD.SENT'), data: data.sent, fill: 'origin', ...colors.sent },
      { label: t('DASHBOARD.DELIVERED'), data: data.delivered, fill: 'origin', ...colors.delivered },
      { label: t('DASHBOARD.FAILED'), data: data.failed, fill: 'origin', ...colors.failed },
      { label: t('DASHBOARD.BLOCKED'), data: data.blocked, fill: 'origin', ...colors.blocked },
    ],
  };

  return (
    <div className="card h-100">
      <div className="card-body">
        <h4 className="card-title">
          {campaignName == 'all' || !campaignName ? t('DASHBOARD.30_DAYS_TREND_(ALL_CAMPAIGNS)') : t('DASHBOARD.30_DAYS_TREND_') + campaignName}
        </h4>
        <div className="chart-wrapper">
          <Line options={chartOptions} data={chartData}/>
        </div>
      </div>
    </div>
  );
};

export default Last30DaysTrend;
