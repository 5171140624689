import {
    FETCH_ALL_COUNTRIES,
    FETCH_COUNTRY,
    CREATE_COUNTRY,
    UPDATE_COUNTRY,
    REMOVE_COUNTRY
  } from '../actions/types/countryTypes';
  import { getError } from '../../utils/common/helper';
  import { FAILED, getFailed, getStarted, getSucceeded, STARTED, SUCCEEDED } from '../../utils/helpers/store-helpers';
  
  // Initial state
  const initialState = {
    errors: null,
    countries: [],
    country: {},
    countriesFetchStatus: '',
    countryFetchStatus: '',
    countryCreateStatus: '',
    countryUpdateStatus: '',
    countryRemoveStatus: '',
  };
  
  // Country reducer
  const events = (state = initialState, { type, payload }) => {
      switch (type) {
        case getStarted(FETCH_ALL_COUNTRIES):
          return { ...state, countriesFetchStatus: STARTED };
        case getFailed(FETCH_ALL_COUNTRIES):
          return { ...state, countriesFetchStatus: FAILED };
        case getSucceeded(FETCH_ALL_COUNTRIES):
          return { ...state, countries: payload, countriesFetchStatus: SUCCEEDED };

        case getStarted(FETCH_COUNTRY):
          return { ...state, countryFetchStatus: STARTED };
        case getFailed(FETCH_COUNTRY):
          return { ...state, country: {}, countryFetchStatus: FAILED, errors: getError(payload) };
        case getSucceeded(FETCH_COUNTRY):
          return { ...state, country: payload.data, countryFetchStatus: SUCCEEDED };

        case getStarted(CREATE_COUNTRY):
          return { ...state, countryCreateStatus: STARTED, errors: null };
        case getFailed(CREATE_COUNTRY):
          return { ...state, countryCreateStatus: FAILED, errors: getError(payload) };
        case getSucceeded(CREATE_COUNTRY):
          return {
            ...state,
            countries: state.countries.concat([payload.data]),
            countryCreateStatus: SUCCEEDED,
            errors: null,
          };

        case getStarted(UPDATE_COUNTRY):
          return { ...state, countryUpdateStatus: STARTED, errors: null };
        case getFailed(UPDATE_COUNTRY):
          return { ...state, countryUpdateStatus: FAILED, errors: getError(payload) };
        case getSucceeded(UPDATE_COUNTRY):
          return {
            ...state,
            countries: state.countries.map((country) =>
              country.id === payload.data.id ? payload.data : country
            ),
            countryUpdateStatus: SUCCEEDED,
            errors: null,
          };

        case getStarted(REMOVE_COUNTRY):
          return { ...state, countryRemoveStatus: STARTED, errors: null };
        case getFailed(REMOVE_COUNTRY):
          return { ...state, countryRemoveStatus: FAILED, errors: getError(payload) };
        case getSucceeded(REMOVE_COUNTRY):
          return {
            ...state,
            countries: state.countries.filter(
              (country) => country.id !== payload.data.id
            ),
            countryRemoveStatus: SUCCEEDED,
            errors: null,
          };
        default:
          return state;
      }
    }
  ;
  
  export default events;
  