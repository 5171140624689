import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import ErrorBoundary from './ErrorBoundary';
import UserRoutes from './routes/UserRoutes';
import AdminRoutes from './routes/AdminRoutes';
import GeneralRoutes from './routes/GeneralRoutes';
import { userDataSelector, tokenSelector } from './store/selectors/authSelector';

const App = () => {
  const token = useSelector(tokenSelector);
  const userData = useSelector(userDataSelector);
  const isUserSuperAdmin = userData && userData.role === 'SuperAdmin';

  return process.env.NODE_ENV === 'development'
    ? (
      <ErrorBoundary>
        <BrowserRouter>
          {
            token
              ? (
                isUserSuperAdmin
                  ? <AdminRoutes/>
                  : <UserRoutes roles={userData.role}/>
              ) : <GeneralRoutes/>
          }
        </BrowserRouter>
      </ErrorBoundary>
    ) : (
      <BrowserRouter>
        {
          token
            ? (
              isUserSuperAdmin
                ? <AdminRoutes/>
                : <UserRoutes/>
            ) : <GeneralRoutes/>
        }
      </BrowserRouter>
    );
};

export default App;
