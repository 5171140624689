import React from 'react';
import TodaySMS from '../../../../Member/TodaySMS';
import YesterdaySMS from '../../../../Member/YesterdaySMS';
import Last7DaysSMS from '../../../../Member/Last7DaysSMS';
import Last30DaysSMS from '../../../../Member/Last30DaysSMS';

const Delivered = ({
  todaySMS,
  smsTimeRange,
  yesterdaySMS,
  last7DaysSMS,
  last30DaysSMS,
  navigateToReport
}) => {
  return (
    <div className="col-xl-2 col-lg-4 col-md-6">
      <div
        className="p-2 h-100 border-md-right border-lg-right-0 border-xl-right border-bottom border-xl-bottom-0">
        {smsTimeRange === 'today' && (
          <TodaySMS todaySMS={todaySMS.delivered} smsType="delivered" navigateToReport={navigateToReport} smsTimeRange={smsTimeRange}/>
        )}
        {smsTimeRange === 'yesterday' && (
          <YesterdaySMS yesterdaySMS={yesterdaySMS.delivered} smsType="delivered" navigateToReport={navigateToReport} smsTimeRange={smsTimeRange}/>
        )}
        {smsTimeRange === 'last7Days' && (
          <Last7DaysSMS last7DaysSMS={last7DaysSMS.delivered} smsType="delivered" navigateToReport={navigateToReport} smsTimeRange={smsTimeRange}/>
        )}
        {smsTimeRange === 'last30Days' && (
          <Last30DaysSMS last30DaysSMS={last30DaysSMS.delivered} smsType="delivered" navigateToReport={navigateToReport} smsTimeRange={smsTimeRange}/>
        )}
      </div>
    </div>
  )
}

export default Delivered;