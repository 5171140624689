import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CountryTable from './CountryTable';
import CountryEntries from './CountryEntries';
import PageControl from '../../Shared/Pagination/PageControl';

const CountryList = ({ countries, handleShowModal }) => {
  const [t] = useTranslation();

  const [page, setPage] = useState(1);
  const [userId, setUserId] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchValue, setSearchValue] = useState('');

  const searchColumns = useMemo(() => ['id','countryName', 'shortName', 'phoneCodes','purchasePrice', 'minimumPrice'], []);
  
  const filterFunc = (country) => searchColumns.some((col) => typeof(country[col]) == "string" && country[col].includes(searchValue));

  const handleSearchChange = ({ target: { value } }) => {
    setSearchValue(value || '');
    setPage(1);
  };

  const changePerPage = (perPage) => {
    if (countries.length / perPage < page) setPage(1);
    setPerPage(perPage);
  };

  const decPage = () => {
    if (page > 1) setPage(page - 1);
  };
  
  const incPage = () => {
    if (page < countries.length / perPage) setPage(page + 1);
  };

  const handleChangeSort = (newSortBy) => {
    setSortBy(newSortBy);
    setSortOrder((sortBy === newSortBy && sortOrder === 'asc') ? 'desc' : 'asc');
  };

  const filteredItems = countries.filter(filterFunc);

  const handleShowEditModal = (countryRow) => {
    setUserId(countryRow.id);
    handleShowModal(countryRow.id)
  }

  return (
    <>
      <div className="row no-gutters mb-3">
        <CountryEntries changePerPage={changePerPage} perPage={perPage}/>
        <div className="col-sm-12 col-md-6">
          <div className="form-group-sm row">
            <label className="col-sm-5 col-form-label text-right">
              {t('COMMON.SEARCH')}:
            </label>
            <div className="col-sm-7">
              <input
                type="text"
                className="form-control form-control-sm"
                value={searchValue}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
      </div>
      <CountryTable
        page={page}
        sortBy={sortBy}
        perPage={perPage}
        sortOrder={sortOrder}
        filteredItems={filteredItems}
        handleChangeSort={handleChangeSort}
        handleShowEditModal={handleShowEditModal}
      />
      <div className="row">
        <div className="col-sm-12 col-md-5">
          <span>
            {t('COMMON.SHOWING_START_TO_END_OF_TOTAL_ENTRIES', {
              start: (page - 1) * perPage + 1,
              end: Math.min(page * perPage, filteredItems.length),
              total: filteredItems.length,
            })}
          </span>
        </div>
        <PageControl
          page={page}
          perPage={perPage}
          setPage={setPage}
          decPage={decPage}
          incPage={incPage}
          filteredItems={filteredItems}
        />
      </div>
    </>
  );
};

export default CountryList;
