import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

const MultiSelect = ({ inputs, errors, setInputs }) => {
  const [t] = useTranslation();

  const handleChangeFrom = (newValue, actionMeta) => {
    const name = actionMeta.name;
    setInputs({
      ...inputs,
      [name]: newValue,
    });
  };

  // const providerList = useMemo(() => {
  //   return providers && providers.map(p => {
  //     return {value: p.id, label: p.providerName}
  //   })
  // }, [providers]);
 
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Form.Group className="form-group mb-3">
            <Form.Label className="required">
              {t('ADMIN.COUNTRY.PHONE_CODES')}
            </Form.Label>
            <CreatableSelect
              options={[]}
              name="phoneCodes"
              value={inputs.phoneCodes}
              onChange={handleChangeFrom}
              isMulti
            />
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0 }}
              value={inputs.phoneCodes}
              required={true}
              onChange={() => {}}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.phoneCodes
                ? `${t('ADMIN.COUNTRY.PHONE_CODES')} ${errors?.phoneCodes}`
                : ''}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>
      {/* <div className="row">   
        <div className="col-md-12">
          <Form.Group className="form-group mb-3">
            <Form.Label className="required">
              {t('ADMIN.COUNTRY.PROVIDER')}
            </Form.Label>
            <Select
              options={providerList}
              name="providers"
              value={inputs.providers}
              onChange={handleChangeFrom}
              isMulti
            />
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0 }}
              value={inputs.providers}
              required={true}
              onChange={() => {}}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.providers
                ? `${t('ADMIN.COUNTRY.PROVIDER')} ${errors?.providers}`
                : ''}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div> */}
    </>
  );
};

export default MultiSelect;