import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiCheck, mdiClose } from '@mdi/js';

const Hint = ({status}) => {
  const [t] = useTranslation();

  return (
    <div className="d-flex justify-content-between">
      <div>
        <ul className="m-0" style={{listStyleType:'none'}}>
          <li>{status.min_char ? <Icon path={mdiCheck} size={0.9} color="#228B22"/> : 
          (status.min_char == false  ? <Icon path={mdiClose} size={0.9} color="red"/> : '-')} {t('REGISTRATION.8_CHARACTERS_MINIMUM')}</li>
          <li>{status.one_digit ? <Icon path={mdiCheck} size={0.9} color="#228B22"/> : 
           (status.one_digit == false  ? <Icon path={mdiClose} size={0.9} color="red"/> : '-')} {t('REGISTRATION.ONE_DIGIT')}</li>
        </ul>
      </div>
      <div>
        <ul className="m-0" style={{listStyleType:'none'}}>
          <li>{status.one_special_char ? <Icon path={mdiCheck} size={0.9} color="#228B22"/> : 
           (status.one_special_char == false  ? <Icon path={mdiClose} size={0.9} color="red"/> : '-')} {t('REGISTRATION.ONE_SPECIAL_CHARACTER')}</li>
          <li>{status.one_uppercase ? <Icon path={mdiCheck} size={0.9} color="#228B22"/> : 
           (status.one_uppercase == false  ? <Icon path={mdiClose} size={0.9} color="red"/> : '-')} {t('REGISTRATION.ONE_UPPERCASE')}</li>
          <li>{status.one_lowercase ? <Icon path={mdiCheck} size={0.9} color="#228B22"/> : 
           (status.one_lowercase == false  ? <Icon path={mdiClose} size={0.9} color="red"/> : '-')} {t('REGISTRATION.ONE_LOWERCASE')}</li>
        </ul>
      </div>
    </div>
  );
};

export default Hint;