import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import $ from "jquery";
import Select from "react-select";
import IPhone from "../../../IPhone";
import Tooltip from "../../../Tooltip";
import GeneralForm from "./GeneralForm";
import SimpleMessage from "./SimpleMessage";
import FormNavigation from "./FormNavigation";
import MessageToGroups from "./MessageToGroups";
import { SMS_TYPE } from "../../../../shared/types";
import MessageToContacts from "./MessageToContacts";
import { Button, Form, Spinner } from "react-bootstrap";
import SmsCalculationsInfo from "../SmsCalculationsInfo";
import { createSms } from "../../../../store/actions/creators/smsActions";
import { selectedContactsSelector } from "../../../../store/selectors/contactSelector";
import {
  smsCreateStatusSelector,
  smsErrorsSelector,
} from "../../../../store/selectors/smsSelector";
import { STARTED, SUCCEEDED } from "../../../../utils/helpers/store-helpers";
import { errorMessageToast, getError, successMessageToast } from "../../../../utils/common/helper";
import toast from "react-hot-toast";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js/max";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CustomPhoneInput from "src/components/Shared/PhoneInput";
import moment from 'moment';

const CombinedForm = ({ setShowSuccessAlert, client, redirectPhoneNumber }) => {
  const [t] = useTranslation();

  const errors = useSelector(smsErrorsSelector);
  const smsCreateStatus = useSelector(smsCreateStatusSelector);
  const selectedContacts = useSelector(selectedContactsSelector);

  const dispatch = useDispatch();

  const [from, setFrom] = useState("");
  const [phone, setPhone] = useState("");
  const [messagesCount, setMessagesCount] = useState();
  const [message, setMessage] = useState("");
  const [tempMessage, setTempMessage] = useState("");
  const [template, setTemplate] = useState("");
  const [inputGroups, setGroups] = useState([]);
  const [inputContacts, setContacts] = useState([]);
  const [scheduled, setScheduled] = useState(false);
  const [scheduledDt, setScheduledDt] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [channel, setChannel] = useState(10);
  const [shortUrl, setShortUrl] = useState(false);
  const [trackUrl, setTrackUrl] = useState(false);
  const [timeZone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [testPhone, setTestPhone] = useState("");
  const [testPhoneValid, setTestPhoneValid] = useState(false);
  const [phoneValid , setPhoneValid] = useState(false);
  const [countries, setCountries] = useState([]);
  const [fromOptions , setFromOptions] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [activeTab, setActiveTab] = useState("simpleMessage");
 
  useEffect(()=> {
    if(client && client.credits){
      let countries = Object.keys(client.credits).map(e => (_.lowerCase(e)));
      setCountries(countries);
    }
    if(client && client.fromFieldSet){
      let temp = client.fromFieldSet.split(',');
      let options = temp.map(value => ({'label': value, 'value':value}))
      setFromOptions(options);
    }
  }, [client]);
 
  const channelList = useMemo(
    () => [
      { value: 10, label: "Marketing" },
      { value: 10002, label: "Transactional" },
    ],
    []
  );

  useEffect(() => {
    if (selectedContacts) {
      setActiveTab("messageToContacts");
      setContacts(
        selectedContacts.map((contact) => ({
          value: contact.id,
          label: `${contact.phone} (${contact.firstName} ${contact.lastName})`,
        }))
      );
    }
    setPhone(redirectPhoneNumber)
  }, []);

  useEffect(() => {
    if (!shortUrl) {
      setTrackUrl(false);
    }
  }, [shortUrl]);

  useEffect(() => {
    // $(function () {
    //   $('[data-toggle="tooltip"]').tooltip();
    // });

    if (submitClicked && smsCreateStatus === SUCCEEDED) {
      // resetInputs();
      // setShowSuccessAlert(true);
      setSubmitClicked(false);
    }
  }, [smsCreateStatus]);

  const resetInputs = () => {
    setFrom("");
    setPhone("");
    setMessage("");
    setTemplate("");
    setGroups([]);
    setContacts([]);
    setScheduled(false);
    setScheduledDt("");
    setTemplateName("");
    setSaveAsTemplate(false);
    setChannel(10);
    setShortUrl(false);
    setTrackUrl(false);
    setPhoneValid(false);
    setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  };
 
  useEffect(()=>{
    if(channel == 10 && message.length > 0 && !message.includes(' STOP 36xxx')){
      setTempMessage(message + ' STOP 36xxx')
    }else{
      setTempMessage(message.replace(" STOP 36xxx", ""))
    }
  },[channel, message])

  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });

  const onTestClicked = async (event) => {
    event.preventDefault();

    if (!testPhone || testPhone.length == 0) {
      errorMessageToast("Phone number required for testing.")
      return false;
    }
    
    let parseNumber = parsePhoneNumber(testPhone);
 
    if(!isValidPhoneNumber(testPhone) || !countries.includes(_.lowerCase(parseNumber.country)) || parseNumber.getType() != 'MOBILE'){
      errorMessageToast(t('MEMBER.CONTACTS.PHONE_NOT_VALID'));
      return false;
    }

    if (!message || message.length == 0) {
      errorMessageToast("Message is required for testing.")
      return false;
    }

    const data = {
      phone: testPhone,
      from,
      message,
      channel,
      shortUrl,
      trackUrl,
    };

    const pickFields = [
      "phone",
      "from",
      "message",
      "channel",
      "shortUrl",
      "trackUrl",
      "type",
      "test"
    ];
    data.type = channel == 10 ? SMS_TYPE.MARKETING : SMS_TYPE.NOTIFICATION;

    try {
      const result = await dispatch(createSms(_.pick(data, pickFields)));
      if(result.blockedContacts.length > 0 && result.createdSmsStatuses.length == 0){
        errorMessageToast("Number status is Blocked/Inactive/Stop");
      }else{
        successMessageToast("Test SMS sent successfully.")
      }
      
    } catch (error) {
      let err = getError(error);
      typeof err == "string" && errorMessageToast(err);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowSuccessAlert(false);

    if(activeTab === "simpleMessage"){
      let parseNumber = parsePhoneNumber(phone);
 
      if(!isValidPhoneNumber(phone) || !countries.includes(_.lowerCase(parseNumber.country)) || parseNumber.getType() != 'MOBILE'){
        errorMessageToast(t('MEMBER.CONTACTS.PHONE_NOT_VALID'));
        return false;
      }
    }
    
    const data = {
      from,
      phone,
      message,
      template,
      timeZone,
      scheduled,
      scheduledDt: scheduledDt ? moment(scheduledDt).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') : "",
      templateName,
      saveAsTemplate,
      channel,
      shortUrl,
      trackUrl,
      groups: inputGroups,
      contacts: inputContacts,
    };
    data.groups = data.groups.map(({ value }) => value);
    data.contacts = data.contacts.map(({ value }) => value);

    const pickFields = [
      "from",
      "message",
      "saveAsTemplate",
      "templateName",
      "scheduled",
      "scheduledDt",
      "type",
      "channel",
      "shortUrl",
      "trackUrl",
    ];
    const tabNameToField = {
      simpleMessage: "phone",
      messageToContacts: "contacts",
      messageToGroups: "groups",
    };
    // const types = {
    //   simpleMessage: SMS_TYPE.NOTIFICATION,
    //   messageToContacts: SMS_TYPE.MARKETING,
    //   messageToGroups: SMS_TYPE.MARKETING,
    // };
    // data.type = types[activeTab];
    data.type = channel == 10 ? SMS_TYPE.MARKETING : SMS_TYPE.NOTIFICATION;
    pickFields.push(tabNameToField[activeTab]);
    try {
      const result = await dispatch(createSms(_.pick(data, pickFields)));
 
      if(result.blockedContacts.length > 0 && result.createdSmsStatuses.length == 0){
        errorMessageToast("Number status is Blocked/Inactive/Stop");
      }else if(result.blockedContacts.length > 0 && result.createdSmsStatuses.length > 0){
        errorMessageToast("Some number statuses are Blocked/Inactive/Stop and for others sms is sent succesfully.");
        resetInputs();
        setShowSuccessAlert(true);
      }else{
        resetInputs();
        setShowSuccessAlert(true);
      }
     
    } catch (error) {
      let err = getError(error);
      typeof err == "string" && errorMessageToast(err);
    }
  };
  
  return (
    <>
      <FormNavigation activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="row">
        <div className="col-md-8">
          <Form
            className={"form-sample" + (submitClicked ? " was-validated" : "")}
            onSubmit={handleSubmit}
          >
            {activeTab === "simpleMessage" && ( 
              <>
                <Form.Label className="required">
                  {t('MEMBER.SMS.PHONE_NUMBER')}
                </Form.Label>
                <Tooltip
                  originalTitle={t('MEMBER.SMS.PHONE_NUMBER_TOOL_TIP')}
                />
                <CustomPhoneInput phone={phone} countries={countries} setPhone={setPhone} setPhoneValid={setPhoneValid} />
              </>
            )}
            {activeTab === "messageToContacts" && (
              <MessageToContacts
                errors={errors}
                inputContacts={inputContacts}
                setContacts={setContacts}
              />
            )}
            {activeTab === "messageToGroups" && (
              <MessageToGroups
                errors={errors}
                inputGroups={inputGroups}
                setGroups={setGroups}
              />
            )}
            <GeneralForm
              fromOptions={fromOptions}
              from={from}
              errors={errors}
              message={message}
              setFrom={setFrom}
              template={template}
              setMessage={setMessage}
              setTemplate={setTemplate}
              scheduledDt={scheduledDt}
              setScheduled={setScheduled}
              messagesCount={messagesCount}
              setScheduledDt={setScheduledDt}
              channel={channel}
              tempMessage={tempMessage}
            />
            <SmsCalculationsInfo
              setMessagesCount={setMessagesCount}
              message={tempMessage}
            />

            <Form.Group className="form-group mb-0" >
              <div className="row">
                <div className="col-sm-6 d-flex align-items-center">
                  <Form.Group className="form-check form-check-flat form-check-primary">
                    <Form.Label className="form-check-label">
                      <Form.Control
                        type="checkbox"
                        name="saveAsTemplate"
                        className="form-check-input"
                        checked={saveAsTemplate}
                        onChange={() => setSaveAsTemplate(!saveAsTemplate)}
                        isInvalid={errors?.saveAsTemplate}
                      />
                      {t("MEMBER.SMS.SAVE_MESSAGE_AS_TEMPLATE")}
                      <i className="input-helper"></i>
                    </Form.Label>
                  </Form.Group>
                  <Tooltip
                    originalTitle={t("MEMBER.SMS.TEMPLATE_NAME_TOOL_TIP")}
                  />
                </div>
                <div className="col-sm-6">
                  {saveAsTemplate && (
                    <>
                      <Form.Label className="required mb-0">
                        {t("MEMBER.SMS.TEMPLATE_NAME")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="templateName"
                        size="sm"
                        value={templateName}
                        onChange={({ target: { value } }) =>
                          setTemplateName(value)
                        }
                        required
                      ></Form.Control>
                    </>
                  )}
                </div>
              </div>
            </Form.Group>
            <div className="row my-3">
              <div className="col-md-8">
                <h5>Advance Options</h5>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <Form.Label>
                  {t("MEMBER.SMS.CHANNEL")} 
                 <Tooltip
                    originalTitle={t("MEMBER.SMS.CHANNEL_TOOL_TIP")}
                  />
                </Form.Label>
                <Select
                  options={channelList}
                  name="channel"
                  value={channelList.filter((option) => option.value == channel)}
                  onChange={(op) => setChannel(op.value)}
                ></Select>
              </div>
              <div className="col-md-3 mt-4">
                <Form.Group className="form-check form-check-flat form-check-primary">
                  <Form.Label className="form-check-label">
                    <Form.Control
                      type="checkbox"
                      name="shortUrl"
                      className="form-check-input"
                      checked={shortUrl}
                      onChange={() => setShortUrl(!shortUrl)}
                      isInvalid={errors?.shortUrl}
                    />
                    {t("MEMBER.SMS.SHORT_URL")}
                    <i className="input-helper"></i>
                  </Form.Label>
                </Form.Group>
                <Tooltip
                  originalTitle={t("MEMBER.SMS.SHORT_URL_TOOL_TIP")}
                />
              </div>
              <div className="col-sm-3 mt-4">
                {shortUrl && (
                  <>
                    <Form.Group className="form-check form-check-flat form-check-primary">
                      <Form.Label className="form-check-label">
                        <Form.Control
                          type="checkbox"
                          name="trackUrl"
                          className="form-check-input"
                          checked={trackUrl}
                          onChange={() => setTrackUrl(!trackUrl)}
                          isInvalid={errors?.trackUrl}
                        />
                        {t("MEMBER.SMS.TRACK_URL")}
                        <i className="input-helper"></i>
                      </Form.Label>
                    </Form.Group>
                    <Tooltip
                      originalTitle={t("MEMBER.SMS.TRACK_URL_TOOL_TIP")}
                    />
                  </>
                )}
              </div>
            </div>
            {Boolean(errors?.data) && (
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="invalid-feedback d-sm-inline-block">
                    {errors?.data}
                  </div>
                </div>
              </div>
            )}

            <Button
              variant="primary"
              className="btn-icon-text"
              type="submit"
              onClick={(event) => setSubmitClicked(true)}
            >
              {smsCreateStatus === STARTED ? (
                <Spinner
                  className="mr-2"
                  animation="border"
                  variant="secondary"
                  size="sm"
                />
              ) : (
                <i className="feather-mail btn-icon-prepend"></i>
              )}
              {t("MEMBER.SMS.SEND")}
            </Button>
          </Form>
        </div>
        <div className="col-md-4" style={{ width: "251px", height: "561px" }}>
        <div className="row">
          <div className="col-md-12">
            <IPhone from={from ?? ''} message={tempMessage} />
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
               <CustomPhoneInput phone={testPhone} countries={countries} 
                  setPhone={setTestPhone} setPhoneValid={setTestPhoneValid} />
              </div>
            </div>
            <div className="row"> 
              <div className="col-md-5 mt-3 mx-auto">
                  <Button
                    variant="primary"
                    className="btn-icon-text"
                    onClick={onTestClicked}
                  >
                    {t("MEMBER.SMS.TEST_SMS")}
                  </Button>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CombinedForm;
