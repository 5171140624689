import { useEffect, useMemo, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import { Button, Form } from "react-bootstrap";
import Tooltip from "../../../../Tooltip";
import {
  templatesSelector,
  templateFetchStatusSelector,
} from "../../../../../store/selectors/templateSelector";
import { isCmtelecomProvider } from "../../../../../utils/helpers";
import { MESSAGE_MAX_LENGTH } from "../../../../../utils/common/constants";
import { fetchTemplates } from "../../../../../store/actions/creators/templateActions";
import CreatableSelect from "react-select/creatable";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import Icon from "@mdi/react";
import { mdiEmoticon } from "@mdi/js";

const GeneralForm = ({
  fromOptions,
  from,
  errors,
  setFrom,
  message,
  template,
  setMessage,
  scheduledDt,
  setTemplate,
  setScheduled,
  messagesCount,
  setScheduledDt,
  channel,
  tempMessage,
}) => {
  const [t] = useTranslation();
  const textareaRef = useRef();
  const dispatch = useDispatch();

  const templates = useSelector(templatesSelector);
  const templatesFetched = useSelector(templateFetchStatusSelector);
  const [regexError, setRegexError] = useState(false);
  const [localForm, setLocalForm] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [messagePosition,setMessagePosition] = useState(false);
  const isEmojiAllowed = useMemo(()=> isCmtelecomProvider(), []);
  
  useEffect(() => {
    dispatch(fetchTemplates());
  }, []);

  const templatesList = useMemo(
    () =>
      templates
        .map((template) => {
          if (template.status === "Active")
            return {
              value: template.id,
              label: template.name,
            };
          return null;
        })
        .filter((el) => el),
    [templates]
  );

  const onScheduledDtChange = ({ target: { value } }) => {
    const date = moment(value).format("YYYY-MM-DD HH:mm:ss");

    if (moment(date).isBefore(moment())) {
      value = ""; // TODO: add error message here instead of clearing the field
    }

    if (moment(date).day() === 0) {
      value = ""; // TODO: add error message here instead of clearing the field
    }

    const minutes = moment(date).minutes();
    const roundedMinutes = Math.ceil(minutes / 15) * 15;

    value = moment(date).minutes(roundedMinutes).format("YYYY-MM-DD HH:mm:ss");

    setScheduledDt(value);
    setScheduled(true);
  };

  const onMessageChange = ({ target: { value } }, tempMessagePosition = null) => {
    let extra = "";
    if (channel == 10) {
      extra = " STOP 36xxx";
    }
    if (value.length + extra.length > 4 * MESSAGE_MAX_LENGTH) {
      value = value.substring(0, 4 * MESSAGE_MAX_LENGTH);
    }
    if(tempMessagePosition == null){
      setMessagePosition(false)
    }
    setMessage(value);
  };

  const changeTemplate = ({ target: { value } }) => {
    setTemplate(value);
    setMessage(value ? templates.find((elem) => elem.id == value).body : "");
  };

  const handleChangeFrom = (newValue, actionMeta) => {
    let reg = /^(?=.*[a-zA-Z])(?!.*\s)[a-zA-Z\d]{1,11}$/;

    if (!reg.test(newValue.value)) {
      setRegexError(true);
      setFrom(null);
      setLocalForm("");
    } else {
      setRegexError(false);
      setFrom(newValue.value);
      setLocalForm(newValue);
    }
  };

  const addEmoji = (e) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    let cursorPosition = messagePosition || textareaRef.current.selectionEnd;
    setMessagePosition(cursorPosition + emoji.length)
    let textBeforeCursorPosition = message.substring(0, cursorPosition)
    let textAfterCursorPosition = message.substring(cursorPosition, message.length)
    let value = textBeforeCursorPosition + emoji + textAfterCursorPosition
    onMessageChange({ target: { value: value } }, true);
    setShowEmojis(!showEmojis)
  };

  useEffect(() => {
    messagePosition && textareaRef.current.setSelectionRange(messagePosition, messagePosition);
  },[message]);
  
  return (
    <>
      <Form.Group className="form-group mb-3">
        <Form.Label>
          Schedule <i>{t("MEMBER.SMS.OPTIONAL")}</i>
        </Form.Label>
        <Tooltip originalTitle={t("MEMBER.CAMPAIGNS.START_DATE_TOOL_TIP")} />
        <Form.Control
          type="datetime-local"
          name="scheduledDt"
          size="sm"
          value={scheduledDt}
          onChange={onScheduledDtChange}
          min={moment().format("YYYY-MM-DDThh:mm")}
        />
        {errors?.scheduledDt && (
          <Form.Control.Feedback type="invalid" className="d-block">
            {t("MEMBER.CAMPAIGNS.START_DATE")} {errors?.scheduledDt}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="form-group mb-3">
        <Form.Label>
          {t("MEMBER.SMS.FROM")} <i>{t("MEMBER.SMS.OPTIONAL")}</i>
        </Form.Label>
        <Tooltip originalTitle={t("MEMBER.SMS.FROM_TOOL_TIP")} />
        <CreatableSelect
          options={fromOptions}
          name="from"
          value={localForm}
          onChange={handleChangeFrom}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.from ? `${t("MEMBER.SMS.FROM")} ${errors?.from}` : ""}
        </Form.Control.Feedback>
        {regexError && (
          <p className="text-danger">
            {t("ADMIN.CLIENTS.FROM_FIELD_REGEX_ERROR")}
          </p>
        )}
      </Form.Group>

      <Form.Group className="form-group mb-3">
        <Form.Label>
          {t("MEMBER.SMS.USE_TEMPLATE")} <i>{t("MEMBER.SMS.OPTIONAL")}</i>
        </Form.Label>
        <Tooltip originalTitle={t("MEMBER.SMS.TEMPLATE_TOOL_TIP")} />
        <Form.Select
          className="form-control"
          size="sm"
          name="template"
          value={template}
          onChange={changeTemplate}
        >
          <option value="">---</option>
          {templatesList.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group mb-3">
        <Form.Label className="required">{t("COMMON.MESSAGE")}</Form.Label>
        <Tooltip originalTitle={t("MEMBER.SMS.MESSAGE_TOOL_TIP")} />
        {isEmojiAllowed && <>
            <Button
              className="mx-1 px-2 py-1 mb-1"
              onClick={() => setShowEmojis(!showEmojis)}
              size="sm"
            >
              <Icon path={mdiEmoticon} size={0.7} color="white" /> Emoji
            </Button>
            <div style={{ zIndex: '2', position: 'absolute', height: '100px' }} >
              {showEmojis && (
                <Picker
                  data={data}
                  onEmojiSelect={addEmoji}
                  onClickOutside={() => (showEmojis ? setShowEmojis(false) : "")}
                  emojiButtonSize={28}
                  emojiSize={22}
                  maxFrequentRows={1}
                  perLine={20}
                  previewPosition='none'
                  skinTonePosition='search'
                />
              )}
            </div>
          </> 
        }
        <Form.Control
          ref={textareaRef}
          as="textarea"
          rows={4}
          name="message"
          placeholder={t("COMMON.ENTER...")}
          size="sm"
          onChange={onMessageChange}
          value={message}
          isInvalid={errors?.message}
          required
          onMouseUp={(e)=> setMessagePosition(e.target.selectionEnd)}
        />
        <Form.Text>
          {tempMessage.length} / {MESSAGE_MAX_LENGTH * messagesCount}(
          {messagesCount}) - {t("MEMBER.SMS.SMS_COUNT_NOTE")}
          <span className="text-danger">
            {" "}
            {t("MEMBER.SMS.SMS_COUNT_NOTE_RED")}
          </span>
        </Form.Text>
        <Form.Control.Feedback type="invalid">
          {errors?.message
            ? `${t("MEMBER.SMS.MESSAGE_TEXT")} ${errors?.message}`
            : ""}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default GeneralForm;
