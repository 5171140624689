import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ClientsCredentialsTable from './ClientsCredentialsTable';
import ClientsCredentialsEntries from './ClientsCredentialsEntries';
import PageControl from '../../Shared/Pagination/PageControl';

const ClientCredentialsList = ({ clients }) => {
  const [t] = useTranslation();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchValue, setSearchValue] = useState('');

  const searchColumns = useMemo(() => ['login', 'firstName', 'lastName', 'company'], []);

  const filterFunc = (client) => searchColumns.some((col) => client[col].includes(searchValue));

  const handleSearchChange = ({ target: { value } }) => {
    setSearchValue(value || '');
    setPage(1);
  };

  const changePerPage = (perPage) => {
    if (clients.length / perPage < page) setPage(1);
    setPerPage(perPage);
  };

  const decPage = () => {
    if (page > 1) setPage(page - 1);
  };
  
  const incPage = () => {
    if (page < clients.length / perPage) setPage(page + 1);
  };

  const handleChangeSort = (newSortBy) => {
    setSortBy(newSortBy);
    setSortOrder((sortBy === newSortBy && sortOrder === 'asc') ? 'desc' : 'asc');
  };

  const filteredItems = useMemo(() => clients ? clients.filter(filterFunc) : [], [clients]) ;


  return (
    <>
      <div className="row no-gutters mb-3">
        <ClientsCredentialsEntries changePerPage={changePerPage} perPage={perPage}/>
        <div className="col-sm-12 col-md-6">
          <div className="form-group-sm row">
            <label className="col-sm-5 col-form-label text-right">
              {t('ADMIN.CLIENTS.SEARCH')}:
            </label>
            <div className="col-sm-7">
              <input
                type="text"
                className="form-control form-control-sm"
                value={searchValue}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
      </div>
      <ClientsCredentialsTable
        page={page}
        sortBy={sortBy}
        perPage={perPage}
        sortOrder={sortOrder}
        filteredClients={filteredItems}
        handleChangeSort={handleChangeSort}
      />
      <div className="row">
        <div className="col-sm-12 col-md-5">
          <span>
            {t('ADMIN.CLIENTS.SHOWING_START_TO_END_OF_TOTAL_ENTRIES', {
              start: (page - 1) * perPage + 1,
              end: Math.min(page * perPage, filteredItems.length),
              total: filteredItems.length,
            })}
          </span>
        </div>
        <PageControl
          page={page}
          perPage={perPage}
          setPage={setPage}
          decPage={decPage}
          incPage={incPage}
          filteredItems={filteredItems}
        />
      </div>
    </>
  );
};

export default ClientCredentialsList;
