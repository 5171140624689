import { Form } from 'react-bootstrap';

const FormGroup = ({ title, className, type, name, errors, placeholder, value, handleChange, disabled, required }) => {
  return (
    <Form.Group className="form-group mb-3" key={title}>
      <Form.Label className={className || 'required'}>{title}</Form.Label>
      <Form.Control
        type={type || 'text'}
        name={name}
        isInvalid={Boolean(errors && errors[name])}
        placeholder={placeholder || title}
        value={value}
        onChange={handleChange}
        disabled={disabled || false}
        required={required || false}
      />
      <Form.Control.Feedback type="invalid">
        {errors && errors[name] ? `${title} ${errors && errors[name]}` : ''}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default FormGroup;