import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from "react-select";

const TopBar = ({ campaignId, setCampaignId, setCampaignName, campaignName,campaignList}) => {
  const [t] = useTranslation();

  return (
    <div className="row">
      <div className="col-md-12 grid-margin">
        <div className="row">
          <div className="col-12 col-xl-6 mb-4 mb-xl-0">
            <h3 className="font-weight-bold">
              { campaignName == 'all' ? t('DASHBOARD.ALL_THE_CAMPAIGNS') : campaignName}
            </h3>
          </div>
          <div className="col-12 col-xl-6">
            <div className="d-flex justify-content-end">
              <div className="dropdown flex-md-grow-1 flex-xl-grow-0">
              <span className="pt-1 mr-3 font-weight-normal">
                  {t('DASHBOARD.SELECT_CAMPAIGN')}
                </span>
                <Select
                  options={campaignList}
                  name="campaign"
                  value={campaignList.filter((option) => option.value == campaignId)}
                  onChange={(op) => {
                    setCampaignId(op.value);
                    setCampaignName(op.label);
                  }}
                ></Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;