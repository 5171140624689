import _ from 'lodash';
import { createActionThunk } from 'redux-thunk-actions';

import {
  LOGIN,
  LOGOUT,
  REGISTER,
  RESET_LOGIN,
  CLEAR_TOKEN,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  SET_LOGIN_MESSAGE,
  SET_LOGIN_REDIRECT,
  VERIFY_PHONE_NUMBER,
  SEND_VERIFICATION_CODE,
  UPDATE_PROFILE,
  LOGIN_AS_USER,
  SET_SUPERADMIN
} from '../types/authTypes';
import AuthService from '../../../services/auth';

const removeTokens = () => {
  window.localStorage.removeItem('authToken');
  window.sessionStorage.removeItem('authToken');
  window.localStorage.removeItem('authUserData');
  window.sessionStorage.removeItem('authUserData');
};

export const login = createActionThunk(LOGIN, async (login, password, remember) => {
  try {
    const { token, userData } = await AuthService.login(login, password, remember);

    removeTokens();

    if (remember) {
      localStorage.setItem('authToken', token);
      localStorage.setItem('authUserData', JSON.stringify(userData));
    } else {
      sessionStorage.setItem('authToken', token);
      sessionStorage.setItem('authUserData', JSON.stringify(userData));
    }

    return { token, userData };
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const sendVerificationCode = createActionThunk(SEND_VERIFICATION_CODE, (data) =>
  AuthService.sendVerificationCode(data).then((data) => data),
  false
);

export const verifyPhoneNumber = createActionThunk(VERIFY_PHONE_NUMBER, (data) =>
  AuthService.verifyPhoneNumber(data).then((data) => data),
  false
);

export const register = createActionThunk(REGISTER, (data) =>
  AuthService.register(data).then((data) => data),
  false
);

export const forgotPassword = createActionThunk(FORGOT_PASSWORD, (data) =>
  AuthService.forgotPassword(data).then((data) => data),
  false
);

export const resetPassword = createActionThunk(RESET_PASSWORD, (data) =>
  AuthService.resetPassword(data).then((data) => data),
  false
);

export const logout = createActionThunk(LOGOUT, () => {
  removeTokens();
  AuthService.logout();
});

export const clearToken = createActionThunk(CLEAR_TOKEN, removeTokens);

export const setLoginMessage = createActionThunk(SET_LOGIN_MESSAGE,(message) => message);

export const setLoginRedirect = createActionThunk(SET_LOGIN_REDIRECT, (url) => url);

export const resetLogin = createActionThunk(RESET_LOGIN, _.noop);

export const loginAsUser = createActionThunk(LOGIN_AS_USER, async (id) => {
  try {
    const { token, userData } = await AuthService.loginAsUser(id);

    sessionStorage.setItem('authTokenAs', sessionStorage.getItem('authToken'));
    sessionStorage.setItem('authUserDataAs', sessionStorage.getItem('authUserData'));

    sessionStorage.setItem('authToken', token);
    sessionStorage.setItem('authUserData', JSON.stringify(userData));

    return { token, userData };
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const setSuperAdmin = createActionThunk(SET_SUPERADMIN,() => {
  const superAdminToken = sessionStorage.getItem('authTokenAs');
  const superAdminData = sessionStorage.getItem('authUserDataAs');

  sessionStorage.setItem('authToken', superAdminToken);
  sessionStorage.setItem('authUserData', superAdminData);

  window.sessionStorage.removeItem('authTokenAs');
  window.sessionStorage.removeItem('authUserDataAs');
  
  return { token: superAdminToken, userData: JSON.parse(superAdminData) };
});

export const updateProfile = createActionThunk(UPDATE_PROFILE, async (data) => {
    const { userData } = await AuthService.updateProfile(data).then((data) => data);
  
    if(localStorage.getItem('authUserData')){
      localStorage.setItem('authUserData', JSON.stringify(userData));
    }
    if(sessionStorage.getItem('authUserData')){
      sessionStorage.setItem('authUserData', JSON.stringify(userData));
    }
    
    return { userData };
},false);
