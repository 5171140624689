import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ItemsList from '../../ItemsList';
import { getCampaignListColumns } from '../../../utils/helpers';

const CampaignsList = ({ campaigns, handleShowModal }) => {
  const [t] = useTranslation();

  const columns = useMemo(() => getCampaignListColumns(t), [t]);
  const searchColumns = useMemo(() => ['name', 'message'], []);
  const campaignsList = useMemo(() => campaigns.map((campaign) => ({
    ...campaign,
    startDate: moment(campaign.startDate).format('DD/MM/YYYY HH:mm'),
    creationDate: moment(campaign.creationDate).format('DD/MM/YYYY HH:mm'),
    status: campaign.status == 'Active' ? 'Active and Scheduled' : campaign.status
  })), [campaigns]);
  
  const actions = (item, action) =>
    ({
      view: true
    }[action]);

  return (
    <ItemsList
      items={campaignsList}
      columns={columns}
      searchColumns={searchColumns}
      actions={actions}
      handleShowModal={handleShowModal}
      viewUrl={`/admin/campaign`}
      uniqueId='campaignId'
    />
  );
};

export default CampaignsList;