import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

export const SubMenuNavItem = function ({
  href,
  iClassName,
  defaultHref = "xxx",
}) {
  const [t] = useTranslation();
  
  const name = href.substring(href.lastIndexOf("/") + 1);
  const location = useLocation();
  const path = location.pathname.replace(/\/+$/, "");
  const isActive = path === href || path === defaultHref ? "active" : "";
  let finalHref = href.replace('/stops','/stops?status=Stop');
  finalHref = finalHref.replace('/blocked','/blocked?status=Blocked');

  return (
    <li className={`nav-item ${isActive}`}>
      <Link className="nav-link" to={finalHref}>
        <i className={`${iClassName} menu-icon`}></i>
        <span className="sub-menu-title">
          {t(`SIDEBAR.${name.toUpperCase()}`)}
        </span>
      </Link>
    </li>
  );
};

export const MenuNavItem = function ({ children, name, iClassName }) {
  const [t] = useTranslation();
  const location = useLocation();
  let subLinks = Array.isArray(children)
    ? children.map((child) => child.props?.href)
    : [];


    subLinks = subLinks.concat(
      Array.isArray(children)
        ? children
        .map(({ props }) => props?.defaultHref).filter(Boolean)
        : []
    );
  const path = location.pathname.replace(/\/+$/, "");
  const isActive = subLinks.includes(path);

  return (
    <li className={`nav-item ${isActive ? "active" : ""}`}>
      <a
        className="nav-link"
        data-toggle="collapse"
        href={`#${name}`}
        aria-expanded={isActive}
        aria-controls={name}
      >
        <i className={`${iClassName} menu-icon`}></i>
        <span className="menu-title">{t(`SIDEBAR.${name.toUpperCase()}`)}</span>
        <i className="menu-arrow"></i>
      </a>
      <div className={`collapse ${isActive ? "show" : ""}`} id={name}>
        <ul className="nav flex-column sub-menu">{children}</ul>
      </div>
    </li>
  );
};

export const Menu = function ({ children }) {
  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">{children}</ul>
    </nav>
  );
};
