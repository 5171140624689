export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const CLEAR_TOKEN = 'CLEAR_TOKEN';
export const RESET_LOGIN = 'RESET_LOGIN';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const SET_LOGIN_MESSAGE = 'SET_LOGIN_MESSAGE';
export const SET_LOGIN_REDIRECT = 'SET_LOGIN_REDIRECT';
export const VERIFY_PHONE_NUMBER = 'VERIFY_PHONE_NUMBER';
export const SEND_VERIFICATION_CODE = 'SEND_VERIFICATION_CODE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const LOGIN_AS_USER = 'LOGIN_AS_USER';
export const SET_SUPERADMIN = 'SET_SUPERADMIN';