import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { SMS_TYPE } from "../../../shared/types";
import { Button } from "react-bootstrap";
import {
  createCampaign,
  fetchCampaign,
  updateCampaign,
} from "../../../store/actions/creators/campaignActions";
import Loading from "../../../components/Shared/Loading";
import NewCampaignForm from "../../../components/Member/NewCampaignForm";
import { STARTED, SUCCEEDED } from "../../../utils/helpers/store-helpers";
import { fetchGroups } from "../../../store/actions/creators/groupActions";
import { fetchTemplates } from "../../../store/actions/creators/templateActions";
import toast from "react-hot-toast";
import {
  errorMessageToast,
  getError,
  successMessageToast,
} from "../../../utils/common/helper";
import CustomPhoneInput from "src/components/Shared/PhoneInput";
import IPhone from "src/components/IPhone";
import { fetchClient } from "src/store/actions/creators/clientActions";
import { clientSelector } from "src/store/selectors/clientSelector";
import { newCampaignFormInitialState } from "src/utils/helpers";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js/max";
import { createSms } from "src/store/actions/creators/smsActions";
import {
  campaignFetchStatusSelector,
  campaignSelector,
} from "src/store/selectors/campaignSelector";
import moment from "moment";
import { FESTIVAL_DAYS } from 'src/utils/common/constants';

const NewCampaign = () => {
  const [t] = useTranslation();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialState = useMemo(() => newCampaignFormInitialState, []);

  const [inputs, setInputs] = useState(initialState);

  const [testPhone, setTestPhone] = useState("");
  const [testPhoneValid, setTestPhoneValid] = useState(false);
  const [countries, setCountries] = useState([]);

  let campaign = useSelector(campaignSelector);
  const campaignStatus = useSelector(campaignFetchStatusSelector);
  const localStorageUser = JSON.parse(localStorage.getItem("authUserData"));
  const sessionStorageUser = JSON.parse(sessionStorage.getItem("authUserData"));

  const storageUser = localStorageUser || sessionStorageUser;
  const client = useSelector(clientSelector);

  if (location.pathname == "/member/new_campaign") {
    campaign = null;
  }

  useEffect(()=>{
    if(campaign && location.pathname != "/member/new_campaign" && moment(campaign.startDate).isBefore(moment())){
      errorMessageToast("Old campaigns cannot be edited.");
      navigate("/member/campaign_list");
    }
  },[campaign])

  useEffect(() => {
    dispatch(fetchClient(storageUser.accountId));
    dispatch(fetchGroups());
    dispatch(fetchTemplates());
  }, []);

  useEffect(() => {
    if (params.campaignId) dispatch(fetchCampaign(params.campaignId));
  }, [params]);

  useEffect(() => {
    if (client && client.credits) {
      let countries = Object.keys(client.credits).map((e) => _.lowerCase(e));
      setCountries(countries);
    }
  }, [client]);

  const onTestClicked = async (event) => {
    event.preventDefault();

    if (!testPhone || testPhone.length == 0) {
      errorMessageToast("Phone number required for testing.");
      return false;
    }

    let parseNumber = parsePhoneNumber(testPhone);
    if (
      !isValidPhoneNumber(testPhone) ||
      !countries.includes(_.lowerCase(parseNumber.country)) ||
      parseNumber.getType() != "MOBILE"
    ) {
      errorMessageToast(t("MEMBER.CONTACTS.PHONE_NOT_VALID"));
      return false;
    }

    if (!inputs.message || inputs.message.length == 0) {
      errorMessageToast("Message is required for testing.");
      return false;
    }

    const data = {
      phone: testPhone,
      from: inputs.from,
      message: inputs.message,
      channel: 10,
      shortUrl: inputs.shortUrl,
      trackUrl: inputs.trackUrl,
      type: SMS_TYPE.MARKETING,
      test: true,
    };

    try {
      await dispatch(createSms(data));
      successMessageToast("Test SMS sent successfully.");
    } catch (error) {
      let err = getError(error);
      typeof err == "string" && errorMessageToast(err);
    }
  };

  const handleSubmit = async (data) => {
    try {
      const getDates = moment(data.startDate);
      const fetchDay = getDates.day();
      const formattedTime =
        getDates.hours().toString().length === 1
          ? `0${getDates.hours()}`
          : getDates.hours();
    
      const arrayOfFestivalDates = FESTIVAL_DAYS;
   
      if (
        fetchDay === 0 ||
        arrayOfFestivalDates.includes(
          moment(data.startDate).format("YYYY-MM-DD")
        )
      ) {
        errorMessageToast(t("MEMBER.CAMPAIGNS.ERROR_SUNDAY_FESTIVAL"));
        return false;
      } else if (
        fetchDay === 1 ||
        fetchDay === 2 ||
        fetchDay === 3 ||
        fetchDay === 4 ||
        fetchDay === 5
      ) {
        if (
          !(
            `${formattedTime}:${getDates.minute()}` >= "08:00" &&
            `${formattedTime}:${getDates.minute()}` <= "20:00"
          )
        ) {
          errorMessageToast(t("MEMBER.CAMPAIGNS.ERROR_WEEKDAY"));
          return false;
        }
      } else if (fetchDay === 6) {
        if (
          !(
            `${formattedTime}:${getDates.minute()}` >= "10:00" &&
            `${formattedTime}:${getDates.minute()}` <= "20:00"
          )
        ) {
          errorMessageToast(t("MEMBER.CAMPAIGNS.ERROR_SATURDAY"));
          return false;
        }
      } 
      data.SelectedTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (data.id) {
        await dispatch(
          updateCampaign(data.id, _.omit(data, ["id", "creationDate", "clientId", "userId", "smsSent", "smsFailed", "smsDelivered", "SelectedTz"]))
        );
      } else {
        await dispatch(createCampaign(data));
      }
      
      successMessageToast("Details saved successfully.");
      navigate("/member/campaign_list");
    } catch (error) {
      let err = getError(error);
      typeof err == "string" && errorMessageToast(err);
    }
  };

  return (
    <div className="card card-body">
      <h4 className="card-title">{t("MEMBER.CAMPAIGNS.CAMPAIGNS")}</h4>
      <div className="row">
        <div className="col-md-8">
          {params.campaignId && !_.isEmpty(campaign) && (
            <NewCampaignForm
              handleSubmit={handleSubmit}
              campaign={campaign}
              inputs={inputs}
              setInputs={setInputs}
            />
          )}
          {!params.campaignId && (
            <NewCampaignForm
              handleSubmit={handleSubmit}
              campaign={campaign}
              inputs={inputs}
              setInputs={setInputs}
            />
          )}
        </div>
        <div className="col-md-4" style={{ width: "251px", height: "561px" }}>
          <div className="row">
            <div className="col-md-12">
              <IPhone
                from={inputs.from ?? ''}
                message={inputs.message ? inputs.message + " STOP 36xxx" : ""}
              />
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <CustomPhoneInput
                    phone={testPhone}
                    countries={countries}
                    setPhone={setTestPhone}
                    setPhoneValid={setTestPhoneValid}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-5 mt-3 mx-auto">
                  <Button
                    variant="primary"
                    className="btn-icon-text"
                    onClick={onTestClicked}
                  >
                    {t("MEMBER.SMS.TEST_SMS")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCampaign;
