import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';

import { registeredUsersSelector, registeredUsersFetchStatusSelector } from '../../../store/selectors/clientSelector';
import { registrationSettingSelector } from "../../../store/selectors/registrationSettingSelector";
import Loading from '../../../components/Shared/Loading';
import RegisteredUserList from '../../../components/Admin/RegisteredUserList';
import RegisteredUserForm from '../../../components/Admin/RegisteredUserForm';
import { fetchRegisteredUsers, removeRegisteredUser, updateRegisteredUser } from '../../../store/actions/creators/clientActions';
import { fetchRegistrationSetting } from '../../../store/actions/creators/registrationSettingActions';
import { STARTED, SUCCEEDED } from '../../../utils/helpers/store-helpers';
import toast from 'react-hot-toast';
import {errorMessageToast, getError, successMessageToast} from '../../../utils/common/helper'

const RegisteredUsers = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const users = useSelector(registeredUsersSelector);
  const usersFetchStatus = useSelector(registeredUsersFetchStatusSelector);
  const setting = useSelector(registrationSettingSelector);

  const [user, setUser] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(fetchRegisteredUsers());
    dispatch(fetchRegistrationSetting(1));
  }, []);

  const handleShowModal = (userId) => {
    setShowModal(true);
    let temp = userId ? users.find(
      (u) => u.id === userId
    ) : {};
    setUser(_.omit(temp, ['lastLogin', 'lastIp']));
  };

  const handleSubmit = async (data) => {
    try{
      if (data.id) {
        await dispatch(updateRegisteredUser(
          data.id,
          _.omit(data, ['id'])
        )); 
        successMessageToast("Details successfully saved.")
      }
    }catch(error){
        let err = getError(error);
        typeof err == 'string' && errorMessageToast(err);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setUser({});
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-body">
          <div className="d-flex justify-content-between mb-4">
            <h4 className="card-title">{t('SIDEBAR.REGISTERED_USERS')}</h4>
            {usersFetchStatus && showModal && (
              <RegisteredUserForm
                handleSubmit={handleSubmit}
                handleCloseModal={handleCloseModal}
                user={user}
                setting={setting}
              />
            )}
          </div>
            {usersFetchStatus === STARTED ? (
            <Loading/>
          ) : usersFetchStatus === SUCCEEDED && users.length > 0 ? (
            <RegisteredUserList
              users={users}
              handleShowModal={handleShowModal}
              removeUser={(u) => dispatch(removeRegisteredUser(u.accountId, null, u.id))}
            />
          ) : (
            t('ADMIN.CLIENTS.CAN\'T_FETCH_USERS')
          )}
        </div>
      </div>
    </div>
  );
};

export default RegisteredUsers;