import React from "react";
import Tab from "../../../CampaignGeneralInfo/Tab";
import {  mdiCellphoneCheck, mdiSendCheck, mdiSendClock } from "@mdi/js";
import { useTranslation } from "react-i18next";
import moment from "moment";

const Message = ({ inputs }) => {
  const [t] = useTranslation();
  return (
    <div className="row">
      <div className="col-lg-12 grid-margin">
        <div className="card card-body mt-2">
          <div className="row no-gutters">
          <Tab
              lg={4}
              titleStyle={"ml-1"}
              title={t("COMMON.PHONE")}
              className={"p-2 border-lg-right border-bottom border-lg-bottom-0"}
              icon={mdiCellphoneCheck}
              color={"#0000CD"}
              value={inputs.phone}
            />
            <Tab
              lg={4}
              titleStyle={"ml-1"}
              title={t("MEMBER.SMS_STATUS.SENT_AT")}
              className={"p-2 border-lg-right border-bottom border-lg-bottom-0"}
              icon={mdiSendClock}
              color={"#0000CD"}
              value={moment(inputs.sendDt).format("DD/MM/YYYY HH:mm")}
            />
            <Tab
              lg={4}
              titleStyle={"ml-1"}
              title={t("MEMBER.SMS_STATUS.STATUS_UPDATED_AT")}
              className={"p-2 border-bottom border-lg-bottom-0"}
              icon={mdiSendCheck}
              color={"#0000CD"}
              value={moment(inputs.statusDt).format("DD/MM/YYYY HH:mm")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
