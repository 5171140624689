import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Tab from './Tab';
import { mdiAccountGroup, mdiAccountMultiple, mdiCalendarMonth } from '@mdi/js';
import { campaignsSelector } from '../../../../store/selectors/campaignSelector';

const TopBar = ({ groups, contacts }) => {
  const [t] = useTranslation();

  const campaigns = useSelector(campaignsSelector);

  return (
    <div className="row">
      <div className="col-md-12 grid-margin">
        <div className="card card-body">
          <div className="row no-gutters">
            <Tab 
              value={campaigns.length} 
              title={t('COMMON.CAMPAIGNS')}
              iconPath={mdiCalendarMonth}
              to="/member/campaigns"
            />
            <Tab
              value={groups.length}
              title={t('COMMON.GROUPS')}
              iconPath={mdiAccountGroup}
              to="/member/groups"
            />
            <Tab
              value={contacts.length}
              title={t('MEMBER.CONTACTS.CONTACTS')}
              iconPath={mdiAccountMultiple}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;