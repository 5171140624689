import { useTranslation } from 'react-i18next';

import { Button, Form } from 'react-bootstrap';

const ActiveCountrySmsPrices = ({ activeCountryTab, index, errors, range, handleChange, inputs }) => {
  const [t] = useTranslation();

  return (
    <Form.Group className="form-group mb-3">
      <div className="row">
        <div className="col-md-1">
          <Form.Label className="required pt-2">
            {t('ADMIN.CLIENTS.MINIMUM')}
          </Form.Label>
        </div>
        <div className="col-md-3">
          <Form.Control
            type="number"
            name={`smsPricesPerCountry.${activeCountryTab}.${index}.min`}
            isInvalid={Boolean(
              errors?.smsPricesPerCountry &&
              errors?.smsPricesPerCountry[activeCountryTab]
            )}
            value={range.min}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-1">
          <Form.Label className="required pt-2">
            {t('ADMIN.CLIENTS.MAXIMUM')}
          </Form.Label>
        </div>
        <div className="col-md-3">
          <Form.Control
            type="number"
            name={`smsPricesPerCountry.${activeCountryTab}.${index}.max`}
            isInvalid={Boolean(
              errors?.smsPricesPerCountry &&
              errors?.smsPricesPerCountry[activeCountryTab]
            )}
            min={range.min + 1}
            value={range.max}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-1 pr-0">
          <Form.Label className="required pt-2">
            {t('ADMIN.CLIENTS.PRICE')}
          </Form.Label>
        </div>
        <div className="col-md-2 pl-0">
          <Form.Control
            type="text"
            name={`smsPricesPerCountry.${activeCountryTab}.${index}.price`}
            isInvalid={Boolean(
              errors?.smsPricesPerCountry &&
              errors?.smsPricesPerCountry[activeCountryTab]
            )}
            value={range.price}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-1 p-0">
          <Button
            variant="primary"
            className="btn-icon-text pl-3 pr-3"
            name={`smsPricesPerCountry.${activeCountryTab}.${index}.${
              index + 1 ===
              inputs.smsPricesPerCountry[activeCountryTab]
                .length
                ? 'add'
                : 'del'
            }`}
            onClick={handleChange}
          >
            {index + 1 ===
            inputs.smsPricesPerCountry[activeCountryTab]
              .length ? (
              <i className="feather-plus"></i>
            ) : (
              <i className="feather-minus"></i>
            )}
          </Button>
        </div>
      </div>
    </Form.Group>
  );
};

export default ActiveCountrySmsPrices;