import React from 'react';

export const MainContainer = ({ children }) => (
  <div className="container-scroller">{children}</div>
);

export const Container = ({ children }) => (
  <div className="container-fluid page-body-wrapper">{children}</div>
);

export const FullPageContainer = ({ children }) => (
  <div className="container-fluid page-body-wrapper full-page-wrapper">
    {children}
  </div>
);

export const MainPanel = ({ children }) => (
  <div className="main-panel">{children}</div>
);

export const ContentWrapper = ({ children }) => (
  <div className="content-wrapper">{children}</div>
);
