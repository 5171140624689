import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import Icon from '@mdi/react';
import { mdiCheck, mdiEye } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import Hint from 'src/components/ResetPassword/Hint';
import Tooltip from "src/components/Tooltip";

const PasswordInputs = ({
  errors,
  password,
  setPassword,
  showPassword,
  setShowPassword,
  confirmPassword,
  setConfirmPassword,
}) => {
  const [t] = useTranslation();
  
  const passwordPattern = useMemo(() => '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()-_=+;:\'"<,>.]).{8,}$', []);

  const [status, setStatus] = useState({});
  
  const onChangePassword = (value) => {
    let check = {
      min_char: false,
      one_digit:false,
      one_special_char:false,
      one_uppercase:false,
      one_lowercase: false,
    };
   
    if(/^.{8,}$/.test(value)){
      check.min_char = true;
    }   
    if(/([0-9])/.test(value)){
      check.one_digit = true;
    }
    if(/[!@#$%^&*()_=+;:\'"<,>.-]/.test(value)){
      check.one_special_char = true;
    }
    if(/([A-Z])/.test(value)){
      check.one_uppercase = true;
    }
    if(/([a-z])/.test(value)){
      check.one_lowercase = true;
    }
    setStatus(check); 
    setPassword(value);
  }

  return (
    <>
      <div className="form-group">
        <div className="d-flex justify-content-between">
          <label>
            {t('COMMON.PASSWORD')} 
            <Tooltip originalTitle={t("REGISTRATION.TOOLTIP_PASSWORD")} />
          </label>
          <label>
            <Link to="#" onClick={() => setShowPassword(!showPassword)}>
              <Icon path={mdiEye} size={0.6} color="#000"/>{' '}
              {showPassword
                ? t('REGISTRATION.HIDE')
                : t('REGISTRATION.VIEW')}{' '}
              {t('COMMON.PASSWORD')}
            </Link>
          </label>
        </div>
        <input
          type={showPassword ? 'text' : 'password'}
          className="form-control"
          name="password"
          pattern={passwordPattern}
          onChange={({ target: { value } }) => onChangePassword(value)}
          value={password}
          required
        />
      </div>
      <div className="form-group">
        <label>{t('REGISTRATION.CONFIRM_PASSWORD')}</label>
        <Tooltip originalTitle={t("REGISTRATION.TOOLTIP_CONFIRMPASSWORD")} />
        <input
          type={showPassword ? 'text' : 'password'}
          className="form-control"
          name="confirmPassword"
          pattern={passwordPattern}
          onChange={({ target: { value } }) => setConfirmPassword(value)}
          value={confirmPassword}
          required
        />
        <Hint status={status}/>
        <div>
          <p>
            {t('REGISTRATION.AUTHORIZED_SPECIAL_CHARACTERS')} 
            !@#$%^&amp;*&#40;&#41;-_=+;:'"&lt;,&gt;.
          </p>
        </div>
        {errors?.password ? (
          <div className="invalid-feedback d-sm-inline-block">
            {t(
              'REGISTRATION.PASSWORDS_MUST_MEET_THE_PATTERN_AND_BE_THE_SAME'
            )}
          </div>
        ) : null}
      </div>
    </>
  )
};

export default PasswordInputs;