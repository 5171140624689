import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

const PerPageCount = ({ changePerPage }) => {
  const perPageCounts = useMemo(() => [10, 25, 50, 100], []);

  return (
    <div className="dropdown-menu" aria-labelledby="dropdownMenuSizeButton3">
      {perPageCounts.map(el => (
        <Link key={el} className="dropdown-item" to="#" onClick={() => changePerPage(el)}>{el}</Link>
      ))}
    </div>
  );
};

export default PerPageCount;