import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {getError} from '../../../../utils/common/helper'
import _ from 'lodash';

import Spinner from 'react-bootstrap/Spinner';
import { verifyPhoneNumber } from '../../../../store/actions/creators/authActions';

const Step4 = ({ loginDisabled, setLoginDisabled, phone, token }) => {
  const [t] = useTranslation();
  
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState([]);
  const [errors, setErrors] = useState();

  const onChange = (event) => {
    let index = parseInt(event.target.getAttribute('data-index'));
    if (event.keyCode === 8 && event.target.value === '') {
      index = Math.max(0, index - 1);
    } else if (event.keyCode === undefined) {
      if (!event.target.value) {
        verificationCode[index] = '';
      } else {
        for (let i = 0; i < event.target.value.length && index < 6; ++i, ++index) {
          verificationCode[index] = event.target.value[i];
        }
        verificationCode[index] = '';
        index = Math.min(5, index);
      }
    }
    document.querySelector(`input[data-index='${index}']`).focus();

    setVerificationCode([...verificationCode]);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const number = Number(verificationCode.join(''))
    try {
      await dispatch(verifyPhoneNumber({ phone, token, code: number }));
      setLoginDisabled(true);
    } catch (error) {
      setErrors(getError(error));
      setIsLoading(false);
      setLoginDisabled(false);
    }
  }

  return (<>
    <h6 className="font-weight-light">
      {t('REGISTRATION.ENTER_CODE_FROM_SMS')}
    </h6>
    <form
      className="pt-3 content-area"
      onSubmit={(e) => handleSubmit(e)}
    >
      <div className="form-group m-0">
        <label>{t('REGISTRATION.CODE_FROM_SMS')}</label>

        <fieldset className="number-code">
          <div>
            {[0, 1, 2, 3, 4, 5].map((idx) => (<input
              key={idx}
              name="verificationCode"
              data-index={idx}
              className="code-input"
              value={verificationCode[idx] || ''}
              onKeyDown={onChange}
              onChange={onChange}
              required
              autoFocus={idx === 0}
            />))}
          </div>
        </fieldset>
        {!_.isEmpty(errors) ? (<div className="invalid-feedback d-sm-inline-block">
          {errors}
        </div>) : ('')}
      </div>
      <div className="mt-3">
        <button
          type="submit"
          className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
          disabled={loginDisabled}
        >
          {isLoading ? (<Spinner
            className="mr-2"
            animation="border"
            variant="secondary"
            size="sm"
          />) : t('REGISTRATION.SEND_CODE')}
        </button>
      </div>
    </form>
  </>);
};

export default Step4;
