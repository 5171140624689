import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import { useTranslation, Trans } from 'react-i18next';
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import {
  sendVerificationCodeErrorSelector,
  verificationCodeSendStatusSelector,
  phoneNumberVerificationErrorSelector,
  phoneNumberVerificationStatusSelector
} from "../../../store/selectors/authSelector";
import { registrationSettingSelector } from "../../../store/selectors/registrationSettingSelector";
import { register } from "../../../store/actions/creators/authActions";
import { fetchRegistrationSetting } from '../../../store/actions/creators/registrationSettingActions';

import { FAILED, STARTED, SUCCEEDED } from '../../../utils/helpers/store-helpers';
import toast from 'react-hot-toast';
import { errorMessageToast } from "src/utils/common/helper";

const Steps = () => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const [phone, setPhone] = useState();
  const [step, setStep] = useState(1);
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loginDisabled, setLoginDisabled] = useState(false);

  const phoneNumberVerificationStatus = useSelector(phoneNumberVerificationStatusSelector);
  const verificationCodeSendStatus = useSelector(verificationCodeSendStatusSelector);
  const sendVerificationCodeError = useSelector(sendVerificationCodeErrorSelector);
  const phoneNumberVerificationError = useSelector(phoneNumberVerificationErrorSelector);
  const setting = useSelector(registrationSettingSelector);

  useEffect(() => {
    !_.isEmpty(inputs) && checkState(verificationCodeSendStatus, { sendVerificationCodeError });
  }, [verificationCodeSendStatus]);
  
  useEffect(() => {
    //!_.isEmpty(inputs) && checkState(phoneNumberVerificationStatus, { phoneNumberVerificationError });
    if (!_.isEmpty(inputs) && phoneNumberVerificationStatus === SUCCEEDED) registerFnc();
  }, [phoneNumberVerificationStatus]);

  useEffect(() => {
    dispatch(fetchRegistrationSetting(1));
  }, []);

  const checkState = (state, error) => {
    if (!state) return;
    
    if (state === STARTED) return setIsLoading(true);
    else if (state === FAILED) setErrors(error);
    else setStep(step + 1);
    setIsLoading(false);
  }

  const registerFnc = async () => {
    const data = _.omit(inputs);
    data.language = i18n.language;
  
    try {
      await dispatch(register(data));
      setStep(step + 1)
    } catch (error) {
     errorMessageToast(error?.response?.data?.message)
    }
  }
  
  return [
    <Step1
      step={step}
      inputs={inputs}
      errors={errors}
      setStep={setStep}
      setInputs={setInputs}
      setErrors={setErrors}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      loginDisabled={loginDisabled}
      setting={setting}
    />,
    <Step2
      step={step}
      setStep={setStep}
      setIsLoading={setIsLoading}
    />,
    <Step3
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      inputs={inputs}
      setInputs={setInputs}
      errors={errors}
      setErrors={setErrors}
      setPhone={setPhone}
      phone={phone}
      setting={setting}
    />,
    <Step4
      phone={phone}
      loginDisabled={loginDisabled}
      setLoginDisabled={setLoginDisabled}
      errors={errors}
    />,
    <Step5/>,
  ][step - 1]
};

export default Steps;