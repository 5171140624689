import { useTranslation } from 'react-i18next';

import { Form, Nav } from 'react-bootstrap';
import ActiveCountrySmsPrices from '../ActiveCountrySmsPrices';

const ActiveCountryTab = ({
  errors,
  inputs,
  handleChange,
  activeCountryTab,
  selectedCountries,
  setActiveCountryTab
}) => {
  const [t] = useTranslation();

  const handleChangeCountryTab = (eventKey) => setActiveCountryTab(eventKey);
  
  return (selectedCountries.length > 0 &&
    (
      <>
        <div className="row">
          <div className="col-md-12">
            <Nav
              variant="tabs"
              defaultActiveKey=""
              activeKey={activeCountryTab}
              className="mb-2"
              onSelect={handleChangeCountryTab}
            >
              {selectedCountries.map((country) => (
                <Nav.Item key={country.value}>
                  <Nav.Link eventKey={country.value}>
                    {country.label}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-md-6">
            <Form.Group className="form-group mb-3">
              <Form.Label className="required pt-2">
                {t('ADMIN.CLIENTS.COUNT_OF_SMS')}
              </Form.Label>
              <Form.Control
                type="number"
                name={'credits.' + activeCountryTab}
                isInvalid={Boolean(errors?.credits && errors?.credits[activeCountryTab])}
                value={inputs.credits[activeCountryTab]}
                onChange={handleChange}
                required
                disabled={inputs.prepaid === 'No'}
              />
            </Form.Group>
          </div>
        </div> */}

        {inputs.smsPricesPerCountry[activeCountryTab].map((range, index) => (
          <ActiveCountrySmsPrices
            key={index}
            inputs={inputs}
            handleChange={handleChange}
            errors={errors}
            activeCountryTab={activeCountryTab}
            index={index}
            range={range}
          />
        ))}
        <hr></hr>
      </>
    )
  );
};

export default ActiveCountryTab;