import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useFirstRender } from '../../../utils/hooks';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { STARTED, SUCCEEDED } from '../../../utils/helpers/store-helpers';
import { groupCreateStatusSelector, groupUpdateStatusSelector } from '../../../store/selectors/groupSelector';

const NewGroupForm = ({
  group,
  errors,
  handleSubmit,
  handleCloseModal
}) => {
  const initialInputs = useMemo(() => ({
    name: ''
  }), []);

  const [t] = useTranslation();
  
  const groupCreateStatus = useSelector(groupCreateStatusSelector);
  const groupUpdateStatus = useSelector(groupUpdateStatusSelector);
  
  const firstRender = useFirstRender();
  
  const [inputs, setInputs] = useState(initialInputs);
  const [submitClicked, setSubmitClicked] = useState(false);

  useEffect(() => {
    if (!group) setInputs(initialInputs);
    else setInputs({
        ...initialInputs,
        ...group
      });
  }, []);
  
  useEffect(() => {
    if (!firstRender && groupCreateStatus === SUCCEEDED) handleCloseModal();
  }, [groupCreateStatus]);

  useEffect(() => {
    if (!firstRender && groupUpdateStatus === SUCCEEDED) handleCloseModal();
  }, [groupUpdateStatus]);

  const handleChange = ({ target: { name, value } }) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setSubmitClicked(true);
    handleSubmit(inputs);
  };

  return (
    <Modal show={true} onHide={handleCloseModal}>
      <Modal.Header closeButton={false}>
        <Modal.Title>
          {inputs.id ? t('MEMBER.GROUPS.EDIT_GROUP') : t('MEMBER.GROUPS.INSERT_GROUP')}
        </Modal.Title>
        <button type="button" className="close" aria-label="Close" onClick={handleCloseModal}>
          <span aria-hidden="true">x</span>
        </button>
      </Modal.Header>
      <Form className={'form-sample' + (submitClicked ? ' was-validated' : '')}>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <Form.Group className="form-group mb-3">
                <Form.Label className="required">
                  {t('COMMON.NAME')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  isInvalid={Boolean(errors?.name)}
                  placeholder={t('MEMBER.GROUPS.GROUP_NAME')}
                  value={inputs.name}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.name && t('COMMON.NAME') + ' ' + errors?.name}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t('COMMON.CLOSE')}
          </Button>
          <Button
            variant="primary"
            className="btn-icon-text"
            type="submit"
            onClick={onSubmit}
          >
            {groupCreateStatus === STARTED || groupUpdateStatus === STARTED ? (
              <Spinner className="mr-2" animation="border" variant="secondary" size="sm"/>
            ) : (
              <i className={(inputs.id ? 'feather-save' : 'feather-plus') + ' btn-icon-prepend'}></i>
            )}
            {inputs.id ? t('COMMON.SAVE') : t('COMMON.INSERT')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NewGroupForm;
