/**
 * Service for SMS
 */
import useApi from '../utils/api';

class SmsService {
  #prefix = '/sms';
  
  createSms = (data) => {
    return useApi.post(this.#prefix, data);
  };

  fetchSmsStatuses = (clientId) => {
    return clientId && typeof  clientId != 'object' ? useApi.get(`${this.#prefix}?clientId=${clientId}`) : useApi.get(`${this.#prefix}`);
  };
}

export default new SmsService();
