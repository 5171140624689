import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ToolBar = ({
  groups,
  filterStatus,
  filterGroupId,
  handleShowModal,
  contactStatuses,
  setFilterStatus,
  setFilterGroupId,
  handleShowImportContactsModal
}) => {
  const [t] = useTranslation();
  
  return (
    <div className="d-flex justify-content-between mb-4">
      <h4 className="card-title">{t('MEMBER.CONTACTS.CONTACTS')}</h4>
      <Button
        variant="primary"
        size="sm"
        className="btn-icon-text"
        onClick={() => handleShowModal()}
      >
        <i className="feather-user-plus btn-icon-prepend"></i>
        {t('MEMBER.CONTACTS.ADD_NEW_CONTACT')}
      </Button>
      <Button
        variant="primary"
        size="sm"
        className="btn-icon-text"
        onClick={() => handleShowImportContactsModal()}
      >
        <i className="feather-file-plus btn-icon-prepend"></i>
        {t('MEMBER.CONTACTS.IMPORT_CONTACT_LIST')}
      </Button>
      <Form.Label className="pt-2">
        {t('MEMBER.CONTACTS.FILTER_BY_GROUP')}
      </Form.Label>
      <Form.Select
        className="form-control w-25"
        size="sm"
        name="filterGroupId"
        value={filterGroupId}
        onChange={({ target: { value } }) => setFilterGroupId(value)}
      >
        <option value="">All groups</option>
        {groups.map((item) => (
          <option key={item.id} value={item.name}>
            {item.name}
          </option>
        ))}
      </Form.Select>

      <Form.Label className="pt-2">
        {t('MEMBER.CONTACTS.FILTER_BY_STATUS')}
      </Form.Label>
      <Form.Select
        className="form-control w-15"
        size="sm"
        name="filterStatus"
        value={filterStatus}
        onChange={({ target: { value } }) => setFilterStatus(value)}
      >
        <option value="">All statuses</option>
        {contactStatuses.map((status, id) => (
          <option key={id} value={status}>
            {status}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};

export default ToolBar;