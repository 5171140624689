import React from "react";
import Tab from "../../../CampaignGeneralInfo/Tab";
import {
  mdiCommentText,
  mdiCounter,
  mdiTextBoxMultiple,
} from "@mdi/js";
import { useTranslation } from "react-i18next";

const CampaignGeneralInfo = ({ inputs }) => {
  const [t] = useTranslation();

  return (
    <div className="row">
      <div className="col">
        <div className="card card-body ">
          <div className="row no-gutters">
            <Tab
              lg={8}
              titleStyle={"ml-1"}
              title={t("COMMON.MESSAGE")}
              className={"p-2 border-lg-right border-bottom border-lg-bottom-0"}
              icon={mdiCommentText}
              color={"#0000CD"}
              value={inputs.message}
              message={inputs.smsId}
              horizontal
            />

            <Tab lg={4}>
              <Tab
                lg={12}
                titleStyle={"ml-1"}
                title={t("COMMON.MESSAGE_LENGTH")}
                className={"p-2  border-bottom border-lg-bottom-0"}
                icon={mdiCounter}
                color={"#0000CD"}
                value={inputs.message.length}
              />
              <Tab
                lg={12}
                titleStyle={"ml-1"}
                title={t("COMMON.MESSAGE_TYPE")}
                className={"p-2 border-bottom border-lg-bottom-0"}
                icon={mdiTextBoxMultiple}
                color={"#0000CD"}
                value={inputs.type}
              />
            </Tab>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignGeneralInfo;
