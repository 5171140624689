import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Modal } from "react-bootstrap";

const ContactModalBody = ({ inputs, handleChange }) => {
  const [t] = useTranslation();
  return (
    <Modal.Body>
      <div className="row">
        <div className="col-md-12">
          <Form.Group className="form-group row mb-0">
            <Form.Label className="col-sm-2 col-form-label">
              {t("COMMON.STATUS")}
            </Form.Label>
            <div className="col-sm-2">
              <div className="form-check">
                <Form.Label className="form-check-label">
                  <Form.Control
                    type="radio"
                    className="form-check-input"
                    name="status"
                    defaultValue="Active"
                    checked={inputs.status === "Active"}
                    onChange={handleChange}
                  />
                  {t("COMMON.ACTIVE")}
                  <i className="input-helper"></i>
                </Form.Label>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="form-check">
                <Form.Label className="form-check-label">
                  <Form.Control
                    type="radio"
                    className="form-check-input"
                    name="status"
                    defaultValue="Inactive"
                    checked={inputs.status === "Inactive"}
                    onChange={handleChange}
                  />
                  {t("COMMON.INACTIVE")}
                  <i className="input-helper"></i>
                </Form.Label>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="form-check">
                <Form.Label className="form-check-label">
                  <Form.Control
                    type="radio"
                    className="form-check-input"
                    name="status"
                    defaultValue="Blocked"
                    checked={inputs.status === "Blocked"}
                    onChange={handleChange}
                  />
                  {t("COMMON.BLOCKED")}
                  <i className="input-helper"></i>
                </Form.Label>
              </div>
            </div>

            <div className="col-sm-2">
              <div className="form-check">
                <Form.Label className="form-check-label">
                  <Form.Control
                    type="radio"
                    className="form-check-input"
                    name="status"
                    defaultValue="Stop"
                    checked={inputs.status === "Stop"}
                    onChange={handleChange}
                  />
                  {t("COMMON.STOP")}
                  <i className="input-helper"></i>
                </Form.Label>
              </div>
            </div>
          </Form.Group>
        </div>
      </div>
    </Modal.Body>
  );
};

export default ContactModalBody;
