import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ItemsList from '../../../components/ItemsList';
import Loading from '../../../components/Shared/Loading';
import { fetchSmsStatuses } from '../../../store/actions/creators/smsActions';
import { smsStatusesFetchStatusSelector } from '../../../store/selectors/smsSelector';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { getReportColumns } from '../../../utils/helpers';
import ToolBar from '../../../components/AdminHome/Reports/ToolBar';
import { STARTED, SUCCEEDED } from '../../../utils/helpers/store-helpers';
import { fetchAllClients } from '../../../store/actions/creators/clientActions';
import { clientsSelector, clientsFetchStatusSelector } from '../../../store/selectors/clientSelector';

const Reports = () => {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const smsStatusesFetchStatus = useSelector(smsStatusesFetchStatusSelector);
  const clients = useSelector(clientsSelector);

  const [clientList, setClientList] = useState([]);
  const [tmpSmsList, setTmpSmsList] = useState([]);

  const columns = useMemo(() => getReportColumns(t), [t]);

  useEffect(() => {
    dispatch(fetchAllClients(true));
    dispatch(fetchSmsStatuses());
  }, [])

  useEffect(()=> {
    if(clients && clients.length){
      let temp = clients.map(({ id, company }) =>  ({ 'value': id, 'label': company}) )
      setClientList(temp);
    }
  },[clients]);

  const actions = (item, action) => ({
    view: true,
  }[action]);

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-body">
          <div className="d-flex justify-content-between mb-3">
            <h4 className="card-title">{t('MEMBER.REPORTS.REPORTS')}</h4>
          </div>
          <ToolBar setTmpSmsList={setTmpSmsList} tmpSmsList={tmpSmsList} clientList={clientList} />
          {smsStatusesFetchStatus === STARTED
            ? <Loading/>
            : smsStatusesFetchStatus === SUCCEEDED
              ? <ItemsList
                items={tmpSmsList}
                columns={columns}
                searchColumns={[]}
                actions={actions}
                viewUrl="/admin/statuses"
              />
              : t('MEMBER.SMS_STATUSES.CAN\'T_FETCH_SMS_STATUSES')
          }
        </div>
      </div>
    </div>
  );
};

export default Reports;