import React, { useMemo } from "react";
import ItemsList from "../../ItemsList";
import { useTranslation } from "react-i18next";
import { getCreditColumns } from "../../../utils/helpers";

const CreditList = ({ credit }) => {
  const [t] = useTranslation();

  const columns = useMemo(() => getCreditColumns(t,credit[0]), [t]);

  const creditDetails = Object.keys(credit[0]?.credits).map((value) => {
    return { country: value, credits: credit[0]?.credits[value] };
  });

  const searchColumns = useMemo(() => [], []);
  const actions = (item, action) =>
    ({
      paymentLink:true
    }[action]);

  return (
    <ItemsList
      items={creditDetails}
      columns={columns}
      searchColumns={searchColumns}
      actions={actions}
      viewUrl="/member/contacts/group"
    />
  );
};

export default CreditList;
