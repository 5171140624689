/**
 * Service for SMS
 */
import {
  CREATE_SMS,
  FETCH_SMS_STATUSES,
} from '../actions/types/smsTypes';
import { getError } from '../../utils/common/helper';
import { FAILED, getFailed, getStarted, getSucceeded, STARTED, SUCCEEDED } from '../../utils/helpers/store-helpers';

// Initial state
const initialState = {
  errors: null,
  smsList: [],
  smsCreateStatus: '',
  smsStatusesFetchStatus: '',
};

// Client reducer
const events = (state = initialState, { type, payload }) => {
  switch (type) {
    case getStarted(CREATE_SMS):
      return { ...state, smsCreateStatus: STARTED, errors: null };
    case getFailed(CREATE_SMS):
      return { ...state, smsCreateStatus: FAILED, errors: getError(payload) };
    case getSucceeded(CREATE_SMS): 
      return { ...state, smsList: state.smsList.concat(payload.createdSmsStatuses), smsCreateStatus: SUCCEEDED, errors: null };
    case getStarted(FETCH_SMS_STATUSES):
      return { ...state, smsStatusesFetchStatus: STARTED, errors: null };
    case getFailed(FETCH_SMS_STATUSES): 
      return { ...state, smsStatusesFetchStatus: FAILED, errors: getError(payload) };
    case getSucceeded(FETCH_SMS_STATUSES): 
      return { ...state, smsList: payload, smsStatusesFetchStatus: SUCCEEDED, errors: null };
    default:
      return state;
  }
};

export default events;
