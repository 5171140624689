import { useEffect, useRef } from 'react';

const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const useFirstRender = () => {
  const ref = useRef(true);
  const firstRender = ref.current;
  
  ref.current = false;
  
  return firstRender;
}

export default usePrevious;