import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import SuccessAlert from '../../../components/MemberHome/SingleMessage/SuccessAlert';
import CombinedForm from '../../../components/MemberHome/SingleMessage/CombinedForm';
import { fetchClient } from '../../../store/actions/creators/clientActions';
import { clientSelector } from "../../../store/selectors/clientSelector";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

const SingleMessage = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  
  const localStorageUser = JSON.parse(localStorage.getItem('authUserData'));
  const sessionStorageUser = JSON.parse(sessionStorage.getItem('authUserData'));

  const storageUser = localStorageUser || sessionStorageUser;
  const client = useSelector(clientSelector);

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
 
  useEffect(() => {
    dispatch(fetchClient(storageUser.accountId));
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card card-body">
            <div className="d-flex justify-content-between mb-4">
              <h4 className="card-title">
                {t('MEMBER.SMS.SEND_SINGLE_MESSAGE')}
              </h4>
            </div>
            {showSuccessAlert && <SuccessAlert setShowSuccessAlert={setShowSuccessAlert}/>}
            <CombinedForm setShowSuccessAlert={setShowSuccessAlert} client={client} redirectPhoneNumber={location.state?.number ?? null}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleMessage;
