import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Spinner from 'react-bootstrap/Spinner';
import {
  countrySelector,
  countryFetchStatusSelector,
} from '../../../store/selectors/countrySelector';
import { STARTED, SUCCEEDED } from '../../../utils/helpers/store-helpers';
import { fetchCountry} from '../../../store/actions/creators/countryActions';
import { getClientDetailsTableRows } from '../../../utils/helpers';

const AdminViewCountry = () => {
  const params = useParams();

  const [t] = useTranslation();

  const dispatch = useDispatch();
  const [country, setCountry] = useState(null);

  const countriesWithList = useSelector(countrySelector);
  const countryFetchStatus = useSelector(countryFetchStatusSelector);

  useEffect(() => {
    if (params.countryId) dispatch(fetchCountry(params.countryId));
  }, [params]);

  useEffect(()=> {
    if(!_.isEmpty(countriesWithList)){
      let temp = countriesWithList;
      temp.providersWithName = _.map(temp.providerList, 'providerName').join(",");
      setCountry(temp);
    }
  },[countriesWithList])

  const rows = useMemo(() => country && getClientDetailsTableRows(t, country), [t, country]);
  
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-body">
          <div className="d-flex justify-content-between mb-4">
            <h4 className="card-title">
              {t('ADMIN.COUNTRY.COUNTRY_DETAILS') +
                ' #' +
                params.countryId}
            </h4> <br/>
            {!country && countryFetchStatus === STARTED && (
              <Spinner animation="border" role="status"/>
            )}
            {country && countryFetchStatus === SUCCEEDED && (
                <div className="card-body">
                <table className="table user-view-table m-0">
                    <tbody>
                     {rows.map((el,index) => 
                        <tr key={index}>
                            <td>{el.title}</td>
                            <td>{el.value}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
                </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminViewCountry;
