import { createActionThunk } from 'redux-thunk-actions';

import {
  FETCH_TEMPLATES,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  REMOVE_TEMPLATE,
} from '../types/templateTypes';
import TemplateService from '../../../services/templates';

export const fetchTemplates = createActionThunk(FETCH_TEMPLATES, () =>
    TemplateService.fetchTemplates().then(res => res)
  , false
);

export const createTemplate = createActionThunk(CREATE_TEMPLATE, (data) =>
    TemplateService.createTemplate(data).then((data) => ({ data }))
  , false
);

export const updateTemplate = createActionThunk(UPDATE_TEMPLATE, (id, data) =>
    TemplateService.updateTemplate(id, data).then((data) => ({ data }))
  , false
);

export const removeTemplate = createActionThunk(REMOVE_TEMPLATE, (id) =>
    TemplateService.removeTemplate(id).then((data) => ({ data }))
  , false
);
