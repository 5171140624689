import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import moment from "moment";
import ItemsList from "../../ItemsList";
import { getGroupListColumns } from "../../../utils/helpers";

const GroupsList = ({ groups, handleShowModal, removeGroup }) => {
  const [t] = useTranslation();
  const localStorageUser = JSON.parse(localStorage.getItem("authUserData"));
  const sessionStorageUser = JSON.parse(sessionStorage.getItem("authUserData"));

  const storageUser = localStorageUser || sessionStorageUser;

  const columns = useMemo(() => getGroupListColumns(t), [t]);
  const searchColumns = useMemo(() => ["name"], []);

  const handleExport = (data, type) => {
    const modify = data.map((value) => {
      let temp = { ...value };
      delete temp.id;
      delete temp.contacts;
      return {
        ...temp,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(modify);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet");
    XLSX.writeFile(
      workbook,
      `SMSREFLEX_groups_${storageUser.accountId}_${moment(new Date()).format(
        "DDMMYYYY"
      )}.${type}`
    );
  };

  const actions = (item, action) =>
    ({
      edit: true,
      view: true,
      delete: true,
    }[action]);

  return (
    <ItemsList
      items={groups}
      columns={columns}
      searchColumns={searchColumns}
      actions={actions}
      handleShowModal={handleShowModal}
      removeItem={removeGroup}
      defaultSorting={"createdAt"}
      viewUrl="/member/contacts/group"
      exportData={true}
      handleExport={handleExport}
    />
  );
};

export default GroupsList;
