import React from 'react';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const FormNavigation = ({ activeTab, setActiveTab }) => {
  const [t] = useTranslation();
  
  return (
    <Nav
      variant="tabs"
      defaultActiveKey="simpleMessage"
      activeKey={activeTab}
      className="mb-2"
      onSelect={(eventKey) => setActiveTab(eventKey)}
    >
      <Nav.Item>
        <Nav.Link eventKey="simpleMessage">
          {t('MEMBER.SMS.SIMPLE_MESSAGE')}
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="messageToContacts">
          {t('MEMBER.SMS.MESSAGE_TO_SEVERAL_CONTACTS')}
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="messageToGroups">
          {t('MEMBER.SMS.MESSAGE_TO_GROUP')}
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default FormNavigation;