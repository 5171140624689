import {
  FETCH_TEMPLATES,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  REMOVE_TEMPLATE,
} from '../actions/types/templateTypes';
import { getError } from '../../utils/common/helper';
import { FAILED, getFailed, getStarted, getSucceeded, STARTED, SUCCEEDED } from '../../utils/helpers/store-helpers';

// Initial state
const initialState = {
  errors: null,
  templates: [],
  templateFetchStatus: '',
  templateCreateStatus: '',
  templateUpdateStatus: '',
  templateRemoveStatus: '',
};

// Template reducer
const events = (state = initialState, { type, payload }) => {
    switch (type) {
      case getStarted(FETCH_TEMPLATES):
        return { ...state, templateFetchStatus: STARTED };
      case getFailed(FETCH_TEMPLATES):
        return { ...state, templateFetchStatus: FAILED };
      case getSucceeded(FETCH_TEMPLATES):
        return { ...state, templates: payload, templateFetchStatus: SUCCEEDED };
      case getStarted(CREATE_TEMPLATE):
        return { ...state, templateCreateStatus: STARTED, errors: null };
      case getFailed(CREATE_TEMPLATE):
        return { ...state, templateCreateStatus: FAILED, errors: getError(payload) };
      case getSucceeded(CREATE_TEMPLATE):
        return {
          ...state,
          templates: state.templates.concat([payload.data]),
          templateCreateStatus: SUCCEEDED,
          errors: null,
        };
      case getStarted(UPDATE_TEMPLATE):
        return { ...state, templateUpdateStatus: STARTED, errors: null };
      case getFailed(UPDATE_TEMPLATE):
        return { ...state, templateUpdateStatus: FAILED, errors: getError(payload) };
      case getSucceeded(UPDATE_TEMPLATE):
        return {
          ...state,
          templates: state.templates.map((template) =>
            template.id === payload.data.id ? payload.data : template
          ),
          templateUpdateStatus: SUCCEEDED,
          errors: null,
        };
      case getStarted(REMOVE_TEMPLATE):
        return { ...state, templateRemoveStatus: STARTED, errors: null };
      case getFailed(REMOVE_TEMPLATE):
        return { ...state, templateRemoveStatus: FAILED, errors: getError(payload) };
      case getSucceeded(REMOVE_TEMPLATE):
        return {
          ...state,
          templates: state.templates.filter(
            (template) => template.id !== payload.data.id
          ),
          templateRemoveStatus: SUCCEEDED,
          errors: null,
        };
      default:
        return state;
    }
  }
;

export default events;
