/**
 * Service for authorization and authentication
 */
import useApi from '../utils/api';

class AuthService {
  #prefix = '/auth';

  login = (login, password, rememberMe) => {
    return useApi.post(`${this.#prefix}/login`, {
      login,
      password,
      rememberMe,
    });
  };

  loginAsUser = (id) => {
    return useApi.post(`${this.#prefix}/loginAsUser`, {
      id,
    });
  };

  forgotPassword = (email) => {
    return useApi.post(`${this.#prefix}/forgotPassword`, {
      email,
    });
  };

  resetPassword = (data) => {
    return useApi.post(`${this.#prefix}/resetPassword`, data);
  };

  register = (data) => {
    return useApi.post(`${this.#prefix}/register`, data);
  };

  sendVerificationCode = (data) => {
    return useApi.post(`${this.#prefix}/sendVerificationCode`, data);
  };

  verifyPhoneNumber = (data) => {
    return useApi.post(`${this.#prefix}/verifyPhoneNumber`, data);
  };

  logout = () => {
    return Promise.resolve();
  };

  updateProfile = (data) => {
    return useApi.post(`${this.#prefix}/updateProfile`, data);
  }
}

export default new AuthService();
