import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import InfoColumn from './InfoColumn';
import { mdiAccountGroup, mdiAccountPlus, mdiChartAreaspline } from '@mdi/js';
import { last7DaysSMSSelector, totalSMSSelector } from '../../../../store/selectors/dashboardSelector';
import moment from 'moment';

const GeneralInfo = ({ todayAllSMS, last30DaysSMS, totalUsers, totalContacts }) => {
  const [t] = useTranslation();

  const last7DaysSMS = useSelector(last7DaysSMSSelector);
  const totalSMS = useSelector(totalSMSSelector);

  const last7AllSMS = useMemo(() => Object.values(last7DaysSMS).reduce(
    (acc, arr) => acc + arr.length, 0
  ), [last7DaysSMS]);

  const last30AllSMS = useMemo(() => Object.values(last30DaysSMS).reduce(
    (acc, arr) => acc + arr.length, 0
  ), [last30DaysSMS]);

  const totalSMSCount = useMemo(() => Object.values(totalSMS).reduce(
    (acc, item) => acc + item, 0
  ), [totalSMS]);
 
  return (
    <div className="row">
      <div className="col-sm-12 grid-margin">
        <div className="card card-body">
          <div className="row no-gutters">
            <InfoColumn
              text={t('DASHBOARD.TOTAL_USERS')}
              count={totalUsers}
              className="p-2 border-md-right border-bottom border-xl-bottom-0"
              iconPath={mdiAccountPlus}
              iconColor="#0c2a6f"
              horizontal
              redirect={`/admin/clients`}
            />
            <InfoColumn
              text={t('DASHBOARD.TOTAL_CONTACTS')}
              count={totalContacts}
              className="p-2 border-lg-right border-bottom border-xl-bottom-0"
              iconPath={mdiAccountGroup}
              iconColor="#0c2a6f"
              horizontal
              redirect={`/admin/contacts`}
            />
            <InfoColumn
              text={t('DASHBOARD.SMS_SENT_TODAY')}
              count={todayAllSMS}
              className="p-2 border-md-right border-lg-right-0 border-xl-right border-bottom border-xl-bottom-0"
              iconPath={mdiChartAreaspline}
              iconColor="#0c2a6f"
              redirect={`/admin/reports?startDate=${moment().format('YYYY/MM/DD')}&endDate=${moment().format('YYYY/MM/DD')}`}
            />
            <InfoColumn
              text={t('DASHBOARD.SMS_SENT_LAST_7_DAYS')}
              count={last7AllSMS}
              className="p-2 border-lg-right border-bottom border-lg-bottom-0"
              iconPath={mdiChartAreaspline}
              iconColor="#0c2a6f"
              redirect={`/admin/reports?startDate=${moment().subtract(7, 'days').format('YYYY/MM/DD')}&endDate=${moment().format('YYYY/MM/DD')}`}
            />
            <InfoColumn
              text={t('DASHBOARD.SMS_SENT_LAST_30_DAYS')}
              count={last30AllSMS}
              className="p-2 border-md-right border-bottom border-md-bottom-0"
              iconPath={mdiChartAreaspline}
              iconColor="#0c2a6f"
              redirect={`/admin/reports?startDate=${moment().subtract(30, 'days').format('YYYY/MM/DD')}&endDate=${moment().subtract(1, 'days').format('YYYY/MM/DD')}`}
            />
            <InfoColumn
              text={t('DASHBOARD.ALL_SMS')}
              count={totalSMSCount}
              className="p-2"
              iconPath={mdiChartAreaspline}
              iconColor="#0c2a6f"
              redirect={`/admin/reports?startDate=${moment().subtract(180, 'days').format('YYYY/MM/DD')}&endDate=${moment().subtract(1, 'days').format('YYYY/MM/DD')}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfo;