import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Icon from "@mdi/react";
import { mdiArrowDown, mdiArrowUp } from "@mdi/js";
import { getUserListColumns, sortFunc } from "../../../../utils/helpers";

const Table = ({
  page,
  sortBy,
  perPage,
  sortOrder,
  handleShowModal,
  handleChangeSort,
  filteredItems,
  handleShowConfirmModal,
  handleLoginAs,
  isIcon,
}) => {
  const [t] = useTranslation();

  const columns = useMemo(() => getUserListColumns(t), [t]);

  return (
    <div className="row">
      <div className="col-sm-12 mb-2">
        <table className="table table-bordered table-striped table-hover">
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.accessor} className="justify-content-between">
                  {column.Header}{" "}
                  <Link
                    to="#"
                    onClick={() => handleChangeSort(column.accessor)}
                  >
                    {sortBy === column.accessor ? (
                      sortOrder === "desc" ? (
                        <Icon path={mdiArrowDown} size={0.6} color="#4B49AC" />
                      ) : (
                        <Icon path={mdiArrowUp} size={0.6} color="#4B49AC" />
                      )
                    ) : (
                      <Icon path={mdiArrowDown} size={0.6} color="#CCC" />
                    )}
                  </Link>
                </th>
              ))}
              <th>{t("ADMIN.CLIENTS.ACTIONS")}</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems
              .sort(sortFunc(sortBy, sortOrder))
              .slice((page - 1) * perPage, page * perPage)
              .map((row) => (
                <tr key={row.id}>
                  {columns.map((column) => (
                    <td key={`${row.id}-${column.accessor}`}>
                      {column.accessor == "lastLogin" && row[column.accessor]
                        ? moment(row[column.accessor]).format(
                            "DD/MM/YYYY HH:mm"
                          )
                        : row[column.accessor]
                        ? row[column.accessor]
                        : "-"}
                    </td>
                  ))}
                  <td>
                    <Link
                      to="#"
                      className="mr-1"
                      onClick={(e) => {
                        e.preventDefault();
                        handleShowModal(row.id);
                      }}
                    >
                      <i className="feather-edit btn-icon-prepend"></i>
                    </Link>
                    <Link
                      to="#"
                      className="mr-1"
                      onClick={(e) => {
                        e.preventDefault();
                        handleShowConfirmModal(row.id);
                      }}
                    >
                      <i className="feather-trash-2 btn-icon-prepend"></i>
                    </Link>
                    {!isIcon && (
                      <Link
                        to="#"
                        className="mr-1"
                        onClick={(e) => {
                          e.preventDefault();
                          handleLoginAs(row.id);
                        }}
                      >
                        <i className="feather-log-in btn-icon-prepend"></i>
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
