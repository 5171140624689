import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import moment from "moment";

import ItemsList from '../../ItemsList';
import { getContactListColumns } from '../../../utils/helpers';

const ContactsList = ({
  contacts,
  removeContact,
  handleShowModal,
  removeManyContacts,
  sendMessageToContacts,
  addToGroup
}) => {
  const [t] = useTranslation();

  const localStorageUser = JSON.parse(localStorage.getItem('authUserData'));
  const sessionStorageUser = JSON.parse(sessionStorage.getItem('authUserData'));

  const storageUser = localStorageUser || sessionStorageUser;

  const columns = useMemo(() => getContactListColumns(t), [t]);
  
  const actions = (item, action) =>
    ({
      edit: true,
      delete: true,
      sendSms: true
    }[action]);
  
  const actionsWithSelected = [
    {
      name: 'Send message to selected contacts',
      func: (selectedContacts) => {
        sendMessageToContacts(
          contacts.filter((contact) =>
            selectedContacts.includes(contact.id)
          )
        );
      },
    },
    {
      name: 'Delete selected contacts',
      func: (selectedContacts) => {
        removeManyContacts(selectedContacts);
      },
    },
    {
      name: 'Add to Group',
      func: (selectedContacts) => {
        addToGroup(selectedContacts);
      },
    },
  ];
  
  const contactsList = contacts.map((contact) => ({
    ...contact,
    groups: contact.groups?.map(({ name }) => name).join(', '),
  }));


  const handleExport = (data, type) => {
    if(!Array.isArray(data)){
      return false;
    }
    const modify = data.map((value) => {
      //copy object
      let temp={...value}
      delete temp.id;
      return {
        ...temp,
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(modify);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet");
    XLSX.writeFile(
      workbook,
      `SMSREFLEX_contacts_${storageUser.accountId}_${moment(new Date()).format("DDMMYYYY")}.${type}`
    );
  };
  
  return (
    <>
      <ItemsList
        columns={columns}
        actions={actions}
        searchColumns={[]}
        isSelectable={true}
        items={contactsList}
        removeItem={removeContact}
        handleShowModal={handleShowModal}
        actionsWithSelected={actionsWithSelected}
        defaultSorting={'createdAt'}
        exportData={true}
        handleExport={handleExport}
        downloadContacts={true}
      />
    </>
  );
};

export default ContactsList;
