import React from "react";

import moment from "moment";

import { useTranslation } from "react-i18next";
import {
  Title,
  Legend,
  Tooltip,
  BarElement,
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
} from "chart.js";
import { Bar } from "react-chartjs-2";

const Last7DaysSMS = ({ last7DaysSMS, smsType, smsTimeRange, navigateToReport }) => {
  const [t] = useTranslation();
  const DAYS_COUNT = 7;

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const chartOptions = {
    responsive: true,
    scales: {
      x: { display: false },
      y: { display: false },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const labels = [];
  const today = moment();
  for (let i = 0; i < DAYS_COUNT; ++i) {
    labels.unshift(
      today.format("MMMM") +
        " " +
        today.get("date") +
        ", " +
        today.format("dddd")
    );
    today.subtract(1, "days");
  }

  const data = new Array(DAYS_COUNT).fill(0);
  const todayAsDayOfYear = moment().dayOfYear();
  
  if (last7DaysSMS) {
    for (let sms of last7DaysSMS) {
      let dayOfYear = moment(sms.sendDt).dayOfYear();
      let idx = DAYS_COUNT - (todayAsDayOfYear - dayOfYear) - 1;
      data[idx]++;
    }
  }

  const chartData = {
    labels,
    datasets: [
      {
        label: t("DASHBOARD." + smsType.toUpperCase()),
        data,
        fill: true,
        borderRadius: Number.MAX_SAFE_INTEGER,
        borderColor: "#a6a9b7",
        backgroundColor: "rgba(43,203,186,0.85)",
      },
    ],
  };
  return (
    <>
      <div className="d-flex mb-1">
        <p className="mb-0 font-regular text-muted font-weight-bold">
          {t("DASHBOARD.SMS_" + smsType.toUpperCase() + "_LAST_7_DAYS")}
        </p>
        <a
          className="mb-0 ml-auto font-weight-bold"
          onClick={() => navigateToReport(smsTimeRange, smsType)}
        >
          <i className="ti ti-more-alt"></i>{" "}
        </a>
      </div>
      <div className="d-block d-sm-flex align-items-center">
        <div className="chart-wrapper-two">
          <Bar options={chartOptions} data={chartData} />
        </div>
        <div className="statistics mt-3 mt-sm-0 ml-sm-auto text-center text-sm-right">
          <h3 className="mb-0">
            <i className="icon-arrow-up-circle"></i>{" "}
            {data.reduce((val, acc) => acc + val, 0)}
          </h3>
          <p></p>
        </div>
      </div>
    </>
  );
};

export default Last7DaysSMS;
