import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Spinner from "react-bootstrap/Spinner";
import {
  clientSelector,
  clientErrorsSelector,
  clientFetchStatusSelector,
} from "../../../store/selectors/clientSelector";
import { STARTED, SUCCEEDED } from "../../../utils/helpers/store-helpers";
import { fetchClient } from "../../../store/actions/creators/clientActions";

import ClientTable from "../../../components/AdminHome/Client/ClientTable";
import AdminTable from "../../../components/AdminHome/Client/AdminTable";
import AdminSubaccounts from "../Subaccounts";
import AdminUsers from "../Users";

const AdminViewClient = () => {
  const [t] = useTranslation();

  const params = useParams();

  const dispatch = useDispatch();

  const client = useSelector(clientSelector);
  const clientFetchStatus = useSelector(clientFetchStatusSelector);

  useEffect(() => {
    if (params.clientId) dispatch(fetchClient(params.clientId));
  }, [params]);

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-body">
          <h4 className="card-title">
            {t("ADMIN.CLIENTS.BASIC_DATA_CLIENT") + " #" + params.clientId}
          </h4>
          {!client && clientFetchStatus === STARTED && (
            <Spinner animation="border" role="status" />
          )}
          {client && clientFetchStatus === SUCCEEDED && (
            <>
              <div className="row justify-content-center">
                <div className="col-sm-5 m-3 round-border">
                  <ClientTable client={client} />
                </div>
                <div className="col-sm-5 m-3 round-border">
                  <AdminTable client={client} />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <AdminUsers client={client}/>
                  {/* <Tabs defaultActiveKey="direct_user" className="mb-3">
                    <Tab eventKey="direct_user" title={t('ADMIN.CLIENTS.USERS')}>
                      <AdminUsers />
                    </Tab>
                    <Tab eventKey="sub_accounts" title={t('ADMIN.CLIENTS.SUBACCOUNTS')}>
                      <AdminSubaccounts />
                    </Tab>
                  </Tabs> */}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminViewClient;
