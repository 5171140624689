import React from "react";
import { useTranslation } from "react-i18next"

import moment from "moment";

import {
  Title,
  Legend,
  Tooltip,
  BarElement,
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { CHARTS_JS_COLORS as colors }  from "../../../utils/common/constants";

const YesterdaySMS = ({ yesterdaySMS, smsType, smsTimeRange, navigateToReport }) => {
  const [t] = useTranslation();

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const chartOptions = {
    responsive: true,
    scales: {
      x: { display: false },
      y: { display: false },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const labels = [
    "01am-03am",
    "04am-06am",
    "07am-09am",
    "10am-12pm",
    "01pm-03pm",
    "04pm-06pm",
    "07pm-09pm",
    "10pm-12pm",
  ];

  const data = new Array(labels.length).fill(0);

  if (yesterdaySMS) {
   
    for (let sms of yesterdaySMS) {
      let hour = moment(sms.sendDt).hour();
      let idx = Math.floor(hour / 3);
      data[idx]++;
    }
  }

  const chartData = {
    labels,
    datasets: [
      {
        label: t("DASHBOARD." + smsType.toUpperCase()),
        data: data,
        fill: true,
        borderRadius: Number.MAX_SAFE_INTEGER,
        ...colors[smsType],
      },
    ],
  };

  return (
    <>
      <div className="d-flex mb-1">
        <p className="mb-0 font-regular text-muted font-weight-bold">
          {t("DASHBOARD.SMS_" + smsType.toUpperCase() + "_YESTERDAY")}
        </p>
        <a
          className="mb-0 ml-auto font-weight-bold"
          onClick={() => navigateToReport(smsTimeRange, smsType)}
        >
          <i className="ti ti-more-alt"></i>{" "}
        </a>
      </div>
      <div className="d-block d-sm-flex align-items-center">
        <div className="chart-wrapper-two">
          <Bar options={chartOptions} data={chartData} />
        </div>
        <div className="statistics mt-3 mt-sm-0 ml-sm-auto text-center text-sm-right">
          <h3 className="mb-0">
            <i className="icon-arrow-up-circle"></i>{" "}
            {data.reduce((val, acc) => acc + val, 0)}
          </h3>
          <p></p>
        </div>
      </div>
    </>
  );
};

export default YesterdaySMS;
