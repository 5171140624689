import { useTranslation } from 'react-i18next';
import moment from 'moment';

const CampaignInfo = ({lastCampaigns, futureCampaigns}) => {
  const [t] = useTranslation();
  
  return (
    <div className="row">
      <div className="col-md-4">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">
              {t('DASHBOARD.LAST_10_CAMPAIGNS')}
            </h4>
            <ul>
              {lastCampaigns && lastCampaigns.slice(0, 10).map((campaign) => (
                <li key={campaign.id}>
                  <a href={`/admin/campaign/${campaign.campaignId}`}>{campaign.name}</a>
                  {'  (' + moment(campaign.creationDate).format('DD/MM/YYYY HH:mm') + ')'}
                </li>
              ))}
            </ul>
            {/* {lastCampaigns && lastCampaigns.length == 0 &&
              <p>
                {t('DASHBOARD.NO_PAST_CAMPAIGN')} <br/>
                <a href="/member/new_campaign">{t('DASHBOARD.CREATE_NEW_CAMPAIGN')}</a>
              </p> 
            } */}
          </div>
        </div>
      </div>

      <div className="col-md-4">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">
              {t('DASHBOARD.FUTURE_10_CAMPAIGNS')}
            </h4>
            <ul>
              {futureCampaigns && futureCampaigns.slice(0, 10).map((campaign) => (
                <li key={campaign.id}>
                  <a href={`/admin/campaign/${campaign.campaignId}`}>{campaign.name}</a>
                  {'  (' + moment(campaign.creationDate).format('DD/MM/YYYY HH:mm') + ')'}
                </li>
              ))}
            </ul>
            {/* {futureCampaigns && futureCampaigns.length == 0 && 
              <p>
                {t('DASHBOARD.NO_SCHEDULED_CAMPAIGN')} <br/>
                <a href="/member/new_campaign">{t('DASHBOARD.CREATE_NEW_CAMPAIGN')}</a>
              </p>
            } */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignInfo;