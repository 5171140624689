import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  createPayment,
  fetchProducts,
} from "src/store/actions/creators/PaymentAction";
import { errorMessageToast } from "src/utils/common/helper";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { t } from "i18next";
import CreatableSelect from "react-select/creatable";

const PayButton = ({ Items, credits }) => {
  const dispatch = useDispatch();
  const [localCredits, setLocalCredits] = useState(0);
  const [productList, setProductList] = useState();
  const [creditValue, setCreditValue] = useState({});
  const [show, setShow] = useState(false);
  const [getResponse, setGetResponse] = useState("");
  const [error, setError] = useState("");

  const fetchProductList = async () => {
    try {
      const products = await dispatch(fetchProducts());
      setProductList(products);
    } catch (error) {
    }
  };
  useEffect(() => {
    fetchProductList();
  }, []);

  const getPrice = (id) => {
    const price=productList?.productList.prices.find((price) => id === price.product) 
    return {
     priceId:price.id,
     price: price.unit_amount_decimal / 100 ,
     currency:price.currency
    }

  };

  const productArray = productList?.productList.products.map((product) => {
    return {
      label: product.name,
      value: getPrice(product.id),
      credits: product.metadata.credit,
    };
  });

  const modalClose = () => {
    setShow(false), setError("");
  };
  const modalShow = () => setShow(true);

  const onChangeEvent = async (newValue) => {
    setLocalCredits(newValue);
    setCreditValue({
      credits: newValue.credits,
      productName: newValue.label,
      creditPrice: newValue.value.price,
      currency:newValue.value.currency,
      priceId:newValue.value.priceId
    });
    setError("");
  };

  const searchProductIndex = productArray?.findIndex(
    (product) => product.label === creditValue.productName
  );

  const data = {
    Items,
    credits,
    creditValue,
  };
  const makePayment = async () => {
    try {
      if (!creditValue.creditPrice) {
        setError(t("COMMON.FIELD_IS_REQUIRED"));
        return false;
      }
      if (creditValue.creditPrice === "0" || creditValue.creditPrice <= "-1") {
        setError(t("COMMON.INVALID_VALUE"));
        return false;
      }
      if (searchProductIndex === -1) {
        setError(t("COMMON.INVALID_VALUE"));
        return false;
      }
      const response = await dispatch(createPayment(data));
      setGetResponse(response.session.id);
    } catch (error) {
      errorMessageToast(error.message);
    }
  };

  const checkoutSession = async () => {
    const stripe = await loadStripe(
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    );
    const result = stripe.redirectToCheckout({
      sessionId: getResponse,
    });
    // if (result.error) {
    //   console.log(result.error);
    // }
  };
  useEffect(() => {
    if (getResponse) {
      checkoutSession();
    }
  }, [getResponse]);

  return (
    <>
      <Button variant="outline-primary" onClick={modalShow}>
        {t("COMMON.ADD_CREDITS")}
      </Button>
      <Modal show={show} onHide={modalClose}>
        <Modal.Header>
          <Modal.Title> {t("COMMON.ADD_CREDITS")}</Modal.Title>
        </Modal.Header>

        <Form className="px-4 pb-2">
          <Form.Group controlId="formName">
            <Form.Label className="py-2">{t("COMMON.CREDITS")}</Form.Label>
            <CreatableSelect
              options={productArray}
              name="credits"
              value={localCredits}
              onChange={onChangeEvent}
              formatCreateLabel={(userInput) => userInput}
              createOptionPosition="first"
            />
            {/* <Form.Control
              type="number"
              onChange={onChangeEvent}
              placeholder={t("COMMON.ENTER_CREDITS")}
              required
            /> */}
          </Form.Group>
        </Form>
        {error && <p className="text-danger pl-4">{error}</p>}
        <Modal.Footer>
          <Button variant="outline-primary" onClick={modalClose}>
            {t("COMMON.CLOSE")}
          </Button>
          <Button variant="outline-primary" onClick={makePayment}>
            {t("COMMON.PAY")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PayButton;
