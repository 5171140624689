import React from "react";

const Tooltip = ({ originalTitle }) => {
  return (
    <span
      className="badge badge-pill badge-primary ml-1"
      data-toggle="tooltip"
      data-original-title={originalTitle}
    >
      ?
    </span>
  );
};

export default Tooltip;
