import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import FormRow from 'src/components/Shared/Form/FormRow';
import MultiSelect from './MultiSelect';
import { fetchAllProviders } from 'src/store/actions/creators/providerAction';
import { providersSelector } from 'src/store/selectors/providerSelector';

const ModalBody = ({ inputs, setInputs, initialState, country, submitErrors }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  //const providers = useSelector(providersSelector);

  const [errors, setErrors] = useState({});
  const [formInitialized, setFormInitialized] = useState(false);

  useEffect(() => {
    setErrors(submitErrors)
  },[submitErrors])

  // useEffect(() => {
  //   dispatch(fetchAllProviders());
  // }, []);

  const handleChange = ({ target: { name, value } }) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const rows = [
    [
      { title: t('ADMIN.COUNTRY.COUNTRY_NAME'), name: 'countryName', required: true },
      { title: t('ADMIN.COUNTRY.SHORT_NAME'), name: 'shortName', required: true }
    ],
    [
      { title: t('ADMIN.COUNTRY.PURCHASE_PRICE'), name: 'purchasePrice', required: true },
      { title: t('ADMIN.COUNTRY.MINIMUM_PRICE'), name: 'minimumPrice', required: true }
    ]
  ];

  useEffect(() => {
    if (formInitialized) return;
  
      if (_.isEmpty(country)) {
        setInputs(initialState);
      } else {

        setInputs({
          id: country.id,
          countryName: country.countryName,
          shortName: country.shortName,
          phoneCodes: country.phoneCodes
            ? country.phoneCodes.split(',').map((from) => ({
              label: from,
              value: from,
            }))
            : '',
          // providers: country.providerList
          //   ? country.providerList.map((p) => ({ label: p.providerName, value: p.id }))
          //   : '',
          purchasePrice: country.purchasePrice,
          minimumPrice:country.minimumPrice,
        });
      }
      setFormInitialized(true);
    
  }, [formInitialized]);
  
  return (
    <Modal.Body>
      {rows.map((row, idx) => <FormRow key={idx} id={idx} row={row} inputs={inputs} handleChange={handleChange} errors={errors}/>)}
      <MultiSelect inputs={inputs} setInputs={setInputs}/>
    </Modal.Body>
  );
};

export default ModalBody;