import React from 'react';
import { Link } from 'react-router-dom';

import Icon from '@mdi/react';
import ActionsCell from '../ActionsCell';
import { sortFunc } from '../../../utils/helpers';
import { mdiArrowDown, mdiArrowUp } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const Table = ({
  hideIcon,
  page,
  sortBy,
  columns,
  perPage,
  actions,
  viewUrl,
  editRedirectUrl,
  sortOrder,
  selectAll,
  isSelectable,
  filteredItems,
  selectedItems,
  handleShowModal,
  handleChangeSort,
  handleShowConfirmModal,
  handleSelectedItemsChange,
  uniqueId,
  actionViewStyle
}) => {
  const [t] = useTranslation();
 
  return (
    <div className="row">
      <div className="col-sm-12 mb-2 overflow-auto">
        <table className="table table-bordered table-striped table-hover">
          <thead>
          <tr>
            {isSelectable && (
              <th>
                <input type="checkbox" name="selectAll" checked={selectAll} onChange={handleSelectedItemsChange}/>
              </th>
            )}
            {columns.map((column) => (
              <th key={column.accessor}>
                {column.Header}{' '}
                <Link to="#" onClick={() => handleChangeSort(column.accessor)}>
                  {sortBy === column.accessor
                    ? sortOrder === 'desc'
                      ? <Icon path={mdiArrowDown} size={0.6} color="#4B49AC"/>
                      : <Icon path={mdiArrowUp} size={0.6} color="#4B49AC"/>
                    : <Icon path={mdiArrowDown} size={0.6} color="#CCC"/>
                  }
                </Link>
              </th>
            ))}
            { (actions(1, "edit") || actions(1, "editRedirect") || actions(1, "statistics")  
  || actions(1, "view") || actions(1, "delete") ||actions(1, "paymentLink")) && <th>{t('ITEMSLIST.ACTIONS')}</th> }
          </tr>
          </thead>
          <tbody>
          {filteredItems
            .sort(sortFunc(sortBy, sortOrder))
            .slice((page - 1) * perPage, page * perPage)
            .map((row ,index) => (
              <tr key={index}>
                {isSelectable && (
                  <td>
                    <input
                      type="checkbox"
                      name="selectedItems"
                      value={JSON.stringify(row)}
                      checked={selectedItems.some((current)=>row.id===current.id)}
                      onChange={handleSelectedItemsChange}
                    />
                  </td>
                )}
                {columns.map((column) => {
                  return (
                    <td key={`${row.id}-${column.accessor}`}>                     
                      {(column.accessor === 'sendDt'||column.accessor === 'creationDate' ||column.accessor === 'createdAt') && moment(row[column.accessor]).isValid() === true?  moment(row[column.accessor]).format('DD/MM/YYYY HH:mm'): row[column.accessor]}
                    </td>
                  );
                })}
                <ActionsCell
                  hideIcon={hideIcon}
                  actions={actions}
                  handleShowModal={handleShowModal}
                  handleShowConfirmModal={handleShowConfirmModal}
                  row={row}
                  viewUrl={viewUrl}
                  editRedirectUrl={editRedirectUrl}
                  uniqueId={uniqueId}
                  actionViewStyle={actionViewStyle}
                />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;