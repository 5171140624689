import React from "react";

import Steps from "../../components/Register/Steps";
import FormHeaderLink from '../../components/Shared/Form/FormHeaderLink';
import { MainContainer, FullPageContainer } from "../../components/Container";

import "../../assets/skydash/css/vertical-layout-light/style.css";
import HomeHeader from "../../components/HomeHeader";

const Register = () => {
  return (
    <MainContainer>
      <HomeHeader />
      <FullPageContainer>
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0 mt-5">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4">
                <FormHeaderLink />
                <Steps />
              </div>
            </div>
          </div>
        </div>
      </FullPageContainer>
    </MainContainer>
  );
};

export default Register;
