import React, { useEffect } from "react";

import jQuery from "jquery";

import template from "../../../assets/skydash/js/template";
import offCanvas from "../../../assets/skydash/js/off-canvas";
import { Menu, MenuNavItem, SubMenuNavItem } from "../../SideMenu";
import hoverableCollapse from "../../../assets/skydash/js/hoverable-collapse";
import { UserRoles } from "../../../utils/constants";

const MemberSideBar = (props) => {
  useEffect(() => {
    hoverableCollapse(jQuery);
    template(jQuery);
    offCanvas(jQuery);
  }, []);
  const localStorageUser = JSON.parse(localStorage.getItem("authUserData"));
  const sessionStorageUser = JSON.parse(sessionStorage.getItem("authUserData"));

  const storageUser = localStorageUser || sessionStorageUser;

  return (
    <Menu>
      {(storageUser.role === UserRoles.Marketing ||
        storageUser.role === UserRoles.Client ||
        storageUser.role === UserRoles.Developer) && (
        <MenuNavItem name="dashboard" iClassName="feather-grid" {...props}>
          <SubMenuNavItem
            href="/member/dashboard"
            defaultHref="/member"
            iClassName="feather-grid"
            {...props}
          />
          <SubMenuNavItem
            href="/member/reports"
            iClassName="icon-paper-stack"
            {...props}
          />
          {storageUser.role !== UserRoles.Marketing && (
            <SubMenuNavItem
              href="/member/statuses"
              iClassName="icon-bar-graph"
              {...props}
            />
          )}
        </MenuNavItem>
      )}
      {(storageUser.role === UserRoles.Client ||
        storageUser.role === UserRoles.Developer ||
        storageUser.role === UserRoles.Webmaster ||
        storageUser.role === UserRoles.Editor) && (
        <>
          <MenuNavItem name="sms" iClassName="feather-send" {...props}>
            <SubMenuNavItem
              href="/member/singlemessage"
              iClassName="feather-mail"
              {...props}
            />
            {!(
              storageUser.role === UserRoles.Webmaster ||
              storageUser.role === UserRoles.Editor
            ) && (
              <SubMenuNavItem
                href="/member/templates"
                iClassName="feather-layout"
                {...props}
              />
            )}
          </MenuNavItem>
        </>
      )}
      {(storageUser.role === UserRoles.Client ||
        storageUser.role === UserRoles.Developer ||
        storageUser.role === UserRoles.Webmaster ||
        storageUser.role === UserRoles.Editor) && (
        <MenuNavItem name="campaigns" iClassName="feather-calendar" {...props}>
          <SubMenuNavItem
            href="/member/campaign_list"
            iClassName="feather-calendar"
            {...props}
          />
          <SubMenuNavItem
            href="/member/new_campaign"
            iClassName="feather-mail"
            {...props}
          />
        </MenuNavItem>
      )}

      {(storageUser.role === UserRoles.Client ||
        storageUser.role === UserRoles.Developer ||
        storageUser.role === UserRoles.Webmaster ||
        storageUser.role === UserRoles.Editor) && (
        <>
          <MenuNavItem name="contacts" iClassName="feather-users" {...props}>
            <SubMenuNavItem
              href="/member/contacts"
              iClassName="feather-menu"
              {...props}
            />
            <SubMenuNavItem
              href="/member/groups"
              iClassName="feather-users"
              {...props}
            />
           {storageUser.role === UserRoles.Client&& <SubMenuNavItem
              href="/member/stops"
              iClassName="feather-menu"
              {...props}
            />}
            {storageUser.role === UserRoles.Client&& <SubMenuNavItem
              href="/member/blocked"
              iClassName="feather-menu"
              {...props}
            />}
          </MenuNavItem>
        </>
      )}
      {storageUser.role === UserRoles.Client 
        && (
        <SubMenuNavItem
          href="/member/holidays"
          iClassName="feather-settings"
          {...props}
        />
      )}
    </Menu>
  );
};

export default MemberSideBar;
