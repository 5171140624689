import React from "react";
import { useTranslation } from "react-i18next";
import {
  Title,
  Legend,
  Tooltip,
  BarElement,
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { CHARTS_JS_COLORS as colors } from "../../../utils/common/constants";

const Last30DaysSMS = ({ last30DaysSMS, smsType, smsTimeRange, navigateToReport }) => {
  const [t] = useTranslation();

  const DAYS_COUNT = 30;
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const chartOptions = {
    responsive: true,
    scales: {
      x: { display: false },
      y: { display: false },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const labels = [];
  const startOf30Days = moment().subtract(DAYS_COUNT - 1, "days");
  for (let i = 0; i < DAYS_COUNT; i += 3) {
    labels.push(
      startOf30Days.format("MMMM") +
      " " +
      startOf30Days.get("date") +
      "-" +
      startOf30Days.add(2, "days").format("MMMM") +
      " " +
      startOf30Days.get("date")
    );
    startOf30Days.add(1, "days");
  }

  const data = new Array(DAYS_COUNT / 3).fill(0);
  const startOf30DaysAsDayOfYear = moment()
    .subtract(DAYS_COUNT - 1, "days")
    .dayOfYear();

  if (last30DaysSMS) {
    for (let sms of last30DaysSMS) {
      let idx = Math.floor(
        (moment(sms.sendDt).dayOfYear() - startOf30DaysAsDayOfYear) / 3
      );
      data[idx]++;
    }
  }
  data[10] = 0;

  const chartData = {
    labels,
    datasets: [
      {
        label: t("DASHBOARD." + smsType.toUpperCase()),
        data,
        fill: true,
        borderRadius: Number.MAX_SAFE_INTEGER,
        ...colors[smsType],
      },
    ],
  };

  return (
    <>
      <div className="d-flex mb-1">
        <p className="mb-0 font-regular text-muted font-weight-bold">
          {t("DASHBOARD.SMS_" + smsType.toUpperCase() + "_LAST_30_DAYS")}
        </p>
        <a
          className="mb-0 ml-auto font-weight-bold"
          onClick={() => navigateToReport(smsTimeRange, smsType)}
        >
          <i className="ti ti-more-alt"></i>{" "}
        </a>
      </div>
      <div className="d-block d-sm-flex align-items-center">
        <div className="chart-wrapper-two">
          <Bar options={chartOptions} data={chartData} />
        </div>
        <div className="statistics mt-3 mt-sm-0 ml-sm-auto text-center text-sm-right">
          <h3 className="mb-0">
            <i className="icon-arrow-up-circle"></i>{" "}
            {data.reduce((val, acc) => acc + val, 0)}
          </h3>
          <p></p>
        </div>
      </div>
    </>
  );
};

export default Last30DaysSMS;
