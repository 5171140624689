import React from 'react';
import TodaySMS from '../../../../Member/TodaySMS';
import YesterdaySMS from '../../../../Member/YesterdaySMS';
import Last7DaysSMS from '../../../../Member/Last7DaysSMS';
import Last30DaysSMS from '../../../../Member/Last30DaysSMS';

const Failed = ({
  todaySMS,
  smsTimeRange,
  yesterdaySMS,
  last7DaysSMS,
  last30DaysSMS,
  navigateToReport
}) => {
  
  return (
    <div className="col-xl-2 col-lg-4 col-md-6">
      <div className="p-2 h-100 border-lg-right border-bottom border-lg-bottom-0">
        {smsTimeRange === 'today' && (
          <TodaySMS todaySMS={todaySMS.failed} smsType="failed" navigateToReport={navigateToReport} smsTimeRange={smsTimeRange}/>
        )}
        {smsTimeRange === 'yesterday' && (
          <YesterdaySMS yesterdaySMS={yesterdaySMS.failed} smsType="failed" navigateToReport={navigateToReport} smsTimeRange={smsTimeRange}/>
        )}
        {smsTimeRange === 'last7Days' && (
          <Last7DaysSMS last7DaysSMS={last7DaysSMS.failed} smsType="failed" navigateToReport={navigateToReport} smsTimeRange={smsTimeRange}/>
        )}
        {smsTimeRange === 'last30Days' && (
          <Last30DaysSMS last30DaysSMS={last30DaysSMS.failed}smsType="failed" navigateToReport={navigateToReport} smsTimeRange={smsTimeRange}/>
        )}
      </div>
    </div>
  );
};

export default Failed;