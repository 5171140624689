import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PageControl = ({ page, decPage, setPage, filteredItems, perPage, incPage }) => {
  const [t] = useTranslation();
  
  return (
    <div className="col-sm-12 col-md-7">
      <nav aria-label="Page navigation">
        <ul className="pagination justify-content-end">
          {page > 1 && (
            <>
              <li className="page-item">
                <Link className="page-link" to="#" onClick={decPage}>
                  {t('ADMIN.CLIENTS.PREVIOUS')}
                </Link>
              </li>
              <li className="page-item">
                <Link
                  className="page-link"
                  to="#"
                  onClick={() => setPage(page - 1)}
                >
                  {page - 1}
                </Link>
              </li>
            </>
          )}
          <li className="page-item active">
            <Link className="page-link" to="#">
              {page}
            </Link>
          </li>
          {page < filteredItems.length / perPage && (
            <>
              <li className="page-item">
                <Link
                  className="page-link"
                  to="#"
                  onClick={() => setPage(page + 1)}
                >
                  {page + 1}
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#" onClick={incPage}>
                  {t('ADMIN.CLIENTS.NEXT')}
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default PageControl;
