/**
 * Service for Countries
 */
import useApi from '../utils/api';

class HolidayService {
  #prefix = '/holidays';
  createHoliday= (data) => {
    return useApi.post(this.#prefix, data);
  };
  fetchHolidaysByAccount = () => {
    return useApi.get(this.#prefix);
  };

  updateHoliday = (id, data) => {
    return useApi.put(`${this.#prefix}/${id}`, data);
  };

  removeHoliday = (id) => {
    return useApi.delete(`${this.#prefix}/${id}`);
  };

  getHolidaysForValidation = () => {
    return useApi.get(`${this.#prefix}/getHolidaysForValidation`);
  };
}

export default new HolidayService();