import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import _ from 'lodash';

import { Button } from 'react-bootstrap';
import {
  createCampaign,
  fetchCampaigns,
  removeCampaign,
  updateCampaign
} from '../../../store/actions/creators/campaignActions';
import Loading from '../../../components/Shared/Loading';
import CampaignsList from '../../../components/Member/CampaignsList';
import NewCampaignForm from '../../../components/Member/NewCampaignForm';
import { STARTED, SUCCEEDED } from '../../../utils/helpers/store-helpers';
import { fetchGroups } from '../../../store/actions/creators/groupActions';
import { fetchTemplates } from '../../../store/actions/creators/templateActions';
import { groupsFetchStatusSelector } from '../../../store/selectors/groupSelector';
import { templateFetchStatusSelector } from '../../../store/selectors/templateSelector';
import { campaignsSelector, campaignsFetchStatusSelector } from '../../../store/selectors/campaignSelector';
import toast from 'react-hot-toast';
import {getError} from '../../../utils/common/helper';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const Campaigns = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const campaigns = useSelector(campaignsSelector);
  const groupsFetchStatus = useSelector(groupsFetchStatusSelector);
  const campaignsFetchStatus = useSelector(campaignsFetchStatusSelector);
  const templatesFetchStatus = useSelector(templateFetchStatusSelector);

  const [campaign, setCampaign] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(fetchCampaigns());
    dispatch(fetchTemplates());
    dispatch(fetchGroups());
  }, []);

  const handleShowModal = (campaignId) => {
    setShowModal(true);
    setCampaign(campaignId ? campaigns.find(
      (campaign) => campaign.id === campaignId
    ) : {});
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-body">
          <div className="d-flex justify-content-between mb-4">
            <h4 className="card-title">{t('MEMBER.CAMPAIGNS.CAMPAIGNS')}</h4>
            {/* <Button
              variant="primary"
              size="sm"
              className="btn-icon-text"
              onClick={() => handleShowModal()}
            >
              {t('MEMBER.CAMPAIGNS.NEW_CAMPAIGN')}
            </Button> */}
            <Link to="/member/new_campaign" className="btn btn-primary">{t('MEMBER.CAMPAIGNS.NEW_CAMPAIGN')}</Link>
          </div>
          {campaignsFetchStatus === STARTED ? (
            <Loading/>
          ) : campaignsFetchStatus === SUCCEEDED && campaigns.length > 0 ? (  
            <Tabs defaultActiveKey="active_campaigns" className="mb-3">
              <Tab eventKey="active_campaigns" title="Active Campaigns">
                <CampaignsList
                  campaigns={(campaigns.filter(i => i.status == 'Active'))}
                  handleShowModal={handleShowModal}
                  removeCampaign={(campaignId) => dispatch(removeCampaign(campaignId))}
                />
              </Tab>
              <Tab eventKey="sent_campaigns" title="Sent Campaigns">
                <CampaignsList
                  campaigns={(campaigns.filter(i => i.status == 'Sent'))}
                  handleShowModal={handleShowModal}
                  removeCampaign={(campaignId) => dispatch(removeCampaign(campaignId))}
                />
              </Tab>
              <Tab eventKey="deleted_campaigns" title="Deleted Campaigns">
                <CampaignsList
                  campaigns={(campaigns.filter(i => i.status == 'Deleted'))}
                  handleShowModal={handleShowModal}
                />
              </Tab>
            </Tabs>
          ) : (
            t('MEMBER.CAMPAIGNS.CAN\'T_FETCH_CAMPAIGNS')
          )}
        </div>
      </div>
    </div>
  );
};

export default Campaigns;
