import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import {
  MainContainer,
} from "../../components/Container";
import { logout } from "../../store/actions/creators/authActions";

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  useEffect(async ()=> {
    await dispatch(logout());
    navigate("/");
  }, [])

  return (
    <MainContainer>
      <h2>Logout...</h2>
    </MainContainer>
  );
};

export default Logout;
