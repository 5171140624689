import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.css"

const ErrorPage = ({ title }) => {
  const [t] = useTranslation();

  return (
    <div className="error-page-container">
      <div className="error-page-child">
        {t(`${title}`)}
      </div>
    </div>
  )
};


export default ErrorPage;
