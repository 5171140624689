import { createActionThunk } from 'redux-thunk-actions';

import {
  FETCH_CLIENT,
  CREATE_CLIENT,
  UPDATE_CLIENT,
  FETCH_ALL_CLIENTS,
  FETCH_SUBACCOUNTS,
  FETCH_SUBACCOUNT,
  CREATE_SUBACCOUNT,
  UPDATE_SUBACCOUNT,
  REMOVE_SUBACCOUNT,
  FETCH_USERS,
  CREATE_USER,
  UPDATE_USER,
  REMOVE_USER,
  REFRESH_SECRET_KEYS,
  ACCESS_FLAGS,
  FETCH_REGISTERED_USERS,
  UPDATE_REGISTERED_USER,
  REMOVE_REGISTERED_USER,
  REMOVE_CLIENT
} from '../types/clientTypes';
import ClientService from '../../../services/clients';

export const fetchAllClients = createActionThunk(FETCH_ALL_CLIENTS, (isSubaccount) =>
    ClientService.fetchAllClients(isSubaccount).then((data) => ({ data })),
  false
);

export const fetchClient = createActionThunk(FETCH_CLIENT, (id) =>
    ClientService.fetchClient(id).then((data) => ({ data })),
  false
);

export const createClient = createActionThunk(CREATE_CLIENT, (data) =>
    ClientService.createClient(data).then((data) => ({ data })),
  false
);

export const updateClient = createActionThunk(UPDATE_CLIENT, (id, data) =>
    ClientService.updateClient(id, data).then((data) => ({ data })),
  false
);

export const removeClient = createActionThunk(REMOVE_CLIENT, (id) =>
ClientService.removeClient(id).then((data) => ({ data })),
  false
);

export const fetchSubaccounts = createActionThunk(
  FETCH_SUBACCOUNTS,
  (clientId) =>
    ClientService.fetchSubaccounts(clientId).then((data) => ({
      data
    })),
  false
);

export const fetchSubaccount = createActionThunk(FETCH_SUBACCOUNT, (clientId,subaccountId) =>
    ClientService.fetchSubaccount(clientId, subaccountId).then((data) => ({ data })),
  false
);

export const createSubaccount = createActionThunk(
  CREATE_SUBACCOUNT,
  (clientId, data) =>
    ClientService.createSubaccount(clientId, data).then((data) => ({
      data,
    }))
);

export const updateSubaccount = createActionThunk(
  UPDATE_SUBACCOUNT,
  (clientId, subaccountId, data) =>
    ClientService.updateSubaccount(clientId, subaccountId, data).then(
      (data) => ({
        data
      })
    ),
  false
);

export const removeSubaccount = createActionThunk(
  REMOVE_SUBACCOUNT,
  (clientId, subaccountId) =>
    ClientService.removeSubaccount(clientId, subaccountId).then(
      (data) => ({ data })
    ),
  false
);

export const fetchUsers = createActionThunk(
  FETCH_USERS,
  (clientId,subaccountId) =>
    ClientService.fetchUsers(clientId,subaccountId).then((data) => ({
      data
    })),
  false
);

export const createUser = createActionThunk(
  CREATE_USER,
  (clientId, subaccountId, data) =>
    ClientService.createUser(clientId, subaccountId, data).then((data) => ({
      data,
    }))
);

export const updateUser = createActionThunk(
  UPDATE_USER,
  (clientId, subaccountId, userId, data) =>
    ClientService.updateUser(clientId, subaccountId, userId, data).then(
      (data) => ({
        data
      })
    ),
  false
);

export const removeUser = createActionThunk(
  REMOVE_USER,
  (clientId, subaccountId,  userId) =>
    ClientService.removeUser(clientId, subaccountId, userId).then(
      (data) => ({ data })
    ),
  false
);

export const refreshSecretKeys = createActionThunk(
  REFRESH_SECRET_KEYS,
  (clientId, subaccountId, field) =>
    ClientService.refreshSecretKeys(clientId, subaccountId, field).then(
      (data) => ({ data })
    ),
  false
);

export const accessFlags = createActionThunk(
  ACCESS_FLAGS,
  (clientId, subaccountId, data) =>
    ClientService.accessFlags(clientId, subaccountId, data).then(
      (data) => ({ data })
    ),
  false
);

export const fetchRegisteredUsers = createActionThunk(
  FETCH_REGISTERED_USERS,
  () =>
    ClientService.fetchRegisteredUsers().then((data) => ({
      data
    })),
  false
);

export const updateRegisteredUser = createActionThunk(
  UPDATE_REGISTERED_USER,
  (userId, data) =>
    ClientService.updateRegisteredUser(userId, data).then(
      (data) => ({
        data
      })
    ),
  false
);

export const removeRegisteredUser = createActionThunk(
  REMOVE_REGISTERED_USER,
  (clientId, subaccountId,  userId) =>
    ClientService.removeUser(clientId, subaccountId, userId).then(
      (data) => ({ data })
    ),
  false
);
