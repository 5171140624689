import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';

import ModalBody from './ModalBody';
import { useFirstRender } from '../../../utils/hooks';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { STARTED } from '../../../utils/helpers/store-helpers';
import { newContactFormInitialState } from '../../../utils/helpers';
import { contactCreateStatusSelector, contactUpdateStatusSelector } from '../../../store/selectors/contactSelector';
import { fetchClient } from 'src/store/actions/creators/clientActions';
import { clientSelector } from "src/store/selectors/clientSelector";
import { useDispatch, useSelector } from "react-redux";

const NewContactForm = ({ groups, errors, contact, handleSubmit, handleCloseModal, countries }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const initialState = useMemo(() => newContactFormInitialState, []);

  const contactCreateStatus = useSelector(contactCreateStatusSelector);
  const contactUpdateStatus = useSelector(contactUpdateStatusSelector);
  
  const [inputs, setInputs] = useState(initialState);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const firstRender = useFirstRender();

  const localStorageUser = JSON.parse(localStorage.getItem("authUserData"));
  const sessionStorageUser = JSON.parse(sessionStorage.getItem("authUserData"));

  const storageUser = localStorageUser || sessionStorageUser;

  useEffect(() => {
    dispatch(fetchClient(storageUser.accountId));
  }, []);

  useEffect(() => {
    if (!firstRender && contactCreateStatus) handleCloseModal();
  }, [contactCreateStatus]);

  useEffect(() => {
    if (!firstRender && contactUpdateStatus) handleCloseModal();
  }, [contactUpdateStatus]);
  
  useEffect(() => {
      if (!firstRender) return;
      
      if (_.isEmpty(contact)) setInputs(initialState);
      else setInputs({
        ...initialState,
        ...contact,
        groups: contact.groups.map(({ id: value, name: label }) => ({
          value,
          label,
        })),
      });
  }, []);

  const handleSubmitButtonClick = () => setSubmitClicked(true);

  const handleChange = ({ target: { name, value } }) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit({
      ...inputs,
      groups: inputs.groups.map((group) => group.value),
    });
  };

  const toggleShowAdditionalFields = () => setShowAdditionalFields(!showAdditionalFields);

  const handleChangeGroups = (newValue) => setInputs({ ...inputs, groups: newValue });

  const groupsList = useMemo(() => {
    const groupsList = [];

    for (let group of groups) {
      groupsList.push({
        value: group.id,
        label: group.name,
      });
    }

    return groupsList;
  }, []);

  return (
    <Modal show={true} onHide={handleCloseModal}>
      <Modal.Header closeButton={false}>
        <Modal.Title>
          {inputs.id ? t('MEMBER.CONTACTS.EDIT_CONTACT') : t('MEMBER.CONTACTS.INSERT_CONTACT')}
        </Modal.Title>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={handleCloseModal}
        >
          <span aria-hidden="true">x</span>
        </button>
      </Modal.Header>
      <Form className={'form-sample' + (submitClicked ? ' was-validated' : '')} onSubmit={onSubmit}>
        <ModalBody
          inputs={inputs}
          handleChange={handleChange}
          errors={errors}
          handleChangeGroups={handleChangeGroups}
          toggleShowAdditionalFields={toggleShowAdditionalFields}
          showAdditionalFields={showAdditionalFields}
          groupsList={groupsList}
          countries={countries}
        />
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t('COMMON.CLOSE')}
          </Button>
          <Button variant="primary" className="btn-icon-text" type="submit" onClick={handleSubmitButtonClick}>
            {contactCreateStatus === STARTED || contactUpdateStatus === STARTED ? (
              <Spinner className="mr-2" animation="border" variant="secondary" size="sm"/>
            ) : (
              <i className={(inputs.id ? 'feather-save' : 'feather-plus') + ' btn-icon-prepend'}></i>
            )}
            {inputs.id ? t('COMMON.SAVE') : t('COMMON.INSERT')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NewContactForm;
