import React from "react";
import { Outlet } from "react-router-dom";

import ErrorPage from "../ErrorPage";
import { useSelector } from 'react-redux';
import { userDataSelector } from '../../store/selectors/authSelector';

const Auth = ({ notAllowedRoles = [], allowedRoles = [] }) => {
  const userData = useSelector(userDataSelector);
  
  return notAllowedRoles.length !== 0 ? (
    notAllowedRoles.includes(userData.role)
      ? <ErrorPage title='ERROR.NOT_PERMITTED' />
      : <Outlet />
  ) : allowedRoles.length && (
    allowedRoles.includes(userData.role)
      ? <Outlet />
      : <ErrorPage title='ERROR.NOT_PERMITTED' />
  );
};

export default Auth;
