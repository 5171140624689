import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { 
  loginAsUser
} from '../../../store/actions/creators/authActions';

import ConfirmationModal from '../../ConfirmationModal';
import PerPageCount from '../../Shared/PerPageCount';
import Table from './Table';
import PageControl from '../../Shared/Pagination/PageControl';
import { errorMessageToast, getError, successMessageToast } from '../../../utils/common/helper';

const DirectUserList = ({ users, handleShowModal, removeUser ,isIcon } ) => {
  const [t] = useTranslation();

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchValue, setSearchValue] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [removingUserId, setRemovingUserId] = useState(null);
  
  const searchColumns = useMemo(() => ['login', 'firstName', 'lastName'], []);
  
  const filterFunc = (item) => searchColumns.some((col) => item[col].includes(searchValue));
  
  const handleSearchChange = (e) => {
    const value = e.target.value || '';
    setSearchValue(value);
    setPage(1);
  };

  const changePerPage = (perPage) => {
    if (users.length / perPage < page) setPage(1);
    setPerPage(perPage);
  };

  const decPage = () => {
    if (page > 1) setPage(page - 1);
  };
  const incPage = () => {
    if (page < users.length / perPage) setPage(page + 1);
  };

  const handleChangeSort = (newSortBy) => {
    setSortBy(newSortBy);
    setSortOrder((sortBy === newSortBy && sortOrder === 'asc') ? 'desc' : 'asc');
  };

  const handleShowConfirmModal = (userId) => {
    setRemovingUserId(userId);
    setShowConfirmModal(true);
  };
  const handleRemoveUser = () => {
    removeUser(removingUserId);
    setShowConfirmModal(false);
  };

  const handleLoginAs = async (id) => {
    try{
      await dispatch(loginAsUser(id));
      successMessageToast("Switched to user login."); 
    }catch(error){
      let err = getError(error);
      typeof err == 'string' && errorMessageToast(err);
    }
  }

  const filteredItems = users.filter(filterFunc);
  return (
    <>
      {showConfirmModal &&
        <ConfirmationModal
        showModal={showConfirmModal}
        handleConfirm={handleRemoveUser}
        handleCancel={() => setShowConfirmModal(false)}
        title={t('COMMON.CONFIRM_MODAL_TITLE')}
        description={t('COMMON.CONFIRM_MODAL_DESCRPTION')}
      />}
      <div className="row no-gutters mb-3">
        <div className="col-sm-12 col-md-6">
          <span className="mr-1">{t('ADMIN.CLIENTS.SHOW')}</span>
          <div className="dropdown d-inline-flex">
            <button
              className="btn btn-outline-primary btn-sm dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {perPage}
            </button>
            <PerPageCount changePerPage={changePerPage}/>
          </div>
          <span className="ml-1">{t('ADMIN.CLIENTS.ENTRIES')}</span>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="form-group-sm row">
            <label className="col-sm-5 col-form-label text-right">
              {t('ADMIN.CLIENTS.SEARCH')}:
            </label>
            <div className="col-sm-7">
              <input
                type="text"
                className="form-control form-control-sm"
                value={searchValue}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Table
        sortOrder={sortOrder}
        page={page}
        perPage={perPage}
        sortBy={sortBy}
        handleShowModal={handleShowModal}
        handleShowConfirmModal={handleShowConfirmModal}
        handleChangeSort={handleChangeSort}
        filteredItems={filteredItems}
        handleLoginAs={handleLoginAs}
        isIcon={isIcon}
      />
      <div className="row">
        <div className="col-sm-12 col-md-5">
          <span>
            {t('ADMIN.CLIENTS.SHOWING_START_TO_END_OF_TOTAL_ENTRIES', {
              start: (page - 1) * perPage + 1,
              end: Math.min(page * perPage, filteredItems.length),
              total: filteredItems.length,
            })}
          </span>
        </div>
        <PageControl
          page={page}
          perPage={perPage}
          setPage={setPage}
          decPage={decPage}
          incPage={incPage}
          filteredItems={filteredItems}
        />
      </div>
    </>
  );
};

export default DirectUserList;
