import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Icon from '@mdi/react';
import { mdiArrowDown, mdiArrowUp } from '@mdi/js';
import { getCountryListColumns, sortFunc } from '../../../../utils/helpers';

const CountryTable = ({
  page,
  sortBy,
  perPage,
  sortOrder,
  filteredItems,
  handleChangeSort,
  handleShowEditModal
}) => {
  const [t] = useTranslation();

  const columns = useMemo(() => getCountryListColumns(t), [t]);

  return (
    <div className="row">
      <div className="col-sm-12 mb-2">
        <table className="table table-bordered table-striped table-hover">
          <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.accessor} className="justify-content-between">
                {column.Header}{' '}
                <Link to="#" onClick={() => handleChangeSort(column.accessor)}>
                  {sortBy === column.accessor ?
                    sortOrder === 'desc'
                      ? <Icon path={mdiArrowDown} size={0.6} color="#4B49AC"/>
                      : <Icon path={mdiArrowUp} size={0.6} color="#4B49AC"/>
                    : <Icon path={mdiArrowDown} size={0.6} color="#CCC"/>}
                </Link>
              </th>
            ))}
            <th>{t('COMMON.ACTIONS')}</th>
          </tr>
          </thead>
          <tbody>
          {filteredItems
            .sort(sortFunc(sortBy, sortOrder))
            .slice((page - 1) * perPage, page * perPage)
            .map((row) => (
              <tr key={row.id}>
                {columns.map((column) => {
                  return (
                    <td key={`${row.id}-${column.accessor}`}>
                      {row[column.accessor]}
                    </td>
                  );
                })}
                <td>
                  <Link to="#" className="mr-1" onClick={(e) => {
                    e.preventDefault();
                    handleShowEditModal(row);
                  }}>
                    <i className="feather-edit btn-icon-prepend"></i>
                  </Link>
                  <Link to={`/admin/tools/countries/${row.id}`}>
                    <i className="feather-eye btn-icon-prepend"></i>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CountryTable;