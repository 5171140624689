import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Login from '../../containers/Login';
import Register from '../../containers/Register';
import ResetPassword from '../../containers/ResetPassword';
import ForgotPassword from '../../containers/ForgotPassword';
import VerifyNumber from '../../containers/VerifyNumber';
import VerifyEmail from '../../containers/VerifyEmail';
import Logout from '../../containers/Logout';

const GeneralRoutes = () => {
  return (
    <Routes>
      <Route path='*' element={
        <Navigate
          to={
            (window.location.pathname !== '/register' &&
              window.location.pathname !== '/forgotpassword' &&
              window.location.pathname !== '/resetpassword')
              ? "/login"
              : window.location.href
          }
        />
      } />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/verifyNumber" element={<VerifyNumber />} />
      <Route path="/verifyEmail" element={<VerifyEmail />} />
    </Routes>
  );
};

export default GeneralRoutes;