import MonthlyTrend from '../../../Member/MonthlyTrend';
import SMSByCampaigns from '../../../Member/SMSByCampaigns';
import Last30DaysTrend from '../../../Member/Last30DaysTrend';
import { useSelector } from 'react-redux';
import { monthlyTrendSelector } from '../../../../store/selectors/dashboardSelector';

const MonthlyInfo = ({ last30DaysSMS }) => {
  const monthlyTrend = useSelector(monthlyTrendSelector);

  return (
    <>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="row">
            <div className="col-md-8">
              <Last30DaysTrend last30DaysSMS={last30DaysSMS}/>
            </div>
            <div className="col-md-4">
              <SMSByCampaigns/>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 grid-margin">
          <MonthlyTrend monthlyTrend={monthlyTrend}/>
        </div>
      </div>
    </>
  );
};

export default MonthlyInfo;