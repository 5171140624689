import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ItemsList from "../../ItemsList";
import { getStopManagementListColumns } from "../../../utils/helpers";
import { clientSelector } from "src/store/selectors/clientSelector";
import { useSelector } from "react-redux";
import moment from "moment";

const StopTable = ({ contacts, handleShowModal, removeContact }) => {
  const [t] = useTranslation();
  const client = useSelector(clientSelector);

  const columns = useMemo(() => getStopManagementListColumns(t), [t]);
  const searchColumns = useMemo(() => ["phone", "provider"], []);
  const searchColumnsForMember = useMemo(() => ["phone"], []);

  const filteredColumns = columns.filter(
    ({ accessor }) => accessor !== "provider"
  );

  const contactList = useMemo(
    () =>
      contacts.map((contact) => ({
        ...contact,
        blockedDate: moment(contact.blockedDate).format("DD/MM/YYYY HH:mm"),
      })),
    [contacts]
  );

  const actions = (item, action) =>
    ({
      // delete: true,
      edit: true,
    }[action]);

  return (
    <ItemsList
      items={contactList}
      columns={client.id ? filteredColumns : columns}
      searchColumns={client.id ? searchColumnsForMember : searchColumns}
      actions={actions}
      handleShowModal={handleShowModal}
      removeItem={removeContact}
    />
  );
};

export default StopTable;
