import React, { useMemo, useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import Select from "react-select";
import Tooltip from "../../../Tooltip";
import { Form, Modal, Button } from "react-bootstrap";
import { MESSAGE_MAX_LENGTH } from "../../../../utils/common/constants";
import { groupsSelector } from "../../../../store/selectors/groupSelector";
import { templatesSelector } from "../../../../store/selectors/templateSelector";
import { campaignErrorsSelector } from "../../../../store/selectors/campaignSelector";
import SmsCalculationsInfo from "../../../MemberHome/SingleMessage/SmsCalculationsInfo";
import CreatableSelect from "react-select/creatable";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import Icon from "@mdi/react";
import { mdiEmoticon } from "@mdi/js";
import { isCmtelecomProvider } from "../../../../utils/helpers";
import moment from "moment";

const ModalBody = ({
  inputs,
  setInputs,
  fromOptions,
  localForm,
  setLocalForm,
  handleChange,
  disabledDate,
  dateErr,
  messagePosition,
  setMessagePosition
}) => {
  const [t] = useTranslation();
  const textareaRef = useRef();

  const groups = useSelector(groupsSelector);
  const templates = useSelector(templatesSelector);
  const errors = useSelector(campaignErrorsSelector);

  const [messagesCount, setMessagesCount] = useState(0);
  const [regexError, setRegexError] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const isEmojiAllowed = useMemo(() => isCmtelecomProvider(), []);

  const templatesList = useMemo(() => {
    const templatesList = [];

    for (let template of templates) {
      if (template.status === "Active") {
        templatesList.push({
          value: template.id,
          label: template.name,
        });
      }
    }

    return templatesList;
  }, [templates]);

  const groupsList = useMemo(() => {
    const groupsList = [];

    for (let group of groups) {
      groupsList.push({
        value: group.id,
        label: `${group.name} (${group.contacts.length} contacts)`,
      });
    }

    return groupsList;
  }, [groups]);

  const handleChangeSelect = (newValue, actionMeta) => {
    const name = actionMeta.name;
    setInputs({ ...inputs, [name]: newValue });
  };
  
  const handleChangeFrom = (newValue, actionMeta) => {
    let reg = /^(?=.*[a-zA-Z])(?!.*\s)[a-zA-Z\d]{1,11}$/;

    if (!reg.test(newValue.value)) {
      setRegexError(true);
      setLocalForm("");
      setInputs({
        ...inputs,
        from: null,
      });
    } else {
      setRegexError(false);
      setInputs({
        ...inputs,
        from: newValue.value,
      });
      setLocalForm(newValue);
    }
  };

  const variableList = useMemo(
    () => [
      { value: "firstName", label: "First name" },
      { value: "lastName", label: "Last name" },
      { value: "f1", label: "field 1" },
      { value: "f2", label: "field 2" },
      { value: "f3", label: "field 3" },
      { value: "f4", label: "field 4" },
      { value: "f5", label: "field 5" },
      { value: "f6", label: "field 6" },
      { value: "f7", label: "field 7" },
      { value: "f8", label: "field 8" },
      { value: "f9", label: "field 9" },
    ],
    []
  );

  useEffect(() => {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }, []);

  const handleVariableChange = (event) => {
    let textToInsert = "#" + event.value + " ";
    let cursorPosition = messagePosition || textareaRef.current.selectionEnd;
    setMessagePosition(cursorPosition + textToInsert.length);
    let textBeforeCursorPosition = inputs.message.substring(0, cursorPosition);
    let textAfterCursorPosition = inputs.message.substring(
      cursorPosition,
      inputs.message.length
    );
    let value =
      textBeforeCursorPosition + textToInsert + textAfterCursorPosition;
    handleChange({ target: { name: "message", value: value } }, true);
  };

  const addEmoji = (e) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    let cursorPosition = messagePosition || textareaRef.current.selectionEnd;
    setMessagePosition(cursorPosition + emoji.length);
    let textBeforeCursorPosition = inputs.message.substring(0, cursorPosition);
    let textAfterCursorPosition = inputs.message.substring(
      cursorPosition,
      inputs.message.length
    );
    let value = textBeforeCursorPosition + emoji + textAfterCursorPosition;
    handleChange({ target: { name: "message", value: value } }, true);
    setShowEmojis(!showEmojis);
  };

  useEffect(() => {
    messagePosition &&
      textareaRef.current.setSelectionRange(messagePosition, messagePosition);
  }, [inputs.message]);

  const filterTime = (date) => {
    const getDates = moment(date);
    const fetchDay = getDates.day();
    const formattedTime =
      getDates.hours().toString().length === 1
        ? `0${getDates.hours()}`
        : getDates.hours();

    if (
      fetchDay === 1 ||
      fetchDay === 2 ||
      fetchDay === 3 ||
      fetchDay === 4 ||
      fetchDay === 5
    ) {
      if (
        !(
          `${formattedTime}:${getDates.minute()}` >= "07:59" &&
          `${formattedTime}:${getDates.minute()}` <= "20:00"
        )
      ) {
        return false;
      }
    } else if (fetchDay === 6) {
      if (
        !(
          `${formattedTime}:${getDates.minute()}` >= "09:59" &&
          `${formattedTime}:${getDates.minute()}` <= "20:00"
        )
      ) {
        return false;
      }
    }
    // else if(validateHolidays)
    return true;
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <Form.Group className="form-group mb-3">
            <Form.Label className="required">
              {t("MEMBER.CAMPAIGNS.CAMPAIGN_NAME")}
            </Form.Label>
            <Tooltip
              originalTitle={t("MEMBER.CAMPAIGNS.CAMPAIGN_NAME_TOOL_TIP")}
            />
            <Form.Control
              type="text"
              name="name"
              isInvalid={Boolean(errors?.name)}
              placeholder={t("MEMBER.CAMPAIGNS.CAMPAIGN_NAME")}
              value={inputs.name}
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors?.name && t("COMMON.NAME") + " " + errors?.name}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          {/* <Form.Group className="form-group mb-3">
            <Form.Label>
              {t('MEMBER.CAMPAIGNS.FROM')}{' '}
              <i>{t('MEMBER.CAMPAIGNS.OPTIONAL')}</i>
            </Form.Label>
            <Tooltip
              originalTitle={t('MEMBER.CAMPAIGNS.FROM_TOOL_TIP')}
            />
            <Form.Control
              type="text"
              name="from"
              size="sm"
              value={inputs.from}
              onChange={handleChange}
              isInvalid={errors?.from}
              minLength={2}
              maxLength={11}
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors?.from
                ? `${t('MEMBER.CAMPAIGNS.FROM')} ${errors?.from}`
                : ''}
            </Form.Control.Feedback>
          </Form.Group> */}
          <Form.Group className="form-group mb-3">
            <Form.Label>
              {t("MEMBER.SMS.FROM")} <i>{t("MEMBER.SMS.OPTIONAL")}</i>
            </Form.Label>
            <Tooltip originalTitle={t("MEMBER.SMS.FROM_TOOL_TIP")} />
            <CreatableSelect
              options={fromOptions}
              name="from"
              value={localForm}
              onChange={handleChangeFrom}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.from ? `${t("MEMBER.SMS.FROM")} ${errors?.from}` : ""}
            </Form.Control.Feedback>
            {regexError && (
              <p className="text-danger">
                {t("ADMIN.CLIENTS.FROM_FIELD_REGEX_ERROR")}
              </p>
            )}
          </Form.Group>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <Form.Group className="form-group mb-3">
            <Form.Label>{t("MEMBER.CAMPAIGNS.START_DATE")}</Form.Label>
            <Tooltip
              originalTitle={t("MEMBER.CAMPAIGNS.START_DATE_TOOL_TIP")}
            />
            <Form.Control
              as={DatePicker}
              selected={inputs.startDate}
              onChange={(date) => {
                handleChange({ target: { name: "startDate", value: date } });
              }}
              dateFormat="dd-MM-yyyy HH:mm"
              timeFormat="HH:mm"
              timeIntervals={5}
              showTimeSelect
              filterDate={disabledDate}
              filterTime={filterTime}
              // className='datePicker'
            />
            {dateErr && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {dateErr}
              </Form.Control.Feedback>
            )}
            {errors?.startDate && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {t("MEMBER.CAMPAIGNS.START_DATE")} {errors?.startDate}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <Form.Group className="form-group mb-3">
            <Form.Label>
              {t("MEMBER.CAMPAIGNS.USE_TEMPLATE")}{" "}
              <i>{t("MEMBER.CAMPAIGNS.OPTIONAL")}</i>
            </Form.Label>
            <Tooltip originalTitle={t("MEMBER.CAMPAIGNS.TEMPLATE_TOOL_TIP")} />
            <Form.Select
              className="form-control"
              size="sm"
              name="template"
              value={inputs.template}
              onChange={handleChange}
            >
              <option value="">---</option>
              {templatesList.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
      </div>

      <div className="row">
        <div className="col-md-8">
          <Form.Group className="form-group mb-3">
            <Form.Label className="required">{t("COMMON.MESSAGE")}</Form.Label>
            <Tooltip originalTitle={t("MEMBER.SMS.MESSAGE_TOOL_TIP")} />

            <Form.Control
              ref={textareaRef}
              as="textarea"
              rows={4}
              name="message"
              placeholder={t("COMMON.ENTER...")}
              size="sm"
              onChange={handleChange}
              value={inputs.message}
              isInvalid={errors?.message}
              required
              onMouseUp={(e) => setMessagePosition(e.target.selectionEnd)}
            />
            <Form.Text>
              {inputs.message.length} / {MESSAGE_MAX_LENGTH * messagesCount}(
              {messagesCount}) - {t("MEMBER.SMS.SMS_COUNT_NOTE")}{" "}
              <span className="text-danger">
                {t("MEMBER.SMS.SMS_COUNT_NOTE_RED")}
              </span>
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors?.message
                ? `${t("MEMBER.SMS.MESSAGE_TEXT")} ${errors?.message}`
                : ""}
            </Form.Control.Feedback>
          </Form.Group>

          <SmsCalculationsInfo
            message={inputs.message ? inputs.message + " STOP 36xxx" : ""}
            setMessagesCount={setMessagesCount}
          />
        </div>
        <div className="col-md-4 pt-4">
          <Form.Group className="form-group mb-3">
            <Form.Label className="required">
              {t("MEMBER.SMS.DYNAMIC_FIELD")}
            </Form.Label>
            <Tooltip originalTitle={t("MEMBER.SMS.DYNAMIC_FIELD_TOOL_TIP")} />
            <Select
              options={variableList}
              name="varibles"
              onChange={handleVariableChange}
            ></Select>
          </Form.Group>
          {isEmojiAllowed && (
            <>
              <Button
                className="mx-1 px-2 py-1 mb-1"
                onClick={() => {
                  setShowEmojis(!showEmojis);
                }}
                size="sm"
              >
                <Icon path={mdiEmoticon} size={0.7} color="white" /> Emoji
              </Button>
              <div
                style={{ zIndex: "2", position: "absolute", height: "100px" }}
              >
                {showEmojis && (
                  <Picker
                    data={data}
                    onEmojiSelect={addEmoji}
                    onClickOutside={() =>
                      showEmojis ? setShowEmojis(false) : ""
                    }
                    emojiButtonSize={26}
                    emojiSize={20}
                    maxFrequentRows={1}
                    perLine={14}
                    previewPosition="none"
                    skinTonePosition="search"
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <Form.Group className="form-group mb-3">
            <Form.Label className="required">
              {t("MEMBER.SMS.CHOOSE_GROUP")}
            </Form.Label>
            <Tooltip originalTitle={t("MEMBER.SMS.GROUPS_LIST_TOOL_TIP")} />
            <Select
              options={groupsList}
              name="groups"
              value={inputs.groups}
              onChange={handleChangeSelect}
              isMulti
            ></Select>
            {errors?.groups ? (
              <Form.Control.Feedback type="invalid" className="d-block">
                {t("MEMBER.SMS.GROUPS_LIST") + " " + errors?.groups}
              </Form.Control.Feedback>
            ) : (
              ""
            )}
          </Form.Group>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <Form.Group className="form-group row mb-0">
            <Form.Label className="col-sm-2 col-form-label">
              {t("COMMON.STATUS")}
            </Form.Label>
            <div className="col-sm-3">
              <div className="form-check">
                <Form.Label className="form-check-label">
                  <Form.Control
                    type="radio"
                    className="form-check-input"
                    name="status"
                    defaultValue="Active"
                    checked={inputs.status === "Active"}
                    onChange={handleChange}
                  />
                  {t("COMMON.ACTIVE")}
                  <i className="input-helper"></i>
                </Form.Label>
                {errors?.status ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {t("COMMON.STATUS") + " " + errors?.status}
                  </Form.Control.Feedback>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-check">
                <Form.Label className="form-check-label">
                  <Form.Control
                    type="radio"
                    className="form-check-input"
                    name="status"
                    defaultValue="Inactive"
                    checked={inputs.status === "Inactive"}
                    onChange={handleChange}
                  />
                  {t("COMMON.INACTIVE")}
                  <i className="input-helper"></i>
                </Form.Label>
              </div>
            </div>
          </Form.Group>
        </div>
      </div>

      <div className="row my-3">
        {/* <div className="col-md-4">
          <Form.Group className="form-group mb-3">
            <Form.Label>
              {t('MEMBER.CAMPAIGNS.NO_OF_CONTACTS')}{' '}
              <i>{t('MEMBER.CAMPAIGNS.OPTIONAL')}</i>
            </Form.Label>
            <Form.Control
              type="text"
              name="numContacts"
              size="sm"
              value={inputs.numContacts}
              onChange={handleChange}
              isInvalid={errors?.numContacts}
              minLength={1}
              maxLength={11}
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors?.from
                ? `${t('MEMBER.CAMPAIGNS.NO_OF_CONTACTS')} ${errors?.numContacts}`
                : ''}
            </Form.Control.Feedback>
          </Form.Group>
        </div> */}
        <div className="col-md-4 mt-4">
          <Form.Group className="form-check form-check-flat form-check-primary">
            <Form.Label className="form-check-label">
              <Form.Control
                type="checkbox"
                name="shortUrl"
                className="form-check-input"
                checked={inputs.shortUrl}
                onChange={() =>
                  setInputs({
                    ...inputs,
                    shortUrl: !inputs.shortUrl,
                  })
                }
                isInvalid={errors?.shortUrl}
              />
              {t("MEMBER.SMS.SHORT_URL")}
              <i className="input-helper"></i>
            </Form.Label>
          </Form.Group>
          <Tooltip originalTitle={t("MEMBER.SMS.SHORT_URL_TOOL_TIP")} />
        </div>
        <div className="col-sm-3 mt-4">
          {inputs.shortUrl && (
            <>
              <Form.Group className="form-check form-check-flat form-check-primary">
                <Form.Label className="form-check-label">
                  <Form.Control
                    type="checkbox"
                    name="trackUrl"
                    className="form-check-input"
                    checked={inputs.trackUrl}
                    onChange={() =>
                      setInputs({
                        ...inputs,
                        trackUrl: !inputs.trackUrl,
                      })
                    }
                    isInvalid={errors?.trackUrl}
                  />
                  {t("MEMBER.SMS.TRACK_URL")}
                  <i className="input-helper"></i>
                </Form.Label>
              </Form.Group>
              <Tooltip originalTitle={t("MEMBER.SMS.TRACK_URL_TOOL_TIP")} />
            </>
          )}
        </div>
      </div>
      {Boolean(errors?.data) && (
        <div className="row">
          <div className="col-md-12">
            <div className="invalid-feedback d-sm-inline-block">
              {errors?.data}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalBody;
