/**
 * Service for Providers
 */
import useApi from '../utils/api';

class ProviderService {
  #prefix = '/provider';
  
  fetchAllProviders = () => {
    return useApi.get(this.#prefix);
  };

  fetchProvider= (id) => {
    return useApi.get(`${this.#prefix}/${id}`);
  };

}

export default new ProviderService();