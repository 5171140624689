import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CheckboxesRow = ({ inputs, setInputs, }) => {
  const [t] = useTranslation();

  return (
    <div className="row">
      <div className="col-md-6">
        <Form.Group className="form-check form-check-flat form-check-primary mb-3">
          <Form.Label className="form-check-label">
            <Form.Control
              type="checkbox"
              name="sendEmailVerification"
              className="form-check-input"
              checked={inputs.sendEmailVerification}
              onChange={() => setInputs({
                ...inputs,
                sendEmailVerification: !inputs.sendEmailVerification
              })}
            />
            {t('ADMIN.CLIENTS.SEND_EMAIL_FOR_VERIFICATION')}
            <i className="input-helper"></i>
          </Form.Label>
        </Form.Group>
      </div>
      <div className="col-md-6">
        <Form.Group className="form-check form-check-flat form-check-primary mb-3">
          <Form.Label className="form-check-label">
            <Form.Control
              type="checkbox"
              name="autoGeneratePassword"
              className="form-check-input"
              checked={inputs.autoGeneratePassword}
              onChange={() => setInputs({
                ...inputs,
                autoGeneratePassword: !inputs.autoGeneratePassword
              })}
            />
            {t('ADMIN.CLIENTS.AUTOMATICALLY_GENERATE_PASSWORD')}
            <i className="input-helper"></i>
          </Form.Label>
        </Form.Group>
      </div>
    </div>
  );
};

export default CheckboxesRow;