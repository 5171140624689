import React from 'react';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

const MonthlyTrend = ({ monthlyTrend, campaignName }) => {
  const [t] = useTranslation();

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      filler: {
        drawTime: 'beforeDatasetDraw',
        propagate: true,
      },
      tooltip: {
        enabled: true,
      },
      title: {
        display: false,
      },
    },
    scales: {
      xAxis: {
        display: true,
      },
    },
    radius: 1,
    interaction: {
      intersect: false,
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
  };

  const labels = Object.keys(monthlyTrend).map((yearMonth) => moment(yearMonth).format('MMMM'));

  const chartData = {
    labels,
    datasets: [
      {
        label: t('DASHBOARD.SENT'),
        data: Object.values(monthlyTrend),
        borderColor: 'rgb(40, 167, 69)',
        backgroundColor: 'rgba(40, 167, 69, 0.5)',
        fill: 'origin',
      },
    ],
  };

  return (
    <>
      <div className="card h-100">
        <div className="card-body">
          <h4 className="card-title">
            {campaignName == 'all' || !campaignName ? t('DASHBOARD.MONTHLY_TREND_(ALL_CAMPAIGNS)') : t('DASHBOARD.MONTHLY_TREND_') + campaignName}
          </h4>
          <div className="chart-wrapper monthly-campaigns">
            <Line options={chartOptions} data={chartData}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default MonthlyTrend;
