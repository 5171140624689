export const clientSelector = (state) => state.client.client;
export const clientsSelector = (state) => state.client.clients;
export const clientErrorsSelector = (state) => state.client.errors;
export const clientFetchStatusSelector = (state) => state.client.clientFetchStatus;
export const clientCreateStatusSelector = (state) => state.client.clientCreateStatus;
export const clientUpdateStatusSelector = (state) => state.client.clientUpdateStatus;
export const clientsFetchStatusSelector = (state) => state.client.clientsFetchStatus;

export const subaccountsSelector = (state) => state.client.subaccounts;
export const subaccountSelector = (state) => state.client.subaccount;
export const subaccountFetchStatusSelector = (state) => state.client.subaccountFetchStatus;
export const subaccountsFetchStatusSelector = (state) => state.client.subaccountsFetchStatus;
export const subaccountCreateStatusSelector = (state) => state.client.subaccountCreateStatus;
export const subaccountUpdateStatusSelector = (state) => state.client.subaccountUpdateStatus;

export const usersSelector = (state) => state.client.users;
export const usersFetchStatusSelector = (state) => state.client.usersFetchStatus;
export const userCreateStatusSelector = (state) => state.client.userCreateStatus;
export const userUpdateStatusSelector = (state) => state.client.userUpdateStatus;

export const registeredUsersSelector = (state) => state.client.registeredUsers;
export const registeredUsersFetchStatusSelector = (state) => state.client.registeredUsersFetchStatus;
export const registeredUsersUpdateStatusSelector = (state) => state.client.registeredUsersUpdateStatus;