import { createActionThunk } from 'redux-thunk-actions';

import {   
    CREATE_HOLIDAY,
    UPDATE_HOLIDAY,
    FETCH_HOLIDAY_BY_ACCOUNT,
    REMOVE_HOLIDAY,
    GET_HOLIDAYS_FOR_VALIDATION
} from '../types/holidayType';
import HolidayService from '../../../services/holiday';

export const createHoliday = createActionThunk(CREATE_HOLIDAY, (data) =>
    HolidayService.createHoliday(data).then((data) => ({ data }))
  , false
);

export const fetchHolidaysByAccount = createActionThunk(FETCH_HOLIDAY_BY_ACCOUNT, () =>
HolidayService.fetchHolidaysByAccount().then(res => res)
  , false
);

export const updateHoliday = createActionThunk(UPDATE_HOLIDAY, (id, data) =>
  HolidayService.updateHoliday(id, data).then((data) => ({ data }))
  , false
);

export const removeHoliday = createActionThunk(REMOVE_HOLIDAY, (id) =>
HolidayService.removeHoliday(id).then((data) => ({ data })),
  false
);

export const getHolidaysForValidation = createActionThunk(GET_HOLIDAYS_FOR_VALIDATION, () =>
HolidayService.getHolidaysForValidation().then(res => res)
  , false
);
