import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Alert } from 'react-bootstrap';

const SuccessAlert = ({ setShowSuccessAlert }) => {
  const [t] = useTranslation();
  
  return (
    (
      <Alert variant="success">
        <button
          type="button"
          className="close"
          aria-label="Close alert"
          onClick={() => setShowSuccessAlert(false)}
        >
          <span aria-hidden="true" className="font-weight-normal">x</span>
        </button>
        <Alert.Heading className="font-weight-bold">
          {t('MEMBER.SMS.MESSAGES_SENT_TO_QUEUE')}
        </Alert.Heading>
        <p>
          <Link to="/member/statuses" className="alert-link">
            {t('COMMON.CLICK_HERE')}
          </Link>{' '}
          {t('MEMBER.SMS.TO_CHECK_THE_STATUS')}
        </p>
      </Alert>
    )
  );
};

export default SuccessAlert;