import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import _ from 'lodash';
import moment from 'moment';

import Icon from '@mdi/react';
import { mdiCalendarMonth } from '@mdi/js';
import exportFromJSON from 'export-from-json';
import { Button, Form } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import { smsListSelector } from '../../../../store/selectors/smsSelector';
import { fetchSmsStatuses } from '../../../../store/actions/creators/smsActions';

const ToolBar = ({ setTmpSmsList, tmpSmsList, clientList }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const localStorageUser = JSON.parse(localStorage.getItem("authUserData"));
  const sessionStorageUser = JSON.parse(sessionStorage.getItem("authUserData"));

  const storageUser = localStorageUser || sessionStorageUser;
  
  const [searchParams, setSearchParams] = useSearchParams()

  const smsList = useSelector(smsListSelector);

  const now = useMemo(() => new Date(new Date().setHours(0,0,0,0)), []);

  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [filterStatus, setFilterStatus] = useState(_.upperCase(searchParams.get("status")));
  const [endDate, setEndDate] = useState(new Date( searchParams.get("endDate") || now.setFullYear(now.getFullYear() + 1)));
  const [startDate, setStartDate] = useState(new Date(searchParams.get("startDate") || now.setFullYear(now.getFullYear() - 1)));
  const [campaignId, setCampaignId] = useState(searchParams.get("campaignId"));
  const [statusList, setStatusList] = useState([]);

  const [clientId, setClientId] = useState('all');

  const handleClientFilter = (event) => {
    const value = event.target.value;
    setClientId(value);
    dispatch(fetchSmsStatuses(value));
  }
  
  useEffect(() => {
    if (smsList?.length) {
      setStatusList(_.uniq(smsList.map(({ status }) => status)));
      
      const tmpList = _.filter(
        smsList,
        (smsStatus) => {
          // if (!filterStatus) {
          //   return true;
          // }
          let fill = !filterStatus ? true : filterStatus === smsStatus.status;
          fill &&= !campaignId ? true : campaignId == smsStatus.campaignId;
          fill &&= moment(smsStatus.sendDt).isSameOrAfter(startDate);
          fill &&= moment(smsStatus.sendDt).isSameOrBefore(endDate) || moment(smsStatus.sendDt).isSame(endDate, 'day');
          return fill;
        }
      ).map((smsStatus) => ({
        ...smsStatus,
        length: smsStatus.message.length,
        sendDt: moment(smsStatus.sendDt).format('DD/MM/YYYY HH:mm'),
      }));
      
      setTmpSmsList([...tmpList]);
    }else{
      setTmpSmsList([])
    }
  }, [smsList, filterStatus, startDate, endDate]);
 
  const toggleDateRangePicker = () => {
    setShowDateRangePicker(!showDateRangePicker);
  };

  const handleChangeStatus = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleSelectDateRange = ({ selectedDateRange }) => {
    setEndDate(new Date(new Date(selectedDateRange.endDate).setHours(23,59,59,59)));
    setStartDate(selectedDateRange.startDate);
  };

  const handleExport = (data, type) => {
    const fileName = `SMSREFLEX_reports_${storageUser.accountId}_${filterStatus}_${moment(startDate).format(
      'MMDDYYYY'
    )}_${moment(endDate).format('MMDDYYYY')}`;

    exportFromJSON({ data, fileName, exportType: exportFromJSON.types[type] });
  };

  return (
    <div className="row mb-3">
      <div className="col-md-12">
        <Form.Label className="pt-2 mr-4">
          {t('MEMBER.REPORTS.PERIOD')}
        </Form.Label>
        <Icon
          style={{ marginRight: '-39px' }}
          path={mdiCalendarMonth}
          size={1}
          horizontal
          color="#8E54E9"
          onClick={toggleDateRangePicker}
        />
        <Form.Control
          className="d-inline-flex w-25 pl-5 mr-3"
          type="text"
          value={
            moment(startDate).format('DD/MM/YYYY') +
            '-' +
            moment(endDate).format('DD/MM/YYYY')
          }
          onChange={(e) => {
            e.preventDefault();
          }}
          onClick={toggleDateRangePicker}
        />
        <div
          className={
            'date-range-picker ' +
            (showDateRangePicker ? 'd-block' : 'd-none')
          }
        >
          <DateRangePicker
            ranges={[
              {
                startDate: startDate,
                endDate: endDate,
                key: 'selectedDateRange',
              },
            ]}
            onChange={handleSelectDateRange}
          />
        </div>
        <Form.Label className="pt-2 mr-3">
          {t('COMMON.STATUS')}
        </Form.Label>
        <Form.Select
          className="form-control w-15 d-inline-flex form-control-sm mr-3"
          name="filterStatus"
          value={filterStatus}
          onChange={handleChangeStatus}
        >
          <option value="">All statuses</option>
          {statusList.map((status, id) => (
            <option key={id} value={status}>
              {status}
            </option>
          ))}
        </Form.Select>
        <Form.Label className="pt-2 mr-3">
            {t('ADMIN.REPORTS.FILTER_BY_CLIENT')}
        </Form.Label>
        <Form.Select
            className="form-control w-15 d-inline-flex form-control-sm mr-1"
            value={clientId}
            onChange={handleClientFilter}
            >
            <option key='all' value="">All Clients</option>
            {clientList.map((item) => (
                <option key={item.value} value={item.value}>
                    {item.label}
                </option>
            ))}
        </Form.Select>
        <Button
          variant="primary"
          size="md"
          className="btn-icon-text mr-3"
          onClick={() => handleExport(tmpSmsList, 'csv')}
        >
          <i className="feather-download btn-icon-prepend"></i>
          {t('MEMBER.REPORTS.EXPORT_CSV')}
        </Button>
        <Button
          variant="primary"
          size="md"
          className="btn-icon-text"
          onClick={() => handleExport(tmpSmsList, 'xls')}
        >
          <i className="feather-download btn-icon-prepend"></i>
          {t('MEMBER.REPORTS.EXPORT_XLS')}
        </Button>
      </div>
    </div>
  );
};

export default ToolBar;