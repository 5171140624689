import { getError } from "../../utils/common/helper";
import {
  FAILED,
  getFailed,
  getStarted,
  getSucceeded,
  STARTED,
  SUCCEEDED,
} from "../../utils/helpers/store-helpers";
import {
  CREATE_HOLIDAY,
  FETCH_HOLIDAY_BY_ACCOUNT,
  GET_HOLIDAYS_FOR_VALIDATION,
  REMOVE_HOLIDAY,
  UPDATE_HOLIDAY,
} from "../actions/types/holidayType";

// Initial state
const initialState = {
  errors: null,
  holidays: [],
  holiday: {},

  holidayCreateStatus: "",
  holidayFetchStatus: "",
  HolidayUpdateStatus: "",
  holidayRemoveStatus: "",
  holidayValidationFetchStatus: "",
};

// holiday reducer
const events = (state = initialState, { type, payload }) => {
  switch (type) {
    case getStarted(CREATE_HOLIDAY):
      return { ...state, holidayCreateStatus: STARTED, errors: null };
    case getFailed(CREATE_HOLIDAY):
      return {
        ...state,
        holidayCreateStatus: FAILED,
        errors: getError(payload),
      };
    case getSucceeded(CREATE_HOLIDAY):
      return {
        ...state,
        holidays: state.holidays.concat([payload.data]),
        holidayCreateStatus: SUCCEEDED,
        errors: null,
      };

    case getStarted(FETCH_HOLIDAY_BY_ACCOUNT):
      return { ...state, holidayFetchStatus: STARTED };
    case getFailed(FETCH_HOLIDAY_BY_ACCOUNT):
      return { ...state, holidayFetchStatus: FAILED };
    case getSucceeded(FETCH_HOLIDAY_BY_ACCOUNT):
      return { ...state, holidays: payload, holidayFetchStatus: SUCCEEDED };

    case getStarted(UPDATE_HOLIDAY):
      return { ...state, HolidayUpdateStatus: STARTED, errors: null };
    case getFailed(UPDATE_HOLIDAY):
      return {
        ...state,
        HolidayUpdateStatus: FAILED,
        errors: getError(payload),
      };
    case getSucceeded(UPDATE_HOLIDAY):
      return {
        ...state,
        holidays: state.holidays.map((holiday) =>
          holiday.id === payload.data.id ? payload.data : holiday
        ),
        HolidayUpdateStatus: SUCCEEDED,
        errors: null,
      };

    case getStarted(REMOVE_HOLIDAY):
      return { ...state, holidayRemoveStatus: STARTED, errors: null };
    case getFailed(REMOVE_HOLIDAY):
      return {
        ...state,
        holidayRemoveStatus: FAILED,
        errors: getError(payload),
      };
    case getSucceeded(REMOVE_HOLIDAY): {
      return {
        ...state,
        holidays: state.holidays.filter(
          (holiday) => holiday.id !== payload.data.id
        ),
        holidayRemoveStatus: SUCCEEDED,
        errors: null,
      };
    }

    case getStarted(GET_HOLIDAYS_FOR_VALIDATION):
      return { ...state, holidayValidationFetchStatus: STARTED };
    case getFailed(GET_HOLIDAYS_FOR_VALIDATION):
      return { ...state, holidayValidationFetchStatus: FAILED };
    case getSucceeded(GET_HOLIDAYS_FOR_VALIDATION):
      return {
        ...state,
        holidays: payload,
        holidayValidationFetchStatus: SUCCEEDED,
      };

    default:
      return state;
  }
};

export default events;
