/**
 * Service for Campaigns
 */
import useApi from '../utils/api';

class CampaignService {
  #prefix = '/campaigns';
  
  fetchCampaigns = () => {
    return useApi.get(this.#prefix);
  };

  fetchCampaign = (id) => {
    return useApi.get(`${this.#prefix}/${id}`);
  };

  createCampaign = (data) => {
    return useApi.post(this.#prefix, data);
  };

  updateCampaign = (id,data) => {
    return useApi.put(`${this.#prefix}/${id}`, data);
  };

  removeCampaign = (id) => {
    return useApi.delete(`${this.#prefix}/${id}`);
  };
}

export default new CampaignService();
