import { mdiEmail, mdiThumbDownOutline, mdiThumbUpOutline, mdiTimerSand } from '@mdi/js';
import smsSentToday from '../../../../assets/images/sms_sent_today.svg';
import allSmsSentToday from '../../../../assets/images/all_sms_today.svg';
import smsDeliveredToday from '../../../../assets/images/sms_delivered_today.svg';
import smsFailedToday from '../../../../assets/images/sms_failed_today.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoColumn from './InfoColumn';
import moment from 'moment';

const GeneralStatistics = ({ todayAllSMS, todaySMS }) => {
  const [t] = useTranslation();
  
  return (
    <div className="row">
      <div className="col-12 grid-margin">
        <div className="row">
          <InfoColumn 
           lg={3}
           iconPath={allSmsSentToday}
           classNameSuffix="mb-3 mb-lg-0"
           count={todayAllSMS}
           text={t('DASHBOARD.ALL_SMS_SENT_TODAY')}
           redirect={`/admin/reports?startDate=${moment().format('YYYY/MM/DD')}&endDate=${moment().format('YYYY/MM/DD')}`}
          />        
          <InfoColumn 
           lg={3}
           iconPath={smsSentToday}
           iconColor="#0276f8"
           classNameSuffix="mb-3 mb-lg-0"
           count={todaySMS.delivering?.length}
           text={t('DASHBOARD.SMS_SENT_TODAY')}
           redirect={`/admin/reports?startDate=${moment().format('YYYY/MM/DD')}&endDate=${moment().format('YYYY/MM/DD')}&status=delivering`}
          />
          <InfoColumn 
           lg={3}
           iconPath={smsDeliveredToday}
           iconColor="#46a146"
           classNameSuffix="mb-3 mb-lg-0"
           count={todaySMS.delivered?.length}
           text={t('DASHBOARD.SMS_DELIVERED_TODAY')}
           redirect={`/admin/reports?startDate=${moment().format('YYYY/MM/DD')}&endDate=${moment().format('YYYY/MM/DD')}&status=delivered`}
          />
          <InfoColumn 
            lg={3}
            iconPath={smsFailedToday}
            iconColor="#ff2121"
            count={todaySMS.failed?.length}
            text={t('DASHBOARD.SMS_FAILED_TODAY')}
            redirect={`/admin/reports?startDate=${moment().format('YYYY/MM/DD')}&endDate=${moment().format('YYYY/MM/DD')}&status=failed`}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralStatistics;
