export const holidaysSelector = (state) => state.holiday.holidays;
export const holidaySelector = (state) => state.holiday.holiday;
export const holidayErrorsSelector = (state) => state.holiday.errors;
export const holidayFetchStatusSelector = (state) =>
  state.holiday.holidayFetchStatus;
export const holidayCreateStatusSelector = (state) =>
  state.holiday.holidayCreateStatus;
export const HolidayUpdateStatusSelector = (state) =>
  state.holiday.HolidayUpdateStatus;
export const holidayValidationFetchStatusSelector = (state) =>
  state.holiday.holidayValidationFetchStatus;
