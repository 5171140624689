import { createActionThunk } from 'redux-thunk-actions';

import {
    FETCH_ALL_PROVIDERS,
    FETCH_PROVIDER,
    CREATE_PROVIDER,
    UPDATE_PROVIDER,
    REMOVE_PROVIDER,
} from '../types/providerTypes';
import ProviderService from '../../../services/provider';

export const fetchAllProviders = createActionThunk(FETCH_ALL_PROVIDERS, () =>
    ProviderService.fetchAllProviders().then(res => res)
  , false
);

export const fetchProvider = createActionThunk(FETCH_PROVIDER, (id) =>
    ProviderService.fetchProvider(id).then((data) => ({ data })),
  false
);

