import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Morris } from 'morris.js06';

import { dashboardDataFetchStatusSelector, smsByCampaignsSelector } from '../../../store/selectors/dashboardSelector';

const SMSByCampaigns = ( {campaignName}) => {
  const [t] = useTranslation();

  const dashboardDataFetched = useSelector(dashboardDataFetchStatusSelector);
  const smsByCampaigns = useSelector(smsByCampaignsSelector);

  // TODO check it
  useEffect(() => {
    if (!dashboardDataFetched) return;

    const SMSByCampaignChart = new Morris.Donut({
      element: 'SMSByCampaignChart',
      data: smsByCampaigns,
    });
  }, [dashboardDataFetched]);

  return (
    <div className="card h-100 mb-0">
      <div className="card-body">
        <h4 className="card-title">
          {campaignName == 'all' || !campaignName ? t('DASHBOARD.SMS_BY_CAMPAIGNS') : t('DASHBOARD.SMS_BY_CAMPAIGN_') + campaignName}
        </h4>
        <div className="morris-wrapper">
          <div id="SMSByCampaignChart"></div>
        </div>
      </div>
    </div>
  );
};

export default SMSByCampaigns;
