import axios from 'axios';

import { getStoredAuthToken } from './authToken';
import i18next from 'i18next';

let timer = null;

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api` || 'https://dev-app.smsreflex.com/api',
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Access-Control-Allow-Origin': '*',
  },
});

api.interceptors.request.use((config) => {
  try{
    const access_token = getStoredAuthToken();
    if (access_token && config.headers) config.headers['Authorization'] = `Bearer ${access_token}`;
    config.headers['Accept-Language'] = i18next.language.includes('fr') ? 'fr' : 'en';
    return config;
  }catch(err){
     console.log(err);
  }
});

api.interceptors.response.use(({ data }) => {
  if(timer != null){
    window.clearTimeout(timer); 
    timer = window.setTimeout(logoutInactive, 30 * 60 * 1000);
  }else{
    timer = window.setTimeout(logoutInactive, 30 * 60 * 1000);
  }
  return data;
});

const logoutInactive = ()=> {
  return window.location.href = '/logout'
}

export default api;