import React, { useMemo } from 'react';

import NavigationLink from '../../Shared/Navbar/NavigationLink';
import { getAuthorizedRoutes, getGeneralRoutes, getUnauthorizedRoutes } from '../../../utils/helpers';

const NavigationBar = ({ logout, token }) => {
  const generalRoutes = useMemo(() => getGeneralRoutes(), []);
  const unauthorizedRoutes = useMemo(() => getUnauthorizedRoutes(), []);
  const authorizedRoutes = useMemo(() => getAuthorizedRoutes(logout), []);

  return (
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      {/* <ul className="navbar-nav mr-lg-2">
        {generalRoutes.map((el) => <NavigationLink key={el.name} name={el.name} to={el.to}/>)}
      </ul> */}
      <ul className="navbar-nav navbar-nav-right">
        {!token
          ? unauthorizedRoutes.map((el) => <NavigationLink key={el.name} name={el.name} to={el.to}/>)
          : authorizedRoutes.map((el) => (
            <NavigationLink key={el.name} name={el.name} to={el.to} onClick={el.onClick}/>
          ))
        }
      </ul>
    </div>
  );
};

export default NavigationBar;