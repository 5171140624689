import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{
          margin: 'auto',
          width: '100%',
          maxWidth: '1200px',
          padding: '20px',
          display: 'flex',
          alignItems: 'center',
          gap: '30px',
          minHeight: '200px'
        }}>
          <h2>Error: {}</h2>
          <details style={{ whiteSpace: 'pre-line' }}>
            {this.state.error && this.state.error.toString()}
          </details>
        </div>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;