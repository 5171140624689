import {
  FETCH_GROUPS,
  CREATE_GROUP,
  UPDATE_GROUP,
  REMOVE_GROUP,
} from '../actions/types/groupTypes';
import { getError } from '../../utils/common/helper';
import { FAILED, getFailed, getStarted, getSucceeded, STARTED, SUCCEEDED } from '../../utils/helpers/store-helpers';

// Initial state
const initialState = {
  groups: [],
  errors: null,
  groupFetchStatus: '',
  groupCreateStatus: '',
  groupUpdateStatus: '',
  groupRemoveStatus: '',
};

// Group reducer
const events = (state = initialState, { type, payload }) => {
  switch (type) {
    case getStarted(FETCH_GROUPS):
      return { ...state, groupFetchStatus: STARTED };
    case getFailed(FETCH_GROUPS):
      return { ...state, groupFetchStatus: FAILED };
    case getSucceeded(FETCH_GROUPS):
      return { ...state, groups: payload, groupFetchStatus: SUCCEEDED };
    case getStarted(CREATE_GROUP):
      return { ...state, groupCreateStatus: STARTED, errors: null };
    case getFailed(CREATE_GROUP):
      return { ...state, groupCreateStatus: FAILED, errors: getError(payload) };
    case getSucceeded(CREATE_GROUP):
      return { ...state, groups: state.groups.concat([payload.data]), groupCreateStatus: SUCCEEDED, errors: null };
    case getStarted(UPDATE_GROUP):
      return { ...state, groupUpdateStatus: STARTED, errors: null };
    case getFailed(UPDATE_GROUP):
      return { ...state, groupUpdateStatus: FAILED, errors: getError(payload) };
    case getSucceeded(UPDATE_GROUP):
      return {
        ...state,
        groups: state.groups.map((group) =>
          group.id === payload.data.id ? payload.data : group
        ),
        groupUpdateStatus: SUCCEEDED,
        errors: null,
      };
    case getStarted(REMOVE_GROUP):
      return { ...state, groupRemoveStatus: STARTED, errors: null };
    case getFailed(REMOVE_GROUP): 
      return { ...state, groupRemoveStatus: FAILED, errors: getError(payload) };
    case getSucceeded(REMOVE_GROUP): {
      return {
        ...state,
        groups: state.groups.filter(
          (group) => group.id !== payload.data.id
        ),
        groupRemoveStatus: SUCCEEDED,
        errors: null,
      };
    }
    default:
      return state;
  }
};

export default events;
